import styled from 'styled-components';
import { TemplatePreset, SocialAccountIconTypeEnum } from 'graphqlQueries';

interface StyleProps {
  styles?: TemplatePreset;
  type?: SocialAccountIconTypeEnum | null
}

export const Container = styled.div<StyleProps>(
  ({ styles }) => `
  width: 100%;
  background-color: ${styles?.socialsContainer?.backgroundColor || 'transparent'};
  margin-top: ${styles?.socialsContainer?.margin?.top || 0}px;
  margin-bottom: ${styles?.socialsContainer?.margin?.bottom || 0}px;
  
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: ${styles?.socialsContainer?.padding?.top || 0}px;
  padding-bottom: ${styles?.socialsContainer?.padding?.bottom || 0}px;
  box-sizing: border-box;
  flex-wrap: wrap;

  &::-webkit-scrollbar {
    display: none;
  }

  &.bottom {
    margin-top: 16px;
    margin-bottom: 16px;
  }
`);
export const ContainerInner = styled.div<StyleProps>(
  ({ styles }) => `
  max-width: ${styles?.socialsContainer?.maxWidth ? `${styles?.socialsContainer?.maxWidth}px` : "100%"};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`);

export const SocialLink = styled.a<StyleProps>(
  ({ styles, type }) => `
  height: ${type === SocialAccountIconTypeEnum?.Color ? '30' : '25'}px;
  border-radius: 6px;
  margin-right: ${(styles?.socialsContainer?.gap || 10) / 2}px;
  margin-left: ${(styles?.socialsContainer?.gap || 10) / 2}px;
  margin-top: 5px;
  margin-bottom: 5px;
`);

export const SocialImage = styled.img<StyleProps>(
  ({ styles, type }) => `
  width: ${type === SocialAccountIconTypeEnum?.Color ? '30' : '25'}px;
  height: ${type === SocialAccountIconTypeEnum?.Color ? '30' : '25'}px;
`);




