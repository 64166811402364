import styled, { css } from 'styled-components';

export const H1 = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: 54px;
  font-weight: ${({ theme }) => theme.fontWeight.extraBold};
  line-height: 1.18;
`;

export const H2 = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.title};
  font-weight: ${({ theme }) => theme.fontWeight.extraBold};
  line-height: 1.33;
`;

export const H3 = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 1.33;
`;

export const H4 = styled.h4`
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 1.5;
`;

export const Body1 = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body1};
  line-height: 1.5;
`;

export const Body2 = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 1.57;
`;

export const Body3 = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 1.67;
`;

export const Emphasized = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const DangerText = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.danger};
`;

export const ButtonTextMixin = css`
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 1.5;
`;

export const LinkTextMixin = css`
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 1.57;
  color: ${({ theme }) => theme.colors.accentMainColor};
`;

export const LabelTextMixin = css`
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: 13px;
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 1.69;
`;

export const InputTextMixin = css`
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.size.body2};
  line-height: 1.57;
`;

export const InputBoldTextMixin = css`
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.body1};
  line-height: 1.5;
`;

export const ErrorTextMixin = css`
  font-size: ${({ theme }) => theme.size.caption};
  color: ${({ theme }) => theme.colors.danger};
  line-height: 1.5;
`;

export const TooltipMixin = css`
  max-width: 300px;

  h3 {
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    font-size: ${({ theme }) => theme.size.body2};
    margin: 0 0 3px 0;
  }
  p {
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-size: ${({ theme }) => theme.size.body2};
    margin: 0;
  }
`;

export const MultilineOverflowMixin = css`
  overflow: hidden;
  position: relative; 
  line-height: 1.15em;
  max-height: 35px; 
  margin-right: -1em;
  padding-right: 1em;

  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white;
  }
`;
