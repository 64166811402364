import React, { FC } from 'react';
import TooltipComponent from 'rc-tooltip';
import { TooltipProps } from 'rc-tooltip/lib/Tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { QuestionMark } from 'assets/svg';
import overlayStyles from './styles';
import { useSSRContext } from '../../context/SSRContext';

interface Props extends Omit<TooltipProps, 'overlay'> {
  text: (() => React.ReactNode) | React.ReactNode;
}

const DEFAULT_TRIGGER = ['click', 'hover'];
const DEFAULT_PLACEMENT = 'top';

const Tooltip: FC<Props> = ({
  placement = DEFAULT_PLACEMENT,
  text,
  trigger = DEFAULT_TRIGGER,
  ...rest
}) => {
  const { isOnServer } = useSSRContext();
  if (isOnServer) {
    return null;
  }
  return (
    // Wrap inner reactHtmlContent with span, otherwise tooltip will be not shown
    <TooltipComponent
      overlay={text}
      overlayStyle={overlayStyles}
      placement={placement}
      trigger={trigger}
      {...rest}
    >
      <span>
        <QuestionMark />
      </span>
    </TooltipComponent>
  );
};

export default Tooltip;
