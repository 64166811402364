const getError = (errorText?: string | null) => {
  switch (errorText) {
    case 'Email already exist':
      return {
        message: 'This email is already linked with the LinkTree username.',
        showDisclaimer: true,
        showName: false,
      };
    case 'Username already exist':
      return {
        message:
          'This username is already linked with the email. Please log in.',
        showDisclaimer: false,
        showName: true,
      };
    case 'User with this email already exist':
      return {
        message: 'This email address is already in use.',
        showDisclaimer: true,
        showName: false,
      };
    default:
      return {
        message: 'Some error has occured.',
        showDisclaimer: true,
        showName: false,
      };
  }
};

export default getError;
