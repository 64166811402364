import window from 'global';
import { LinkTypes, getLinkType } from 'helpers/LinkItem';
import React from 'react';
import { Link } from 'graphqlQueries';

const useUpgradeTracking = ({ countImpressions }) => {
  const SESSION_IMPRESSION_ITEMS_KEY = '__upgradeImpressionItems';


  interface IUpgradeData {
    isInitialized: boolean;
    settingImpressions: boolean;
    newImpressionItems: Link[];
    impressionItems: string[];
    allItems: Link[];
    pageItems: Link[];
    impressionsRequestIntervalId: NodeJS.Timeout | number | undefined;
    IMPRESSION_REQUEST_INTERVAL: number;
    username: string;
  }

  const upgradeData: IUpgradeData = {
    isInitialized: false,
    settingImpressions: false,
    newImpressionItems: [],
    impressionItems: [],
    allItems: [],
    pageItems: [],
    impressionsRequestIntervalId: undefined,
    IMPRESSION_REQUEST_INTERVAL: 2500,
    username: '',
  };

  const getSettingImpressionsLock = () => {
    return upgradeData.settingImpressions;
  };

  const setSettingImpressionsLock = (val) => {
    upgradeData.settingImpressions = val;
  };

  const getNewImpressionItems = () => {
    return upgradeData.newImpressionItems;
  };

  const setNewImpressionItems = (val) => {
    return (upgradeData.newImpressionItems = val);
  };

  const getImpressionItems = () => {
    return upgradeData.impressionItems;
  };

  const setImpressionItems = (val) => {
    return (upgradeData.impressionItems = val);
  };

  const setAllItems = (val) => {
    return (upgradeData.allItems = val);
  };

  const sendImpressionsAnalytics = (filteredItems) => {
    const itemsData = filteredItems.map((item) => {
      return {
        LinkID: item.id || '',
        LinkTitle: item.name || '',
        LinkURL: item.url,
      };
    });

    const data = {
      applicationSource: 'DLP',
      source: 'LinkPage',
      username: upgradeData.username,
      items: itemsData || [],
    };

    (window as any)?.analytics?.track('Link Impressions', data);
  };

  const sendImpressions = () => {
    if (getSettingImpressionsLock()) return;
    if (getNewImpressionItems().length) {
      const filteredItems = [
        (upgradeData.pageItems || []).filter((item) =>
          getNewImpressionItems().find((impItem) => impItem.id === item.id)
        ),
      ];

      sendImpressionsAnalytics(filteredItems);
      sessionStorage.setItem(
        SESSION_IMPRESSION_ITEMS_KEY,
        JSON.stringify(getImpressionItems())
      );
      setNewImpressionItems([]);
    }
  };

  const startImpressionsSendInterval = () => {
    clearInterval(
      (upgradeData.impressionsRequestIntervalId as NodeJS.Timeout) || undefined
    );

    upgradeData.impressionsRequestIntervalId = setInterval(
      () => sendImpressions(),
      upgradeData.IMPRESSION_REQUEST_INTERVAL
    );
  };
  const startIntervals = () => {
    startImpressionsSendInterval();
  };
  const clearIntervals = () => {
    clearInterval(
      (upgradeData.impressionsRequestIntervalId as NodeJS.Timeout) || undefined
    );
  };

  const fillImpressionsArray = (items: Link[]) => {
    if (!items?.length) return;

    setSettingImpressionsLock(true);

    const _newItems =
      items.filter((item: Link) => {
        return (
          getImpressionItems().indexOf(item.id || '') === -1 &&
          !getNewImpressionItems().find((_item) => _item.id === item.id)
        );
      }) || [];

    const _newImpressionItems = [...getNewImpressionItems(), ..._newItems];
    setNewImpressionItems(_newImpressionItems);

    const _impressionItems = [
      ...getImpressionItems(),
      ...getNewImpressionItems().map((item) => item.id),
    ];
    setImpressionItems(_impressionItems);

    setSettingImpressionsLock(false);
  };

  const sendInitAnalytics = () => {
    const data = {
      applicationSource: 'DLP',
      source: 'UpgradePage',
      linkTreeUsername: upgradeData.username,
    };
    (window as any)?.analytics?.track('Upgrade Page: Page View', data);
  };
  const sendClaimAnalytics = (email: string, success: boolean, reason?: string) => {
    const data = {
      applicationSource: 'DLP',
      source: 'UpgradePage',
      linkTreeUsername: upgradeData.username,
      email,
      success,
      ...(reason ? { reason } : {})
    };
    (window as any)?.analytics?.track(`Upgrade Page: Email Submitted - ${success ? 'Success' : 'Failed'}`, data);
  };
  
  const onEmailSubmit = async (email: string, success: boolean, reason?: string) => {
    sendClaimAnalytics(email, success, reason);
  };

  const onBeforeUnload = async () => {
    sendImpressions();
  };

  

  const onImpressionsChange = (items: Link[]) => {
    fillImpressionsArray(items);
  };

  

  const initImpressionsData = () => {
    const _allItemsForImpressionsTracking = (upgradeData.pageItems || []).map(
      (item) => {
        const type = getLinkType(item);
        const cardId =
          type === LinkTypes.collection
            ? item.collection?.id
            : item.offer
            ? item.offer.id
            : item.id;
        return {
          id: item.id,
          type,
          cardId,
        };
      }
    );

    setAllItems(_allItemsForImpressionsTracking);
  };
  const init = (username: string, pageItems: Link[] = []) => {
    upgradeData.pageItems = pageItems;
    upgradeData.username = username;
    upgradeData.isInitialized = true;
    sendInitAnalytics();
    if (countImpressions) {
      initImpressionsData();
      startIntervals();
    }

  };

  React.useEffect(() => {
    return clearIntervals;
    // eslint-disable-next-line
  }, []);

  return {
    onBeforeUnload,
    onImpressionsChange,
    onEmailSubmit,
    init,
  };
};

export default useUpgradeTracking;
