import styled, { css } from 'styled-components';
import { DrumLogoColored, CheckmarkAlt, CloseIconAlt } from 'assets/svg';

const maxContainerWidth = '744px';
const submitContainerHeight = '117px';
const submitContainerHeightMobile = '152px';
const submitContainerFinishedHeight = '280px';

const submitIconMixin = css`
  position: absolute;
  right: 19px;
`;

const submitInfoTextMixin = css`
  margin-top: 20px;
  max-width: 364px;
  text-align: center;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.dlpEmailClaimInfoTextColor};
`;

const buttonMixin = css`
  width: 117px;
  height: 51px;
  background:${({ theme }) => theme.colors.dlpEmailClaimContainerButtonBg};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.dlpEmailClaimContainerButtonTextColor};
  border: none;
  cursor: pointer;
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  @media (max-width: 690px) {
    & {
      margin-bottom: 33px;
      margin-left: 0;
      width: 80px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  height: ${submitContainerHeight};
  position: fixed;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.dlpEmailClaimContainerBg};
  z-index: 10;

  &.submitted {
    height: ${submitContainerFinishedHeight};
  }

  @media (max-width: 770px) {
    & {
      padding: 0 5px;
    }
  }

  @media (max-width: 690px) {
    & {
      height: ${submitContainerHeightMobile};
    }
  }

  
`;
export const ContainerInner = styled.div`
  width: 100%;
  max-width: ${maxContainerWidth};
  height: 100%;
  padding: 0 0;
  box-sizing: border-box;
  display: flex;
  margin: auto;
  justify-content: space-between;
  align-items: center;

  ${Container}.submitted & {
    box-sizing: border-box;
    flex-wrap: wrap;
    height: auto;
  }

  @media (max-width: 690px) {
    & {
      flex-wrap: wrap;
      padding: 0 11px;
      align-items: flex-start;
    }
  }
`;
export const LogoContainer = styled.div`
  width: 174px;

  @media (max-width: 900px) {
    ${Container}.submitted & {
      display: flex;
      width: 115px;
      height: auto;
      justify-content: flex-start;
      align-items: flex-start;
      order: 0;
    }
  }

  @media (max-width: 690px) {
    & {
      display: flex;
      width: 100%;
      height: 62px;
      justify-content: center;
      align-items: center;
      order: 1;
    }
  }
  
`;
export const Logo = styled(DrumLogoColored)`
  width: 127px;
  height: 27px;

  ${Container}.submitted & {
    width: 104px;
    height: 22px;
  }

  @media (max-width: 690px) {
    & {
      width: 104px;
      height: 22px;
    }
  }
`;
export const ECCenter = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: flex-end;
  align-self: flex-end;
  margin-bottom: 4px;

  ${Container}.submitted & {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: flex-start;
    width: 100%;
    min-width: 100%;
    order: 3;
  }

  @media (max-width: 900px) {
    ${Container}.submitted & {
      padding-top: 0;
    }
  }

  @media (max-width: 690px) {
    & {
      flex: 1;
      order: 2;
    }
  }
`;
export const EmailInputContainer = styled.div`
  display: flex;
  flex: 1;
  box-sizing: border-box;
  height: 100%;
  padding: 0 5px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 690px) {
    & {
      flex: 1;
      height: auto;
      padding: 0;
      width: auto;
    }
  }
`;
export const EmailInput = styled.input`
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  height: 54px;
  padding: 15px 24px;
  background: ${({ theme }) => theme.colors.dlpFormInputBg};
  border-radius: 8px;
  border: none;
  font-family: inherit;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.size.subtitle};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.dlpEmailClaimContainerInputTextColor};

  @media (max-width: 690px) {
    & {
      padding: 15px;
      font-size: ${({ theme }) => theme.size.body1};
    }
  }
`;
export const InputError = styled.div`
  width: 100%;
  box-sizing: border-box;
  font-size: 13px;
  line-height: 14px;
  height: 29px;
  padding: 2px 0;
  color: ${({ theme }) => theme.colors.danger};
  text-align: center;

  @media (max-width: 690px) {
    & {
      font-size: 12px;
      line-height: 15px;
      height: 32px;
      padding: 2px 0;
    }
  }
`;
export const ECRight = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 690px) {
    & {
      order: 3;
      align-items: center;
      height: 62px;
    }
  }      
`;
export const SubmitButtonContainer = styled.div`
  width: 152px;
  text-align: right;

  @media (max-width: 690px) {
    & {
      width: 92px;
      align-self: flex-start;
    }
  }
  
`;
export const SubmitButtonDesktop = styled.button`
  ${buttonMixin};
  @media (max-width: 690px) {
    & {
      display: none;
    }
  }
`;
export const SubmitButtonMobile = styled.button`
  ${buttonMixin};
  display: none;

  @media (max-width: 690px) {
    & {
      display: inline-block;
      width: 68px;
      height: 51px;
    }
  }
`;

export const SubmittedContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
`;

export const EmailContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  width: calc(100% - 20px);
  max-width: 548px;
  margin: 20px 10px 0 10px;
  height: 53px;
  padding: 0 50px 0 19px;
  border: 1px solid ${({ theme }) => theme.colors.dlpEmailClaimFinishedEmailContainerBorderColor};
  box-sizing: border-box;
  border-radius: 8px;
`;

export const Email = styled.p`
  flex: 1;
  text-align: center;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.dlpEmailClaimFinishedEmailContainerTextColor};
`;

export const SubmitSuccessIcon = styled(CheckmarkAlt)`
  ${submitIconMixin};
`;

export const SubmitErrorIcon = styled(CloseIconAlt)`
  ${submitIconMixin};
`;

export const SubmitEmBlock = styled.p`
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.danger};
  margin-top: 4px;
  text-align: center;
`;

export const SubmitError = styled.p`
  ${submitInfoTextMixin};
`;

export const SubmitSuccess = styled.p`
  ${submitInfoTextMixin};
`;

export const SubmitErrorInfo = styled.span`
  
`;

export const SubmitErrorLink = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.size.body2};
  line-height: 24px;
  margin-bottom: 9px;
  color: ${({ theme }) => theme.colors.accentMainColor};
  text-decoration: none;
  cursor: pointer;
`;

export const AppLinks = styled.div`
  margin-top: 25px;
  display: flex;

  @media (max-width: 900px) {
    ${Container}.submitted & {
      justify-content: center;
    }
  }
`;

export const AppLink = styled.a`
  margin-right: 25px;

  &:last-of-type {
    margin-right: 0;
  }
`;

export const AppLinkImage = styled.img`
  height: 40px;
`;

export const ECRightSubmitted = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ECRSLabel = styled.span`
  width: 198px;
  max-height: 32px;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.body2};
  line-height: 16px;
  color: ${({ theme }) => theme.colors.dlpEmailClaimLearnMoreTextColor};
`;

export const ECRSLink = styled.a`
  &,
  &:hover,
  &:focus,
  &:active,
  &:visited {
    font-size: ${({ theme }) => theme.size.body2};
    line-height: 24px;
    color: ${({ theme }) => theme.colors.accentMainColor};
    text-decoration: none;
  }
`;