import styled, { css,  } from 'styled-components';
import { ButtonTextMixin } from '../Typography';
import { TemplatePreset } from 'graphqlQueries';
import { colord } from 'colord';

interface Props {
  styles?: TemplatePreset;
}

export const ButtonBase = styled.button`
  box-sizing: border-box;
  ${ButtonTextMixin};
  border-radius: 21px;
`;

const HOVER_BUTTON_COLOR = '#0086a8';
const ACTIVE_BUTTON_COLOR = '#005D75';

export const PrimaryButtonMixin = css`
  padding: 9px 0;
  border: none;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.accentMainColor};

  :hover {
    background-color: ${HOVER_BUTTON_COLOR};
  }

  :active {
    background-color: ${ACTIVE_BUTTON_COLOR};
  }

  :disabled {
    background-color: ${({ theme }) => theme.colors.borderColor};
  }
`;
export const PrimaryButton = styled(ButtonBase)`
  ${PrimaryButtonMixin}
`;

export const PrimaryButtonStyledMixin = ({styles}: Props) => `
  ${PrimaryButtonMixin}
  ${styles?.primaryButton?.backgroundColor && `background-color: ${styles?.primaryButton?.backgroundColor}`};
  ${styles?.primaryButton?.color && `color: ${styles?.primaryButton?.color}`};
  
  ${styles?.primaryButton?.backgroundColor && 
    `
    :hover {
      background-color: ${colord(styles.primaryButton.backgroundColor).darken(0.25).toHex()};
    }

    :active {
      background-color: ${colord(styles.primaryButton.backgroundColor).darken(0.25).toHex()};
    }

    :disabled {
      background-color: ${colord(styles.primaryButton.backgroundColor).desaturate(1).toHex()};
    }
    `
  }
`;

export const PrimaryButtonStyled = styled(PrimaryButton)((styles: Props) =>`
  ${console.log("styles", styles)};
  ${PrimaryButtonStyledMixin(styles)}
  
`);

const borderedButtonMixin = css`
  padding: 7px 0;
  border: 2px solid currentColor;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const SecondaryButton = styled(ButtonBase)`
  ${borderedButtonMixin};
  color: ${({ theme }) => theme.colors.accentMainColor};

  :hover {
    color: ${HOVER_BUTTON_COLOR};
  }

  :active {
    color: ${ACTIVE_BUTTON_COLOR};
  }

  :disabled {
    border-color: ${({ theme }) => theme.colors.backgroundColor};
    color: ${({ theme }) => theme.colors.borderColor};
  }
`;

export const OutlinedButton = styled(ButtonBase)`
  ${borderedButtonMixin};
  color: ${({ theme }) => theme.colors.borderColor};

  :hover {
    color: ${({ theme }) => theme.colors.inputColor};
  }

  :active {
    color: ${({ theme }) => theme.colors.subtitleColor};
  }

  :disabled {
    color: ${({ theme }) => theme.colors.backgroundColor};
  }
`;

// TODO: apply to all buttons that require reset in project
export const resetButtonStyles = css`
  border: none;
  padding: 0;
  background-color: transparent;
`;

export const CloseModalButton = styled.button`
  width: 23px;
  height: 23px;
  padding: 0;
  border: none;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
`;
