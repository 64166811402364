import styled, { css } from 'styled-components';
import { PrimaryButtonStyledMixin } from 'uikit';
import { TemplatePreset } from 'graphqlQueries';

interface Props {
  styles?: TemplatePreset;
}

interface ContainerProps {
  font?: string | null;
}

interface DetailsContainerProps {
  font?: string | null;
  textColor?: string | null;
}

interface IShareButtonProps {
  backgroundColor?: string | null;
}


export const defaultTextMixin = css`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 24px;
`;

export const wideContainerMixin = css`
  max-width: 360px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
`;

export const innerContainerMixin = css`
  width: 100%;
  max-width: 360px;
  box-sizing: border-box;
`;


export const Container = styled.div<ContainerProps>`
  
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: inherit;
  font-family: inherit;

  * {
    font-family: inherit;
  }
`;

export const VideoContainer = styled.div`
  width: 100%;
  height: 360px;
  position: relative;
  cursor: pointer;

  & > div {
    height: 100%;
  }
`;
export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`;

export const PreviewButton = styled.div`
  position: absolute;
  margin: auto;
  top: 0;
  bottom:0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  padding-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.44);
`;

export const DetailsContainer = styled.div<DetailsContainerProps>`
  ${wideContainerMixin};
  font-family: ${({ font }) => font || 'inherit'};
  color: ${({ textColor }) => textColor || 'inherit'};
  text-align: center;
`;

export const Title = styled.p`
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 19px;
  margin-top: 32px;
`;

export const Description = styled.pre`
  ${defaultTextMixin};
  margin-top: 27px;
  font-family: inherit;
  font-size: ${({ theme }) => theme.size.body1};
  white-space: normal;
`;

export const ButtonsContainer = styled.div`
  ${innerContainerMixin};
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DownloadButton = styled.a<Props>((styles) => `
  ${PrimaryButtonStyledMixin(styles)}
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-wisth: 357px;
  height: 56px;
  border-radius: 8px;
`);

export const ShareButtonContainer = styled.div`
  position: absolute;
  top: -88px;
  right: 36px;
  z-index: 999;
  display: flex;
`;

export const ShareButton = styled.div<IShareButtonProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 33px;
  padding: 0;
  border-radius: 50%;
  background-color: ${({ backgroundColor }) => backgroundColor || '#fff'};
  cursor: pointer;
  border: none;
  outline: none;

  &:focus {
    border: none !important;
    outline: none !important;
  }

  & path {
    stroke: ${({ theme }) => theme.colors.accentMainColor};
  }
`;

export const CopyTooltip = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -30px;
  padding: 4px 7px;
  border-radius: 3px;
  background: ${({ theme }) => theme.colors.dlpOfferTooltipBg};
  color: ${({ theme }) => theme.colors.dlpOfferTooltipTextColor};
`;

