import React, { FC, useEffect, useState } from 'react';
import useGeoLocation from 'helpers/ipGeolocation';
import { Formik, useFormikContext, FormikErrors } from 'formik';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { sendSubscriptionAnalytics } from 'helpers/analytics/productsAndLinks';
import {
  useCreateSubscriberMutation,
  LinkFormatEnum,
  CreateSubscriberMutation,
  SubscribersContactTypeEnum,
  Link
} from 'graphqlQueries';
import {
  validationSchemas,
  SchemaTypeSmallEmail,
  SchemaTypeSmallPhone,
  SchemaTypeLargeEmail,
  SchemaTypeLargePhone,
} from './validationSchema';
import BaseProps from '../../types';
import { PhoneInputByCountry } from 'uikit';
import './styles.scss';
import {
  Container,
  ContainerInner,
  Content,
  InputContainer,
  InputGroupStyled,
  InputBaseStyled,
  CardTitle,
  FieldFooterStyled,
  SubmitButton,
  FormFooter,
  ErrorContainer,
  SuccessMessage,
  FormLoaderContainer,
  FormLoader,
} from './styles';

interface Props extends BaseProps {
  error?: string | null;
  submitSuccess?: boolean;
  isLoading?: boolean;
  onChange?: (
      values?:
        | SchemaTypeSmallEmail
        | SchemaTypeSmallPhone
        | SchemaTypeLargeEmail
        | SchemaTypeLargePhone
    ) => void,
  onSubmit?: (
    validateForm: (
      values?:
        | SchemaTypeSmallEmail
        | SchemaTypeSmallPhone
        | SchemaTypeLargeEmail
        | SchemaTypeLargePhone
    ) => Promise<
      FormikErrors<
        | SchemaTypeSmallEmail
        | SchemaTypeSmallPhone
        | SchemaTypeLargeEmail
        | SchemaTypeLargePhone
      >
    >,
    setSubmitting: (isSubmitting: boolean) => void,
    values:
      | SchemaTypeSmallEmail
      | SchemaTypeSmallPhone
      | SchemaTypeLargeEmail
      | SchemaTypeLargePhone
  ) => void;
  onSubmitSuccess?: (item: Partial<Link>) => void;
}

const SubscriptionItem: FC<Props> = ({
  item,
  linkStyles,
  styles,
  error,
  isLoading = false,
  submitSuccess = false,
  onChange,
  onSubmit,
  className
}) => {
  const { format, meta } = item;
  const sizeClass = format === LinkFormatEnum.Large ? 'large' : 'small';
  const typeClass = meta?.subscribersContactType === SubscribersContactTypeEnum.Email ? 'type-email' : 'type-phone';
  const classSet = `${sizeClass} ${typeClass} ${className}`;
  const location = useGeoLocation();

  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);

  const {
    values,
    handleChange,
    setFieldValue,
    errors,
    isSubmitting,
    setSubmitting,
    validateForm,
  } = useFormikContext<
    | SchemaTypeSmallEmail
    | SchemaTypeSmallPhone
    | SchemaTypeLargeEmail
    | SchemaTypeLargePhone
  >();

  useEffect(() => {
    const _enabled =
      !isSubmitting &&
      (format === LinkFormatEnum.Small || (values as any).fullName) &&
      (meta?.subscribersContactType === SubscribersContactTypeEnum.Email ? (values as any).email : (values as any).phone);
    setIsSubmitDisabled(!_enabled);
  }, [isSubmitting, values]);

  useEffect(() => {
    onChange?.(values);
  }, [values]);

  const handleSubmit = () => {
    onSubmit?.(validateForm, setSubmitting, values);
  };

  return (
    <Container className={classSet} styles={styles}>
      <ContainerInner
        styles={styles}
        backgroundColor={linkStyles?.background_color}
      >
        <Content styles={styles}>
          <CardTitle styles={styles}>{item.name}</CardTitle>
          {format === LinkFormatEnum.Large && (
            <InputContainer>
              <InputGroupStyled styles={styles}>
                <InputBaseStyled
                  name={'fullName'}
                  onChange={handleChange}
                  value={(values as any).fullName}
                  styles={styles}
                  placeholder="Full Name"
                />
              </InputGroupStyled>
            </InputContainer>
          )}
          {meta?.subscribersContactType === SubscribersContactTypeEnum.Phone && (
            <InputContainer>
              <InputGroupStyled styles={styles}>
                <PhoneInputByCountry
                  name="phone"
                  onChange={(value) => {
                    setFieldValue('phone', value);
                  }}
                  value={(values as any).phone}
                  country={(location.country || (location.error ? 'us' : '')).toLowerCase()}
                  placeholder="Phone Number"
                />
                {format === LinkFormatEnum.Small && (
                  <SubmitButton
                    styles={styles}
                    onClick={handleSubmit}
                    disabled={isSubmitDisabled}
                  >
                    Submit
                  </SubmitButton>
                )}
              </InputGroupStyled>
            </InputContainer>
          )}
          {meta?.subscribersContactType === SubscribersContactTypeEnum.Email && (
            <InputContainer>
              <InputGroupStyled styles={styles}>
                <InputBaseStyled
                  name={'email'}
                  onChange={handleChange}
                  value={(values as any).email}
                  styles={styles}
                  placeholder="Email Address"
                />
                {format === LinkFormatEnum.Small && (
                  <SubmitButton
                    styles={styles}
                    onClick={handleSubmit}
                    disabled={isSubmitDisabled}
                  >
                    Submit
                  </SubmitButton>
                )}
              </InputGroupStyled>
            </InputContainer>
          )}
          <FormFooter>
            <ErrorContainer>
              {!isLoading && (
                <FieldFooterStyled
                  error={
                    (errors as any).fullName ||
                    (errors as any).email ||
                    (errors as any).phone ||
                    error
                  }
                  showAlways={false}
                />
              )}
              {!isLoading && submitSuccess && (
                <SuccessMessage styles={styles}>
                  Thank you for subscribing!
                </SuccessMessage>
              )}
              {isLoading && (
                <FormLoaderContainer>
                  <FormLoader></FormLoader>
                  <span>Submitting</span>
                </FormLoaderContainer>
              )}
            </ErrorContainer>
            {format === LinkFormatEnum.Large && (
              <SubmitButton
                styles={styles}
                onClick={handleSubmit}
                disabled={isSubmitDisabled}
              >
                Submit
              </SubmitButton>
            )}
          </FormFooter>
        </Content>
      </ContainerInner>
    </Container>
  );
};

let lastSubmittedValues :
  | SchemaTypeSmallEmail
  | SchemaTypeSmallPhone
  | SchemaTypeLargeEmail
  | SchemaTypeLargePhone 
  | undefined
  = undefined;

const SubscriptionItemContainer: FC<Props> = (props) => {
  const meta = useSelector(
    (state: RootState) => state.userMetaData.root
  );
  const drummerId = useSelector(
    (state: RootState) => state.userMetaData.root?.user?.id
  );
  const drummerUsername = useSelector(
    (state: RootState) => state.userMetaData.root?.user?.username
  );

  const schema =
    validationSchemas[props.item.format || LinkFormatEnum.Small][props?.item?.meta?.subscribersContactType || SubscribersContactTypeEnum.Email];

  const [shouldValidateOnChange, setShouldValidateOnChange] =
    useState<boolean>(false);
  const [isFormSubmitSuccessful, setIsFormSubmitSuccessful] =
    useState<boolean>(false);
  const [serverError, setServerError] = useState<string>('');

  const sendSubscriptionSubmitAnalytics = (success: boolean, error?: string) => {
    if(!lastSubmittedValues || !success) return;
    const { fullName, phone, email} = lastSubmittedValues as any;
    sendSubscriptionAnalytics({
      success,
      meta,
      fullName,
      phone,
      email,
      error
    })
  }

  const [createSubscriber, { loading: createSubscriberLoading }] =
    useCreateSubscriberMutation({
      onCompleted: async (data: CreateSubscriberMutation) => {
        if(data?.createSubscriber?.success) {
          setIsFormSubmitSuccessful(true);
          sendSubscriptionSubmitAnalytics(true);
          props?.onSubmitSuccess?.(props?.item);
        } else {
          const _error = data?.createSubscriber?.message || 'Error. Please try again.';
          setServerError(_error);
          sendSubscriptionSubmitAnalytics(false, _error);
        }
      },
      onError: (err) => {
        const _error = err.message || 'Error. Please try again.';
        setServerError(_error);
        sendSubscriptionSubmitAnalytics(false, _error);
      },
    });

  const handleChange = () => {
    setServerError('');
    setIsFormSubmitSuccessful(false);
  }

  const handleSubmit = (
    validateForm: (
      values?:
        | SchemaTypeSmallEmail
        | SchemaTypeSmallPhone
        | SchemaTypeLargeEmail
        | SchemaTypeLargePhone
    ) => Promise<
      FormikErrors<
        | SchemaTypeSmallEmail
        | SchemaTypeSmallPhone
        | SchemaTypeLargeEmail
        | SchemaTypeLargePhone
      >
    >,
    setSubmitting: (isSubmitting: boolean) => void,
    values:
      | SchemaTypeSmallEmail
      | SchemaTypeSmallPhone
      | SchemaTypeLargeEmail
      | SchemaTypeLargePhone
  ) => {
    setSubmitting(true);
    setIsFormSubmitSuccessful(false);
    setShouldValidateOnChange(true);
    setServerError('');
    validateForm(values).then(async (errors) => {
      const errorList = !!Object.values(errors)?.length;
      if (!errorList) {
        const fullName = (values as SchemaTypeLargeEmail | SchemaTypeLargePhone).fullName || '';
        lastSubmittedValues = values;
        createSubscriber({
          variables: {
            input: {
              drummerId: drummerId || '',
              fullName,
              email: (values as SchemaTypeSmallEmail | SchemaTypeLargeEmail)?.email || undefined,
              phone: (values as SchemaTypeSmallPhone | SchemaTypeLargePhone)?.phone || undefined,
            },
          },
        });
      }
      setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={{}}
      onSubmit={() => {}}
      validationSchema={schema}
      validateOnChange={shouldValidateOnChange}
    >
      <SubscriptionItem
        {...props}
        onSubmit={props.onSubmit || handleSubmit}
        submitSuccess={props.submitSuccess || isFormSubmitSuccessful}
        error={props.error || serverError}
        isLoading={props.isLoading || createSubscriberLoading}
        onChange={props.onChange || handleChange}
        onSubmitSuccess={props.onSubmitSuccess}
      />
    </Formik>
  );
};

export default SubscriptionItemContainer;
