import window from 'global';
import { getSelectedOptionsFromArray, getLinkType } from './index';
import {
  IImpression,
  ILinkClick,
  IViewedProduct,
  IProvidedOrderDetails,
  IProvidedInformation,
  IPlacedOrder,
  IProductDownloaded,
  ISubscription,
  IMethods
} from './types';

const baseFields = {
  applicationSource: 'Drum'
};

const AFFILIATION = "Drum";

//USER SAW LINK
export const sendImpressionsAnalytics = (
  props: IImpression
) => {

  const gtag = window?.gtag;
  
  (props?.items || []).forEach(item => {
    gtag("event", "link_impression", {
      ...baseFields,
      drummerId: props.drummerId,
      username: props.username,
      source: props.source,
      itemId: item.CardID || '',
      itemTitle: item.LinkTitle || '',
      itemType: getLinkType(item.itemType),
    });
  })
};

//USER CLICKED LINK
export const sendLinkClickAnalytics = (
  props: ILinkClick
) => {

  const gtag = window?.gtag;
  
  gtag("event", "link_click", {
    ...baseFields,
    ...props
  });
};

export const sendViewedProductAnalytics = (props: IViewedProduct) => {
  const gtag = window?.gtag;
  gtag("event", "buyer_viewed_product", {
    ...baseFields,
    drummerId: props.meta?.user_id,
    username: props.meta?.user?.username,
    itemId: props.productId,
    itemType: getLinkType(props.item?.type),
    itemTitle: props.item?.name,
    source: props.source
  });
};

//USER STARTED CHECKOUT PROCESS
export const sendProvidedOrderDetailsAnalytics = (
  props: IProvidedOrderDetails
) => {

  const gtag = window?.gtag;

  const {
    selectedOptions,
    source
  } = props;

  const drumData = {
    drummerId: props.meta?.user_id,
    username: props.meta?.user?.username,
    itemId: props.product?.id,
    itemType: getLinkType(props.product?.type),
    itemTitle: props.product?.title,
    source
  };

  const _selectedOptions = getSelectedOptionsFromArray(selectedOptions);
  if(_selectedOptions) {
    Object.keys(_selectedOptions).forEach(key => {
      drumData[`selectedOptions_${key}`] = _selectedOptions[key];
    });
  }
  
  gtag("event", "begin_checkout", {
    ...baseFields,
    ...drumData,
    currency: "USD",
    value: (props.product?.price || 0) * (props.quantity || 1),
    price: props.product?.price,
    quantity: props.quantity || 1,
    items: [
      {
        item_id: props.product?.id,
        item_name: props.product?.title,
        affiliation: AFFILIATION,
        currency: "USD",
        discount: 0,
        item_variant: `${props.selectedOptions?.[0]?.value || ''} ${props.selectedOptions?.[1]?.value || ''}`,
        price: props.product?.price,
        quantity: props.quantity || 1
      }
    ]
  })
};

//USER FULFILLED STEP 2 - INFORMATION
export const sendProvidedInformationAnalytics = (
  props: IProvidedInformation
) => {
  const {
    shippingAddress,
    meta,
    buyerEmail,
    buyerPhone,
    selectedOptions,
    source
  } = props;
  
  const gtag = window?.gtag;

  const _shippingAddress = shippingAddress || {};

  const drumData = {
    drummerId: props.meta?.user_id,
    username: props.meta?.user?.username,
    itemId: props.product?.id,
    itemType: getLinkType(props.product?.type),
    itemTitle: props.product?.title,
    source
  };

  const _selectedOptions = getSelectedOptionsFromArray(selectedOptions);
  if(_selectedOptions) {
    Object.keys(_selectedOptions).forEach(key => {
      drumData[`selectedOptions_${key}`] = _selectedOptions[key];
    });
  }

  gtag("event", "add_shipping_info", {
    ...baseFields,
    ..._shippingAddress,
    ...drumData,
    currency: "USD",
    value: (props.product?.price || 0) * (props.quantity || 1),
    drummerId: meta?.user_id,
    username: meta?.user?.username,
    source: props.source,
    buyerEmail,
    buyerPhone,
    items: [
      {
        item_id: props.product?.id,
        item_name: props.product?.title,
        affiliation: AFFILIATION,
        currency: "USD",
        discount: 0,
        item_variant: `${props.selectedOptions?.[0]?.value || ''} ${props.selectedOptions?.[1]?.value || ''}`,
        price: props.product?.price,
        quantity: props.quantity || 1
      }
    ]
  });
};

//USER FULFILLED STEP 3 - PAYMENT - ORDER FULFILLED
export const sendPlacedOrderAnalytics = (
  props: IPlacedOrder
) => {

  const gtag = window?.gtag;

  const { firstName, lastName, username } = props?.meta?.user || {};
  const {
    buyerEmail,
    buyerPhone,
    orderID,
    productCoverImageURL,
    totalPrice,
    profileImageURL,
    productType,
    selectedOptions,
    source
  } = props;

  const type = getLinkType(productType);

  const drumData = {
    drummerId: props.meta?.user_id,
    //influencerFirstName: firstName,
    //influencerLastName: lastName,
    //influencerUsername: username,
    buyerEmail,
    buyerPhone,
    source,
    orderID,
    //productCoverImageURL,
    totalPrice,
    //profileImageURL,
    //productType: type,
    //username: props.meta?.user?.username,
    itemId: props.product?.id,
    itemType: getLinkType(props.product?.type),
    itemTitle: props.product?.title,
    //buyerId: props.meta?.user_id,
    date: new Date().toISOString(),
  };
  
  ["billingAddress", "shippingAddress"].forEach((addressKey) => {
    if(props[addressKey]) {
      // Object.keys(props[addressKey]).forEach(key => {
      //   drumData[`${addressKey}_${key}`] = props[addressKey][key];
      // });
      ["firstName", "lastName", "state", "zipCode"].forEach(key => {
        drumData[`${addressKey}_${key}`] = props[addressKey][key];
      });
    }
  })

  const _selectedOptions = getSelectedOptionsFromArray(selectedOptions);
  if(_selectedOptions) {
    Object.keys(_selectedOptions).forEach(key => {
      drumData[`selectedOptions_${key}`] = _selectedOptions[key];
    });
  }

  gtag("event", "purchase", {
    ...baseFields,
    ...drumData,
    currency: "USD",
    transaction_id: props.transactionID,
    //value: props.cartTotal,
    //affiliation: AFFILIATION,
    //shipping: 0,
    tax: props.taxes,
    price: props.productPrice || 0,
    quantity: props.quantity || 1,
    items: [
      {
        item_id: props.product?.id,
        item_name: props.product?.title,
        affiliation: AFFILIATION,
        currency: "USD",
        discount: 0,
        item_variant: `${props.selectedOptions?.[0]?.value || ''} ${props.selectedOptions?.[1]?.value || ''}`,
        price: props.productPrice || 0,
        quantity: props.quantity || 1,
      }
    ]
  });
};

export const sendProductDownloadedAnalytics = (props: IProductDownloaded) => {
  const gtag = window?.gtag;
  
  const { orderId, orderShortUrlId } = props;
  const data = {
    ...baseFields,
    drummerId: props.meta?.user_id,
    orderId,
    orderShortUrlId,
    username: props.meta?.user?.username,
    itemId: props.orderData?.product?.id,
    itemTitle: props.orderData?.product?.title,
    itemType: getLinkType(props.orderData?.product?.type),
  };

  gtag('event', 'file_download', data);
};

export const sendSubscriptionAnalytics = (
  props: ISubscription
) => {
  const { meta, fullName, email, phone } = props;
  const gtag = window?.gtag;
  const data = {
    ...baseFields,
    drummerId: meta?.user?.id,
    username: meta?.user?.username,
    fullName,
    email,
    phoneNumber: phone
  }
  gtag('event', 'complete_registration', data);
};

export const googleAnalytics: IMethods = {
  sendImpressionsAnalytics,
  sendLinkClickAnalytics,
  sendViewedProductAnalytics,
  sendProvidedOrderDetailsAnalytics,
  sendProvidedInformationAnalytics,
  sendPlacedOrderAnalytics,
  sendProductDownloadedAnalytics,
  sendSubscriptionAnalytics
}

export default googleAnalytics;