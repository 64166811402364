import React, { FC, ReactNode } from 'react';
import { getImagePath } from 'helpers';
import makeFullNameAndUserAvatarSubstitute from 'utils/makeFullNameAndUserAvatarSubstitute';
import SocialMediaLinks from 'components/SocialMediaLinks';
import {
  Container,
  UserAvatarContainer,
  UserAvatarSubstitute,
  UserName,
  UserDescriptionContainer,
  UserDescription,
} from './styles';
import {
  ImageStyleName,
  SocialAccount
} from 'graphqlQueries';

interface Props {
  user?: any;
  title?: string;
  description?: string;
  socials?: Partial<SocialAccount>[];
  onSocialClick?: (item: SocialAccount) => void;
  children?: ReactNode;
}

const UserInfoUpgraded: FC<Props> = ({ user, title = '', description = '', socials, onSocialClick, children }) => {
  const { userAvatarSubstitute, fullNameFormatted } =
    makeFullNameAndUserAvatarSubstitute(user, '@');

  const avatarSrc = user?.avatarImage?.styles
    ? getImagePath(user?.avatarImage.styles, ImageStyleName.Original)
    : user?.avatarImage?.url || null;

  return (
    <Container>
      <UserAvatarContainer style={{backgroundImage: avatarSrc ? `url('${avatarSrc}')` : "none"}}>
        {!avatarSrc &&
          <UserAvatarSubstitute>{userAvatarSubstitute}</UserAvatarSubstitute>
        }
      </UserAvatarContainer>
      <UserName>{title || fullNameFormatted || (!!user?.username && `@${user?.username}`)}</UserName>
      {!!socials?.length && <SocialMediaLinks items={socials} onItemClick={onSocialClick}/>}
      <UserDescriptionContainer>
        <UserDescription>{description}</UserDescription>
      </UserDescriptionContainer>
      {children}
    </Container>
  );
};

export default UserInfoUpgraded;
