export const CHECKOUT_DEFAULT_DATA = 'CHECKOUT_DEFAULT_DATA'
export const CHECKOUT_TAB_SWITCH = 'CHECKOUT_TAB_SWITCH'
export const CHECKOUT_SET_INFO_FORM_DATA = 'CHECKOUT_SET_INFO_FORM_DATA'
export const CHECKOUT_SET_PAYMENT_FORM_DATA = 'CHECKOUT_SET_PAYMENT_FORM_DATA'
export const CHECKOUT_SET_PAYPAL_PAYLOAD = 'CHECKOUT_SET_PAYPAL_PAYLOAD'
export const CHECKOUT_SET_CREDIT_CARD_PAYLOAD = 'CHECKOUT_SET_CREDIT_CARD_PAYLOAD'
export const CHECKOUT_SET_BRAINTREE_TOKEN = 'CHECKOUT_SET_BRAINTREE_TOKEN'
export const CHECKOUT_SET_LAST_CHECKOUT_TITLE = 'CHECKOUT_SET_LAST_CHECKOUT_TITLE'
export const CHECKOUT_RESET_CHECKOUT = 'CHECKOUT_RESET_CHECKOUT'

//USER METADATA
export const USER_METADATA_DEFAULT_DATA = 'USER_METADATA_DEFAULT_DATA'
export const USER_METADATA_SET_METADATA = 'USER_METADATA_SET_METADATA'
