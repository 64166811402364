import React, { FC } from 'react';
import { Container,
  TotalShipping,
  ShippingFee,
  TotalNotes,
  TaxesNote, } from './styles';

interface Props {
  className?: string;
  shippingFee?: number | string;
  showShipping?: boolean;
  showTaxes?: boolean;
}

const ShippingHandling: FC<Props> = ({ className, shippingFee, showShipping = true, showTaxes = false }) => {
  return (
    <Container className={className}>
      {showShipping && (
        <>
          <TotalShipping>
            {`Shipping & Handling: `}
            <ShippingFee>
              {Number(shippingFee) ? `$${shippingFee}` : 'FREE'}
            </ShippingFee>
          </TotalShipping>
          <TotalNotes>*Shipping within the United States only.</TotalNotes>
        </>
      )}
      {showTaxes && <TaxesNote>{'Taxes are calculated at checkout.'}</TaxesNote>}
    </Container>
  );
};

export default ShippingHandling;
