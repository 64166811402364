import styled from 'styled-components';
import { breakpoint } from 'helpers';
import { DrumLogo } from 'assets/svg';
import { Body2 } from 'uikit';

interface ContainerProps {
  backgroundColor?: string | null;
  textColor?: string | null;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  bottom: 0;
  padding: 60px 0 40px 0;
  text-align: center;
  color: ${({ textColor }) => textColor || 'inherit'};
  font-family: inherit;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  background: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  text-align: center;
  border-radius: 0 0 15px 15px;

  @media (max-width: 400px) {
    & {
      width: 100%;
      position: relative;
      padding: 48px 0;
    }
  }

  ${breakpoint('xs', 'md')`
    text-align: left;
	`}

  
`;

export const ContainerInner = styled.div`
  
`;

export const CreateLink = styled.a`
  display: block;
  text-align: center;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 16px;
  margin-bottom: 30px;
  opacity: 0.6;
  

  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: inherit;
    text-decoration: underline;
  }
`;

export const Info = styled.div`
  flex-grow: 0;
`;

export const ImageOuter = styled.div`
  margin-bottom: 6px;
  display: flex;
`;

export const ImageContainer = styled.div`
  margin: auto;
  padding: 8px 0 9px 0;
  ${breakpoint('xs', 'md')`
    background: none;
    margin: 0 auto;
	`}
`;

export const Image = styled(DrumLogo)`
  opacity: 0.6;
  fill: inherit;

  path {
    fill: inherit;
  }

  ${breakpoint('xs', 'md')`
    margin-bottom: 0px;
	`}
`;
export const BottomText = styled(Body2)`
  opacity: 0.6;
  line-height: 16px;
`;


