import React, { FC } from 'react';
import IconProps from './IconProps';

const DrumLogoColored: FC<IconProps> = ({ className }) => (
  <svg className={className} width="378" height="80" viewBox="0 0 378 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M35.1983 29.8103L11.9883 2.73033C11.9883 2.73033 6.40828 -2.62967 2.04828 1.67033C1.40418 2.24263 0.888632 2.9449 0.535583 3.73087C0.182535 4.51685 0 5.3687 0 6.23033C0 7.09195 0.182535 7.9438 0.535583 8.72978C0.888632 9.51575 1.40418 10.218 2.04828 10.7903L32.0483 35.5103C32.7024 35.8978 33.4793 36.0225 34.2218 35.8591C34.9643 35.6958 35.6172 35.2566 36.0483 34.6303C37.9083 32.3803 35.1983 29.8103 35.1983 29.8103Z" fill="#F94780"/>
    <path d="M33.1183 45.8503L13.0483 62.6403C13.0483 62.6403 9.98828 66.2703 13.0483 68.6403C16.6883 71.4503 19.1183 68.6403 19.1183 68.6403L35.9683 48.6403C35.9683 48.6403 37.0483 47.0403 35.9683 45.8303C34.8883 44.6203 33.1183 45.8503 33.1183 45.8503Z" fill="#F94780"/>
    <path d="M138.508 54.4703C138.508 50.3116 135.137 46.9403 130.978 46.9403C126.82 46.9403 123.448 50.3116 123.448 54.4703V72.3403C123.448 76.499 126.82 79.8703 130.978 79.8703C135.137 79.8703 138.508 76.499 138.508 72.3403V54.4703Z" fill="#17AACF"/>
    <path d="M74.0483 0.830326H52.9483C50.8266 0.830326 48.7917 1.67318 47.2914 3.17347C45.7911 4.67376 44.9483 6.70859 44.9483 8.83033C44.9483 10.9521 45.7911 12.9869 47.2914 14.4872C48.7917 15.9875 50.8266 16.8303 52.9483 16.8303H74.0483C74.2683 16.8303 96.5983 17.4503 96.5983 39.7103C96.5983 59.4903 79.5983 63.7703 75.3683 63.7703H52.9483C50.8266 63.7703 48.7917 64.6132 47.2914 66.1135C45.7911 67.6138 44.9483 69.6486 44.9483 71.7703C44.9483 73.8921 45.7911 75.9269 47.2914 77.4272C48.7917 78.9275 50.8266 79.7703 52.9483 79.7703H75.3783C87.0383 79.7703 112.608 69.6803 112.608 39.7103C112.608 11.3903 88.9583 0.830326 74.0483 0.830326Z" fill="#17AACF"/>
    <path d="M52.2183 60.7103C54.2154 60.7103 56.1306 59.917 57.5428 58.5048C58.9549 57.0927 59.7483 55.1774 59.7483 53.1803V28.3203C59.7483 26.3232 58.9549 24.408 57.5428 22.9958C56.1306 21.5837 54.2154 20.7903 52.2183 20.7903C50.2212 20.7903 48.3059 21.5837 46.8938 22.9958C45.4816 24.408 44.6883 26.3232 44.6883 28.3203V53.1803C44.6883 55.1774 45.4816 57.0927 46.8938 58.5048C48.3059 59.917 50.2212 60.7103 52.2183 60.7103Z" fill="#17AACF"/>
    <path d="M187.417 30.9203C187.417 19.5803 182.627 12.8203 178.607 9.15033C168.827 0.190334 154.747 0.630334 151.797 0.830334H130.977C128.855 0.830334 126.82 1.67319 125.32 3.17348C123.819 4.67377 122.977 6.7086 122.977 8.83033C122.977 10.9521 123.819 12.9869 125.32 14.4872C126.82 15.9875 128.855 16.8303 130.977 16.8303H152.097C152.337 16.8303 152.567 16.8303 152.807 16.8303C153.047 16.8303 162.577 16.2003 167.807 20.9803C170.237 23.2003 171.417 26.4603 171.417 30.9803C171.417 37.9103 169.087 42.6303 164.287 45.4203C161.69 46.8615 158.816 47.7309 155.857 47.9703C154.287 48.004 152.763 48.4985 151.473 49.3922C150.183 50.2859 149.184 51.5393 148.601 52.9964C148.018 54.4535 147.876 56.0499 148.194 57.5869C148.511 59.1239 149.274 60.5335 150.387 61.6403L166.387 77.5103C167.901 79.0035 169.946 79.8339 172.073 79.8189C174.2 79.8039 176.233 78.9447 177.727 77.4303C179.22 75.9159 180.05 73.8704 180.035 71.7438C180.02 69.6171 179.161 67.5835 177.647 66.0903L171.287 59.7803C179.947 55.0003 187.417 45.9203 187.417 30.9203Z" fill="#17AACF"/>
    <path d="M261.049 0.480347C258.928 0.480347 256.893 1.3232 255.393 2.82349C253.892 4.32378 253.049 6.35861 253.049 8.48035V40.5903C253.049 63.1403 238.839 63.1403 234.169 63.1403C216.719 63.1403 214.109 49.8403 214.109 41.9103V8.48035C214.109 6.35861 213.267 4.32378 211.766 2.82349C210.266 1.3232 208.231 0.480347 206.109 0.480347C203.988 0.480347 201.953 1.3232 200.453 2.82349C198.952 4.32378 198.109 6.35861 198.109 8.48035V41.9103C198.109 59.0803 207.549 79.1403 234.169 79.1403C256.049 79.1403 269.049 64.7303 269.049 40.5903V8.48035C269.049 6.35861 268.207 4.32378 266.706 2.82349C265.206 1.3232 263.171 0.480347 261.049 0.480347Z" fill="#17AACF"/>
    <path d="M349.138 0.480327C340.228 0.480327 332.968 3.66033 327.998 9.57033C325.613 6.64411 322.589 4.30329 319.159 2.72756C315.728 1.15183 311.982 0.383081 308.208 0.480327C296.008 0.480327 286.888 6.41033 282.518 17.1803C279.318 25.0603 279.328 33.6303 279.328 38.7603V71.1403C279.328 73.2621 280.171 75.2969 281.671 76.7972C283.172 78.2975 285.207 79.1403 287.328 79.1403C289.45 79.1403 291.485 78.2975 292.985 76.7972C294.485 75.2969 295.328 73.2621 295.328 71.1403V38.7503C295.328 34.7503 295.328 28.1803 297.328 23.2003C299.268 18.4303 302.418 16.4803 308.198 16.4803C318.688 16.4803 320.198 29.7903 320.198 37.7203V71.1403C320.198 73.2621 321.041 75.2969 322.541 76.7972C324.042 78.2975 326.077 79.1403 328.198 79.1403C330.32 79.1403 332.355 78.2975 333.855 76.7972C335.355 75.2969 336.198 73.2621 336.198 71.1403V38.7503C336.198 34.7503 336.198 28.1803 338.198 23.2003C340.138 18.4303 343.288 16.4803 349.058 16.4803C359.548 16.4803 361.118 29.7903 361.118 37.7203V71.1403C361.118 73.2621 361.961 75.2969 363.461 76.7972C364.962 78.2975 366.997 79.1403 369.118 79.1403C371.24 79.1403 373.275 78.2975 374.775 76.7972C376.275 75.2969 377.118 73.2621 377.118 71.1403V37.7203C377.198 14.7503 366.448 0.480327 349.138 0.480327Z" fill="#17AACF"/>
  </svg>
);

export default DrumLogoColored;
