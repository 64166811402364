import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  height: -webkit-fill-available;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.dlpUpgradeLoaderBg};
  opacity: 1;

  &.hidden {
    opacity: 0;
    transition: opacity 1s linear;
  }
  &.no-display {
    display: none;
  }
`;

export const LoaderImage = styled.img`
  width: 170px;
  height: 110px;
  margin-bottom: 70px;
`;

export const LoaderText = styled.div`
  font-family: 'DrumFont Raleway', 'Raleway';
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.size.subtitle};
  line-height: 38px;
  color: ${({ theme }) => theme.colors.dlpUpgradeLoaderTextColor};
  margin-top: 7px;
  text-align: center;
  padding: 0 8px;
`;