import styled from 'styled-components';

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
`;
export const Radio = styled.input`
  display: none;
`;
export const RadioLabel = styled.label`
  position: relative;
  left: -28px;
  padding-left: 44px;
  min-height: 28px;
  line-height: 28px;
  
  z-index: 1;
  cursor: pointer;
  font-size: ${({ theme }) => theme.size.body2};

  &.emphasized-label.checked{
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    color: ${({ theme }) => theme.colors.radioActiveLabel};
    font-size: 15px;
  }
  
`;
export const CheckMark = styled.span`
  position: relative;
  box-sizing: border-box;
  border-radius: 50%;
  min-width: 28px;
  min-height: 28px;
  background: transparent;
  
  border: 2px solid ${({ theme }) => theme.colors.radioBorder};
  
  &:checked,
  &.checked {
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      content: '';
      position: absolute;
      top: 4px;
      left: 4px;
      width: 18px;
      height: 18px;
      background: ${({ theme }) => theme.colors.radioFillBg};
      border-radius: 50%;
      
    }
  }
`;
