import window from 'global';
import {
  LinkedPage,
  LinkTypeEnum,
} from 'graphqlQueries';
import { getSelectedOptionsFromArray, getLinkType } from './index';
import {
  IImpression,
  ILinkClick,
  IViewedProduct,
  IStartedCheckout,
  IProvidedOrderDetails,
  IProvidedInformation,
  IPlacedOrder,
  IPlacedOrderFailed,
  IProductViewed,
  IProductDownloaded,
  ISubscription,
  IMethods,
  IPaymentOrderFailed,
  IContactClick
} from './types';

const baseFields = {
  applicationSource: 'DLP'
};

export const indentifySegment = (meta?: Partial<LinkedPage> | null) => {
  if (!meta) return;
  const analytics = (window as any)?.analytics;
  if (!analytics?.user?.()?.id?.()) {
    analytics?.identify?.(meta?.user_id);
  }
};

//-----------------------------------------------------------------EVENTS------------------------------------------------------------------//

//USER SAW LINK
export const sendImpressionsAnalytics = (
  props: IImpression
) => {
  const analytics = window?.analytics;
  analytics?.track('Link Impressions', {
    ...baseFields,
    props
  });
};

//USER CLICKED LINK
export const sendLinkClickAnalytics = (
  props: ILinkClick
) => {
  const analytics = window?.analytics;
  analytics?.track('Link Clicked', {
    ...baseFields,
    props
  });
};

export const sendViewedProductAnalytics = (props: IViewedProduct) => {
  const analytics = window?.analytics;
  indentifySegment(props.meta);
  analytics.track('Buyer Viewed Product', {
    ...baseFields,
    productId: props.productId,
    drummerId: props.meta?.user_id,
    drummerUsername: props.meta?.user?.username,
    itemType: getLinkType(props.item?.type),
    itemTitle: props.item?.name,
    source: props.source
  });
};

export const sendStartedCheckoutAnalytics = (props: IStartedCheckout) => {
  const analytics = window?.analytics;
  indentifySegment(props.meta);
  analytics.track('Buyer Started Checkout', {
    ...baseFields,
    productId: props.productId,
    drummerId: props.meta?.user_id,
    drummerUsername: props.meta?.user?.username,
    quantity: props.quantity,
    selectedOptions: getSelectedOptionsFromArray(props.selectedOptions)
  });
};

export const sendProvidedOrderDetailsAnalytics = (
  props: IProvidedOrderDetails
) => {
  const { requestText, field1, field2, quantity, selectedOptions } = props;
  const analytics = window?.analytics;
  indentifySegment(props.meta);
  const data = {
    drummerId: props.meta?.user_id,
    drummerUsername: props.meta?.user?.username,
    productId: props.productId,
    requestText,
    field1,
    field2,
    quantity,
    selectedOptions: getSelectedOptionsFromArray(selectedOptions)
  };
  analytics.track('Buyer Provided Order Details', { ...baseFields, ...data });
};

export const sendProvidedInformationAnalytics = (
  props: IProvidedInformation
) => {
  const {
    recipientFirstName,
    recipientLastName,
    recipientEmail,
    buyerEmail,
    buyerPhone,
    shippingAddress
  } = props;
  const analytics = window?.analytics;

  indentifySegment(props.meta);
  const data = {
    drummerId: props.meta?.user_id,
    drummerUsername: props.meta?.user?.username,
    source: props.source,
    recipientFirstName,
    recipientLastName,
    recipientEmail,
    buyerEmail,
    buyerPhone,
    shippingAddress: shippingAddress ? JSON.parse(JSON.stringify(shippingAddress)) : undefined
  };
  analytics.track('Buyer Provided Information', { ...baseFields, ...data });
};

export const sendPlacedOrderAnalytics = (
  props: IPlacedOrder
) => {
  const { firstName, lastName, username } = props?.meta?.user || {};
  const {
    buyerEmail,
    buyerPhone,
    collectionId,
    orderID,
    productTitle,
    productCoverImageURL,
    productPrice,
    totalPrice,
    profileImageURL,
    productID,
    productType,
    shippingAddress,
    billingAddress,
    quantity,
    taxes,
    selectedOptions
  } = props;
  const type = getLinkType(productType);

  const analytics = window?.analytics;
  indentifySegment(props.meta);

  const buyerId = analytics?.user?.()?.id?.();
  const data = {
    drummerId: props.meta?.user_id,
    influencerFirstName: firstName,
    influencerLastName: lastName,
    influencerUsername: username,
    buyerEmail,
    buyerPhone,
    source: collectionId ? "collection" : "linkpage",
    orderID,
    productTitle,
    productCoverImageURL,
    productPrice,
    totalPrice,
    profileImageURL,
    productID,
    productType: type,
    quantity,
    taxes,
    buyerId,
    billingAddress: billingAddress ? JSON.parse(JSON.stringify(billingAddress)) : undefined,
    shippingAddress: shippingAddress ? JSON.parse(JSON.stringify(shippingAddress)) : undefined,
    selectedOptions: getSelectedOptionsFromArray(selectedOptions),
    date: new Date().toISOString()
  };
  analytics.track('Placed Order', { ...baseFields, ...data });
};

export const sendPlacedOrderFailedAnalytics = (
  props: IPlacedOrderFailed
) => {
  const { firstName, lastName, username } = props?.meta?.user || {};
  const {
    buyerEmail,
    orderID,
    productTitle,
    productCoverImageURL,
    productPrice,
    totalPrice,
    profileImageURL,
    productID,
    productType,
    shippingAddress,
    billingAddress,
    quantity,
    taxes,
    selectedOptions,
    errorMessage
  } = props;
  const type = getLinkType(productType);

  const analytics = window?.analytics;
  indentifySegment(props.meta);

  const buyerId = analytics?.user?.()?.id?.();
  const data = {
    drummerId: props.meta?.user_id,
    influencerFirstName: firstName,
    influencerLastName: lastName,
    influencerUsername: username,
    buyerEmail,
    orderID,
    productTitle,
    productCoverImageURL,
    productPrice,
    totalPrice,
    profileImageURL,
    productID,
    productType: type,
    quantity,
    taxes,
    buyerId,
    billingAddress: billingAddress ? JSON.parse(JSON.stringify(billingAddress)) : undefined,
    shippingAddress: shippingAddress ? JSON.parse(JSON.stringify(shippingAddress)) : undefined,
    selectedOptions: getSelectedOptionsFromArray(selectedOptions),
    date: new Date().toISOString(),
    errorMessage
  };
  analytics.track('Order Placed Failed', { ...baseFields, ...data });
};

export const sendProductViewedAnalytics = (props: IProductViewed) => {
  const analytics = window?.analytics;
  indentifySegment(props.meta);
  const { orderId, orderShortUrlId } = props;
  const data = {
    drummerId: props.meta?.user_id,
    drummerUsername: props.meta?.user?.username,
    orderId,
    orderShortUrlId
  };
  analytics.track('Product Viewed', { ...baseFields, ...data });
};

export const sendProductDownloadedAnalytics = (props: IProductDownloaded) => {
  const analytics = window?.analytics;
  indentifySegment(props.meta);
  const { orderId, orderShortUrlId } = props;
  const data = {
    drummerId: props.meta?.user_id,
    drummerUsername: props.meta?.user?.username,
    orderId,
    orderShortUrlId,
    itemId: props.orderData?.product?.id,
    itemTitle: props.orderData?.product?.title,
    itemType: getLinkType(props.orderData?.product?.type),
  };
  analytics.track('Product Downloaded', { ...baseFields, ...data });
};

export const sendSubscriptionAnalytics = (
  props: ISubscription
) => {
  const analytics = window?.analytics;
  indentifySegment(props.meta);
  const { meta, fullName, email, phone, success, error } = props;
  const data = {
    ...baseFields,
    drummerId: meta?.user?.id,
    username: meta?.user?.username,
    fullName,
    email,
    phoneNumber: phone,
    success,
    error
  }
  analytics?.track(props.success ? 'Successful Subscribe' : 'Error Subscribe', data);
};

export const sendPaymentOrderFailedAnalytics = (
  props: IPaymentOrderFailed
) => {
  const type = getLinkType(props.productType);
  const analytics = window?.analytics;
  indentifySegment(props.meta);
  const data = {
    drummerId: props.meta?.user_id,
    drummerUsername: props.meta?.user?.username,
    productId: props.productId,
    buyerEmail: props.buyerEmail,
    productType: type,
    error: props.error
  };
  console.log(data)
  analytics.track('Order Payment Failed', data);
};

export const sendContactClickAnalytics = (props: IContactClick) => {
  const analytics = window?.analytics;
  indentifySegment(props.meta);
  const data = {
    linkId: props.linkId,
    drummerId: props.drummerId,
    drummerUsername: props.drummerUsername,
    title: props.title,
    place: props.place
  };
  analytics.track('Contact card clicked', data);
}


export const segment: IMethods = {
  sendViewedProductAnalytics,
  sendStartedCheckoutAnalytics,
  sendProvidedOrderDetailsAnalytics,
  sendProvidedInformationAnalytics,
  sendPlacedOrderAnalytics,
  sendPlacedOrderFailedAnalytics,
  sendProductViewedAnalytics,
  sendProductDownloadedAnalytics,
  sendSubscriptionAnalytics,
  sendContactClickAnalytics
}

export default segment;
