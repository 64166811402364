import window, { document } from 'global';

const cookies: any = {
  DRUM_REF_COUNT_COOKIE_KEY: `drum_refCount::${encodeURIComponent(
    window?.location?.href
  )}`,
};
cookies.set = (name, val, optionsInput) => {
  const options = {
    ...optionsInput,
    ...(window?.location?.hostname === 'localhost' ? {} : { Secure: true }),
  };
  const optionsArray = Object.entries(options)
    .map(([k, v]) => {
      if (v === true) {
        return k;
      } else if (v === false) {
        return null;
      }
      return [k, v].join('=');
    })
    .filter(Boolean);
  const nameValue = [name, val].join('=');
  const cookieVal = [nameValue, ...optionsArray].join('; ');
  if (document) {
    document.cookie = cookieVal;
  }
};
cookies.del = (name) => {
  cookies.set(name, '', { 'max-age': 0 });
};
/**
 * https://stackoverflow.com/a/15724300
 */
cookies.get = (name) => {
  const value = `; ${document?.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts?.pop()?.split(';').shift();
};
cookies.getRefCountCookie = () => {
  const refCount = cookies.get(cookies.DRUM_REF_COUNT_COOKIE_KEY);
  return parseInt(refCount);
};
cookies.increaseRefCountCookie = () => {
  const refCount = cookies.getRefCountCookie() || 0;
  cookies.set(cookies.DRUM_REF_COUNT_COOKIE_KEY, parseInt(refCount) + 1, {
    'max-age': 31536000,
    Path: window?.location?.pathname,
  });
};
cookies.increaseRefCountCookie();

export default cookies;
