import * as yup from 'yup';

export interface IOption {
  label: string;
  value: string;
}

export interface IField {
  id?: string;
  field?: string;
  values?: any;
}

const validationSchema = yup.object({
  //size: yup.object<IOption>(),
  //color: yup.object<IOption>(),
  // color: yup
  //   .string()
  //   .required('Please choose color'),
  quantity: yup.number().required('Please choose correct quantity'),
});

export const formSchema = (fieldsArray: IField[] = []) => {
  const schemaObject = {
    quantity: yup.number()
    .test('value is 0', 'Quantity must be at least 1', (value) => value > 0)
    .test({
      name: 'quantity exceeds available',
      test: function (value) {
        const showQuantity = this.parent?.availableQuantity <= 10 && this.parent?.availableQuantity > 0;
        return value > this.parent?.availableQuantity
          ? this.createError({
              message: `There are not enough products in stock. ${showQuantity ? `Only ${this.parent?.availableQuantity} left.` : ''}`,
              path: 'quantity',
            })
          : true;
      },
    }),
    availableQuantity: yup.number(),
  };

  fieldsArray.forEach((item) => {
    if (!item?.id) return;
    schemaObject[item.id] = yup
      .object<IOption>().test({
        name: 'field is empty',
        test: function (value) {
          console.log("Value", value);
          return !value
            ? this.createError({
                message: `${item.field} cannot be empty`,
                path: item.id,
              })
            : true;
        },
      })
  });

  const validationSchema = yup.object(schemaObject);

  return validationSchema;
};

export type SchemaType = yup.InferType<typeof validationSchema>;

export default validationSchema;
