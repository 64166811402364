import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: inherit;

  * {
    font-family: inherit;
  }
`;

export const ItemsList = styled.div`
  width: 100%;
`;

export const TotalContainer = styled.p`
  width: 100%;
  text-align: right;
  padding: 27px 0 20px 0;
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  vertical-align: bottom;
`;

export const NotesContainer = styled.div`
  width: 100%;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const Total = styled.span`
  font-size: 22px;
`;

export const TotalShipping = styled.p`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 24px;
  margin-top: 24px;
`;

export const ShippingFee = styled.span`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 24px;
`;

export const TotalNotes = styled.div`
  width: 100%;
  font-size: ${({ theme }) => theme.size.caption};
  
`;

export const TaxesNote = styled.p`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 24px;
  margin-top: 20px;
`;

export const EmptyCartNote = styled.div`
  display: flex;
  width: 100%;
  height: 150px;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 24px;
  margin-top: 20px;
`;
