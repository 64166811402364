import React, { FC, ChangeEventHandler } from 'react';
import {
  Container,
  ContainerInner,
  LogoContainer,
  Logo,
  ECCenter,
  EmailInputContainer,
  EmailInput,
  InputError,
  ECRight,
  SubmitButtonContainer,
  SubmitButtonDesktop,
  SubmitButtonMobile,
  SubmittedContainer,
  EmailContainer,
  Email,
  SubmitSuccessIcon,
  SubmitErrorIcon,
  SubmitEmBlock,
  SubmitError,
  SubmitSuccess,
  SubmitErrorInfo,
  SubmitErrorLink,
  AppLinks,
  AppLink,
  AppLinkImage,
  ECRightSubmitted,
  ECRSLabel,
  ECRSLink,
} from './styles';

interface Props {
  email?: string;
  onEmailChange: ChangeEventHandler<HTMLInputElement>;
  submitted?: boolean;
  submittedEmail?: string;
  submitHasSucceeded?: boolean;
  showBottomDisclaimer?: boolean;
  error?: string;
  onSubmit?: () => void;
  onTryAnotherEmail?: () => void;
  name?: string;
  showName?: boolean;
}

const EmailClaim: FC<Props> = ({
  email = '',
  onEmailChange,
  submitted = false,
  submittedEmail = '',
  submitHasSucceeded = true,
  showBottomDisclaimer,
  error = '',
  onSubmit,
  onTryAnotherEmail,
  name,
  showName,
}) => {
  return (
    <Container className={`${submitted ? 'submitted' : ''}`}>
      <ContainerInner>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <ECCenter>
          {submitted ? (
            <SubmittedContainer>
              <EmailContainer>
                <Email>{showName ? name || '' : submittedEmail}</Email>
                {submitHasSucceeded ? (
                  <SubmitSuccessIcon />
                ) : (
                  <SubmitErrorIcon />
                )}
              </EmailContainer>
              {error && <SubmitEmBlock>{error}</SubmitEmBlock>}
              {submitHasSucceeded ? (
                <SubmitSuccess>
                  You have claimed this page. Download Drum and Sign in with
                  this email address to customize it.
                </SubmitSuccess>
              ) : (
                <SubmitError>
                  {showBottomDisclaimer && (
                    <SubmitErrorInfo>
                      Try logging into Drum or{' '}
                      <SubmitErrorLink onClick={onTryAnotherEmail}>
                        try another email address.
                      </SubmitErrorLink>
                    </SubmitErrorInfo>
                  )}
                </SubmitError>
              )}

              <AppLinks>
                <AppLink
                  target="_blank"
                  href={process.env.REACT_APP_IOS_APP_LINK}
                >
                  <AppLinkImage src="/assets/images/ios-store.png" />
                </AppLink>
                <AppLink
                  target="_blank"
                  href={process.env.REACT_APP_ANDROID_APP_LINK}
                >
                  <AppLinkImage src="/assets/images/google-play.png" />
                </AppLink>
              </AppLinks>
            </SubmittedContainer>
          ) : (
            <EmailInputContainer>
              <EmailInput
                value={email}
                onChange={onEmailChange}
                placeholder="Enter your email to claim your page"
              />
              <InputError>{error}</InputError>
            </EmailInputContainer>
          )}
        </ECCenter>
        {submitted ? (
          <ECRightSubmitted>
            <ECRSLabel>
              Customize your background, colors, and more.{' '}
              <ECRSLink href="http://drum.io" target="_blank">
                Learn more
              </ECRSLink>
            </ECRSLabel>
          </ECRightSubmitted>
        ) : (
          <ECRight>
            <SubmitButtonContainer>
              <SubmitButtonDesktop onClick={onSubmit}>
                Submit
              </SubmitButtonDesktop>
              <SubmitButtonMobile onClick={onSubmit}>Go</SubmitButtonMobile>
            </SubmitButtonContainer>
          </ECRight>
        )}
      </ContainerInner>
    </Container>
  );
};

export default EmailClaim;
