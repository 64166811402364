import { window } from 'global';
import { ProductTypeEnum } from 'graphqlQueries';

const localStorage = window?.localStorage;

export const DRUM_PRODUCT_STORAGE_KEY = '__drumProductStorage__';

export const saveItem = (item: any) => {
  localStorage?.setItem(DRUM_PRODUCT_STORAGE_KEY, JSON.stringify(item));
}

export const getItem = () => {
  const item = localStorage?.getItem(DRUM_PRODUCT_STORAGE_KEY);
  return item ? JSON.parse(item) : undefined;
}

export const clearItems = () => {
  localStorage?.removeItem(DRUM_PRODUCT_STORAGE_KEY);
}

export const getType = (): ProductTypeEnum | undefined => {
  const item = getItem();
  return item?.product?.type as ProductTypeEnum || undefined;
}