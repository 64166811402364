import styled, { DefaultTheme } from 'styled-components';
import { InputTextMixin } from 'uikit';
import { TemplatePreset } from 'graphqlQueries';
import ITemplatePreset from 'types/TemplatePreset';

const InputMixin = (theme: DefaultTheme, styles?: TemplatePreset) => `
  width: 100%;
  padding: 15px 20px;
  border: 1px solid ${theme.colors.borderColor};
  box-sizing: border-box;
  background: transparent;
  background-color: ${theme.colors.white};
  border-radius: ${styles?.primaryButton?.borderRadius || 0}px;
  ${InputTextMixin};

  ::placeholder {
    color: ${theme.colors.inputColor};
  }

  :hover:not(:disabled) {
    border-color: ${theme.colors.accentMainColor};
  }

  :focus:not(:disabled) {
    border-color: ${theme.colors.inputColor};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }
`;

export const InputBase = styled.input<ITemplatePreset>(
  ({ styles, theme }) => `
  ${InputMixin(theme, styles)};
`);

export const TextareaBase = styled.textarea<ITemplatePreset>(
  ({ styles, theme }) => `
  ${InputMixin(theme, styles)};
`);

export const InputFooter = styled.div`
  display: flex;
  align-self: center;
  margin-top: 5px;
`;
