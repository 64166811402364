import React, { FC, ReactNode } from 'react';
import { getImagePath } from 'helpers';
import makeFullNameAndUserAvatarSubstitute from 'utils/makeFullNameAndUserAvatarSubstitute';
import SocialMediaLinks from 'components/SocialMediaLinks';
import {
  Container,
  UserAvatarContainer,
  UserAvatarSubstitute,
  UserName,
  UserDescriptionContainer,
  UserSubTitle,
  UserDescription,
} from './styles';
import {
  ImageStyleName,
  SocialAccount,
  TemplatePreset
} from 'graphqlQueries';

interface Props {
  user?: any;
  title?: string;
  subTitle?: string;
  description?: string;
  socials?: Partial<SocialAccount>[];
  onSocialClick?: (item: SocialAccount) => void;
  children?: ReactNode;
  styles?: TemplatePreset;
}

const UserInfoSmall: FC<Props> = ({ user, title = '', subTitle = '', description = '', socials, onSocialClick, children, styles }) => {
  const { fullNameFormatted } =
    makeFullNameAndUserAvatarSubstitute(user, '');

  const avatarSrc = user?.avatarImage?.styles
    ? getImagePath(user?.avatarImage.styles, ImageStyleName.Original)
    : user?.avatarImage?.url || null;

  return (
    <Container>
      <UserAvatarContainer styles={styles} style={{ backgroundImage: avatarSrc ? `url('${avatarSrc}')` : "none" }}>
        {!avatarSrc &&
          <UserAvatarSubstitute styles={styles} style={{ backgroundImage: `url('/assets/avatar-placeholder.svg')` }}></UserAvatarSubstitute>
        }
      </UserAvatarContainer>
      <UserName styles={styles}>{title || fullNameFormatted || (!!user?.username && `@${user?.username}`)}</UserName>
      {!!socials?.length && <SocialMediaLinks items={socials} onItemClick={onSocialClick} />}
      {(description || subTitle) &&
        <UserDescriptionContainer>
          <UserSubTitle styles={styles}>{subTitle}</UserSubTitle>
          <UserDescription styles={styles}>{description}</UserDescription>
        </UserDescriptionContainer>
      }
      {children}
    </Container>
  );
};

export default UserInfoSmall;
