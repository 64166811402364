import styled, { css } from 'styled-components';
import { PrimaryButton, ButtonBase } from 'uikit';
import DefaultAddressForm from '../AddressForm';

export const innerContainerMixin = css`
  width: 100%;
  max-width: 360px;
  box-sizing: border-box;
  margin: 0 auto;
`;

export const Container = styled.div`
  ${innerContainerMixin};
  
  padding-top: 20px;
`;

export const FormGroup = styled.div`
  margin-bottom: 38px;

  &.bigger-margin {
    margin-bottom: 71px;
  }
`;

export const ButtonsContainer = styled.div`
  margin-top: 40px;
`;

export const SubmitButton = styled(PrimaryButton)`
  width: 100%;
  height: 56px;
  border-radius: 8px;
`;

export const CancelButton = styled(ButtonBase)`
  width: 100%;
  height: 56px;
  background: transparent;
  color: ${({ theme }) => theme.colors.dlpPurchaseCancelButtonColor};
  border-radius: 8px;
  box-shadow: none;
  border: none;

  &:hover,
  &:active,
  &:focus {
    background: transparent !important;
    color: ${({ theme }) => theme.colors.dlpPurchaseCancelButtonColor};
    text-decoration: underline;
  }
`;

export const AddressForm = styled(DefaultAddressForm)`

`;