import styled, { Theme } from 'styled-components';
import { TemplatePreset } from 'graphqlQueries';

import { 
  ContainerBase,
  ContainerInnerBase,
  ImageBase,
  ContentBase,
  ContentInnerBase,
  InfoBase,
  BusinessImageContainerBase,
  BusinessImageBase,
  BusinessNameBase,
  DescriptionBase
} from 'uikit/LinkItems';

interface Props {
  backgroundColor?: string | null;
  theme: Theme;
  styles?: TemplatePreset;
}

export const Container = styled(ContainerBase)<Props>(({backgroundColor, theme, styles}) => `
  position: relative;

  margin-bottom: ${styles?.link?.collection?.showBottomDecorations ? `calc(14px + ${styles?.link?.base?.gap || 0}px)` : styles?.link?.base?.gap};
  &:not(:last-child) {
    margin-bottom: ${styles?.link?.collection?.showBottomDecorations ? `calc(14px + ${styles?.link?.base?.gap || 0}px)` : styles?.link?.base?.gap};
  }

  // &:last-child {
  //   margin-bottom: 14px;
  // }
`);

export const ContainerInner = styled(ContainerInnerBase)``;

export const Image = styled(ImageBase)``;
export const Content = styled(ContentBase)``;
export const ContentInner = styled(ContentInnerBase)``;
export const Info = styled(InfoBase)``;
export const BusinessImageContainer = styled(BusinessImageContainerBase)``;
export const BusinessImage = styled(BusinessImageBase)``;
export const BusinessName = styled(BusinessNameBase)``;
export const Description = styled(DescriptionBase)``;

export const EmptyImageBlock = styled.div<Props>(({styles}) => `
  width: 49px;
  height: 49px;
  background: ${({ theme }) => theme.colors.dlpLinkItemEmptyCollectionBg};
  box-shadow: 0px 0px 6px ${({ theme }) => theme.colors.dlpLinkItemEmptyCollectionBoxShadowColor};
  border-radius: ${styles?.link?.base?.borderRadius || 0}px;
`);

export const Decoration = styled.div<Props>(({backgroundColor, theme, styles}) => `
  display: ${styles?.link?.collection?.showBottomDecorations ? "block" : "none"};
  position: absolute;
  content: '';
  box-sizing: border-box;
  height: 7px;
  border-radius: 0 0 7px 7px;
  background-color: ${styles?.link?.base?.backgroundColor || backgroundColor || theme.colors.dlpDefaultCardBgColor};
  color: inherit;
  box-shadow: 0px 1px 10px ${theme.colors.dlpLinkItemCollectionDecorationShadowColor};

  &:nth-of-type(1) {
    width: calc(100% - 14px);
    bottom: -7px;
    left: 7px;
    z-index: 2;
  }

  &:nth-of-type(2) {
    width: calc(100% - 28px);
    bottom: -14px;
    left: 14px;
    z-index: 1;
  }
`);

export const ItemsCount = styled.div<Props>(({styles}) => `
  position: absolute;
  bottom: 4px;
  right: 7px;
  display: ${styles?.link?.collection?.showItemCount ? "flex" : "none"};
  color: ${styles?.link?.collection?.itemCountColor || "inherit"};
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  line-height: 18px;
  margin-top: 13px;
  margin-bottom: 0;
  opacity: 1;

  @media(max-width: 600px) {
    right: 0;
  } 
`);