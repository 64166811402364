import React, { FC } from 'react';
import { Emphasized } from 'uikit';
import { IComponentParams } from '../../types';
import { Title, SubTitle, Paragraph } from '../../styles';

const PhysicalPurchaseConfirmation: FC<IComponentParams> = ({orderData}) => {
  return (
    <>
      <Title>Thank You</Title>
      <SubTitle>Purchase Confirmation</SubTitle>   
      <Paragraph>Thank you for your purchase! A confirmation email has been sent to the email you provided:{' '}
      <Emphasized>{orderData?.email}</Emphasized>
      </Paragraph>  
    </>
  );
};

export default PhysicalPurchaseConfirmation;