import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100%;
  
  .react-tel-input .flag {
    width: 48px;
    height: 32px;
    background-image: url('${process.env.REACT_APP_AWS_S3_DOMAIN}/assets/images/flags.png');
  }

  .react-tel-input .selected-flag .flag {
    top: 0;
  }

  .react-tel-input .ad {
    background-position: -49px 0;
  }
  .react-tel-input .ae {
    background-position: -99px 0;
  }
  .react-tel-input .af {
    background-position: -149px 0;
  }
  .react-tel-input .ag {
    background-position: -199px 0;
  }
  .react-tel-input .ai {
    background-position: -249px 0;
  }
  .react-tel-input .al {
    background-position: -299px 0;
  }
  .react-tel-input .am {
    background-position: -349px 0;
  }
  .react-tel-input .ao {
    background-position: -449px 0;
  }
  .react-tel-input .ar {
    background-position: -499px 0;
  }
  .react-tel-input .as {
    background-position: -499px 0;
  }
  .react-tel-input .at {
    background-position: -599px 0;
  }
  .react-tel-input .au {
    background-position: -649px 0;
  }
  .react-tel-input .aw {
    background-position: -699px 0;
  }
  .react-tel-input .az {
    background-position: -499px 0;
  }
  .react-tel-input .ba {
    background-position: -49px -32px;
  }
  .react-tel-input .bb {
    background-position: -99px -32px;
  }
  .react-tel-input .bd {
    background-position: -149px -32px;
  }
  .react-tel-input .be {
    background-position: -199px -32px;
  }
  .react-tel-input .bf {
    background-position: -249px -32px;
  }
  .react-tel-input .bg {
    background-position: -299px -32px;
  }
  .react-tel-input .bh {
    background-position: -349px -32px;
  }
  .react-tel-input .bi {
    background-position: -399px -32px;
  }
  .react-tel-input .bj {
    background-position: -449px -32px;
  }
  .react-tel-input .bm {
    background-position: -128px -11px;
  }
  .react-tel-input .bn {
    background-position: -599px -32px;
  }
  .react-tel-input .bo {
    background-position: -649px -32px;
  }
  .react-tel-input .br {
    background-position: -699px -32px;
  }
  .react-tel-input .bs {
    background-position: -749px -32px;
  }
  .react-tel-input .bt {
    background-position: -0px -64px;
  }
  .react-tel-input .bw {
    background-position: -49px -64px;
  }
  .react-tel-input .by {
    background-position: -99px -64px;
  }
  .react-tel-input .bz {
    background-position: -149px -64px;
  }
  .react-tel-input .ca {
    background-position: -199px -64px;
  }
  .react-tel-input .cd {
    background-position: -249px -64px;
  }
  .react-tel-input .cf {
    background-position: -299px -64px;
  }
  .react-tel-input .cg {
    background-position: -349px -64px;
  }
  .react-tel-input .ch {
    background-position: -399px -64px;
  }
  .react-tel-input .ci {
    background-position: -449px -64px;
  }
  .react-tel-input .ck {
    background-position: -112px -22px;
  }
  .react-tel-input .cl {
    background-position: -549px -64px;
  }
  .react-tel-input .cm {
    background-position: -599px -64px;
  }
  .react-tel-input .cn {
    background-position: -649px -64px;
  }
  .react-tel-input .co {
    background-position: -699px -64px;
  }
  .react-tel-input .cr {
    background-position: -749px -64px;
  }
  .react-tel-input .cu {
    background-position: -0 -96px;
  }
  .react-tel-input .cv {
    background-position: -49px -96px;
  }
  .react-tel-input .cw {
    background-position: -99px -96px;
  }
  .react-tel-input .cy {
    background-position: -149px -96px;
  }
  .react-tel-input .cz {
    background-position: -199px -96px;
  }
  .react-tel-input .de {
    background-position: -249px -96px;
  }
  .react-tel-input .dj {
    background-position: -299px -96px;
  }
  .react-tel-input .dk {
    background-position: -349px -96px;
  }
  .react-tel-input .dm {
    background-position: -399px -96px;
  }
  .react-tel-input .do {
    background-position: -449px -96px;
  }
  .react-tel-input .dz {
    background-position: -499px -96px;
  }
  .react-tel-input .ec {
    background-position: -549px -96px;
  }
  .react-tel-input .ee {
    background-position: -599px -96px;
  }
  .react-tel-input .eg {
    background-position: -649px -96px;
  }
  .react-tel-input .er {
    background-position: -749px -96px;
  }
  .react-tel-input .es {
    background-position: -0px -128px;
  }
  .react-tel-input .et {
    background-position: -49px -128px;
  }
  .react-tel-input .fi {
    background-position: -149px -128px;
  }
  .react-tel-input .fj {
    background-position: -199px -128px;
  }
  .react-tel-input .fk {
    background-position: 0 -44px;
  }
  .react-tel-input .fm {
    background-position: -299px -128px;
  }
  .react-tel-input .fo {
    background-position: -32px -44px;
  }
  .react-tel-input .fr,
  .react-tel-input .bl,
  .react-tel-input .mf {
    background-position: -399px -128px;
  }
  .react-tel-input .ga {
    background-position: -449px -128px;
  }
  .react-tel-input .gb {
    background-position: -499px -128px;
  }
  .react-tel-input .gd {
    background-position: -549px -128px;
  }
  .react-tel-input .ge {
    background-position: -599px -128px;
  }
  .react-tel-input .gf {
    background-position: -399px -128px;
  }
  .react-tel-input .gh {
    background-position: -699px -128px;
  }
  .react-tel-input .gi {
    background-position: -160px -44px;
  }
  .react-tel-input .gl {
    background-position: -176px -44px;
  }
  .react-tel-input .gm {
    background-position: -49px -160px;
  }
  .react-tel-input .gn {
    background-position: -99px -160px;
  }
  .react-tel-input .gp {
    background-position: -399px -128px;
  }
  .react-tel-input .gq {
    background-position: -149px -160px;
  }
  .react-tel-input .gr {
    background-position: -199px -160px;
  }
  .react-tel-input .gt {
    background-position: -299px -160px;
  }
  .react-tel-input .gu {
    background-position: -349px -160px;
  }
  .react-tel-input .gw {
    background-position: -399px -160px;
  }
  .react-tel-input .gy {
    background-position: -449px -160px;
  }
  .react-tel-input .hk {
    background-position: -499px -160px;
  }
  .react-tel-input .hn {
    background-position: -549px -160px;
  }
  .react-tel-input .hr {
    background-position: -599px -160px;
  }
  .react-tel-input .ht {
    background-position: -649px -160px;
  }
  .react-tel-input .hu {
    background-position: -699px -160px;
  }
  .react-tel-input .id {
    background-position: -199px -256px;
  }
  .react-tel-input .ie {
    background-position: -49px -192px;
  }
  .react-tel-input .il {
    background-position: -99px -192px;
  }
  .react-tel-input .in {
    background-position: -199px -192px;
  }
  .react-tel-input .io {
    background-position: -249px -128px;
  }
  .react-tel-input .iq {
    background-position: -249px -192px;
  }
  .react-tel-input .ir {
    background-position: -299px -192px;
  }
  .react-tel-input .is {
    background-position: -349px -192px;
  }
  .react-tel-input .it {
    background-position: -399px -192px;
  }
  .react-tel-input .je {
    background-position: -144px -154px;
  }
  .react-tel-input .jm {
    background-position: -499px -192px;
  }
  .react-tel-input .jo {
    background-position: -549px -192px;
  }
  .react-tel-input .jp {
    background-position: -599px -192px;
  }
  .react-tel-input .ke {
    background-position: -649px -192px;
  }
  .react-tel-input .kg {
    background-position: -699px -192px;
  }
  .react-tel-input .kh {
    background-position: -749px -192px;
  }
  .react-tel-input .ki {
    background-position: 0 -224px;
  }
  .react-tel-input .xk {
    background-position: 0 0;
  }
  .react-tel-input .km {
    background-position: -49px -224px;
  }
  .react-tel-input .kn {
    background-position: -99px -224px;
  }
  .react-tel-input .kp {
    background-position: -149px -224px;
  }
  .react-tel-input .kr {
    background-position: -199px -224px;
  }
  .react-tel-input .kw {
    background-position: -249px -224px;
  }
  .react-tel-input .ky {
    background-position: -240px -66px;
  }
  .react-tel-input .kz {
    background-position: -349px -224px;
  }
  .react-tel-input .la {
    background-position: -399px -224px;
  }
  .react-tel-input .lb {
    background-position: -449px -224px;
  }
  .react-tel-input .lc {
    background-position: -499px -224px;
  }
  .react-tel-input .li {
    background-position: -549px -224px;
  }
  .react-tel-input .lk {
    background-position: -599px -224px;
  }
  .react-tel-input .lr {
    background-position: -649px -224px;
  }
  .react-tel-input .ls {
    background-position: -699px -224px;
  }
  .react-tel-input .lt {
    background-position: -749px -224px;
  }
  .react-tel-input .lu {
    background-position: -0px -256px;
  }
  .react-tel-input .lv {
    background-position: -49px -256px;
  }
  .react-tel-input .ly {
    background-position: -99px -256px;
  }
  .react-tel-input .ma {
    background-position: -149px -256px;
  }
  .react-tel-input .mc {
    background-position: -199px -256px;
  }
  .react-tel-input .md {
    background-position: -249px -256px;
  }
  .react-tel-input .me {
    background-position: -299px -256px;
  }
  .react-tel-input .mg {
    background-position: -399px -256px;
  }
  .react-tel-input .mh {
    background-position: -449px -256px;
  }
  .react-tel-input .mk {
    background-position: -499px -256px;
  }
  .react-tel-input .ml {
    background-position: -549px -256px;
  }
  .react-tel-input .mm {
    background-position: -599px -256px;
  }
  .react-tel-input .mn {
    background-position: -649px -256px;
  }
  .react-tel-input .mo {
    background-position: -699px -256px;
  }
  .react-tel-input .mp {
    background-position: -112px -88px;
  }
  .react-tel-input .mq {
    background-position: -399px -128px;
  }
  .react-tel-input .mr {
    background-position: -49px -288px;
  }
  .react-tel-input .ms {
    background-position: -160px -88px;
  }
  .react-tel-input .mt {
    background-position: -149px -288px;
  }
  .react-tel-input .mu {
    background-position: -199px -288px;
  }
  .react-tel-input .mv {
    background-position: -249px -288px;
  }
  .react-tel-input .mw {
    background-position: -299px -288px;
  }
  .react-tel-input .mx {
    background-position: -349px -288px;
  }
  .react-tel-input .my {
    background-position: -399px -288px;
  }
  .react-tel-input .mz {
    background-position: -449px -288px;
  }
  .react-tel-input .na {
    background-position: -499px -288px;
  }
  .react-tel-input .nc {
    background-position: -399px -128px;
  }
  .react-tel-input .ne {
    background-position: -599px -288px;
  }
  .react-tel-input .nf {
    background-position: -80px -99px;
  }
  .react-tel-input .ng {
    background-position: -699px -288px;
  }
  .react-tel-input .ni {
    background-position: -749px -288px;
  }
  .react-tel-input .nl,
  .react-tel-input .bq {
    background-position: 0 -320px;
  }
  .react-tel-input .no {
    background-position: -49px -320px;
  }
  .react-tel-input .np {
    background-position: -99px -320px;
  }
  .react-tel-input .nr {
    background-position: -149px -320px;
  }
  .react-tel-input .nu {
    background-position: -192px -99px;
  }
  .react-tel-input .nz {
    background-position: -249px -320px;
  }
  .react-tel-input .om {
    background-position: -299px -320px;
  }
  .react-tel-input .pa {
    background-position: -349px -320px;
  }
  .react-tel-input .pe {
    background-position: -399px -320px;
  }
  .react-tel-input .pf {
    background-position: -449px -320px;
  }
  .react-tel-input .pg {
    background-position: -499px -320px;
  }
  .react-tel-input .ph {
    background-position: -549px -320px;
  }
  .react-tel-input .pk {
    background-position: -599px -320px;
  }
  .react-tel-input .pl {
    background-position: -649px -320px;
  }
  .react-tel-input .pm {
    background-position: -96px -110px;
  }
  .react-tel-input .pr {
    background-position: -749px -320px;
  }
  .react-tel-input .ps {
    background-position: -0px -352px;
  }
  .react-tel-input .pt {
    background-position: -49px -352px;
  }
  .react-tel-input .pw {
    background-position: -99px -352px;
  }
  .react-tel-input .py {
    background-position: -149px -352px;
  }
  .react-tel-input .qa {
    background-position: -199px -352px;
  }
  .react-tel-input .re {
    background-position: -399px -128px;
  }
  .react-tel-input .ro {
    background-position: -249px -352px;
  }
  .react-tel-input .rs {
    background-position: -299px -352px;
  }
  .react-tel-input .ru {
    background-position: -349px -352px;
  }
  .react-tel-input .rw {
    background-position: -399px -352px;
  }
  .react-tel-input .sa {
    background-position: -449px -352px;
  }
  .react-tel-input .sb {
    background-position: -499px -352px;
  }
  .react-tel-input .sc {
    background-position: -549px -352px;
  }
  .react-tel-input .sd {
    background-position: -599px -352px;
  }
  .react-tel-input .se {
    background-position: -649px -352px;
  }
  .react-tel-input .sg {
    background-position: -699px -352px;
  }
  .react-tel-input .sh {
    background-position: -128px -121px;
  }
  .react-tel-input .si {
    background-position: -0px -384px;
  }
  .react-tel-input .sk {
    background-position: -49px -384px;
  }
  .react-tel-input .sl {
    background-position: -99px -384px;
  }
  .react-tel-input .sm {
    background-position: -149px -384px;
  }
  .react-tel-input .sn {
    background-position: -199px -384px;
  }
  .react-tel-input .so {
    background-position: -249px -384px;
  }
  .react-tel-input .sr {
    background-position: -299px -384px;
  }
  .react-tel-input .ss {
    background-position: -349px -384px;
  }
  .react-tel-input .st {
    background-position: -399px -384px;
  }
  .react-tel-input .sv {
    background-position: -449px -384px;
  }
  .react-tel-input .sx {
    background-position: -48px -132px;
  }
  .react-tel-input .sy {
    background-position: -499px -384px;
  }
  .react-tel-input .sz {
    background-position: -549px -384px;
  }
  .react-tel-input .tc {
    background-position: -96px -132px;
  }
  .react-tel-input .td {
    background-position: -249px -352px;
  }
  .react-tel-input .tg {
    background-position: -749px -384px;
  }
  .react-tel-input .th {
    background-position: -0px -416px;
  }
  .react-tel-input .tj {
    background-position: -49px -416px;
  }
  .react-tel-input .tk {
    background-position: -176px -132px;
  }
  .react-tel-input .tl {
    background-position: -149px -416px;
  }
  .react-tel-input .tm {
    background-position: -199px -416px;
  }
  .react-tel-input .tn {
    background-position: -249px -416px;
  }
  .react-tel-input .to {
    background-position: -299px -416px;
  }
  .react-tel-input .tr {
    background-position: -349px -416px;
  }
  .react-tel-input .tt {
    background-position: -399px -416px;
  }
  .react-tel-input .tv {
    background-position: -449px -416px;
  }
  .react-tel-input .tw {
    background-position: -499px -416px;
  }
  .react-tel-input .tz {
    background-position: -549px -416px;
  }
  .react-tel-input .ua {
    background-position: -599px -416px;
  }
  .react-tel-input .ug {
    background-position: -649px -416px;
  }
  .react-tel-input .us {
    background-position: -699px -416px;
  }
  .react-tel-input .uy {
    background-position: -749px -416px;
  }
  .react-tel-input .uz {
    background-position: -0px -448px;
  }
  .react-tel-input .va {
    background-position: -49px -448px;
  }
  .react-tel-input .vc {
    background-position: -99px -448px;
  }
  .react-tel-input .ve {
    background-position: -149px -448px;
  }
  .react-tel-input .vg {
    background-position: -208px -143px;
  }
  .react-tel-input .vi {
    background-position: -224px -143px;
  }
  .react-tel-input .vn {
    background-position: -299px -448px;
  }
  .react-tel-input .vu {
    background-position: 0 -160px;
  }
  .react-tel-input .wf {
    background-position: -16px -154px;
  }
  .react-tel-input .ws {
    background-position: -449px -448px;
  }
  .react-tel-input .ye {
    background-position: -499px -448px;
  }
  .react-tel-input .za {
    background-position: -599px -448px;
  }
  .react-tel-input .zm {
    background-position: -649px -448px;
  }
  .react-tel-input .zw {
    background-position: -699px -448px;
  }

`;
