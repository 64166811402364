import styled from 'styled-components';
import { LinkTextMixin } from 'uikit';

export const Container = styled.div`
  font-family: Roboto;
`;

export const Text = styled.p`
  margin-bottom: 32px;
  line-height: 26px;
`;

export const AppLink = styled.a`
  ${LinkTextMixin};
`;

export const DownloadButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 32px auto 0 auto;
  
  img {
    width: 133px;
    height: 40px;
    box-sizing: border-box;
  }
`;