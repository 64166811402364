import styled from 'styled-components';
import Loader from './Loader';

export interface IDotProps {
  color: string; 
}

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const OverlayAbsolute = styled(Overlay)`
  position: absolute;
  border-radius: 11px;
`;

export const StyledLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const DotContainer = styled.div`
  width: 100%;
  position: relative;
  text-align: center;
`;
export const DotContainerInner = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
export const Dot = styled.div<IDotProps>(
  ({ color }) => `
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${color};
  color: ${color};
  animation: dotFrames 0.6s infinite linear alternate;
  animation-delay: .3s;

  &::before, &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: -15px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${color};
    color: ${color};
    animation: dotFrames 0.6s infinite alternate;
    animation-delay: 0s;
  }

  &::after {
    left: 15px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${color};
    color: ${color};
    animation: dotFrames 0.6s infinite alternate;
    animation-delay: .6s;
  }
`);
