
import React, { FC } from 'react';
import { FullScreenLoader } from 'uikit';


const ConnectPayment: FC = () => {
   return (
      <FullScreenLoader />
   )
}


export default ConnectPayment