import React, { FC, useState, useEffect } from 'react';
import Big from 'big-js';
import { Formik, useFormikContext, FormikErrors } from 'formik';
import Braintree from 'braintree-web';
import { useSelector, useDispatch } from 'react-redux';
import NumberFormat from 'react-number-format';
import { RootState } from 'redux/store';
import actions from 'redux/actions';
import { getType as getCartItemType } from 'utils/checkoutManagement';
import ShippingHandling from 'components/ShippingHandling';
import PrimaryButtonStyled from 'components/PrimaryButtonStyled';
import CancelButtonStyled from 'components/CancelButtonStyled';
import { hideEmail } from 'helpers';
import { CardVisa, CardMasterCard, CardAmex, CardDiscover } from 'assets/svg';
import {
  validationSchemaNonPhysical,
  validationSchemaPhysical,
  SchemaTypeNonPhysical,
  SchemaTypePhysical,
} from './validationSchema';
import { ICartItem, PaymentMethods, AddressOptions } from '../../types';
import { getMoneyFormat, getTotalSum, getTotalItems } from '../../utils';
import {
  LinkStyleConfiguration,
  ProductTypeEnum,
  TemplatePreset,
} from 'graphqlQueries';
import {
  InputContainer,
  InputGroup,
  InputBase,
  InputLabel,
  FormGroupDivider2,
  FieldFooter,
  CardExpirationInput,
  CardNumberInput,
  FullScreenLoader,
  FormSeparator,
  Body2,
  Emphasized,
} from 'uikit';
import {
  Container,
  PaymentGroup,
  AddressGroup,
  AddressForm,
  GroupLabel,
  GroupSubLabel,
  ItemsContainer,
  ItemsContainerInner,
  ItemsLeft,
  ItemsQuantity,
  IQEm,
  ItemsRight,
  Price,
  TotalContainer,
  TotalSum,
  Form,
  CreditCardsContainer,
  RadioGroup,
  RadioContainer,
  RadioButton,
  PaypalButtonContainer,
  PaymentDetails,
  PaymentInfoContainer,
  PaymentInfo,
  PaymentIconContainer,
  PaymentIcon,
  ChangePaymentLinkContainer,
  ChangePaymentLink,
} from './styles';
import { ButtonsContainer } from '../../styles';

interface IPayment {
  items: ICartItem[];
  tax?: number;
  inputStyles?: Pick<
    LinkStyleConfiguration,
    'background_color' | 'color' | 'font_family'
  > | null;
  templateStyles?: TemplatePreset;
  onSubmit: (
    validateForm: (
      values?: SchemaTypeNonPhysical | SchemaTypePhysical
    ) => Promise<FormikErrors<SchemaTypeNonPhysical | SchemaTypePhysical>>,
    setSubmitting: (isSubmitting: boolean) => void,
    values: SchemaTypeNonPhysical | SchemaTypePhysical
  ) => void;
  onCancel?: () => void;
  onStateChange?: (values: SchemaTypeNonPhysical | SchemaTypePhysical) => void;
  onZipChange?: (values: SchemaTypeNonPhysical | SchemaTypePhysical) => void;
  shouldValidateOnChange?: boolean;
  taxIsCalculating?: boolean;
}

const addressFields = [
  'billing.firstName',
  'billing.lastName',
  'billing.street',
  'billing.appartment',
  'billing.city',
  'billing.state',
  'billing.zipCode',
];

const Payment: FC<IPayment> = ({
  items,
  tax = 0,
  inputStyles,
  templateStyles,
  onSubmit,
  onCancel,
  onStateChange,
  onZipChange,
  taxIsCalculating = false,
}) => {
  const {
    errors,
    handleChange,
    values,
    setFieldValue,
    setSubmitting,
    validateForm,
    setErrors,
  } = useFormikContext<SchemaTypeNonPhysical | SchemaTypePhysical>();
  const dispatch = useDispatch();
  let payPalButtons;

  const [paypalLoading, setPaypalLoading] = useState<boolean>(false);

  const paypalPayload = useSelector(
    (state: RootState) => state.checkout.paypalPayload
  );
  const braintreeToken = useSelector(
    (state: RootState) => state.checkout.braintreeToken
  );

  let braintreeClient;
  let paypalInstance;

  const createPaypalWindow = async () => {
    if (paypalLoading || paypalPayload || taxIsCalculating) {
      return;
    }

    if (!braintreeToken) {
      //Place some error here
      return;
    }

    setPaypalLoading(true);

    try {
      braintreeClient = await Braintree.client.create({
        authorization: braintreeToken,
      });

      paypalInstance = await Braintree.paypalCheckout.create({
        client: braintreeClient,
      });

      const paypalBlock = document?.getElementById?.('paypal-button');
      if (paypalBlock) {
        paypalBlock.innerHTML = '';
      }

      const paypal = window?.paypal as any;
      payPalButtons = paypal
        ?.Buttons({
          locale: 'en_US',
          style: {
            color: 'white',
            size: 'large',
            shape: 'rect',
            tagline: false,
          },
          // when createPayment resolves, it is automatically passed to checkout.js
          onClick: () => {
            setFieldValue('payment.paymentMethod', PaymentMethods.payPal);
          },
          createOrder: () => {
            return paypalInstance.createPayment({
              flow: 'checkout',
              currency: 'USD',
              amount: Big((window as any)?.__paymentPageTax || 0)
                .plus(getTotalSum(items, false))
                .valueOf(),
              intent: 'capture',
            });
          },
          onApprove: (data) => {
            return paypalInstance
              .tokenizePayment(data)
              .then(function (payload) {
                dispatch(actions.checkout.setPaypalPayload(payload));
              })
              .catch(function (err) {
                console.log('Error', err);
                setErrors({
                  payment: {
                    paymentMethod: err,
                  },
                });
              });
          },
          onCancel: function () {
            setFieldValue('payment.paymentMethod', PaymentMethods.creditCard);
          },

          onError: function () {
            setFieldValue('payment.paymentMethod', PaymentMethods.creditCard);
          },
        })
        .render('#paypal-button');
    } catch (err: any) {
      console.log('createPaypalWindow::err', err);
      if (err?.code === 'PAYPAL_POPUP_CLOSED') {
        //handlePaypalPopupClosed();
        return;
      }
      throw err;
    } finally {
      setPaypalLoading(false);
    }
  };

  const handleCancel = () => {
    onCancel?.();
  };

  const handleChangePaymentMethod = () => {
    setFieldValue('payment.paymentMethod', PaymentMethods.creditCard);
    payPalButtons?.close?.();
    dispatch(actions.checkout.setPaypalPayload(undefined));
  };

  const handleSubmit = () => {
    onSubmit?.(validateForm, setSubmitting, values);
  };

  useEffect(() => {
    dispatch(actions.checkout.setCheckoutPaymentForm(values));
    // eslint-disable-next-line
  }, [values]);

  useEffect(() => {
    onStateChange?.(values);
    // eslint-disable-next-line
  }, [values.billing.state]);

  useEffect(() => {
    onZipChange?.(values);
    // eslint-disable-next-line
  }, [values.billing.zipCode]);

  // useEffect(() => {
  //   if (braintreeToken) {
  //     createPaypalWindow();
  //   }
  // }, [braintreeToken]);

  useEffect(() => {
    if (window) {
      (window as any).__paymentPageTax = tax; //Paypal caches data from local variables, so using window here. UseCallback or useMemo don't work
    }
  }, [tax]);

  useEffect(() => {
    if (!paypalPayload && braintreeToken) {
      setTimeout(() => {
        createPaypalWindow();
      });
    }
    // eslint-disable-next-line
  }, [paypalPayload, braintreeToken]);

  return (
    <>
      {!braintreeToken ? (
        <FullScreenLoader />
      ) : (
        <Container>
          <Form onSubmit={handleSubmit}>
            {/* Credit cards group */}
            <PaymentGroup>
              {values.payment.paymentMethod === PaymentMethods.creditCard && (
                <>
                  <GroupLabel>Payment Method</GroupLabel>
                  <GroupSubLabel>
                    All transactions are secure and encrypted
                  </GroupSubLabel>
                </>
              )}

              {values.payment.paymentMethod === PaymentMethods.payPal && (
                <>
                  <PaymentDetails>
                    <PaymentInfoContainer>
                      <Body2>Checkout with</Body2>
                      <PaymentInfo>
                        <Emphasized>PayPal</Emphasized>
                        <span>
                          {hideEmail(paypalPayload?.details?.email || '')}
                        </span>
                      </PaymentInfo>
                    </PaymentInfoContainer>
                    <PaymentIconContainer>
                      <PaymentIcon>
                        <img src="/assets/paypal-logo.png" alt="paypal" />
                      </PaymentIcon>
                    </PaymentIconContainer>
                  </PaymentDetails>
                  <ChangePaymentLinkContainer>
                    <ChangePaymentLink onClick={handleChangePaymentMethod}>
                      Change Payment Method
                    </ChangePaymentLink>
                  </ChangePaymentLinkContainer>
                  {/* <GroupLabel>Checkout With PayPal</GroupLabel>
                  {paypalPayload?.details?.email &&
                    <PaypalEmail>{hideEmail(paypalPayload.details.email)}</PaypalEmail>
                  } */}
                </>
              )}

              {values.payment.paymentMethod === PaymentMethods.creditCard && (
                <RadioGroup>
                  <RadioContainer className="large-padding">
                    <GroupLabel>Credit Card</GroupLabel>
                    <CreditCardsContainer>
                      <CardVisa />
                      <CardMasterCard />
                      <CardAmex />
                      <CardDiscover />
                    </CreditCardsContainer>
                  </RadioContainer>

                  <InputContainer>
                    <InputGroup styles={templateStyles}>
                      <InputLabel>Card Number</InputLabel>
                      <CardNumberInput
                        name="payment.cardNumber"
                        onChange={(value) => {
                          setFieldValue('payment.cardNumber', value);
                        }}
                        value={values.payment.cardNumber || ''}
                      />
                    </InputGroup>
                    <FieldFooter
                      error={errors.payment?.cardNumber}
                      showAlways={false}
                    />
                  </InputContainer>

                  <InputContainer>
                    <InputGroup styles={templateStyles}>
                      <InputLabel>Name on card</InputLabel>
                      <InputBase
                        name="payment.cardName"
                        onChange={handleChange}
                        value={values.payment.cardName || ''}
                        styles={templateStyles}
                      />
                    </InputGroup>
                    <FieldFooter
                      error={errors.payment?.cardName}
                      showAlways={false}
                    />
                  </InputContainer>

                  <FormGroupDivider2>
                    <InputContainer>
                      <InputGroup styles={templateStyles}>
                        <InputLabel>Expiration date</InputLabel>
                        <CardExpirationInput
                          name="payment.expirationDate"
                          onChange={(value) => {
                            setFieldValue('payment.expirationDate', value);
                          }}
                          value={values.payment.expirationDate || ''}
                        />
                      </InputGroup>
                      <FieldFooter
                        error={errors.payment?.expirationDate}
                        showAlways={false}
                      />
                    </InputContainer>

                    <InputContainer>
                      <InputGroup styles={templateStyles}>
                        <InputLabel>Security Code</InputLabel>
                        <NumberFormat
                          name="payment.securityCode"
                          onChange={handleChange}
                          value={values.payment.securityCode || ''}
                          pattern="[0-9]*"
                          maxLength={4}
                        />
                      </InputGroup>
                      <FieldFooter
                        error={errors.payment?.securityCode}
                        showAlways={false}
                      />
                    </InputContainer>
                  </FormGroupDivider2>
                </RadioGroup>
              )}

              {!paypalPayload && (
                <>
                  <GroupLabel>Or</GroupLabel>
                  <PaypalButtonContainer id="paypal-button"></PaypalButtonContainer>
                </>
              )}
            </PaymentGroup>

            {/* Address group */}
            {getCartItemType() === ProductTypeEnum.PhysicalProduct ? (
              <AddressGroup>
                <FormSeparator />
                <GroupLabel>Billing Address</GroupLabel>

                <RadioGroup>
                  <RadioContainer>
                    <RadioButton
                      id={AddressOptions.same}
                      name="payment.billingAddressOption"
                      label="Same as shipping address"
                    />
                  </RadioContainer>
                </RadioGroup>

                <RadioGroup>
                  <RadioContainer>
                    <RadioButton
                      id={AddressOptions.different}
                      name="payment.billingAddressOption"
                      label="Use a different billing address"
                    />
                  </RadioContainer>
                </RadioGroup>

                {values.payment.billingAddressOption === 'different' && (
                  <AddressForm
                    fieldsArray={addressFields}
                    templateStyles={templateStyles}
                  />
                )}
              </AddressGroup>
            ) : (
              <AddressGroup>
                <FormSeparator />
                <GroupLabel>Billing Address</GroupLabel>
                <AddressForm
                  fieldsArray={addressFields}
                  templateStyles={templateStyles}
                />
                <FormSeparator />
              </AddressGroup>
            )}

            <ItemsContainer>
              <GroupLabel>Confirm Order</GroupLabel>
              <ItemsContainerInner>
                <ItemsLeft>
                  <ItemsQuantity>
                    <IQEm>{getTotalItems(items)}</IQEm> Item
                    {(getTotalItems(items) || 0) > 1 ? 's' : ''}
                  </ItemsQuantity>
                </ItemsLeft>
                <ItemsRight>
                  <Price>
                    <span>Cart Total:</span>
                    <span>${getTotalSum(items)}</span>
                  </Price>
                  <Price>
                    <span>Taxes:</span>
                    <span>
                      {taxIsCalculating
                        ? 'Calculating...'
                        : `$${getMoneyFormat(tax)}`}
                    </span>
                  </Price>
                  <TotalContainer>
                    <span>Total:</span>
                    <TotalSum>
                      $
                      {getMoneyFormat(
                        Big(tax).plus(getTotalSum(items, false)).valueOf()
                      )}
                    </TotalSum>
                  </TotalContainer>
                </ItemsRight>
              </ItemsContainerInner>
              <ItemsContainerInner>
                {getCartItemType() === ProductTypeEnum.PhysicalProduct && (
                  <ShippingHandling shippingFee={0} />
                )}
              </ItemsContainerInner>
            </ItemsContainer>

            <ButtonsContainer>
              <PrimaryButtonStyled
                disabled={!!taxIsCalculating}
                type="button"
                onClick={handleSubmit}
              >
                Place Order
              </PrimaryButtonStyled>
              <CancelButtonStyled type="button" onClick={handleCancel}>
                Cancel
              </CancelButtonStyled>
            </ButtonsContainer>
          </Form>
        </Container>
      )}
    </>
  );
};

const PaymentContainer: FC<IPayment> = (props) => {
  const initialValues = useSelector(
    (state: RootState) => state.checkout.paymentFormValues
  );
  const validationSchema =
    getCartItemType() === ProductTypeEnum?.PhysicalProduct
      ? validationSchemaPhysical
      : validationSchemaNonPhysical;
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
      validationSchema={validationSchema}
      validateOnChange={props.shouldValidateOnChange}
    >
      <Payment {...props} />
    </Formik>
  );
};

export default PaymentContainer;
