import styled from 'styled-components';

export const ToggleTruncateButton = styled.button`
  display: block;
  border: none;
  padding: 0;
  color: ${({ theme }) => theme.colors.accentMainColor};
  background: transparent;

  &:hover {
    color: #005d75;
  }
`;
