import React, { FC } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Container } from './styles';

interface Props {
  name: string;
  onChange: (value: string) => void;
  value: string;
  placeholder?: string;
  country?: string;
  countryCodeEditable?: boolean
  disableDropdown?: boolean
}

const PhoneInputByCountry: FC<Props> = (props) => (
  <Container>
    <PhoneInput
      enableAreaCodes={false}  
      countryCodeEditable={false}  
      {...props}
      
    />
  </Container>
);

export default PhoneInputByCountry;
