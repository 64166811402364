import React, { FC } from 'react';

interface Props {
  className?: string;
}

const Open: FC<Props> = ({ className }) => (
  <svg
    className={className}
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="3"
      width="14"
      height="14"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 3.07964H13.8635V17H0V3.07964Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.1362 14.2998C12.1362 14.5628 12.0313 14.8115 11.8406 14.9998C11.6501 15.1884 11.3984 15.2921 11.1324 15.2921H2.73129C2.46513 15.2921 2.21377 15.1884 2.02308 14.9998C1.83255 14.8115 1.72747 14.5627 1.72747 14.2998V5.77973C1.72747 5.51675 1.83255 5.26809 2.02308 5.07973C2.21377 4.89121 2.46513 4.78748 2.73129 4.78748H8.71653L10.4438 3.0798L2.73129 3.07964C2.00377 3.07964 1.31841 3.36107 0.80154 3.87207C0.284828 4.38307 0 5.06064 0 5.77973V14.2998C0 15.0191 0.284667 15.6965 0.80154 16.2075C1.31841 16.7185 2.00377 17.0001 2.73129 17.0001H11.1324C11.8599 17.0001 12.5453 16.7185 13.0622 16.2075C13.579 15.6965 13.8637 15.0191 13.8635 14.2998V6.55291L12.1362 8.26075V14.2998Z"
        fill="#00A3C6"
      />
    </g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.0095 0H13.5795H11.3486C10.8015 0 10.358 0.443492 10.358 0.990512C10.358 1.53769 10.8015 1.98102 11.3486 1.98102H11.5987H13.6183L12.19 3.40915L11.9073 3.69195L10.44 5.1593L5.28999 10.3091C4.90334 10.6959 4.90334 11.3232 5.28999 11.7099C5.67695 12.0967 6.30401 12.0967 6.69082 11.7099L11.7946 6.60617L13.2904 5.11056L13.591 4.80997L15.0191 3.38184V5.40161V5.65138C15.0191 6.1984 15.4625 6.6419 16.0095 6.6419C16.2831 6.6419 16.5306 6.53106 16.7099 6.35179C16.8892 6.17252 17 5.92481 17 5.65138V3.42059V0.990354C17 0.72772 16.8957 0.475884 16.7099 0.290104C16.5241 0.104323 16.2723 0 16.0095 0Z"
      fill="#00A3C6"
    />
  </svg>
);

export default Open;
