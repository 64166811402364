const fontsCSS = `
/* Arial */

@font-face {
  font-family: 'DrumFont Arial';
  src: local('Arial'), url('/assets/fonts/Arial-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'DrumFont Arial';
  src: local('Arial Bold'), url('/assets/fonts/Arial-Bold.ttf');
  font-weight: bold;
}

/* Comic Sans */

@font-face {
  font-family: 'DrumFont Comic Sans MS';
  src: local('ComicSansMS'), local('ComicSans'), local('Comic Sans'), local('Comic Sans MS'),
    url('/assets/fonts/ComicSansMS-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'DrumFont Comic Sans MS';
  src: local('ComicSansMS Bold'), local('ComicSans Bold'), local('Comic Sans Bold'),
    local('Comic Sans MS Bold'), url('/assets/fonts/ComicSansMS-Bold.ttf');
  font-weight: bold;
}

/* Courier New */

@font-face {
  font-family: 'DrumFont Courier New';
  src: local('CourierNew'), local('Courier New'),
    url('/assets/fonts/CourierNew-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'DrumFont Courier New';
  src: local('CourierNew Bold'), local('Courier New Bold'),
    url('/assets/fonts/CourierNew-Bold.ttf');
  font-weight: bold;
}

/* Georgia */

@font-face {
  font-family: 'DrumFont Georgia';
  src: local('Georgia'), url('/assets/fonts/Georgia-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'DrumFont Georgia';
  src: local('Georgia Bold'), url('/assets/fonts/Georgia-Bold.ttf');
  font-weight: bold;
}

/* Lucida / Lucida Grande */

@font-face {
  font-family: 'DrumFont Lucida';
  src: local('Lucida'), url('/assets/fonts/Lucida-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'DrumFont Lucida';
  src: local('Lucida Bold'), url('/assets/fonts/Lucida-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'DrumFont Lucida Grande';
  src: local('Lucida'), url('/assets/fonts/Lucida-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'DrumFont Lucida Grande';
  src: local('Lucida Bold'), url('/assets/fonts/Lucida-Bold.ttf');
  font-weight: bold;
}

/* Raleway */

@font-face {
  font-family: 'DrumFont Raleway';
  src: local('Raleway'), url('/assets/fonts/Raleway-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'DrumFont Raleway';
  src: local('Raleway Bold'), url('/assets/fonts/Raleway-Bold.ttf');
  font-weight: bold;
}

/* Tahoma */

@font-face {
  font-family: 'DrumFont Tahoma';
  src: local('Tahoma'), url('/assets/fonts/Tahoma-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'DrumFont Tahoma';
  src: local('Tahoma Bold'), url('/assets/fonts/Tahoma-Bold.ttf');
  font-weight: bold;
}

/* Trebuchet MS */

@font-face {
  font-family: 'DrumFont Trebuchet MS';
  src: local('TrebuchetMS'), local('Trebuchet MS'), local('Trebuchet'),
    url('/assets/fonts/TrebuchetMS-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'DrumFont Trebuchet MS';
  src: local('TrebuchetMS Bold'), local('Trebuchet MS Bold'), local('Trebuchet Bold'),
    url('/assets/fonts/TrebuchetMS-Bold.ttf');
  font-weight: bold;
}

/* Verdana */

@font-face {
  font-family: 'DrumFont Verdana';
  src: local('Verdana'), url('/assets/fonts/Verdana-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'DrumFont Verdana';
  src: local('Verdana Bold'), url('/assets/fonts/Verdana-Bold.ttf');
  font-weight: bold;
}

/* Arial */

@font-face {
  font-family: 'Arial';
  src: local('Arial'), url('/assets/fonts/Arial-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Arial';
  src: local('Arial Bold'), url('/assets/fonts/Arial-Bold.ttf');
  font-weight: bold;
}

/* Comic Sans */

@font-face {
  font-family: 'Comic Sans MS';
  src: local('ComicSansMS'), local('ComicSans'), local('Comic Sans'), local('Comic Sans MS'),
    url('/assets/fonts/ComicSansMS-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Comic Sans MS';
  src: local('ComicSansMS Bold'), local('ComicSans Bold'), local('Comic Sans Bold'),
    local('Comic Sans MS Bold'), url('/assets/fonts/ComicSansMS-Bold.ttf');
  font-weight: bold;
}

/* Courier New */

@font-face {
  font-family: 'Courier New';
  src: local('CourierNew'), local('Courier New'),
    url('/assets/fonts/CourierNew-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Courier New';
  src: local('CourierNew Bold'), local('Courier New Bold'),
    url('/assets/fonts/CourierNew-Bold.ttf');
  font-weight: bold;
}

/* Georgia */

@font-face {
  font-family: 'Georgia';
  src: local('Georgia'), url('/assets/fonts/Georgia-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Georgia';
  src: local('Georgia Bold'), url('/assets/fonts/Georgia-Bold.ttf');
  font-weight: bold;
}

/* Lucida / Lucida Grande */

@font-face {
  font-family: 'Lucida';
  src: local('Lucida'), url('/assets/fonts/Lucida-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Lucida';
  src: local('Lucida Bold'), url('/assets/fonts/Lucida-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'Lucida Grande';
  src: local('Lucida'), url('/assets/fonts/Lucida-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Lucida Grande';
  src: local('Lucida Bold'), url('/assets/fonts/Lucida-Bold.ttf');
  font-weight: bold;
}

/* Raleway */

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url('/assets/fonts/Raleway-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway Bold'), url('/assets/fonts/Raleway-Bold.ttf');
  font-weight: bold;
}

/* Tahoma */

@font-face {
  font-family: 'Tahoma';
  src: local('Tahoma'), url('/assets/fonts/Tahoma-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Tahoma';
  src: local('Tahoma Bold'), url('/assets/fonts/Tahoma-Bold.ttf');
  font-weight: bold;
}

/* Trebuchet MS */

@font-face {
  font-family: 'Trebuchet MS';
  src: local('TrebuchetMS'), local('Trebuchet MS'), local('Trebuchet'),
    url('/assets/fonts/TrebuchetMS-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Trebuchet MS';
  src: local('TrebuchetMS Bold'), local('Trebuchet MS Bold'), local('Trebuchet Bold'),
    url('/assets/fonts/TrebuchetMS-Bold.ttf');
  font-weight: bold;
}

/* Verdana */

@font-face {
  font-family: 'Verdana';
  src: local('Verdana'), url('/assets/fonts/Verdana-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Verdana';
  src: local('Verdana Bold'), url('/assets/fonts/Verdana-Bold.ttf');
  font-weight: bold;
}

/* Impact */

@font-face {
  font-family: 'Impact';
  src: local('Impact'), url('/assets/fonts/Impact.ttf');
}

/* Dream Orphans */

@font-face {
  font-family: 'Dream Orphans';
  src: local('Dream Orphans'), url('/assets/fonts/Dream-Orphans.otf');
}
`;


export default fontsCSS;
