import React, { FC } from 'react';
import IconProps from './IconProps';

const DrumLogo: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="91"
    height="20"
    viewBox="0 0 91 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.652181 2.7967C2.42791 4.28721 6.20403 7.34501 7.80985 8.63827C8.41463 9.12597 9.41567 8.49031 8.67031 7.57517C7.92494 6.66003 4.28581 2.27612 2.82798 0.675978C1.10556 -1.21459 -1.12354 1.30619 0.652181 2.7967Z"
      fill="#909090"
    />
    <path
      d="M8.2812 11.1974C7.60161 11.7509 4.50506 14.2168 3.4199 15.132C3.09106 15.4061 3.13491 16.1567 3.46922 16.5019C3.80354 16.8472 4.63111 16.9568 5.07504 16.4362C5.51897 15.9156 8.5333 12.2386 8.89502 11.8112C9.25674 11.3837 8.75801 10.8091 8.2812 11.1974Z"
      fill="#909090"
    />
    <path
      d="M17.7517 4.2982H13.0987C11.3284 4.2982 11.0379 2.72547 11.0379 2.54463C11.0379 2.3638 11.1147 0.48419 13.0987 0.48419H17.7517C23.2104 0.48419 27.3537 4.55027 27.3537 9.80002C27.3537 14.6388 23.6981 19.1816 17.7517 19.1816H13.0987C10.3419 19.1816 10.3638 15.4717 13.0987 15.4717H17.7517C21.7087 15.4717 23.5063 12.2988 23.5063 9.80002C23.5063 7.30118 21.3305 4.2982 17.7517 4.2982Z"
      fill="#909090"
    />
    <path
      d="M14.7702 7.21351C14.7702 4.57767 11.016 4.68727 11.016 7.21351V12.7811C11.016 15.3621 14.7702 15.1758 14.7702 12.7975V7.21351Z"
      fill="#909090"
    />
    <path
      d="M33.7605 13.5334C33.7605 10.8976 30.0063 11.0072 30.0063 13.5334V17.3365C30.0063 19.9175 33.7605 19.7312 33.7605 17.3529V13.5334Z"
      fill="#909090"
    />
    <path
      d="M38.008 0.46227H31.6341C29.2116 0.46227 29.1788 4.0242 31.6341 4.0242H38.008C39.7892 4.0242 41.8883 5.64625 41.8883 8.02453C41.8883 10.4028 39.8221 11.8385 38.008 11.8385C36.1939 11.8385 35.602 13.7784 36.605 14.8744C37.6079 15.9704 38.5223 16.766 40.2605 18.4692C42.4473 20.6119 44.804 17.7121 42.8857 15.943C41.8579 14.995 41.5631 14.7356 41.4169 14.6223C42.8145 13.9556 45.6096 11.6467 45.6096 7.74505C45.6096 3.67897 41.8883 0.46227 38.008 0.46227Z"
      fill="#909090"
    />
    <path
      d="M51.8685 2.36928C51.8685 -0.206273 48.0594 -0.27752 48.0594 2.36928V11.1919C48.0594 14.8799 51.5944 19.1487 56.5653 19.1487C61.7062 19.1487 65.2028 15.1593 65.2028 11.1919V2.36928C65.2028 -0.282987 61.4266 -0.266555 61.4266 2.36928V10.6823C61.4266 13.9483 58.7028 15.4114 56.5653 15.4114C54.4279 15.4114 51.8685 13.8003 51.8685 10.6823V2.36928Z"
      fill="#909090"
    />
    <path
      d="M67.8116 7.19155V17.3622C67.8116 19.7898 71.6315 19.7899 71.6315 17.3622V7.12579C71.6315 3.33375 77.5232 3.28438 77.5232 7.12579V17.3622C77.5232 19.8501 81.3158 19.7351 81.3158 17.3622V7.15867C81.3158 3.3337 87.2677 3.3063 87.2677 7.15867V17.3622C87.2677 19.6967 91 19.7899 91 17.3622V6.64356C91 5.5147 90.4629 3.11526 88.3309 1.76097C84.2315 -0.84313 80.6818 1.14723 79.4195 2.46788C75.5995 -1.73516 67.8116 0.725306 67.8116 7.19155Z"
      fill="#909090"
    />
  </svg>
);

export default DrumLogo;
