import styled, { DefaultTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';
import { ButtonTextMixin, ButtonBase } from 'uikit';
import { TemplatePreset } from 'graphqlQueries';
import ITemplatePreset from '../../types/TemplatePreset';

export const ButtonStyledMixin = (
  theme: DefaultTheme,
  styles?: TemplatePreset
) => `
  
  ${ButtonTextMixin};
  margin-top: 15px;
  display: inline-block;
  background: transparent;
  color: ${
    styles?.cancelButton?.color || theme.colors.dlpPurchaseCancelButtonColor
  };
  font-size: ${theme.size.subtitle};
  border-radius: 8px;
  box-shadow: none;
  border: none;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    background: transparent !important;
    text-decoration: underline;
  }
`;

export const LinkStyled = styled(Link)<ITemplatePreset>(
  (props) => `
  ${ButtonStyledMixin(theme, props.styles)}
`
);
export const HyperLinkStyled = styled.a<ITemplatePreset>(
  (props) => `
  ${ButtonStyledMixin(theme, props.styles)}
`
);
export const ButtonStyled = styled(ButtonBase)<ITemplatePreset>(
  (props) => `
  ${ButtonStyledMixin(theme, props.styles)}
`
);

export default ButtonStyled;
