import styled, { css } from 'styled-components';
import { breakpoint } from 'helpers';
import { Link } from 'react-router-dom';
import { DrumLogoColored, Logo as DrumLogo } from 'assets/svg';

const innerContainermixin = css`
  max-width: 1231px;
  margin: 0 auto; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  height: 100%;
  padding: 0 8px;

  ${breakpoint('xs', 'sm')`
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 0;
	`}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: Raleway;
  min-height: 100%;
`;

export const Header = styled.div`
  width: 100%;
  height: 72px;
`;

export const HeaderInner = styled.div`
  ${innerContainermixin};
`;

export const LogoContainer = styled(Link)`
  display: flex;
`;

export const Logo = styled(DrumLogoColored)`
  width: 160px;
  height: 35px;
`;

export const Menu = styled.div`
  display: flex;
  ${breakpoint('xs', 'sm')`
    margin-top: 20px;
	`}
`;

export const MenuItem = styled.a`
  margin-left: 40px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.size.body1};
  line-height: 24px;
  opacity: 0.5;
  
  &:first-of-type {
    margin-left: 0;
  }

  &,
  &:focus,
  &:hover,
  &:active,
  &:visited {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.dlp404LinkColor};
  }

  &:hover {
    text-decoration: underline;
    opacity: 1;
  }
  
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  flex: 1;
`;

export const ContentInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 330px;
`;

export const ContentTitle = styled.div`
  margin-bottom: 20px;

  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: 42px;
  line-height: 56px;
  text-align: center;
  letter-spacing: -1.5px;
`;

export const ContentImage = styled.img`
  width: 100%;
`;

export const ContentText = styled.div`
  margin-top: 29px;
  font-weight:  ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.size.subtitle};
  line-height: 32px;
  text-align: center;
`;

export const ReturnButton = styled.div`
  display: flex;
  margin: 20px 0 50px 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 162px;
  height: 48px;
  font-weight:  ${({ theme }) => theme.fontWeight.semiBold};
  background: ${({ theme }) => theme.colors.dlp404ButtonBg};
  box-shadow: 0px 3px 7px ${({ theme }) => theme.colors.dlp404ButtonShadowColor};
  border-radius: 50px;
  cursor: pointer;

  &:hover {
    font-weight:  ${({ theme }) => theme.fontWeight.bold};
    color: ${({ theme }) => theme.colors.dlp404ButtonBg};
    background: ${({ theme }) => theme.colors.dlp404ButtonShadowColor};
  }
`;

export const Footer = styled.div`
  width: 100%;
  height: 107px;
  background: ${({ theme }) => theme.colors.dlp404FooterBg};
`;

export const FooterInner = styled.div`
  ${innerContainermixin};
`;

export const CopyrightContainer = styled.div`
  display: flex;
  heigt: 100%;
  align-items: center;

  ${breakpoint('xs', 'sm')`
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
	`}
`;

export const CopyrightLogo = styled(DrumLogo)`
  width: 49px;
  height: 35px;
  margin-right: 16px;
  ${breakpoint('xs', 'sm')`
    margin-bottom: 15px;
	`}
`;

export const CopyrightText = styled.div`
  font-family: Raleway;
  font-size: ${({ theme }) => theme.size.body1};
  line-height: 24px;

  color: ${({ theme }) => theme.colors.dlp404CopyrightTextColor};

  opacity: 0.5;
`;

export const FooterLinksContainer = styled.div`
  heigt: 100%;
  align-items: center;
`;

export const FooterLink = styled.a`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.size.body1};
  line-height: ${({ theme }) => theme.size.body1};
  border-right: 1px solid ${({ theme }) => theme.colors.dlp404LinkColor};
  padding: 0 10px;

  &,
  &:focus,
  &:hover,
  &:active,
  &:visited {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.dlp404LinkColor};
  }

  &:hover {
    text-decoration: underline;
  }

  &:last-of-type {
    border-right: none;
    padding-right: 0;
  }

  ${breakpoint('xs', 'sm')`
    &:first-of-type {
      padding-left: 0;
    }
	`}
`;