import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 200px;
  padding-bottom: 50px;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.dlpWideFooterBg};

  // @media screen and (max-width: 700px) {
  //   margin-top: 153px;
  // }
`;

export const Logo = styled.img`
  width: 93px;
  height: 20px;
  margin-bottom: 10px;
`;

export const Copyright = styled.p`
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.dlpWideFooterColor};
  opacity: 0.58;
`;


