import styled from 'styled-components';

const GlassPanel = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 5;

  &.no-cursor {
    cursor: default;
  }
`;

export default GlassPanel;
