import React, { FC } from 'react';
import { LocationDescriptor, Location } from 'react-router-dom/Link';
import { ButtonStyled, LinkStyled, HyperLinkStyled } from './styles';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

interface Props {
  to?:
    | LocationDescriptor<unknown>
    | ((location: Location<unknown>) => LocationDescriptor<unknown>);
  href?: string;
  type?: string;
  onClick?: () => void;
}

const PrimaryButtonStyled: FC<any> = (props) => {
  const styles = useSelector(
    (state: RootState) => state.userMetaData.root?.template_preset
  );

  return (
    <>
      {props.to ? (
        <LinkStyled {...props} styles={styles}></LinkStyled>
      ) : props.href ? (
        <HyperLinkStyled {...props} styles={styles}></HyperLinkStyled>
      ) : (
        <ButtonStyled {...props} styles={styles}></ButtonStyled>
      )}
    </>
  );
};

export default PrimaryButtonStyled;
