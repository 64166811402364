import React, { FC } from 'react';
import { getImagePath } from 'helpers';
import Props from '../../types';
import { ImageStyleName } from 'graphqlQueries';
import {
  Container,
  ContainerInner,
  Image
} from './styles';

const ImageItem: FC<Props> = ({ item, linkStyles, styles, className }) => {
  const {
    images,
    type
  } = item;
  const imagesField = JSON.parse(JSON.stringify(images || []));
  const offerPhotoStyles = imagesField?.[0]?.styles;
  const offerPhotoUrl = imagesField?.[0]?.url;
  const recordImages = [getImagePath(offerPhotoStyles, ImageStyleName.Original) || offerPhotoUrl];
  let thumbImage = '';
  if (recordImages?.[0]) {
    const parts = recordImages[0].split('/');
    const last = parts?.pop();
    const lastUrl = encodeURI(last).replace(/[!'()*]/g, function (c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
    thumbImage = `${parts.join('/')}/${lastUrl}`;
  }
  const offerSizeClass = 'large';
  const imagesClass = recordImages?.[0] ? '' : ' no-images';
  const descriptionClass = ' no-description';
  const classSet = `${imagesClass} ${descriptionClass} ${offerSizeClass} ${className}`;
  console.log('thumbImage', thumbImage);
  return (
    <Container
      className={classSet}
      target="_blank"
      backgroundColor={linkStyles?.background_color}
      styles={styles}
      disableAnimation={true}
    >
      <ContainerInner styles={styles} backgroundColor={linkStyles?.background_color}>
        <Image styles={styles} type={type} style={{
          backgroundImage: `url(${thumbImage || ""})`
        }}></Image>
      </ContainerInner>
    </Container>
  );
};

export default ImageItem;
