import { Drummer, LinkTreeUser } from 'graphqlQueries';

export default function makeFullNameAndUserAvatarSubstitute(
  user?: Partial<Drummer | LinkTreeUser> | null,
  startingSymbols?: string
): {
  fullName: string;
  userAvatarSubstitute: string;
  fullNameFormatted: string;
} {
  let userAvatarSubstitute = '';
  let fullName = '';
  let fullNameFormatted = '';

  if (!user) {
    return { fullName, userAvatarSubstitute, fullNameFormatted };
  }

  const { firstName, lastName, username, email } = user;

  if (firstName && firstName[0]) {
    userAvatarSubstitute += firstName[0];
    fullName += firstName + ' ';
    fullNameFormatted = fullName;
  }
  if (lastName && lastName[0]) {
    userAvatarSubstitute += lastName[0];
    fullName += lastName;
    fullNameFormatted = fullName;
  }
  if (!userAvatarSubstitute) {
    userAvatarSubstitute += (
      (username && username[0]) ||
      (email && email[0]) ||
      '?'
    ).toUpperCase();
    fullName += `${username || email}`;
    fullNameFormatted = `${startingSymbols}${fullName}`;
  }
  return { fullName, userAvatarSubstitute, fullNameFormatted };
}
