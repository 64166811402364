import React, { FC, useEffect, useState, KeyboardEvent } from 'react';
import { Formik, useFormikContext } from 'formik';
import DropDown from 'components/DropDown';
import MenuList from 'components/MenuList';
import Swiper from 'components/Swiper';
import Delayed from 'components/Delayed';
import ShippingHandling from 'components/ShippingHandling';
import PrimaryButtonStyled from 'components/PrimaryButtonStyled';
import { FieldFooter, InputContainer, InputBase, InputGroup, InputLabel, Loader } from 'uikit';
import { getImagePath } from 'helpers';
import { formSchema } from './validationSchema';
import { getMoneyFormat } from 'pages/CheckoutPage/utils';
import { SelectedImages, ImageStyle, ImageStyleName, BulkChangeProductProductPropertiesCombinations } from 'graphqlQueries';
import {
  Container,
  ContainerInner,
  ImageContainer,
  DetailsContainer,
  FormContainer,
  ButtonsContainer,
  Title,
  Description,
  PriceBlock,
  MobileSpacer,
  Price,
  OutOfStockContainer
} from '../styles';
import { IOption, IDDOption, IPurchasePhysicalProduct } from './types';

interface IPurchasePhysicalProductExtended extends IPurchasePhysicalProduct {
  dropdownOptions?: Array<IDDOption>;
  validationSchema?: any;
  initialValues?: any;
}

const PurchasePhysicalProduct: FC<IPurchasePhysicalProductExtended> = ({
  values,
  styles,
  onSubmit,
  dropdownOptions = []
}) => {
  const [galleryImages, setGalleryImages] = useState<Array<string>>([]);
  const [currentCombination, setCurrentCombination] = useState<BulkChangeProductProductPropertiesCombinations | undefined | null>(undefined);
  const { title, description, images } = values;
  const shippingFee = 0; //Remove later
  
  const {
    errors,
    validateForm,
    setSubmitting,
    isSubmitting,
    values: formValues,
    setFieldValue
  } = useFormikContext<any>();

  const setCombinationValues = (fieldId?: string, optionValue?: IOption | null) => {
    const currentValues = fieldId ? {...formValues, [fieldId]: optionValue} : formValues;
    const selectedCombinationId = dropdownOptions.reduce((res, curr, index) => {
      return  res + (index ? `_${currentValues[curr.id]?.value}` : currentValues[curr.id]?.value)
    }, '');
    const selectedCombination = values?.properties?.combinations?.find(item => item?.id === selectedCombinationId);
    setCurrentCombination(selectedCombination);
    const availableQuantity = dropdownOptions?.length ? selectedCombination?.quantity : values?.quantity;
    setFieldValue('availableQuantity', availableQuantity === null ? 999 : availableQuantity, true);
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLFormElement>) => {
    if ((e.charCode || e.keyCode) === 13) {
      onSubmit?.(validateForm, setSubmitting, formValues, currentCombination);
    }
  }
  
  const handleSubmit = () => {
    onSubmit?.(validateForm, setSubmitting, formValues, currentCombination);
  }

  useEffect(() => {
    if(!values) return;
    const _imgs =
      (images as SelectedImages[])?.map((item, index) => {
        return getImagePath(
          item.styles as ImageStyle[],
          ImageStyleName.Original
        );
      }) || [];
    setGalleryImages(_imgs);
    // eslint-disable-next-line
  }, [values]);

  useEffect(() => {
    setCombinationValues();
    // eslint-disable-next-line
  }, []);

  return (
    <Container onKeyDown={handleKeyDown}>
      {!!galleryImages?.length && <ImageContainer>
        <Delayed>
          <Swiper images={galleryImages || []} />
        </Delayed>
      </ImageContainer>}
      
      <DetailsContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </DetailsContainer>

      <ContainerInner>
        <FormContainer>
          {
            dropdownOptions.map((option, index) => (
              <InputContainer>
                <InputGroup styles={styles}>
                  <InputLabel>{option.field}</InputLabel>
                  <DropDown<IOption>
                    options={option.values}
                    components={{ MenuList }}
                    onChange={(changeOption) => {
                      setFieldValue(option.id || '', changeOption as IOption);
                      setCombinationValues(option.id || '', changeOption);
                    }}
                    value={formValues[option.id]}
                  />
                </InputGroup>
                <FieldFooter error={(errors[option.field] || '') as string} showAlways={false} />
              </InputContainer>
            ))
          }

          <InputContainer>
            <InputGroup styles={styles}>
              <InputLabel>Quantity</InputLabel>
              <InputBase
                name="quantity"
                onChange={(e) => {
                  const val = Number(e.target.value);
                  const min = Number(e.target.min);
                  const max = Number(e.target.max);
                  if(val < min || val > max) return;
                  setFieldValue('quantity', (val - 0).toString(), true); //Val - 0 causes values to be stripped from leading zeroes
                }}
                type="number"
                value={formValues.quantity}
                placeholder="Set quantity"
                pattern="[0-9]*"
                min={0}
                max={999}
                styles={styles}
              />
              
            </InputGroup>
            <FieldFooter error={(errors.quantity || '') as string} showAlways={true} />
          </InputContainer>


          <PriceBlock>
            <MobileSpacer></MobileSpacer>
            <span>Price: {" "}<Price>${getMoneyFormat(dropdownOptions?.length ? currentCombination?.price || 0 : values?.price || 0)}</Price></span>
            {(currentCombination && !currentCombination.quantity) && (
              <OutOfStockContainer>Sorry, Out of Stock</OutOfStockContainer>
            )}
          </PriceBlock>
          <ShippingHandling shippingFee={shippingFee} />
        </FormContainer>

        <ButtonsContainer>
          <PrimaryButtonStyled
            type="button"
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            Check out
          </PrimaryButtonStyled>
        </ButtonsContainer>
      </ContainerInner>
    </Container>
  );
};

const PurchasePhysicalProductContainer: FC<IPurchasePhysicalProduct> = (props) => {
  const [dropdownOptions, setDropdownOptions] = useState<Array<IDDOption> | undefined>([]);
  const [validationSchema, setValidationSchema] = useState<any>();
  const [initialValues, setInitialValues] = useState<any | undefined>(undefined);

  useEffect(() => {
    if(!props.values) return;
    const _dropdownOptions = props.values?.properties?.options?.map(option => {
      return {
        id: option?.id || ' ',
        field: option?.field || ' ',
        values: option?.values?.map(valueOption => ({
          value: valueOption?.id as string,
          label: valueOption?.value as string,
        }))
      }
    });
    
    setDropdownOptions(_dropdownOptions);
    setValidationSchema(formSchema(_dropdownOptions));
    if(!initialValues) {
      const result  = {
        quantity: 1,
      }
      _dropdownOptions?.forEach(item => {result[item.id] = item?.values?.[0]});
      setInitialValues(result);
    }
    // eslint-disable-next-line
  }, [props.values]);

  return (
    <>
      {!initialValues ? 
        <Loader />
        :
        <Formik
          initialValues={initialValues}
          onSubmit={() => {}}
          validationSchema={validationSchema}
          validateOnChange={false}
        >
          <PurchasePhysicalProduct
            {...props}
            dropdownOptions={dropdownOptions}
            validationSchema={validationSchema}
            initialValues={initialValues}
          />
        </Formik>
      }     
    </>
  );
};

export default PurchasePhysicalProductContainer;
