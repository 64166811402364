import React, { FC, useEffect } from 'react';
import window from 'global';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet-async';

interface RouterProps {
  campaignId?: string;
  customerId?: string;
  linkUriComponent?: string;
}

const SubscribePage: FC = () => {
  const { campaignId, customerId, linkUriComponent } = useParams<RouterProps>();

  useEffect(() => {
    const subscribeLink = `https://${
    process.env.DISTRIBUTION_DOMAIN_NAME
  }/campaign/sub/${campaignId}/${customerId}/${linkUriComponent}`;
    (window as any).location = subscribeLink;
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Helmet>
        <title>Subscribe</title>
      </Helmet>
    </>
  );
};

export default SubscribePage;
