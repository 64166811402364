import styled from 'styled-components';
import { ITheme } from '../../types';

const FooterText = styled.p<ITheme>`
  font-family: ${({ theme }: ITheme) => theme.fontFamily.roboto};
  font-weight: ${({ theme }: ITheme) => theme.fontWeight.regular};
  font-size: ${({ theme }: ITheme) => theme.size.caption};
  line-height: normal;
  opacity: 0.5;
`;

export default FooterText;
