import React, { useState, useEffect, ChangeEvent } from 'react';
import omitDeep from 'omit-deep-lodash';
import { useParams } from 'react-router-dom';
import ScrollLock from 'react-scrolllock';
import MetaData, {
  makeOfferPhotoThumbFromMetaInitials,
  makeAvatarFromMeta,
  makeAvatarSubstituteLink,
} from 'components/MetaData/MetaData';
import routes from 'constants/routes';
import UpgradeLayout from 'layouts/UpgradeLayout';
import UserInfoUpgrade from 'components/UserInfoUpgrade';
import LinksList from 'components/LinksList';
import ScraperLoader from './components/ScraperLoader';
import EmailClaim from './components/EmailClaim';
import getError from './errors';
import { Container, ListContainer } from './styles';
import getGeneralLayoutBg from 'helpers/getGeneralLayoutBg';
import useUpgradeTracking from 'utils/UpgradeTracking';
import { FullScreenLoader } from 'uikit';
import { emailRegExp } from 'constants/regExp';
import {
  useGetUpgradePageLinksQuery,
  useGetUpgradePageMetadataQuery,
  useGetUpgradePageSocialLinksQuery,
  useReserveLinkTreeStyleMutation,
  ReserveLinkTreeStyleMutation,
  SocialNetworkEnum,
  SocialAccount,
} from 'graphqlQueries';
import { useRedirection } from 'utils/serverEffectActions';

interface IParamTypes {
  username: string;
}

const UpgradePage = () => {
  const redirectTo = useRedirection();

  const upgradeTracking = useUpgradeTracking({ countImpressions: false });

  const { username } = useParams<IParamTypes>();
  const [upgradeTrackingIsInitialized, setUpgradeTrackingIsInitialized] =
    useState(true);
  const [pageIsScraping, setPageIsScraping] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [submitHasSucceeded, setSubmitHasSucceeded] = useState(false);
  const [showBottomDisclaimer, setShowBottomDisclaimer] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string | null>('');
  const [showName, setShowName] = useState<boolean>(false);

  const { data: linksData } = useGetUpgradePageLinksQuery({
    variables: {
      username,
    },
    onError: () => {
      redirectTo(routes.PAGE_404);
    },
  });

  const { data: metaData } = useGetUpgradePageMetadataQuery({
    variables: {
      username,
    },
    onError: () => {
      redirectTo(routes.PAGE_404);
    },
  });

  const { data: socialData, loading: socialLoading } =
    useGetUpgradePageSocialLinksQuery({
      variables: {
        username,
      },
      onError: () => {
        redirectTo(routes.PAGE_404);
      },
    });

  const [submitEmail, { loading: submitEmailLoading }] =
    useReserveLinkTreeStyleMutation({
      onCompleted: (data: ReserveLinkTreeStyleMutation) => {
        if (!data?.reserveLinkTreeStyle?.status) {
          const { message, showDisclaimer, showName } = getError(
            data?.reserveLinkTreeStyle?.message
          );
          setError(message);
          setShowBottomDisclaimer(showDisclaimer);
          setShowName(showName);
          setSubmitHasSucceeded(false);
          upgradeTracking.onEmailSubmit(email, false, message);
        } else {
          setError(null);
          setShowBottomDisclaimer(true);
          setSubmitHasSucceeded(true);
          setShowName(false);
          upgradeTracking.onEmailSubmit(email, true);
        }
        setSubmitted(true);
      },
      onError: () => {
        setError('Something went wrong.');
      },
    });

  useEffect(() => {
    setBackgroundImage(
      getGeneralLayoutBg(metaData?.linkTreeGetLinkedPage, false)
    );
  }, [metaData]);

  useEffect(() => {
    if (linksData && metaData && socialData && !socialLoading) {
      upgradeTracking.init(
        metaData?.linkTreeGetLinkedPage?.user?.username || ''
      );
      setUpgradeTrackingIsInitialized(true);
      setPageIsScraping(false);
    }
    // eslint-disable-next-line
  }, [linksData, metaData, socialData]);

  const onItemImpression = (item: any) => {
    upgradeTracking.onImpressionsChange([item]);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const resetValues = () => {
    setError(null);
    setShowBottomDisclaimer(false);
    setSubmitHasSucceeded(false);
    setSubmitted(false);
    setEmail('');
  };

  const handleSubmit = () => {
    if (!email) {
      setError('Field cannot be empty.');
      return;
    } else if (!emailRegExp.test(String(email).toLowerCase())) {
      setError('Email is not valid.');
      return;
    }

    const socials = (socialData?.linkTreeGetSocialAccounts?.items || [])?.map(
      (item) => {
        return {
          link: item?.link || '',
          social: item?.social || SocialNetworkEnum.Email,
        };
      }
    );
    const links = omitDeep(linksData?.linkTreeGetLinks?.items || [], 'id');
    const input = {
      email: email,
      username: username,
      background: metaData?.linkTreeGetLinkedPage?.background?.background_image,
      linkPage: {
        title: metaData?.linkTreeGetLinkedPage?.title,
        description: metaData?.linkTreeGetLinkedPage?.description,
        background: metaData?.linkTreeGetLinkedPage?.background,
        link_style: metaData?.linkTreeGetLinkedPage?.link_style,
        style: metaData?.linkTreeGetLinkedPage?.style,
        user: {
          avatarImage: {
            url: metaData?.linkTreeGetLinkedPage?.user?.avatarImage?.url,
          },
        },
      },
      links: {
        items: links,
      },
      social: {
        items: socials,
      },
    };

    submitEmail({
      variables: {
        input: omitDeep(input, '__typename'),
      },
    });
  };

  const helmetMeta = React.useMemo(() => {
    const meta = metaData?.linkTreeGetLinkedPage;
    const title = meta?.user?.username
      ? `${meta?.user?.username} | My Page`
      : `My Page`;
    return {
      offerPhotoThumb: makeOfferPhotoThumbFromMetaInitials(meta),
      avatarImage: makeAvatarFromMeta(meta),
      avatarSubstituteLink: makeAvatarSubstituteLink(meta),
      title,
      metaTitle: title,
      metaDescription: `Click on any of the links to see more.`,
    };
    // eslint-disable-next-line
  }, [metaData, linksData]);

  return (
    <>
      <MetaData {...helmetMeta} />
      <UpgradeLayout
        backgroundImage={backgroundImage}
        backgroundColor={
          metaData?.linkTreeGetLinkedPage?.background?.background_color
        }
        font={metaData?.linkTreeGetLinkedPage?.style?.font_family}
        textColor={metaData?.linkTreeGetLinkedPage?.style?.color}
        headerContent={
          <>
            {submitEmailLoading && <FullScreenLoader />}
            <ScraperLoader hidden={!pageIsScraping} />
            <EmailClaim
              submitted={submitted}
              submittedEmail={email}
              submitHasSucceeded={submitHasSucceeded}
              showBottomDisclaimer={showBottomDisclaimer}
              error={error || ''}
              onEmailChange={handleEmailChange}
              email={email}
              onTryAnotherEmail={resetValues}
              onSubmit={handleSubmit}
              name={username}
              showName={showName}
            />
          </>
        }
        innerStyles={{ marginTop: submitted ? '378px' : '245px' }}
        showLoader={!metaData}
      >
        <Container className={`${submitted ? 'submitted' : ''}`}>
          <UserInfoUpgrade
            user={metaData?.linkTreeGetLinkedPage?.user}
            title={metaData?.linkTreeGetLinkedPage?.title || ''}
            description={metaData?.linkTreeGetLinkedPage?.description || ''}
            socials={
              (socialData?.linkTreeGetSocialAccounts
                ?.items as SocialAccount[]) || []
            }
          />
          <ListContainer>
            {upgradeTrackingIsInitialized &&
              linksData?.linkTreeGetLinks?.items && (
                <LinksList
                  items={linksData?.linkTreeGetLinks?.items || []}
                  onImpression={onItemImpression}
                  linkStyles={
                    metaData?.linkTreeGetLinkedPage?.link_style || undefined
                  }
                />
              )}
          </ListContainer>
        </Container>
      </UpgradeLayout>
      {pageIsScraping && <ScrollLock />}
    </>
  );
};

export default UpgradePage;
