import window from 'global';
import googleAnalytics from './googleAnalytics';
import fbPixel from './fbPixel';
import segment from './segment';
import {
  BulkChangeProductProductPropertiesCombinationsProperties,
  LinkTypeEnum,
  ProductTypeEnum
} from 'graphqlQueries';

import {
  IImpression,
  ILinkClick,
  IViewedProduct,
  IStartedCheckout,
  IProvidedOrderDetails,
  IProvidedInformation,
  IPlacedOrder,
  IPlacedOrderFailed,
  IProductViewed,
  IProductDownloaded,
  ISubscription
} from './types';

export const shouldSendEvent = () => {
  const analytics = window?.analytics;
  const gtag = window?.gtag;
  const fbq = window?.fbq;

  return { sendSegment: !!analytics, sendGoogle: !!gtag, sendFB: !!fbq };
};

export const getSelectedOptionsFromArray = (options?: Array<BulkChangeProductProductPropertiesCombinationsProperties | null> | null) => {
  let selectedOptions: any = undefined;
  if (options?.length) {
    selectedOptions = {};
    options.forEach(item => selectedOptions[item?.field || 'field'] = item?.value);
  }
  return selectedOptions;
};

export const getLinkType = (productType?: LinkTypeEnum | ProductTypeEnum | null) => {
  let type;
  switch (productType) {
    case LinkTypeEnum.VideoProduct:
      type = 'video';
      break;
    default: type = productType;
  }
  return type;
};

export const sendImpressionsAnalytics = (props: IImpression) => {
  const { sendSegment, sendGoogle, sendFB } = shouldSendEvent();
  
  if(sendSegment) segment?.sendImpressionsAnalytics?.(props);
  if(sendGoogle) googleAnalytics?.sendImpressionsAnalytics?.(props);
  if(sendFB) fbPixel?.sendImpressionsAnalytics?.(props);
};

export const sendLinkClickAnalytics = (props: ILinkClick) => {
  const { sendSegment, sendGoogle, sendFB } = shouldSendEvent();
  
  if(sendSegment) segment?.sendLinkClickAnalytics?.(props);
  if(sendGoogle) googleAnalytics?.sendLinkClickAnalytics?.(props);
  if(sendFB) fbPixel?.sendLinkClickAnalytics?.(props);
};

export const sendViewedProductAnalytics = (props: IViewedProduct) => {
  const { sendSegment, sendGoogle, sendFB } = shouldSendEvent();
  if(sendSegment) segment?.sendViewedProductAnalytics?.(props);
  if(sendGoogle) googleAnalytics?.sendViewedProductAnalytics?.(props);
  if(sendFB) fbPixel?.sendViewedProductAnalytics?.(props);
};

export const sendStartedCheckoutAnalytics = (props: IStartedCheckout) => {
  const { sendSegment, sendGoogle, sendFB } = shouldSendEvent();

  if(sendSegment) segment?.sendStartedCheckoutAnalytics?.(props);
  if(sendGoogle) googleAnalytics?.sendStartedCheckoutAnalytics?.(props);
  if(sendFB) fbPixel?.sendStartedCheckoutAnalytics?.(props);
};

//USER STARTED CHECKOUT PROCESS
export const sendProvidedOrderDetailsAnalytics = (
  props: IProvidedOrderDetails
) => {
  const { sendSegment, sendGoogle, sendFB } = shouldSendEvent();

  if(sendSegment) segment?.sendProvidedOrderDetailsAnalytics?.(props);
  if(sendGoogle) googleAnalytics?.sendProvidedOrderDetailsAnalytics?.(props);
  if(sendFB) fbPixel?.sendProvidedOrderDetailsAnalytics?.(props);
};

//USER FULFILLED STEP 2 - INFORMATION
export const sendProvidedInformationAnalytics = (
  props: IProvidedInformation
) => {
  const { sendSegment, sendGoogle, sendFB } = shouldSendEvent();

  if(sendSegment) segment?.sendProvidedInformationAnalytics?.(props);
  if(sendGoogle) googleAnalytics?.sendProvidedInformationAnalytics?.(props);
  if(sendFB) fbPixel?.sendProvidedInformationAnalytics?.(props);
};

export const sendPlacedOrderAnalytics = (props: IPlacedOrder) => {
  const { sendSegment, sendGoogle, sendFB } = shouldSendEvent();

  if(sendSegment) segment?.sendPlacedOrderAnalytics?.(props);
  if(sendGoogle) googleAnalytics?.sendPlacedOrderAnalytics?.(props);
  if(sendFB) fbPixel?.sendPlacedOrderAnalytics?.(props);
};

export const sendPlacedOrderFailedAnalytics = (props: IPlacedOrderFailed) => {
  const { sendSegment, sendGoogle, sendFB } = shouldSendEvent();

  if(sendSegment) segment?.sendPlacedOrderFailedAnalytics?.(props);
  if(sendGoogle) googleAnalytics?.sendPlacedOrderFailedAnalytics?.(props);
  if(sendFB) fbPixel?.sendPlacedOrderFailedAnalytics?.(props);
};

export const sendProductViewedAnalytics = (props: IProductViewed) => {
  const { sendSegment, sendGoogle, sendFB } = shouldSendEvent();

  if(sendSegment) segment?.sendProductViewedAnalytics?.(props);
  if(sendGoogle) googleAnalytics?.sendProductViewedAnalytics?.(props);
  if(sendFB) fbPixel?.sendProductViewedAnalytics?.(props);
};

export const sendProductDownloadedAnalytics = (props: IProductDownloaded) => {
  const { sendSegment, sendGoogle, sendFB } = shouldSendEvent();

  if(sendSegment) segment?.sendProductDownloadedAnalytics?.(props);
  if(sendGoogle) googleAnalytics?.sendProductDownloadedAnalytics?.(props);
  if(sendFB) fbPixel?.sendProductDownloadedAnalytics?.(props);
};

export const sendSubscriptionAnalytics = (props: ISubscription) => {
  const { sendSegment, sendGoogle, sendFB } = shouldSendEvent();

  if(sendSegment) segment?.sendSubscriptionAnalytics?.(props);
  if(sendGoogle) googleAnalytics?.sendSubscriptionAnalytics?.(props);
  if(sendFB) fbPixel?.sendSubscriptionAnalytics?.(props);
};
