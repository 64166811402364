import styled from 'styled-components';
import {
  ContainerLinkBase,
  ContainerInnerBase,
  ImageBase,
  ContentBase,
  ContentInnerBase,
  InfoBase,
  BusinessImageContainerBase,
  BusinessImageBase,
  BusinessNameBase,
  DescriptionBase
} from 'uikit/LinkItems';

export const Container = styled(ContainerLinkBase)``;
export const ContainerInner = styled(ContainerInnerBase)``;
export const Image = styled(ImageBase)`
  ${Container}.no-images & {
    display: none;
  }
`;
export const Content = styled(ContentBase)``;
export const ContentInner = styled(ContentInnerBase)``;
export const Info = styled(InfoBase)``;
export const BusinessImageContainer = styled(BusinessImageContainerBase)``;
export const BusinessImage = styled(BusinessImageBase)``;
export const BusinessName = styled(BusinessNameBase)``;
export const Description = styled(DescriptionBase)``;


