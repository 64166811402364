import { window } from 'global';
import React, { FC, useState, useEffect } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import InfiniteScroll from 'react-infinite-scroll-component';
import querystring from 'query-string';
import { useLocation } from 'react-router-dom';
import OfferItem from './components/OfferItem';
import CollectionItem from './components/CollectionItem';
import MediaItem from './components/MediaItem';
import ProductItem from './components/ProductItem';
import CustomLinkItem from './components/CustomLinkItem';
import ImageItem from './components/ImageItem';
import TitleItem from './components/TitleItem';
import ParagraphItem from './components/ParagraphItem'
import SubscriptionItem from './components/SubscriptionItem';
import { ContactCardItem } from './components/ContactCardItem';
import { useUsername } from 'context/UserNameContext';
import { getLandingURL } from 'helpers/LinkItem';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import {
  LinkStyleConfiguration,
  DlpCollectionDetails,
  LinkTypeEnum
} from 'graphqlQueries';
import { Container, InfiniteScrollContainer } from './styles';
import { sendContactClickAnalytics } from 'helpers/analytics/productsAndLinks/segment';

type Props = {
  items: any[];
  userName?: string;
  userId?: string;
  onClick?: (item: any, event?: React.MouseEvent<HTMLElement>) => void;
  onImpression?: (item: any) => void;
  linkStyles?: LinkStyleConfiguration;
  collectionData?: Partial<DlpCollectionDetails>;
  onSubscriptionSubmitSuccess?: (item: any) => void;
};

const INFINITE_SCROLL_ITEM_COUNT = 2;
const INITIAL_ITEMS_COUNT = 2;

const LinksList: FC<Props> = ({
  items,
  userName,
  userId,
  onClick,
  onImpression,
  linkStyles,
  collectionData,
  onSubscriptionSubmitSuccess,
}) => {
  const templateStyles = useSelector(
    (state: RootState) => state.userMetaData.root?.template_preset
  );

  const [initializeFinished, setInitializeFinished] = useState(false);
  const { serverUserNameIsUsed } = useUsername();
  const location = useLocation();
  const queryParams = querystring.parse(location?.search);
  const [visibleItemsCount, setVisibleItemsCount] = useState<number>(INITIAL_ITEMS_COUNT);

  const onItemClick = (item: any, event?: React.MouseEvent<HTMLElement>) => {
    onClick && onClick(item, event);
    if (item.type === LinkTypeEnum.Vcard) {
      sendContactClickAnalytics({
        linkId: item.id,
        drummerId: userId || '',
        drummerUsername: userName || '',
        title: item.name,
        place: collectionData ? 'collection' : 'myPage'
      })
    }
  };
  const onItemImpression = (item: any) => {
    onImpression && onImpression(item);
  };
  const fetchData = () => {
    // console.log('Pootis');
    if (visibleItemsCount < items.length) {
      setVisibleItemsCount(visibleItemsCount + INFINITE_SCROLL_ITEM_COUNT);
    }
    return;
  };

  //initial infiniteScroll loader
  useEffect(() => {
    if (!items || /* visibleItemsCount >= items.length || */initializeFinished) {
      return;
    }
    const list = document.getElementsByClassName('listItem');
    const rect = list[visibleItemsCount - 1] && list[visibleItemsCount - 1].getBoundingClientRect() || undefined;
    if (rect && rect.top > document.body.clientHeight) {
      setInitializeFinished(true);
    } else {
      fetchData();
    }
  }, [items, visibleItemsCount, initializeFinished]);

  return (
    <Container styles={templateStyles}>
      {/* <InnerHTML
        html={`<script id="tiktok-embed-script" async src="https://www.tiktok.com/embed.js"></script>`}
      /> */}
      <InfiniteScrollContainer>
        <InfiniteScroll
          dataLength={visibleItemsCount}
          next={fetchData}
          hasMore={visibleItemsCount < items.length}
          loader={<>Loading...</>}
          onScroll={() => console.log('scroll')}
          style={{ width: '100%' }}
        >
          {items.slice(0, visibleItemsCount).map((item) => {
            {
              /* {items.map((item) => { */
            }
            return (
              <VisibilitySensor
                partialVisibility={true}
                key={item.id}
                onChange={(isVisible) => isVisible && onItemImpression(item)}
              >
                <>
                  {item.type === LinkTypeEnum.Offer && (
                    <OfferItem
                      key={item.id}
                      item={item}
                      href={getLandingURL(
                        item,
                        userName,
                        collectionData,
                        serverUserNameIsUsed,
                        queryParams
                      )}
                      onClick={(event) => onItemClick(item, event)}
                      linkStyles={linkStyles}
                      styles={templateStyles}
                      className={'listItem'}
                    />
                  )}
                  {item.type === LinkTypeEnum.Collection && (
                    <CollectionItem
                      item={item}
                      href={getLandingURL(
                        item,
                        userName,
                        collectionData,
                        serverUserNameIsUsed,
                        queryParams
                      )}
                      onClick={(event) => onItemClick(item, event)}
                      linkStyles={linkStyles}
                      styles={templateStyles}
                      className={'listItem'}
                    />
                  )}
                  {item.type === LinkTypeEnum.Page && (
                    <CustomLinkItem
                      item={item}
                      href={getLandingURL(
                        item,
                        userName,
                        collectionData,
                        serverUserNameIsUsed,
                        queryParams
                      )}
                      onClick={(event) => onItemClick(item, event)}
                      linkStyles={linkStyles}
                      styles={templateStyles}
                      className={'listItem'}
                    />
                  )}
                  {(item.type === LinkTypeEnum.Paragraph ||
                    item.subType === LinkTypeEnum.Paragraph) && (
                      <ParagraphItem
                        item={item}

                        linkStyles={linkStyles}
                        styles={templateStyles}
                        className={'listItem'}
                      />
                    )}
                  {(item.type === LinkTypeEnum.Video ||
                    item.type === LinkTypeEnum.Audio ||
                    item.subType === LinkTypeEnum.Video ||
                    item.subType === LinkTypeEnum.Audio) && (
                      <MediaItem
                        item={item}
                        onClick={(event) => onItemClick(item, event)}
                        styles={templateStyles}
                        className={'listItem'}
                      />
                    )}
                  {(item.type === LinkTypeEnum.VideoProduct ||
                    item.type === LinkTypeEnum.DigitalProduct ||
                    item.type === LinkTypeEnum.PhysicalProduct) && (
                      <ProductItem
                        item={item}
                        href={getLandingURL(
                          item,
                          userName,
                          collectionData,
                          serverUserNameIsUsed,
                          queryParams
                        )}
                        onClick={(event) => onItemClick(item, event)}
                        linkStyles={linkStyles}
                        styles={templateStyles}
                        className={'listItem'}
                      />
                    )}
                  {item.type === LinkTypeEnum.Subscribe && (
                    <SubscriptionItem
                      item={item}
                      linkStyles={linkStyles}
                      styles={templateStyles}
                      className={'listItem'}
                      onSubmitSuccess={onSubscriptionSubmitSuccess}
                    />
                  )}
                  {item.type === LinkTypeEnum.Vcard && (
                    <ContactCardItem
                      item={item}
                      onClick={(event) => onItemClick(item, event)}
                      linkStyles={linkStyles}
                      styles={templateStyles}
                      className={'listItem'}
                    />
                  )}
                  {(item.type === LinkTypeEnum.Image ||
                    item.subType === LinkTypeEnum.Image) && (
                      <ImageItem
                        item={item}
                        linkStyles={linkStyles}
                        styles={templateStyles}
                        className={'listItem'}
                      />
                    )}

                  {(item.type === LinkTypeEnum.Title ||
                    item.subType === LinkTypeEnum.Title) && (
                      <TitleItem
                        item={item}
                        linkStyles={linkStyles}
                        styles={templateStyles}
                        className={'listItem'}
                      />
                    )}
                </>
              </VisibilitySensor>
            );
          })}
          {/* {visibleItemsCount < items.length &&
            <p style={{textAlign: "center"}} onClick={fetchData}>Scroll or click to see more</p>
          }  */}
        </InfiniteScroll>
      </InfiniteScrollContainer>
    </Container>
  );
};

export default LinksList;
