import React, { FC } from 'react';
import Modal from 'react-modal';
import { CSSProperties } from 'styled-components';
import {
  Container,
  Image,
  StatusIconContainer,
  MainActionButton,
  Message,
  SecondaryActionButton,
  StyledCloseButton,
  Title,
  contentStyle,
  overlayStyle
} from './styles';
import { WarningLarge, SuccessLarge, CloseRoundIcon } from 'assets/svg';
import { Loader } from 'uikit';
import './styles.scss';

export type statusTypes = 'success' | 'warning' | 'error' | 'progress';

export interface ModalProps {
  className?: string;
  imageURL?: string;
  type?: statusTypes;
  title?: string;
  message?: string;
  mainButton?: {
    text: string;
    onClick: () => void;
  };
  secondaryButton?: {
    text: string;
    onClick: () => void;
  };
  isOpen: boolean;
  onClose?: () => void;
  canClose?: boolean;
  onCloseOnButtons?: boolean;
  icon?: FC;
  childrenBeforeButtons?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  customContentStyle?: CSSProperties;
  customOverlayStyle?: CSSProperties;
}

const UIModal: FC<ModalProps> = ({
  className,
  imageURL,
  type,
  title,
  message,
  mainButton,
  secondaryButton,
  isOpen,
  onClose,
  canClose = true,
  onCloseOnButtons = true,
  children = null,
  childrenBeforeButtons = false,
  shouldCloseOnOverlayClick = true,
  customContentStyle,
  customOverlayStyle
}) => (
  <Modal
    ariaHideApp={false}
    isOpen={isOpen}
    onRequestClose={() => {onClose && onClose()}}
    shouldCloseOnOverlayClick={shouldCloseOnOverlayClick && canClose}
    style={{ content: Object.assign(contentStyle, customContentStyle || {}), overlay: Object.assign(overlayStyle, customOverlayStyle || {}) }}
    className={className || ''}
  >
    <Container>
      {canClose && 
        <StyledCloseButton onClick={onClose} type="button">
          <CloseRoundIcon />
        </StyledCloseButton>
      }

      {imageURL && <Image src={imageURL} alt="" />}
      
      {type && 
        <StatusIconContainer>
          {type === 'warning' && <WarningLarge />}
          {type === 'success' && <SuccessLarge />}
          {type === 'progress' && <Loader />}
        </StatusIconContainer>
      }

      {title && <Title>{title}</Title>}

      {message && <Message>{message}</Message>}

      {!!childrenBeforeButtons && children}

      {mainButton && <MainActionButton
        onClick={() => {
          mainButton.onClick();
          onCloseOnButtons && onClose && onClose();
        }}
        type="button"
      >
        {mainButton.text}
      </MainActionButton>}

      {!!secondaryButton && (
        <SecondaryActionButton
          onClick={() => {
            secondaryButton.onClick();
            onCloseOnButtons && onClose && onClose();
          }}
          type="button"
        >
          {secondaryButton.text}
        </SecondaryActionButton>
      )}
      {!childrenBeforeButtons && children}
    </Container>
  </Modal>
);

export default UIModal;
