import React, { FC } from 'react';
import { Container, ContainerInner, SocialLink, SocialImage } from './styles';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import {
  SocialAccount, SocialNetworkEnum,
} from 'graphqlQueries';

type Props = {
  items: SocialAccount[];
  onItemClick?: (item: SocialAccount) => void;
  className?: string;
};

const SocialMediaLinks: FC<Props> = ({ items, onItemClick, className }) => {

  const styles = useSelector(
    (state: RootState) =>
      state.userMetaData.root?.template_preset
  );
  const socialType = useSelector(
    (state: RootState) =>
      state.userMetaData.root?.social_account_type
  );

  return (
    <Container className={className} styles={styles}>
      <ContainerInner styles={styles}>
        {items.map((item) => (
          <SocialLink
            onClick={() => {
              onItemClick && onItemClick(item);
            }}
            className={item.social || undefined}
            target="_blank"
            href={item.link || undefined}
            styles={styles}
            type={socialType || undefined}
            download={item.social === SocialNetworkEnum.Vcard}
          >
            <SocialImage src={item.logo?.svg || ''} alt={item.social || 'Social Link'} type={socialType || undefined} />
          </SocialLink>
        ))}
      </ContainerInner>
    </Container>
  );
};

export default SocialMediaLinks;
