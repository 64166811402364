import { configureStore } from '@reduxjs/toolkit';
import checkoutReducer from './reducers/checkout';
import userMetaDataReducer from './reducers/userMetaData';

export const store = configureStore({
  reducer: {
    checkout: checkoutReducer,
    userMetaData: userMetaDataReducer,
  },
});

// export const store = configureStore({
//   reducer: {
//     checkout: checkoutReducer
//   },
// });

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;