import { createGlobalStyle } from 'styled-components';
import resetCSS from './reset';
import fontsCSS from './fonts';

export default createGlobalStyle`
${resetCSS}
${fontsCSS}
body {
    font-family: "Raleway";
    font-weight: 400;
}
a {
    text-decoration: none;
}

html,
body,
#root {
    height: 100%;
}

html body {
    /*This selector looks retarded, but do not delete it. 
    It prevents scrolllock from applying styles, which interfere with loaders. Example: upgrade page*/
    
    height: 100% !important;
}
`;
