import styled from 'styled-components';
import { colord } from 'colord';
import {
  ContainerBase,
  ContainerLinkBase,
  ContainerInnerBase,
  ContentBase,
} from 'uikit/LinkItems';
import { InputBase, InputGroup, Body1, Body2, FieldFooter, PrimaryButton } from 'uikit';
import { TemplatePreset } from 'graphqlQueries';

export interface Props {
  styles?: TemplatePreset;
}

export const Container = styled(ContainerLinkBase)(
  ({ styles }) => `
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 4;
  
  &:hover {
    transform: none;
  }

  &, &.large {
    padding-top: ${styles !== undefined ? styles?.link?.base?.padding?.top || 0 : 8
    }px;
    padding-bottom: ${styles !== undefined ? styles?.link?.base?.padding?.bottom || 0 : 8
    }px;
    padding-left: 150px;
    padding-right: 150px;
    border-radius: ${styles !== undefined ? styles?.link?.base?.borderRadius || 0 : 7
    }px;
  }

  @media(max-width: 600px) {
    &, &.large {
      padding-top: ${styles !== undefined ? styles?.link?.base?.mobilePadding?.top || 0 : 8
    }px;
      padding-bottom: ${styles !== undefined ? styles?.link?.base?.mobilePadding?.bottom || 0 : 8
    }px;
      padding-left: ${styles !== undefined ? styles?.link?.base?.mobilePadding?.left || 0 : 8
    }px;
      padding-right: ${styles !== undefined ? styles?.link?.base?.mobilePadding?.right || 0 : 8
    }px;
      border-radius: ${styles !== undefined ? styles?.link?.base?.borderRadius || 0 : 7
    }px;
    }
  }
`
);
export const ContainerInner = styled(ContainerInnerBase)`
  
`;
export const Content = styled(ContentBase)`
  justify-content: flex-start;
  align-items: flex-start;
  text-align: initial;
  overflow: initial;

  width: 100%;
  max-width: 400px;

  &,
  ${ContainerLinkBase}.large &,
  ${ContainerBase}.large & {  
    padding: 6px 0px;
  }
  
  
`;

export const InputContainer = styled.div`
  margin-bottom: 14px;
  width: 100%;
`;
export const InputBaseStyled = styled(InputBase)`
  .small & {
    padding-right: 95px !important;
  }
`;
export const InputGroupStyled = styled(InputGroup)`
  display: flex;
  align-items: center;
  position: relative;

  .small & .react-tel-input input {
    padding-right: 95px !important;
  }
`;

export const FieldFooterStyled = styled(FieldFooter)`

`;

export const CardTitle = styled(Body2)<Props>(
  ({ styles, theme }) => `
  font-weight: ${styles?.link?.base?.titleFontWeight || theme.fontWeight.bold};
  line-height: 24px;
  margin-bottom: 7px;
  font-family: ${styles?.link?.base?.font};
  color: ${styles?.link?.base?.color || theme?.colors?.dlpDefaultCardTextColor
    };
`);

export const SubmitButton = styled(PrimaryButton)<Props>(
  ({ theme, styles }) => `
  font-family: inherit;
  width: 95px;
  height: 37px;
  color: ${styles?.link?.product?.buyButton?.color || 'inherit'};
  cursor: pointer;
  ${styles?.link?.product?.buyButton?.backgroundColor
      ? `
    background-color: ${styles?.link?.product?.buyButton?.backgroundColor};
    :hover {
      background-color: ${colord(
        styles?.link?.product?.buyButton?.backgroundColor
      )
        .darken(0.15)
        .toHex()};
    }
    :active {
      background-color: ${colord(
          styles?.link?.product?.buyButton?.backgroundColor
        )
        .darken(0.15)
        .toHex()};
    }
    :disabled {
      background-color: ${colord(
          styles?.link?.product?.buyButton?.backgroundColor
        )
        .desaturate(1)
        .toHex()};
    }
  `
      : ''
    }
  border-radius: ${styles?.link?.product?.buyButton?.borderRadius || 0}px;
  padding: 0;
  align-self: center;
  font-size: ${({ theme }) => theme.size.body2};

  .large & {
    
  }
  
  ${InputGroupStyled} & {
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    position: absolute;
  }
`
);

export const FormFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ErrorContainer = styled.div`
  flex: 1;

  & > div {
    margin-top: 0;
    & > div {
      height: auto;
      text-align: left;
    }
  }

  .large & {
    padding-right: 22px;
  }
`;

export const SuccessMessage = styled(Body2)<Props>(
  ({ styles, theme }) => `
  font-weight: ${theme.fontWeight.bold};
  line-height: 24px;
  color: ${styles?.link?.base?.color || theme?.colors?.dlpDefaultCardTextColor
    };
  text-align: right;
`);

export const FormLoaderContainer = styled.div`
  text-align: right;
  span {
    display: inline-block;
    color: #009B5D;
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: ${({ theme }) => theme.size.body1};
    line-height: 26px;
    margin-left: 14px;
    vertical-align: top;
  }
`;
export const FormLoader = styled(Body2)`
  display: inline-block;
  width: 19px;
  height: 19px;

  &:after {
    content: " ";
    display: block;
    width: 17px;
    height: 17px;
    margin: 1px;
    border-radius: 50%;
    border: 2px solid #fff;
    border-color: #009B5D transparent #009B5D transparent;
    animation: subscription-item-loader 1.2s linear infinite;
  }
  
`;




