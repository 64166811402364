import styled, { Theme, DefaultTheme } from 'styled-components';
import { breakpoint } from 'helpers';
import { Link } from 'react-router-dom';
import { TemplatePreset, ShadowType, TemplateHoverAnimationType } from 'graphqlQueries';

export interface Props {
  color?: string | null;
  font?: string | null;
  backgroundColor?: string | null;
  fontStyle?: string | null;
  alignment?: string | null;
  isBackgroundCardEnabled?: boolean | null;
  theme: Theme;
  styles?: TemplatePreset;
  disableAnimation?: boolean | null;
  type?: string | null;
}
export interface InnerProps {
  backgroundColor?: string | null;
  styles?: TemplatePreset;
  isBackgroundCardEnabled?: boolean | null;
}

const fontSizeConvertMap = {
  description: {
    SMALL: '12px',
    LARGE: '14px'
  },
  title: {
    SMALL: '16px',
    LARGE: '20px'
  }
}

const alignmentConvertMap = {
  description: {
    LEFT: 'flex-start',
    CENTER: 'center',
    RIGHT: 'flex-end'
  },
  title: {
    LEFT: 'flex-start',
    CENTER: 'center',
    RIGHT: 'flex-end'
  }
}

const textAlignmentConvertMap = {
  description: {
    LEFT: 'left',
    CENTER: 'center',
    RIGHT: 'right'
  },
  title: {
    LEFT: 'left',
    CENTER: 'center',
    RIGHT: 'right'
  }
}

export const ContainerBaseMixin = (
  styles: TemplatePreset | undefined,
  theme: DefaultTheme,
  color?: string | null,
  font?: string | null,
  backgroundColor?: string | null,
  isBackgroundCardEnabled?: boolean | null,
  disableAnimation?: boolean | null
) => `
  display: block;
  width: calc(100% - ${styles?.link?.base?.margin?.left || 0}px - ${styles?.link?.base?.margin?.right || 0}px);
  box-sizing: border-box;
  position: relative;
  transition: transform 0.2s;
  text-decoration: none;
  font-family: ${font || styles?.link?.base?.font || "Raleway"}, "Raleway";
  background-color: ${(isBackgroundCardEnabled !== false) ? (backgroundColor || styles?.link?.base?.backgroundColor || 'transparent') : 'transparent'
  };
  color: ${color || styles?.link?.base?.color || theme?.colors?.dlpDefaultCardTextColor
  };
  border-top: ${styles?.link?.base?.border?.top || 0}px solid ${styles?.link?.base?.borderColor || styles?.link?.base?.divider?.color || 'transparent'
  };
    border-bottom: ${styles?.link?.base?.border?.bottom || 0}px solid ${styles?.link?.base?.borderColor || styles?.link?.base?.divider?.color || 'transparent'
  };
    border-left: ${styles?.link?.base?.border?.left || 0}px solid ${styles?.link?.base?.borderColor || styles?.link?.base?.divider?.color || 'transparent'
  };
    border-right: ${styles?.link?.base?.border?.right || 0}px solid ${styles?.link?.base?.borderColor || styles?.link?.base?.divider?.color || 'transparent'
  };
  padding-top: ${styles !== undefined ? styles?.link?.base?.padding?.top || 0 : 8
  }px;
  padding-bottom: ${styles !== undefined ? styles?.link?.base?.padding?.bottom || 0 : 8
  }px;
  padding-left: ${styles !== undefined ? styles?.link?.base?.padding?.left || 0 : 8
  }px;
  padding-right: ${styles !== undefined ? styles?.link?.base?.padding?.right || 0 : 8
  }px;

  margin-left: ${styles?.link?.base?.margin?.left || 0}px;
  margin-right: ${styles?.link?.base?.margin?.right || 0}px;

  border-radius: ${styles !== undefined ? styles?.link?.base?.borderRadius || 0 : 7
  }px;

  @media(max-width: 600px) {
    width: calc(100% - ${styles?.link?.base?.mobileMargin?.left || 0}px - ${styles?.link?.base?.mobileMargin?.right || 0}px);
    padding-top: ${styles !== undefined ? styles?.link?.base?.mobilePadding?.top || 0 : 8
  }px;
    padding-bottom: ${styles !== undefined ? styles?.link?.base?.mobilePadding?.bottom || 0 : 8
  }px;
    padding-left: ${styles !== undefined ? styles?.link?.base?.mobilePadding?.left || 0 : 8
  }px;
    padding-right: ${styles !== undefined ? styles?.link?.base?.mobilePadding?.right || 0 : 8
  }px;

    margin-left: ${styles?.link?.base?.mobileMargin?.left || 0}px;
    margin-right: ${styles?.link?.base?.mobileMargin?.right || 0}px;
  }
  

  &:first-of-type {
    border-top: ${styles?.link?.base?.firstItemBorder?.top || 0}px solid ${styles?.link?.base?.borderColor || styles?.link?.base?.divider?.color || 'transparent'
  };
        border-bottom: ${styles?.link?.base?.firstItemBorder?.bottom || 0
  }px solid ${styles?.link?.base?.borderColor || styles?.link?.base?.divider?.color || 'transparent'};
        border-left: ${styles?.link?.base?.firstItemBorder?.left || 0}px solid ${styles?.link?.base?.borderColor || styles?.link?.base?.divider?.color || 'transparent'
  };
        border-right: ${styles?.link?.base?.firstItemBorder?.right || 0}px solid ${styles?.link?.base?.borderColor || styles?.link?.base?.divider?.color || 'transparent'
  };
  }

  box-shadow: ${styles?.link?.base?.shadow?.type === ShadowType.Inset ? 'inset' : ''} ${styles?.link?.base?.shadow?.xOffset || 0}px ${styles?.link?.base?.shadow?.yOffset || 0}px ${styles?.link?.base?.shadow?.radius || 0}px ${styles?.link?.base?.shadow?.color || 'transparent'
  },
    ${styles?.link?.base?.topShadow?.type === ShadowType.Inset ? 'inset' : ''} ${styles?.link?.base?.topShadow?.xOffset || 0}px ${styles?.link?.base?.topShadow?.yOffset || 0}px ${styles?.link?.base?.topShadow?.radius || 0}px ${styles?.link?.base?.topShadow?.color || 'transparent'
  }
  ;

  &:hover,
  &:focus,
  &:active,
  &:visited {
    text-decoration: none;
  }

  &:hover {
    ${!disableAnimation && styles?.link?.base?.useHoverAnimation && styles?.link?.base?.hoverAnimationType === TemplateHoverAnimationType.Diagonal ? 'transform: translate(3px, 3px);' : ""
  }
    ${!disableAnimation && styles?.link?.base?.useHoverAnimation && styles?.link?.base?.hoverAnimationType === TemplateHoverAnimationType.Horizontal ? 'transform: translate(3px, 0);' : ""
  }
    ${!disableAnimation && styles?.link?.base?.useHoverAnimation && styles?.link?.base?.hoverAnimationType === TemplateHoverAnimationType.Vertical ? 'transform: translate(0, 3px);' : ""
  }
    box-shadow: ${styles?.link?.base?.activeShadow?.type === ShadowType.Inset ? 'inset' : ''} ${styles?.link?.base?.activeShadow?.xOffset || 0}px ${styles?.link?.base?.activeShadow?.yOffset || 0}px ${styles?.link?.base?.activeShadow?.radius || 0}px ${styles?.link?.base?.activeShadow?.color || 'transparent'
  },
      ${styles?.link?.base?.activeTopShadow?.type === ShadowType.Inset ? 'inset' : ''} ${styles?.link?.base?.activeTopShadow?.xOffset || 0}px ${styles?.link?.base?.activeTopShadow?.yOffset || 0}px ${styles?.link?.base?.activeTopShadow?.radius || 0}px ${styles?.link?.base?.activeTopShadow?.color || 'transparent'
  }
    ;
  }

  &:not(:last-child) {
    margin-bottom: ${!styles ? 19 : styles?.link?.base?.gap || 0}px;
  }

  &.no-images {
    width: 100%; //Template 3
    margin-left: 0;
    margin-right: 0;

    @media(max-width: 600px) {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.large {
    width: 100%;
    margin-left: 0;
    padding-top: ${styles?.link?.base?.largeItemPadding?.top || 0}px;
    padding-bottom: ${styles?.link?.base?.largeItemPadding?.bottom || 0}px;
    padding-left: ${styles?.link?.base?.largeItemPadding?.left || 0}px;
    padding-right: ${styles?.link?.base?.largeItemPadding?.right || 0}px;

    margin-top: ${styles?.link?.base?.largeItemMargin?.top || 0}px;
    margin-left: ${styles?.link?.base?.largeItemMargin?.left || 0}px;

    @media(max-width: 600px) {
      padding-top: ${styles?.link?.base?.largeItemMobilePadding?.top || 0}px;
      padding-bottom: ${styles?.link?.base?.largeItemMobilePadding?.bottom || 0}px;
      padding-left: ${styles?.link?.base?.largeItemMobilePadding?.left || 0}px;
      padding-right: ${styles?.link?.base?.largeItemMobilePadding?.right || 0}px;

      margin-left: ${styles?.link?.base?.largeItemMobileMargin?.left || 0}px;
      margin-right: ${styles?.link?.base?.largeItemMobileMargin?.right || 0}px;
    }
  }
`;

export const ContainerBase = styled(Link)<Props>(
  ({ styles, theme, font, color, backgroundColor }) => `
  ${ContainerBaseMixin(styles, theme, color, font, backgroundColor)};
`
);

export const ContainerLinkBase = styled.a<Props>(
  ({ styles, theme, font, color, backgroundColor, isBackgroundCardEnabled, disableAnimation }) => `
  ${ContainerBaseMixin(styles, theme, color, font, backgroundColor, isBackgroundCardEnabled, disableAnimation)};
`
);

export const ContainerInnerBase = styled.div<InnerProps>(
  ({ styles }) => `
  width: 100%;
  position: relative;
  display: flex;
  box-sizing: border-box;
  min-height: ${styles?.link?.base?.minHeightDesktop || 53}px;
  color: inherit;
  z-index: 3;

  ${ContainerLinkBase}.large &,
  ${ContainerBase}.large & {
    flex-direction: column;
    padding: 0;
  }

  @media(max-width: 600px) {
    min-height: ${styles?.link?.base?.minHeightMobile || 53}px;
  }
`
);

export const ImageBase = styled.div<Props>(
  ({ styles, theme, type }) => `
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  width: ${styles?.link?.base?.image?.size?.desktop?.width || 53}px;
  height: ${styles?.link?.base?.image?.size?.desktop?.height || 53}px;
  margin-right: 33px;
  border-radius: ${styles?.link?.base?.imageBorderRadius || 0}px;
  background-color: ${styles?.link?.base?.backgroundColor || 'transparent'};
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  // filter: drop-shadow(0px 4px 4px ${theme.colors.dlpLinkItemImageFilterColor
    });
  margin-top: ${styles?.link?.base?.image?.offset?.desktop?.top || 0}px;
  margin-left: ${styles?.link?.base?.image?.offset?.desktop?.left || 0}px;
  border: ${styles?.link?.base?.image?.border?.size || 0}px solid ${styles?.link?.base?.image?.border?.color || '#ffffff00'};
  
  box-shadow: ${styles?.link?.base?.image?.shadow?.type === ShadowType.Inset ? 'inset' : ''} ${styles?.link?.base?.image?.shadow?.xOffset || 0}px ${styles?.link?.base?.image?.shadow?.yOffset || 0}px ${styles?.link?.base?.image?.shadow?.radius || 0}px ${styles?.link?.base?.image?.shadow?.color || 'transparent'
    };


  ${ContainerLinkBase}.large &,
  ${ContainerBase}.large & {
    position: relative;
    width: 100%;
    height: 274px;
    margin-right: 0;
    margin-left: 0;
    margin-top: 0;
    border-radius: ${styles !== undefined ? styles?.link?.base?.borderRadius || 0 : 6
    }px  ${styles !== undefined ? styles?.link?.base?.borderRadius || 0 : 6
    }px ${(type === 'image' && styles !== undefined) ? styles?.link?.base?.borderRadius || 0 : 6} px
    ${(type === 'image' && styles !== undefined) ? styles?.link?.base?.borderRadius || 0 : 6} px;
    border: none;
    box-shadow: none;
    background-color: transparent;
    overflow: hidden;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: none;
  }

  ${ContainerLinkBase}.no-images &,
  ${ContainerBase}.no-images & {
    visibility: hidden;
  }

  @media(max-width: 600px) {
    margin-right: 9px;
    width: ${styles?.link?.base?.image?.size?.mobile?.width || 53}px;
    height: ${styles?.link?.base?.image?.size?.mobile?.height || 53}px;
    margin-top: ${styles?.link?.base?.image?.offset?.mobile?.top || 0}px;
    margin-left: ${styles?.link?.base?.image?.offset?.mobile?.left || 0}px;
  }

   @media(max-width: 576px) .large & {
     .large & {
       height: 127px!important;
     }
  }

   @media(max-width: 768px) {
     .large &  {
       height: 157px!important;
     }
  }

  ${breakpoint('xs', 'sm')`
    ${ContainerLinkBase}.large &,
    ${ContainerBase}.large &{
      height: 157px;
    }
	`}
  ${breakpoint('sm', 'md')`
    ${ContainerLinkBase}.large &,
    ${ContainerBase}.large &{
      height: 202px;
    }
	`}
`
);

export const ContentBase = styled.div<Props>(
  ({ styles, alignment }) => `
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 6px 4px;
  align-items: ${(alignment && alignmentConvertMap.description[alignment]) || 'center'};
  justify-content: center;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;

  ${ContainerLinkBase}.large &,
  ${ContainerBase}.large & {   
    padding: 16px 4px;
    min-height: ${styles?.link?.base?.minHeightDesktop || 53}px;
    text-align: center;

    @media(max-width: 600px) {
      padding: 16px 4px;
      min-height: ${styles?.link?.base?.minHeightMobile || 53}px;
    }
  }
`);

export const ContentInnerBase = styled.div<Props>(
  ({ styles }) => `
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  padding: 0 86px 0 86px;
  box-sizing: border-box;

  @media(min-width:600px) {
    max-width: calc(${styles?.headerContainer?.maxContentWidth
      ? `${styles?.headerContainer?.maxContentWidth}px`
      : '100%'
    } - ${styles?.headerContainer?.padding?.left || 0}px - ${styles?.headerContainer?.padding?.right || 0
    }px + 86px);
  }

  ${breakpoint('xs', 'md')`
    padding-right: 70px;
  `}

  ${ContainerLinkBase}.no-images &,
  ${ContainerBase}.no-images & {
    padding-left: 86px;
  }

  ${ContainerLinkBase}.large &,
  ${ContainerBase}.large & {
    padding: 0 86px;
    box-sizing: border-box;
    text-align: center;

    max-width: calc(${styles?.headerContainer?.maxContentWidth
      ? `${styles?.headerContainer?.maxContentWidth}px`
      : '100%'
    } - ${styles?.headerContainer?.padding?.left || 0}px - ${styles?.headerContainer?.padding?.right || 0
    }px + 172px);

    @media(max-width: 600px) {
      max-width: 100%
    }
  }

  ${breakpoint('xs', 'md')`
    ${ContainerLinkBase}.large &,
    ${ContainerBase}.large & {
      padding: 0 70px 0 86px;
      min-height: 70px;
      box-sizing: border-box;
    }

    ${ContainerLinkBase}.large &,
    ${ContainerBase}.large & {
      padding: 0 84px 0 86px;
    }
	`}
`
);

export const InfoBase = styled.div<Props>(
  () => `
  display: flex;
  align-items: flex-start;
  text-align: center;
  max-width: 100%;
  word-break: break-word;

  @media(max-width: 600px) {
    max-width: 100%;
  }
`
);

export const BusinessImageContainerBase = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  margin-right: 6px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.dlpLinkItemBusinessLogoBg};
  border-radius: 4px;
  box-shadow: 0px 2px 8px
    ${({ theme }) => theme.colors.dlpLinkItemBusinessLogoShadowColor};
  border: 2px solid
    ${({ theme }) => theme.colors.dlpLinkItemBusinessLogoBorderColor};
`;

export const BusinessImageBase = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: -100%;
  right: -100%;
  height: 100%;
`;

export const BusinessNameBase = styled.p<Props>(
  ({ theme, fontStyle, alignment }) => `
  overflow: hidden;
  white-space: pre-line;
  position: relative;
  font-size: ${(fontStyle && fontSizeConvertMap.title[fontStyle]) || theme.size.body1};
  text-align: ${(alignment && textAlignmentConvertMap.description[alignment]) || 'center'};;
  font-weight: ${theme.fontWeight.bold};
  font-family: inherit;
  color: inherit;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 20px;
`);

export const DescriptionBase = styled.pre<Props>(
  ({ styles, theme, fontStyle, alignment }) => `
  overflow: hidden;
  white-space: pre-line;
  position: relative;
  font-size: ${fontStyle && fontSizeConvertMap.description[fontStyle] || theme.size.body2};
  font-weight: ${styles?.link?.base?.descriptionFontWeight || theme.fontWeight.regular};
  font-family: inherit;
  color: inherit;
  line-height: 21px;
  margin-top: 11px;
  margin-bottom: 0;
  opacity: 0.8;
  text-align: ${(alignment && textAlignmentConvertMap.description[alignment]) || 'center'};
`);
