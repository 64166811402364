import styled from 'styled-components';
import { breakpoint } from '../../helpers';
import { ColProps, RowProps } from '../../types';

export const Grid = styled.div``;
export const Row = styled.div<RowProps>`
  display: flex;
  flex-grow: 1;
  flex-direction: ${({ direction }: RowProps) =>
    direction ? direction : 'row'};
  justify-content: ${({ justify }: RowProps) =>
    justify ? justify : 'flex-start'};
  align-items: ${({ align }: RowProps) => (align ? align : 'stretch')};
  min-height: ${(props: RowProps) => (props.maxHeight ? '100vh' : 'inherit')};
`;
export const Col = styled.div<ColProps>`
  flex: ${(props: ColProps) => props.size};
  ${(props: ColProps) =>
    props.collapse &&
    breakpoint(props.collapse.from, props.collapse.to)`
    display: none;
  `}
  position: ${(props: ColProps) => (props.relative ? 'relative' : 'inherit')};
`;
