import React, { FC } from 'react';
import QRCode  from 'qrcode.react';
import { 
  Container,
  Text,
  AppLink,
  DownloadButton,
} from './styles';

interface Props {
  link?: string | null;
}

const QrCode: FC<Props> = ({ link }) => {

  return (
    <Container>
      <Text>
       Scan the QR Code to access the app or download from the{" "}
        <AppLink href={process.env.REACT_APP_IOS_APP_LINK} target="_blank">App Store</AppLink> or <AppLink href={process.env.REACT_APP_ANDROID_APP_LINK} target="_blank">Google Play Store</AppLink>.
      </Text>
      <QRCode value={link} size="184" />
      <DownloadButton href={link || ''} target="_blank">Download the app</DownloadButton>
    </Container>
  );
};

export default QrCode;
