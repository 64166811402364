import React, { FC, useState, useEffect } from 'react';
import {
  Container,
  LoaderImage,
  LoaderText,
} from './styles';

interface Props {
  hidden: boolean;
}

const ScraperLoader:FC<Props> = ({ hidden }) => {

  const [hideBlock, setHideBlock] = useState(hidden);

  useEffect(() => {
    setTimeout(() => {
      setHideBlock(hidden);
    }, 1000)
  }, [hidden])

  return (
    <Container className={ `${hidden ? 'hidden' : ''} ${hideBlock ? 'no-display' : ''}`}>
      <LoaderImage src="/assets/images/drum-loader.gif" />
      <LoaderText>
        Hang on! We’re quickly building your Drum page!
      </LoaderText>
    </Container>
  );
};

export default ScraperLoader;
