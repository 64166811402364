import React, { FC, useEffect, useState } from 'react';
import { Formik, FormikErrors } from 'formik';
import { TEXTAREA_MAX_LENGTH_SMALL } from 'constants/fieldLengthLimits';
import Swiper from 'components/Swiper';
import Delayed from 'components/Delayed';
import PrimaryButtonStyled from 'components/PrimaryButtonStyled';
import { FieldFooter, InputGroup, InputLabel } from 'uikit';
import validationSchema, { SchemaType } from './validationSchema';
import {
  Product,
  SelectedImages,
  ImageStyle,
  ImageStyleName,
  LinkStyleConfiguration,
  TemplatePreset,
} from 'graphqlQueries';
import { getImagePath } from 'helpers';
import { getMoneyFormat } from 'pages/CheckoutPage/utils';
import {
  Container,
  ContainerInner,
  ImageContainer,
  DetailsContainer,
  FormContainer,
  ButtonsContainer,
  Title,
  Description,
  PriceContainer,
  PriceBlock,
  Price,
  PriceNotes,
  StyledTextareaBase,
} from '../styles';

export interface IOption {
  label: string;
  value: string;
}

export interface ISubmitValues {
  values: SchemaType;
  setSubmitting: (isSubmitting: boolean) => void;
  validateForm: (values?: SchemaType) => Promise<FormikErrors<SchemaType>>;
}

interface IPurchaseVideoProduct {
  values: Partial<Product>;
  user: any;
  initialNotes: string;
  inputStyles?: Pick<
    LinkStyleConfiguration,
    'background_color' | 'color' | 'font_family'
  > | null;
  styles?: TemplatePreset;
  onSubmit: (values: SchemaType, { setSubmitting, validateForm }: any) => void;
}

const PurchaseVideoProduct: FC<IPurchaseVideoProduct> = ({
  values,
  user,
  initialNotes,
  inputStyles,
  styles,
  onSubmit,
}) => {
  const [galleryImages, setGalleryImages] = useState<Array<string>>([]);
  const { title, description, price, images } = values;

  const initialValues: SchemaType = {
    notes: initialNotes || '',
  };

  useEffect(() => {
    const _imgs =
      (images as SelectedImages[])?.map((item, index) => {
        return getImagePath(
          item.styles as ImageStyle[],
          ImageStyleName.Original
        );
      }) || [];
    setGalleryImages(_imgs);
  }, [images]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({
        errors,
        handleChange,
        setSubmitting,
        handleSubmit,
        validateForm,
        isSubmitting,
        values,
      }) => (
        <Container onSubmit={handleSubmit}>
          {!!galleryImages?.length && styles && (
            <ImageContainer>
              <Delayed>{/* we use it because swiper starts rendering when padding values are not yet received */}
              <Swiper images={galleryImages || []} />
              </Delayed>
            </ImageContainer>
          )}

          <DetailsContainer>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </DetailsContainer>

          <ContainerInner>
            <PriceContainer>
              <PriceBlock>
                <span>
                  Price: <Price>${getMoneyFormat(price || 0)}</Price>
                </span>
              </PriceBlock>
              <PriceNotes>
                *Video message usually sent via email within 48 hours.
              </PriceNotes>
            </PriceContainer>

            <FormContainer>
              <InputGroup styles={styles}>
                <InputLabel>
                  Give {user?.firstName || user?.username} some instructions for
                  your order
                </InputLabel>
                <StyledTextareaBase
                  name="notes"
                  onChange={handleChange}
                  value={values.notes}
                  maxLength={TEXTAREA_MAX_LENGTH_SMALL}
                />
                <FieldFooter error={errors.notes} showAlways={true} />
              </InputGroup>
            </FormContainer>

            <ButtonsContainer>
              <PrimaryButtonStyled type="submit" disabled={isSubmitting}>
                Check out
              </PrimaryButtonStyled>
            </ButtonsContainer>
          </ContainerInner>
        </Container>
      )}
    </Formik>
  );
};

export default PurchaseVideoProduct;
