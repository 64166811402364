import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from 'styled-components';
import { Provider as ReduxProvider } from 'react-redux';
import { UserNameContext } from 'context/UserNameContext';
import { theme } from './styles/theme';
import GlobalStyles from './styles/styled';
import { HelmetProvider } from 'react-helmet-async';
import { store } from 'redux/store';
import App from './App';
import { SSRContextProvider } from './context/SSRContext';

interface RootProps {
  apolloClient: any;
  appContext?: any;
  helmetContext?: any;
  url?: string;
  username?: string | null;
  serverTimeContext?: any;
  pageViewId?: string;
  isOnServer?: boolean;
}

const Root = ({
  apolloClient,
  appContext,
  helmetContext,
  url,
  username,
  serverTimeContext,
  pageViewId,
  isOnServer,
}: RootProps) => {
  return (
    <HelmetProvider context={helmetContext}>
      <ReduxProvider store={store}>
        <ApolloProvider client={apolloClient}>
          <ThemeProvider theme={theme}>
            <UserNameContext.Provider value={{ username }}>
              <GlobalStyles />
              <SSRContextProvider
                isOnServer={Boolean(isOnServer)}
                serverTimeContext={serverTimeContext}
                clientTimeContext={{
                  pageViewId,
                }}
              >
                <App url={url} context={appContext} username={username} />
              </SSRContextProvider>
            </UserNameContext.Provider>
          </ThemeProvider>
        </ApolloProvider>
      </ReduxProvider>
    </HelmetProvider>
  );
};

export default Root;
