import React, { FC } from 'react';
import { ICartItem } from '../../types';
import { getTotalSum, getShippingSum } from '../../utils';
import { ProductTypeEnum, TemplatePreset } from 'graphqlQueries';
import CartItem from '../CartItem';
import ShippingHandling from 'components/ShippingHandling';
import PrimaryButtonStyled from 'components/PrimaryButtonStyled';
import CancelButtonStyled from 'components/CancelButtonStyled';
import {
  Container,
  ItemsList,
  TotalContainer,
  Total,
  EmptyCartNote
} from './styles';

import {
  ButtonsContainer
} from '../../styles';

interface ICart {
  items: ICartItem[];
  templateStyles?: TemplatePreset;
  onContinue?: () => void;
  onCancel?: () => void;
  onItemRemove?: (id: string) => void;
  onItemEdit?: (id: string) => void;
}

const Cart: FC<ICart> = ({ items, templateStyles, onContinue, onCancel, onItemRemove, onItemEdit }) => {

  const handleContinue = () => {
    onContinue?.();
  }

  const handleCancel = () => {
    onCancel?.();
  }

  const handleItemRemove = (id: string) => {
    onItemRemove?.(id);
  }
  
  const handleItemEdit = (id: string) => {
    onItemEdit?.(id);
  }

  const total = getTotalSum(items);
  const shippingFee = getShippingSum(items);
  const showShipping = !!items.find((item) => item.product.type === ProductTypeEnum.PhysicalProduct);

  return (
    <Container>
      {items?.length ?
        <>
          <ItemsList>
            {items.map((item) => (
              <CartItem templateStyles={templateStyles} item={item} onEdit={handleItemEdit} onRemove={handleItemRemove} />
            ))}
          </ItemsList>
          <TotalContainer>
            Total: <Total>${total}</Total>
          </TotalContainer>

          <ShippingHandling showTaxes={true} shippingFee={shippingFee} showShipping={showShipping}/>

          <ButtonsContainer>
            <PrimaryButtonStyled type="button" onClick={handleContinue}>
              Continue
            </PrimaryButtonStyled>
            <CancelButtonStyled type="button" onClick={handleCancel}>
              Cancel
            </CancelButtonStyled>
          </ButtonsContainer>
        </>
        :
        <EmptyCartNote>
          Your cart is empty
        </EmptyCartNote>
      }
      
    </Container>
  );
};

export default Cart;
