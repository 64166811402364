import * as yup from 'yup';

export interface IOption {
  label: string;
  value: string;
}

const validationSchema = yup.object({
  notes: yup
    .string()
    .required('Please give some instructions')
});

export type SchemaType = yup.InferType<typeof validationSchema>;

export default validationSchema;