import React, { FC } from 'react';
import { getImagePath } from 'helpers';
import Props from '../../types';
import { ImageStyleName } from 'graphqlQueries';
import {
  Container,
  ContainerInner,
  Image,
  Content,
  ContentInner,
  Info,
  ContactTitle,
} from './styles';

export const ContactCardItem: FC<Props> = (props) => {
  const imagesField = JSON.parse(JSON.stringify(props.item.images || []));
  const ContactPhotoStyles = imagesField?.[0]?.styles;
  const contactPhotoUrl = imagesField?.[0]?.url;
  const recordImages = [getImagePath(ContactPhotoStyles, ImageStyleName.Original) || contactPhotoUrl];
  let thumbImage = '';
  if (recordImages?.[0]) {
    const parts = recordImages[0].split('/');
    const last = parts?.pop();
    const lastUrl = encodeURI(last).replace(/[!'()*]/g, function (c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
    thumbImage = `${parts.join('/')}/${lastUrl}`;
  }

  return (
    <Container
      href={props.item.url || ''}
      onClick={props.onClick}
      className={props.className}
      color={props.linkStyles?.color || ""}
      font={props.linkStyles?.font_family || ""}
      backgroundColor={props.linkStyles?.background_color}
      styles={props.styles}
      download
    >
      <ContainerInner
        styles={props.styles}
        backgroundColor={props.linkStyles?.background_color}
      >
        <Image
          styles={props.styles}
          style={{ backgroundImage: `url(${thumbImage || ""})` }}
        />
        <Content styles={props.styles}>
          <ContentInner styles={props.styles}>
            <Info>
              <ContactTitle>{props.item.name}</ContactTitle>
            </Info>
          </ContentInner>
        </Content>
      </ContainerInner>
    </Container>
  )
}