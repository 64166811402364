import styled from 'styled-components';
import { InputBase } from '../InputBase';
import { TemplatePreset } from 'graphqlQueries';
import { colord } from 'colord';

export const InputContainer = styled.div`
  margin-bottom: 16px; 
`;

interface InputProps {
  font?: string | null;
  textColor?: string | null;
  background?: string | null;
  styles?: TemplatePreset;
}


export const InputLabel = styled.label`
  display: block; 
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin-bottom: 4px;
  text-transform: capitalize;
`;

export const GroupLabel = styled.p`
  display: block; 
  text-align: center;
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 28px;
`;

export const GroupSubLabel = styled.p`
  display: block; 
  text-align: center;
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin-bottom: 28px;
`;



export const FormGroupDivider2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > ${InputContainer} {
    width: 46%;

    input {
      max-width: 100%;
    }
  }
`;
//border: 1px solid ${({ theme, textColor }) => Color(textColor || theme.colors.dlpDefaultTextColor).alpha(0.8)};
export const InputGroup = styled.div<InputProps>(({styles, theme, background, textColor, font}) => `

  width: 100%;
  min-height 48px;
  background: ${styles?.input?.backgroundColor || background || theme.colors.dlpFormInputBg};
  border-radius: ${styles?.input?.borderRadius || 0}px;
  box-sizing: border-box;
  padding: 7px 15px;
  border: ${styles?.input?.borderColor || textColor ? `1px solid ${styles?.input?.borderColor || textColor}` : 'none'};


  .react-tel-input {

    .form-control {
      width: auto;
      max-width: 300px;
    }

    text-align: initial;

    .flag {
      transform: scale(0.6);
      transform-origin: left;
      top: 0;

      .arrow {
        transform: scale(2);
        transform-origin: left;
        left: 56px;
      }
    }
    .flag-dropdown, .selected-flag {
      padding-left: 0;
      

      &,
      &:hover,
      &:focus,
      &.open {
        background: transparent !important;
        border: none !important;
      }
      
      .flag {
        margin-top: -7px;

        .arrow {
          border-top: 4px solid ${styles?.input?.color || textColor || theme.colors.dlpDefaultTextColor};
          &.up {
            border-bottom: 4px solid ${styles?.input?.color || textColor || theme.colors.dlpDefaultTextColor};
          }
        }
      }
    }

    .form-control {
      margin-left: 52px;
      max-width: calc(100% - 52px);
      box-sizing: border-box;
    }

    

    .country-list {
      left: -8px;
      background: ${styles?.input?.backgroundColor || background || theme.colors.dlpFormInputBg};
      text-align: initial;

      li {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &.highlight,
        &:hover,
        &:active {
          background-color: ${colord(
            styles?.input?.backgroundColor || background || theme.colors.dlpFormInputBg
          )
            .lighten(0.15)
            .toHex()};
        }

        .flag {
          margin-top: -2px;
        }

        .country-name {
          margin-left: 0;
          color: ${styles?.input?.color || textColor || theme.colors.dlpDefaultTextColor};
          opacity: 1;
        }

        .dial-code {
          color: ${styles?.input?.color || textColor || theme.colors.dlpDefaultTextColor};
          opacity: 0.7;
        }
      }
    }

    .country-name {
      margin-left: 10px;
    }
  }

  input,
  textarea,
  .react-tel-input input,
  .custom-select > div > div:first-of-type {
    &,
    &:active,
    &:focus {
      height: 20px;
      padding: 0;
      background: transparent !important;
      border: none !important;
      border-radius: ${styles?.input?.borderRadius || 0}px;
      font-size: ${theme.size.body1};
      font-weight: ${theme.fontWeight.regular};
      color: ${styles?.input?.color || textColor || theme.colors.dlpDefaultTextColor};
    }

    &::placeholder {
      opacity: 0.5;
    }

    &:-webkit-autofill, 
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px ${styles?.input?.backgroundColor || background || theme.colors.dlpFormInputBg} inset;
      transition: background-color 5000s ease-in-out 0s;
    }

    & > div:nth-of-type(2) {
      top: -10px;
      position: relative;
      & svg {
        fill: ${styles?.input?.color || textColor || theme.colors.dlpDefaultTextColor};
      }
    }
  }

  .custom-select [class*=-menu] {
    padding-left: 0;
    background: ${styles?.input?.backgroundColor || background || theme.colors.dlpFormInputBg};
    color: ${styles?.input?.color || textColor || theme.colors.dlpDefaultTextColor};
    top: -13px;
    border-radius: ${styles?.input?.borderRadius || 0}px;

    & > div:first-of-type {
      display: none;
    }

    & > div:nth-of-type(2) {
      background: ${styles?.input?.backgroundColor || background || theme.colors.dlpFormInputBg};
      border-radius: ${styles?.input?.borderRadius || 0}px;
      color: ${styles?.input?.color || textColor || theme.colors.dlpDefaultTextColor};

      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
    
  }

  .custom-select [class*=-menu] > div,
  .custom-select [class*=-menu] > div > div {
    background: ${styles?.input?.backgroundColor || background || theme.colors.dlpFormInputBg};
    color: ${styles?.input?.color || textColor || theme.colors.dlpDefaultTextColor};
    
  }
  .custom-select [class*=-menu] > div > div {
    &:hover,
    &:active {
      background-color: ${colord(
        styles?.input?.backgroundColor || background || theme.colors.dlpFormInputBg
      )
        .lighten(0.15)
        .toHex()};
    }
  }

  & ${InputLabel} {
    font-family: ${font || 'inherit'};
    color: ${styles?.input?.placeholderColor || textColor || theme.colors.dlpDefaultTextColor};
  }

  & ${InputBase} {
    font-family: ${font || 'inherit'};
    color: ${styles?.input?.color || textColor || theme.colors.dlpFormInputColor};
    -webkit-text-fill-color: ${styles?.input?.color || textColor || theme.colors.dlpFormInputColor} !important;
    transition: background-color 5000s ease-in-out 0s !important;

    &::placeholder {
      color: ${styles?.input?.color || textColor || theme.colors.dlpFormInputColor};
    }
  }
`);

