import styled from 'styled-components';

export const baseSelectStyles: any = {
  control: () => ({
    display: 'flex',
    backgroundColor: '#fff',
    borderRadius: '7px',
    'box-sizing': 'border-box',
    cursor: 'pointer'
    
  }),
  valueContainer: (providedStyles) => ({
    ...providedStyles,
    flexWrap: 'nowrap',
    padding: 0,
    cursor: 'pointer'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  dropdownIndicator: (providedStyles) => ({
    ...providedStyles,
    padding: '0 8px'
  }),
  singleValue: () => ({
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    overflow: 'hidden',
    'font-weight': '600',
    'font-size': '16px',
    'line-height': '27px',
  }),
  menu: (providedStyles) => ({
    ...providedStyles,
    zIndex: 100,
    padding: '0 0 0 15px',
    overflow: 'hidden',
    borderRadius: '7px',
    backgroundColor: '#fff',
    top: '-6px;'
  }),
  option: () => ({
    display: 'flex',
    padding: '7px 0 8px 10px',
    backgroundColor: '#fff',
    cursor: 'pointer',
    'font-weight': '600',
    'font-size': '16px',
    'line-height': '27px',
    
  }),
  placeholder: () => ({
    display: 'flex',
    'font-weight': '600',
    'font-size': '16px',
    'line-height': '27px',
  }),
  
};

export const Label = styled.label`
  display: block;
  margin-bottom: 12px;
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: #585858;
`;

export const DDArrow = styled.div`
  position: absolute;
  top: 12px;
  right: 20px;
  z-index: 1;

  svg {
    width: 27px;
    height: 27px;
    transform: rotate(180deg);
    
    path {
      color: ${({ theme }) => theme.colors.snippetCollapseArrowColor};
      color: ${({ theme }) => theme.colors.snippetCollapseArrowColor};
    }
  }
`;
