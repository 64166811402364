import styled from 'styled-components';

export const Container = styled.label`
  display: flex;
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  cursor: pointer;
  caret-color: transparent;
`;

export const CheckboxInput = styled.input`
  display: none;
`;
export const Text = styled.span`
  margin-left: 13px;
`;

export const Checkmark = styled.span`
  display: inline-block;
  width: 17px;
  height: 17px;
  
  box-sizing: border-box;
  border: solid 1px #979797;
  border-radius: 2px;
  
  ${CheckboxInput}:not(:checked) ~ & {
    
  }

  ${CheckboxInput}:checked ~ & {
    background-image: url('/assets/check-icon.png');
    background-position: center center;
    background-repeat: no-repeat;
  }
`;
