import window from 'global';
import React, { FC, useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
import { useParams } from 'react-router-dom';
import Swiper from 'components/Swiper';
import Delayed from 'components/Delayed';
import PrimaryButtonStyled from 'components/PrimaryButtonStyled';
import { Plane } from 'assets/svg';
import { getImagePath } from 'helpers';
import MetaData, {
  makeOfferPhotoThumbFromData,
  makeAvatarFromMeta,
  getFullName,
  makeAvatarImageOriginal,
  makeAvatarSubstituteLink,
} from 'components/MetaData/MetaData';
import GeneralLayout from 'layouts/GeneralLayout';
import { useRedirection } from 'utils/serverEffectActions';
import { useUsername } from 'context/UserNameContext';
import { getRoutes } from 'constants/routes';
import { sendProductViewedAnalytics, sendProductDownloadedAnalytics } from 'helpers/analytics/productsAndLinks';
import {
  Container,
  ImageContainer,
  DetailsContainer,
  ButtonsContainer,
  Title,
  Description,
  Format,
  LoaderContainer,
  ShareButtonContainer,
  ShareButton,
  CopyTooltip
} from './styles';
import { GeneralPageProps } from 'types';
import { Loader } from 'uikit';
import {
  useGetDigitalSaleDetailsQuery,
  useGetLinkPageMetadataQuery,
  ImageStyleName,
  SelectedImages,
  ImageStyle
} from 'graphqlQueries';

interface IParamTypes {
  id: string;
}

const DigitalProductPage: FC<GeneralPageProps> = () => {
  const { serverUserNameIsUsed, username } = useUsername();
  const { id } = useParams<IParamTypes>();
  const routes = getRoutes(serverUserNameIsUsed);
  const redirectTo = useRedirection();

  const [showCopyTooltip, setShowCopyTooltip] = useState<boolean>(false);
  const [galleryImages, setGalleryImages] = useState<Array<string>>([]);

  const handleCopy = () => {
    setShowCopyTooltip(true);
    setTimeout(() => setShowCopyTooltip(false), 1500);
    copy(window?.location?.href);
  };

  const {
    data: orderData,
  } = useGetDigitalSaleDetailsQuery({
    variables: {
      id,
    },
    onError: () => {
      redirectTo(routes.PAGE_404);
    },
  });

  const {
    data: metaData,
  } = useGetLinkPageMetadataQuery({
    fetchPolicy: 'cache-first',
    variables: {
      username,
    },
    onError: () => {
      redirectTo(routes.PAGE_404);
    },
  });

  const handleDownloadClick = () => {
    sendProductDownloadedAnalytics({
      orderId: orderData?.getDigitalSaleDetails?.orderId || '',
      meta: metaData?.getLinkedPage,
      orderData: orderData?.getDigitalSaleDetails
    })
  }

  

  useEffect(() => {
    if(!orderData) return;
    sendProductViewedAnalytics({
      orderId: orderData?.getDigitalSaleDetails?.orderId || '',
      orderShortUrlId: id as string || '',
      meta: metaData?.getLinkedPage,
    })

    const _imgs =
      (orderData?.getDigitalSaleDetails?.product?.images as SelectedImages[])?.map((item, index) => {
        return getImagePath(
          item.styles as ImageStyle[],
          ImageStyleName.Original
        );
      }) || [];
    setGalleryImages(_imgs);
    // eslint-disable-next-line
  }, [orderData])

  const helmetMeta = React.useMemo(() => {
    const meta = metaData?.getLinkedPage;
    const productTitle = orderData?.getDigitalSaleDetails?.product?.title;
    const fullName = getFullName(meta);
    const title = fullName
      ? `${fullName} | ${productTitle || 'Product'}`
      : `Product Page`;

    return {
      offerPhotoThumb: makeOfferPhotoThumbFromData(orderData?.getDigitalSaleDetails?.product?.images?.[0], ImageStyleName.Original),
      avatarImage: makeAvatarFromMeta(meta),
      avatarImageOriginal: makeAvatarImageOriginal(meta),
      avatarSubstituteLink: makeAvatarSubstituteLink(meta),
      title,
      metaTitle: title,
      metaDescription: 'Click to see details.',
    };
  }, [metaData, orderData]);

  return (
    <>
      <MetaData {...helmetMeta} />
      <GeneralLayout
        showLoader={!metaData}
        metaData={metaData?.getLinkedPage}
        outerContent={
          <ShareButtonContainer>
            <ShareButton
              backgroundColor={
                metaData?.getLinkedPage?.background?.background_color
              }
              onClick={handleCopy}
            >
              <Plane />
              {showCopyTooltip && <CopyTooltip>Copied!</CopyTooltip>}
            </ShareButton>
          </ShareButtonContainer>
        }
      >
        
        <Container>
          {!orderData ? (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          ) : (
            <>
              {!!galleryImages?.length && metaData?.getLinkedPage  && <ImageContainer>
                <Delayed>{/* we use it because swiper starts rendering when padding values are not yet received */}
                  <Swiper images={galleryImages || []} />
                </Delayed>
              </ImageContainer>}
            </>
          )}

          <DetailsContainer>
            <Title>{"'"}{orderData?.getDigitalSaleDetails?.product?.title}{"'"}</Title>
            <Description>
              File format:{" "}
              <Format>{orderData?.getDigitalSaleDetails?.product?.file_format || ''}</Format>
            </Description>
          </DetailsContainer>

          <ButtonsContainer>
            <PrimaryButtonStyled
              href={orderData?.getDigitalSaleDetails?.downloadUrl}
              download
              onClick={handleDownloadClick}
            >
              Download
            </PrimaryButtonStyled>
          </ButtonsContainer>
        </Container>
      </GeneralLayout>
    </>
  );
};

export default DigitalProductPage;
