import styled from 'styled-components';
import { Body1 } from 'uikit';
import { ChevronLeft } from 'assets/svg';
import { TemplatePreset } from 'graphqlQueries';
import { ReturnLinkMixin } from 'components/ReturnLink/styles';

interface Props {
  styles?: TemplatePreset;
}

interface LinkButtonProps {
  buttonColor?: string | null;
  buttonTextColor?: string | null;
  buttonCornerRadius?: number | null;
  buttonColorActive?: string | null;
  buttonTextColorAcrive?: string | null;
}

export const Container = styled.div<Props>(
  ({ styles, theme }) => `
  // ${console.log('----------------------------------------------', styles)}
  color: ${
    styles?.innerPagesContentContainer?.color ||
    styles?.container?.color ||
    theme.colors.dlpDefaultCardTextColor
  };
  font-family: ${styles?.container?.font || 'Raleway'}, "Raleway";
  max-width: 100%;

  * {
    font-family: inherit;
  }
`
);

export const UserText = styled.p`
  line-height: 16px;
  color: inherit;
  margin-bottom: 0;
  text-align: center;
  margin-top: 15px;
`;

export const OpenLink = styled.a<Props>(
  ({ styles, theme }) => `
  ${ReturnLinkMixin(theme, styles)};
  right: 12px;
  left: auto;

  svg {
    margin-left: 7px;
  }
`
);

export const GalleryContainer = styled.div`
  position: relative;
  max-width: 100%;
`;

export const BackButtonContainer = styled.a`
  position: absolute;
  display: block;
  top: 26px;
  left: 36px;
  z-index: 999;
  display: flex;
`;
export const BackButtonIcon = styled(ChevronLeft)`
  width: 11px;
  height: 13px;
`;

export const GalleryButton = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 33px;
  padding: 0;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  border: none;
  outline: none;

  &:focus {
    border: none !important;
    outline: none !important;
  }

  & path {
    stroke: ${({ theme }) => theme.colors.accentMainColor};
  }
`;

export const DetailsContainer = styled.div<Props>(
  ({ styles }) => `
  max-width: 353px;
  margin: 0 auto;
  position: relative;
  padding: 0;
  font-family: ${styles?.container?.font || 'Raleway'}, "Raleway";
  color: ${styles?.container?.color || 'inherit'};
`
);

export const LogoContainer = styled.div`
  position: absolute;
  box-sizing: border-box;
  top: -56px;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 112px;
  height: 112px;
  border: solid 4px ${({ theme }) => theme.colors.dlpOpportunityLogoBorderColor};
  border-radius: 8px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.dlpOpportunityLogoBgColor};
  box-shadow: 0px 2px 8px ${({ theme }) => theme.colors.dlpOpportunityLogoShadowColor};
`;

export const Logo = styled.img`
  max-width: 100%;
  height: auto;
`;

export const BusinessDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 53px;
  margin: 0 0 28px 139px;
  color: inherit;
`;

export const BusinessName = styled.p`
  font-size: 18px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 24px;
  max-height: 48px;
  overflow: hidden;
`;

export const ExpirationDate = styled(Body1)`
  color: inherit;
`;

export const OfferDetails = styled.div``;

export const OfferName = styled(Body1)`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 24px;
  margin: 0 0 21px 0;
  color: inherit;
`;

export const DescriptionContainer = styled.div`
  margin-bottom: 50px;
`;

export const Description = styled(Body1)`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 24px;
  margin: 0 0 21px 0;
  color: inherit;
`;

export const LinkButton = styled.a<LinkButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  text-align: center;
  font-size: ${({ theme }) => theme.size.subtitleLarge};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-decoration: none;
  background: ${({ theme, buttonColor }) => buttonColor || theme.colors.dlpDefaultButtonBgColor};
  border: none;
  box-shadow: 0px 1px 3px ${({ theme }) => theme.colors.dlpOpportunityButtonShadowColor};
  border-radius: ${({ theme, buttonCornerRadius }) => `${buttonCornerRadius || 0}px`};

  &,
  &:link,
  &:visited {
    color: ${({ theme, buttonTextColor }) =>
      buttonTextColor || theme.colors.dlpDefaultButtonTextColor};
  }

  &:active,
  &:hover {
    color: ${({ theme, buttonTextColor, buttonTextColorAcrive }) =>
      buttonTextColorAcrive || buttonTextColor || theme.colors.dlpDefaultButtonTextActiveColor};
    background: ${({ theme, buttonColorActive }) =>
      buttonColorActive || theme.colors.dlpDefaultButtonBgActiveColor};
    text-decoration: none;
  }
`;
