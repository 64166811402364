import React, { FC } from 'react';
import IconProps from './IconProps';

const Logo: FC<IconProps> = ({ className }) => (
  <svg className={className} width="49" height="36" viewBox="0 0 49 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M41.4815 18C41.4815 23.6002 36.9915 28.1401 31.453 28.1401H22.792C20.778 28.1401 19.1453 29.791 19.1453 31.8275C19.1453 33.8639 20.778 35.5148 22.792 35.5148H31.453C41.0196 35.5148 48.7749 27.6731 48.7749 18C48.7749 8.32684 41.0196 0.4852 31.453 0.4852L22.792 0.485199C20.778 0.485199 19.1453 2.13607 19.1453 4.17252C19.1453 6.20898 20.778 7.85985 22.792 7.85985H31.453C36.9915 7.85985 41.4815 12.3997 41.4815 18Z" fill="#00A3C6"/>
    <path d="M26.4387 22.7768V13.2233C26.4387 11.2133 24.806 9.58386 22.792 9.58386C20.778 9.58386 19.1453 11.2133 19.1453 13.2233V22.7768C19.1453 24.7868 20.778 26.4163 22.792 26.4163C24.806 26.4163 26.4387 24.7868 26.4387 22.7768Z" fill="#00A3C6"/>
    <path d="M0.85811 6.35347C-0.237369 5.37279 -0.291694 3.65349 0.739609 2.60309C1.77091 1.5527 3.45896 1.60803 4.42183 2.72378L15.0277 15.0138C15.5465 15.6149 15.5178 16.523 14.9622 17.0889C14.4066 17.6548 13.515 17.684 12.9248 17.1557L0.85811 6.35347Z" fill="#FA3475"/>
    <path d="M5.16041 28.8026C4.39165 29.4908 4.35353 30.6973 5.07725 31.4344C5.80097 32.1715 6.98556 32.1327 7.66125 31.3497L15.104 22.7251C15.468 22.3033 15.4479 21.6661 15.058 21.2689C14.6681 20.8718 14.0424 20.8513 13.6282 21.2221L5.16041 28.8026Z" fill="#FA3475"/>
  </svg>
);

export default Logo;
