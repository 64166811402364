import window from 'global';

type PageViewDataType = {
  pageViewId: string;
  isPageViewFromCookie: boolean;
};

export const setViewIdData = (): PageViewDataType => {
  // const linkPageViewIdSessionData = cookies.get(PAGE_VIEW_SESSION_COOKIE_NAME);

  console.log(
    'setViewIdData::window.__drum_pageViewId',
    window.__drum_pageViewId
  );
  return {
    pageViewId: window.__drum_pageViewId,
    isPageViewFromCookie: true,
  };
};

export default setViewIdData;
