import styled, { css } from 'styled-components';
import { TextareaBase, PrimaryButtonStyled } from 'uikit';

interface ContainerProps {
  font?: string | null;
}

interface DetailsContainerProps {
  font?: string | null;
  textColor?: string | null;
}

export const defaultTextMixin = css`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 24px;
`;

export const wideContainerMixin = css`
  width: 100%;
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
`;

export const innerContainerMixin = css`
  width: 100%;
  max-width: 360px;
  box-sizing: border-box;
`;


export const Container = styled.form<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: inherit;
  font-family: inherit;

  * {
    font-family: inherit;
  }
`;

export const ContainerInner = styled.div<ContainerProps>`
  ${innerContainerMixin}
`;

export const ImageContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const Preview = styled.img`
  width: 100%;
`;

export const PreviewButton = styled.div`
  position: absolute;
  margin: auto;
  top: 0;
  bottom:0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  padding-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.44);
`;

export const DetailsContainer = styled.div<DetailsContainerProps>`
  ${wideContainerMixin};
  font-family: ${({ font }) => font || 'inherit'};
  color: ${({ textColor }) => textColor || 'inherit'};
`;

export const Title = styled.p`
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 19px;
  margin-top: 32px;
`;

export const Description = styled.p`
  ${defaultTextMixin};
  margin-top: 20px;
`;

export const FormatContainer = styled.div`
  ${wideContainerMixin};
  ${defaultTextMixin};
  margin-top: 24px;
`;
export const Format = styled.span`
  text-transform: uppercase;
`;

export const PriceContainer = styled.div`
  ${wideContainerMixin};
  ${defaultTextMixin};
  margin-top: 24px;
  margin-bottom: 12px;
`;

export const MobileSpacer = styled.div`
  display: none;
  @media(max-width: 600px) {
    display: block;
  }
`;

export const PriceBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Price = styled.span`
  font-size: 20px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const PriceNotes = styled.p`
  margin-top: 8px;
  font-size: ${({ theme }) => theme.size.caption};
  
`;

export const OutOfStockContainer = styled.div`
  ${defaultTextMixin};
  color: ${({ theme }) => theme.colors.dlpPurchaseOutOfStockColor};
`;

export const FormContainer = styled.div`
  ${wideContainerMixin};
  margin-top: 20px;
`;

export const StyledTextareaBase = styled(TextareaBase)`
  border: none;
  resize: none;
  background: transparent;
  padding: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: auto;
  min-height: 96px;
  line-height: 24px; 

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ButtonsContainer = styled.div`
  ${innerContainerMixin};
  margin-top: 40px;
`;

export const SubmitButton = styled(PrimaryButtonStyled)`
  width: 100%;
  height: 56px;
  border-radius: 8px;
`;

