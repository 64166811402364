import { ImageStyleName, ImageStyle, SelectedImages } from 'graphqlQueries';

const S3_DOMAIN = process.env.REACT_APP_AWS_S3_DOMAIN;

const getImagePath = (
  styles?: ImageStyle[] | null,
  type: ImageStyleName = ImageStyleName.Square,
  noDomain = false
) => {
  if (!styles || !styles.length) return '';
  const companyLogoData = styles.find((imageInfo: ImageStyle) => {
    return imageInfo?.name === type;
  });

  return companyLogoData?.path
    ? !noDomain && !(companyLogoData?.path?.indexOf(String(S3_DOMAIN)) === 0)
      ? `${S3_DOMAIN}/${companyLogoData.path}`
      : `${companyLogoData.path}`
    : '';
};

export const getImagePathSimple = (url: string) => {
  if (!url) {
    return url;
  }
  if (url.indexOf(String(S3_DOMAIN)) === 0) {
    return url;
  }
  return `${S3_DOMAIN}/${url}`;
};

export const getImagesFromMediaObjects = (photos?: SelectedImages[] | null) => {
  if (!photos?.length) return [];
  return photos?.map((item: any) => {
    const _image = getImagePath(
      item?.styles as ImageStyle[],
      ImageStyleName.Original
    );

    return _image ? `${process.env.REACT_APP_DOMAIN_ASSET}/${_image}` : '';
  });
};

export default getImagePath;
