import styled from 'styled-components';
import { breakpoint } from 'helpers';
import { DrumLogo } from 'assets/svg';
import { Body2 } from 'uikit';
import { TemplatePreset } from 'graphqlQueries';

interface ContainerProps {
  backgroundColor?: string | null;
  textColor?: string | null;
  styles?: TemplatePreset;
}

export const Container = styled.div<ContainerProps>(({styles}) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  bottom: 0;

  padding-top: ${styles?.footer?.padding?.top || 60}px;
  padding-bottom: ${styles?.footer?.padding?.bottom || 40}px;
  padding-left: ${styles?.footer?.padding?.left || 0}px;
  padding-right: ${styles?.footer?.padding?.right || 0}px;

  text-align: center;
  color: ${({ textColor }) => textColor || 'inherit'};
  font-family: inherit;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  
  text-align: center;
  border-radius: 0 0 15px 15px;

  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  background-image: ${
    styles?.footer?.background?.image
      ? `url('${process.env.REACT_APP_AWS_S3_DOMAIN}${styles?.footer?.background?.image?.startsWith('/') ? '' : '/'}${styles?.footer?.background?.image}')`
      : 'none'
  };
  background-position: ${styles?.footer?.background?.offset?.desktop?.left || 0}px ${styles?.footer?.background?.offset?.desktop?.top || 0}px;
  background-size: ${styles?.footer?.background?.size?.desktop?.width ? `${styles?.footer?.background?.size?.desktop?.width}px` : '100%'} ${styles?.footer?.background?.size?.desktop?.height ? `${styles?.footer?.background?.size?.desktop?.height}px` : '100%'};
  background-repeat: no-repeat;

  @media (max-width: 400px) {
    & {
      width: 100%;
      position: relative;
      padding-bottom: ${styles?.footer?.padding?.top || 48}px;
    }
  }

  ${breakpoint('xs', 'md')`
    text-align: left;
    background-position: ${styles?.footer?.background?.offset?.mobile?.left || 0}px ${styles?.footer?.background?.offset?.mobile?.top || 0}px;
  background-size: ${styles?.footer?.background?.size?.mobile?.width ? `${styles?.footer?.background?.size?.mobile?.width}px` : '100%'} ${styles?.footer?.background?.size?.mobile?.height ? `${styles?.footer?.background?.size?.mobile?.height}px` : '100%'};
	`}
`);

export const ContainerInner = styled.div<ContainerProps>(({styles}) => `
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: ${styles?.footer?.elementsDirection === 'horizontal' ? "row-reverse" : "column"};
  justify-content: ${styles?.footer?.elementsDirection === 'horizontal' ? "space-between" : "flex-start"};
  align-items: center;
  padding-left: ${styles?.footer?.elementsDirection === 'horizontal' ? `${styles?.contentContainer?.padding?.left}px` : "0"};
  padding-right: ${styles?.footer?.elementsDirection === 'horizontal' ? `${styles?.contentContainer?.padding?.right}px` : "0"};

  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`);

export const CreateLink = styled.a<ContainerProps>(({styles}) => `
  display: block;
  text-align: center;
  color: ${styles?.footer?.linkButton?.color || "inherit"};
  font-family: ${styles?.footer?.linkButton?.font || "Raleway"}, "Raleway";
  font-size: ${styles?.footer?.linkButton?.fontSize ? `${styles?.footer?.linkButton?.fontSize}px` : "inherit"};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  background-color: ${styles?.footer?.linkButton?.backgroundColor || "transparent"};
  line-height: 16px;
  margin-bottom: ${styles?.footer?.elementsDirection === 'horizontal' ? "0" : "13px"};
  padding: 7px 25px;
  border-radius: 7px;

  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: ${styles?.footer?.linkButton?.color || "inherit"};
    text-decoration: underline;
  }

  @media (max-width: 600px) {
    margin-bottom: 15px;
  }
`);

export const Info = styled.div`
  flex-grow: 0;
`;

export const ImageOuter = styled.div<ContainerProps>(({styles}) => `
  margin-bottom: 6px;
  display: flex;
  //margin-bottom: ${styles?.footer?.elementsDirection === 'horizontal' ? "0" : "30px"};
  margin-bottom: 0;

  @media (max-width: 600px) {
    margin-bottom: 15px;
  }
`);

export const ImageContainer = styled.div<ContainerProps>(({styles}) => `
  margin: auto;
  padding: 7px 12px;
  background-color: ${styles?.footer?.logoButton?.backgroundColor || "transparent"};
  border-radius: 7px;
  ${breakpoint('xs', 'md')`
    background: none;
    margin: 0 auto;
	`}
`);

export const Image = styled(DrumLogo)<ContainerProps>(({styles}) => `
  width: ${styles?.footer?.logoButton?.width || 58}px;
  height: ${styles?.footer?.logoButton?.height || 13}px;
  color: ${styles?.footer?.logoButton?.color || "inherit"};
  
  fill: ${styles?.footer?.logoButton?.color || "inherit"};

  path {
    fill: ${styles?.footer?.logoButton?.color || "inherit"};
  }

  ${breakpoint('xs', 'md')`
    margin-bottom: 0px;
	`}
`);

export const BottomText = styled(Body2)`
  line-height: 16px;
`;


