import { ICartItem } from "./types";

export const getMoneyFormat = (value: number | string) => {
  return (Number(value) || 0).toFixed(2);
};

export const getTotalSum = (items: ICartItem[] = [], useMoneyFormat = true) => {
  
  const result = items.reduce((res, curr) => {
    return res + (curr.config?.price || curr.product.price || 0) * (curr.config?.quantity || 1);
  }, 0);
  return useMoneyFormat ? getMoneyFormat(result) : result;
};

export const getTotalItems = (items: ICartItem[] = []) => {
  return items.reduce((res, curr) => {
    return res + (Number(curr?.config?.quantity) || 1);
  }, 0);
};
export const getShippingSum = (items: ICartItem[] = [], useMoneyFormat = true) => {
  // return items.reduce((res, curr) => {
  //   return res + (curr.shippingFee || 0);
  // }, 0);
  const result = 0;
  return useMoneyFormat ? getMoneyFormat(result) : result;
};

export const getTaxes = (items: ICartItem[] = [], useMoneyFormat = true) => {
  const result = 0;
  return useMoneyFormat ? getMoneyFormat(result) : result;
};

export const getTotalWithTaxes = (items: ICartItem[] = [], useMoneyFormat = true) => {
  const result = Number(getTotalSum(items, false)) + Number(getTaxes(items, false));
  return useMoneyFormat ? getMoneyFormat(result) : result;
};

