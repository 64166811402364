import styled from 'styled-components';

export const Container = styled.div`
`;

export const GalleryContainer = styled.div`
  position: relative;
`;

export const ShareButtonContainer = styled.div`
  position: absolute;
  top: 26px;
  right: 36px;
  z-index: 999;
  display: flex;
`;

export const GalleryButton = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 33px;
  padding: 0;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  border: none;
  outline: none;

  &:focus {
    border: none !important;
    outline: none !important;
  }

  & path {
    stroke: ${({ theme }) => theme.colors.accentMainColor};
  }
`;

export const CopyTooltip = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -30px;
  padding: 4px 7px;
  border-radius: 3px;
  background: ${({ theme }) => theme.colors.dlpOfferTooltipBg};
  color: ${({ theme }) => theme.colors.dlpOfferTooltipTextColor};
`;

export const SwiperContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  max-height: 500px;

  @media (max-width: 600px) {
    & {
      max-height: 350px;
    }
  }
`;

export const Slide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 397px !important;
  background-color: ${({ theme }) => theme.colors.dlpDefaultCardBgColor};
  padding: 0 !important;
  @media (max-width: 600px) {
    & {
      height: 265px !important;
    }
  }
`;

export const OfferImage = styled.img`
  //width: 100%;
  height: 100%;
  //object-fit: cover;
  z-index: 1;
`;
