import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.dlpDefaultCardTextColor};

  * {
    font-family: inherit;
  }

  & > * {
    width: 100%;
  }
`;
