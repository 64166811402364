import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const Total = styled.span`
  font-size: 22px;
`;

export const TotalShipping = styled.p`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 24px;
  margin-top: 24px;
`;

export const ShippingFee = styled.span`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 24px;
`;

export const TotalNotes = styled.div`
  width: 100%;
  font-size: ${({ theme }) => theme.size.caption};
  
`;

export const TaxesNote = styled.p`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 24px;
  margin-top: 20px;
`;
