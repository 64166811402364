import React, { FC } from 'react';
import IconProps from './IconProps';


const SuccessLarge: FC<IconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="92"
    height="92"
    fill="none"
    viewBox="0 0 92 92"
  >
    <path
      fill="#00CE7C"
      fillRule="evenodd"
      d="M42.304 55.782L27 40.476l8.301-8.302 7.003 7.003L58.7 22.782 67 31.084 42.304 55.782z"
      clipRule="evenodd"
    ></path>
    <mask
      id="mask0"
      width="92"
      height="92"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M0 0h91.78v91.781H0V0z"
        clipRule="evenodd"
      ></path>
    </mask>
    <g mask="url(#mask0)">
      <path
        fill="#999"
        fillRule="evenodd"
        d="M90.355 40.279c-.6.06-1.038.595-.978 1.196a44.55 44.55 0 01.22 4.416c0 24.1-19.606 43.706-43.706 43.706-24.099 0-43.706-19.607-43.706-43.706 0-24.1 19.607-43.706 43.706-43.706 7.064 0 14.079 1.723 20.289 4.985a1.093 1.093 0 001.015-1.935C60.674 1.81 53.306 0 45.891 0 33.634 0 22.11 4.774 13.441 13.44 4.775 22.11 0 33.633 0 45.891c0 12.257 4.774 23.782 13.442 32.45 8.667 8.667 20.192 13.44 32.45 13.44 12.256 0 23.78-4.773 32.449-13.44 8.667-8.668 13.44-20.193 13.44-32.45 0-1.546-.077-3.106-.23-4.635a1.092 1.092 0 00-1.196-.977z"
        clipRule="evenodd"
      ></path>
    </g>
    <path
      fill="#999"
      fillRule="evenodd"
      d="M88.378 35.602c.068.281.134.563.196.847a1.091 1.091 0 102.133-.47 48.41 48.41 0 00-.205-.89 1.093 1.093 0 00-2.124.513zM79.034 14.151a1.093 1.093 0 00-1.578 1.512 43.993 43.993 0 019.718 15.845 1.093 1.093 0 102.063-.72 46.211 46.211 0 00-10.203-16.637z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M68.513 31.44c0-.29-.115-.567-.32-.772l-8.338-8.338a1.092 1.092 0 00-1.545 0L42.617 38.023l-6.26-6.26a1.095 1.095 0 00-1.544 0l-8.339 8.338a1.09 1.09 0 000 1.545l7.074 7.074h-3.452c-.466 0-.88.294-1.033.733l-4.097 11.803a1.094 1.094 0 001.032 1.45H59.8a1.09 1.09 0 001.032-1.45l-4.096-11.803a1.093 1.093 0 00-1.032-.733h-4.016l16.505-16.507c.206-.205.32-.482.32-.772zM49.502 50.904h5.423l3.339 9.62h-30.73l3.338-9.62h4.86l6.113 6.113a1.082 1.082 0 00.772.32 1.085 1.085 0 00.772-.32l6.113-6.113zM42.617 54.7L28.792 40.873l6.793-6.792 6.26 6.26a1.092 1.092 0 001.544 0l15.693-15.693 6.794 6.793-23.259 23.258z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default SuccessLarge;
