import React from 'react';
import IconProps from './IconProps';

const Checkmark: React.FC<IconProps> = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.00617 15.9902L21.7207 3.27573L19.0718 0.626873L9.00619 10.6925L2.64885 4.33516L-4.71546e-07 6.98401L6.35734 13.3413L6.35732 13.3414L9.00617 15.9902Z"
        fill="#00A161"
      />
    </svg>
  );
};

export default Checkmark;
