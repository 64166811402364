import styled from 'styled-components';
import convertHexToRGBA from 'utils/convertHexToRGBA';
import { TemplatePreset } from 'graphqlQueries';

interface StyleProps {
  styles: TemplatePreset;
}

export const Container = styled.div<StyleProps>(
({ styles: { avatar, headerContainer }, theme }) => `
  display: flex;
  box-sizing: border-box;
  width: calc(100% - ${headerContainer?.margin?.left || 0}px - ${headerContainer?.margin?.right || 0}px);
  max-width: ${avatar?.width && headerContainer?.maxContentWidth ? `${headerContainer?.maxContentWidth}px` : "100%"};
  flex-direction: column;
  align-items: center;
  font-family: inherit;
  border-radius: ${headerContainer?.borderRadius || 0}px;

  position: relative;
  margin-top: ${headerContainer?.top || 0}px;
  background-color: ${headerContainer?.backgroundColor || theme.colors.dlpAvatarContainerBg};

  padding-top: ${headerContainer?.padding?.top || 0}px;
  padding-bottom: ${headerContainer?.padding?.bottom || 0}px;

  margin-left: ${headerContainer?.margin?.left || 0}px;
  margin-right: ${headerContainer?.margin?.right || 0}px;
  margin-bottom: ${headerContainer?.margin?.bottom || 0}px;

  @media(max-width: 600px) {
    width: ${avatar?.width ? `calc(100% - ${headerContainer?.mobileMargin?.left || 0}px - ${headerContainer?.mobileMargin?.right || 0}px)` : '100%' };
    //max-width: ${avatar?.width && headerContainer?.mobileMaxContentWidth ? `${headerContainer?.mobileMaxContentWidth}px` : "100%"};
    padding-top: ${headerContainer?.mobilePadding?.top || 0}px;
    padding-bottom: ${headerContainer?.mobilePadding?.bottom || 0}px;

    margin-top: ${headerContainer?.mobileTop || 0}px;
    margin-left: ${headerContainer?.mobileMargin?.left || 0}px;
    margin-right: ${headerContainer?.mobileMargin?.right || 0}px;
  } 
`);

export const UserAvatarOuter = styled.div<StyleProps>(
  ({ styles: { avatar } }) => `
  display: flex;
  flex-directon: column;
  justify-content: center;
  align-items: center;
  width: ${avatar?.width? `${avatar?.width}px` : "100%"};
  height: ${avatar?.height? `${avatar?.height}px` : "100%"};
  border-radius: ${avatar?.type === 'round' ? "50%" : "0"};
  background-image: ${avatar?.backgroundImage? `url('${process.env.REACT_APP_AWS_S3_DOMAIN}${avatar?.backgroundImage}')` : "none"};
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
  padding-top: ${avatar?.padding?.top || 0}px;
  padding-bottom: ${avatar?.padding?.bottom || 0}px;
  padding-left: ${avatar?.padding?.left || 0}px;
  padding-right: ${avatar?.padding?.right || 0}px;
  margin-top: ${avatar?.top || 0}px;
  position: relative;
  border: ${avatar?.border?.size || 0}px solid ${avatar?.border?.color || '#ffffff00'};

  @media(max-width: 600px) {
    width: ${avatar?.mobileWidth? `${avatar?.mobileWidth}px` : "100%"};
    height: ${avatar?.mobileHeight? `${avatar?.mobileHeight}px` : "100%"};
    padding-top: ${avatar?.mobilePadding?.top || 0}px;
    padding-bottom: ${avatar?.mobilePadding?.bottom || 0}px;
    padding-left: ${avatar?.mobilePadding?.left || 0}px;
    padding-right: ${avatar?.mobilePadding?.right || 0}px;
    margin-left: auto;
    margin-right: auto;
  }
`);

export const UserAvatarContainer = styled.div<StyleProps>(
  ({ styles: { avatar } }) => `
  width: 100%;
  height: 100%;
  border-radius: ${avatar?.type === 'round' ? "50%" : "0"};
  box-sizing: border-box;
  background-size: cover;
  background-position: center;

`);

export const UserTextContainer = styled.div<StyleProps>(
  ({ styles: { headerContainer } }) => `
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  font-family: inherit;
  z-index: 3;
  box-sizing: border-box;
  position: relative;

  padding-left: ${headerContainer?.padding?.left || 0}px;
  padding-right: ${headerContainer?.padding?.right || 0}px;

  @media(max-width: 600px) {
    padding-left: ${headerContainer?.mobilePadding?.left || 0}px;
    padding-right: ${headerContainer?.mobilePadding?.right || 0}px;
  }
  
`);

export const UserAvatarShadow = styled.div<StyleProps>(
  ({ styles: { avatar } }) => `
  position: absolute;
  width: 100%;
  height: 100%;
  //top: 0;
  bottom: -1px;
  height: 200px;
  left: 0;
  z-index: 2;
  display: ${avatar?.useInnerShadow ? "block" : "none"};
  // background: linear-gradient(0deg, ${avatar?.innerShadowColor || "#fff"} 9.57%, rgba(0, 0, 0, 0) 95.79%);
  ${avatar?.innerShadowColor &&
    `background: linear-gradient(
      0deg, ${convertHexToRGBA(avatar?.innerShadowColor, 100)} 9.57%, rgba(196, 196, 196, 0) 95%);`
  }

  
  
`);

export const UserAvatarShapeContainer = styled.div<StyleProps>(
  ({ styles: { avatar } }) => `
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: ${avatar?.bottomShape?.desktop?.height || 0}px; 

  @media(max-width: 600px) {
    height: ${avatar?.bottomShape?.mobile?.height || 0}px;
  }
`);

export const UserAvatarShapeTriangleContainer = styled.div<StyleProps>(
  ({styles: { avatar }}) => `
  display: flex;
  flex-direction: row;
  box-sizing: border-box;

  // height: 0px;
  // width: 100%;
  // border-style: solid;

  // border-width: ${avatar?.bottomShape?.desktop?.height || 0}px ${avatar?.bottomShape?.desktop?.height || 0}px 0 ${avatar?.bottomShape?.desktop?.height || 0}px;
  // border-color: transparent ${avatar?.bottomShape?.desktop?.color || 'transparent'} transparent ${avatar?.bottomShape?.desktop?.color || 'transparent'};
  // @media(max-width: 600px) {
  //   border-width: ${avatar?.bottomShape?.mobile?.height || 0}px 0 0 ${avatar?.bottomShape?.mobile?.height || 0}px;
  //   border-color: transparent transparent transparent ${avatar?.bottomShape?.mobile?.color || 'transparent'};
  }
`);

export const UserAvatarShapeTriangle = styled.div<StyleProps>(
  ({ styles: { avatar, container } }) => `
  width: 50%;
  border-style: solid;
  height: ${avatar?.bottomShape?.desktop?.height || 0}px; 
  box-sizing: border-box;

  @media(max-width: 600px) {
    height: ${avatar?.bottomShape?.mobile?.height || 0}px;
  }

  &:nth-of-type(1) {
    // border-width: ${avatar?.bottomShape?.desktop?.height || 0}px 0 0 ${avatar?.bottomShape?.desktop?.height || 0}px;
    // border-color: transparent transparent transparent ${avatar?.bottomShape?.desktop?.color || 'transparent'};
    // @media(max-width: 600px) {
    //   border-width: ${avatar?.bottomShape?.mobile?.height || 0}px 0 0 ${avatar?.bottomShape?.mobile?.height || 0}px;
    //   border-color: transparent transparent transparent ${avatar?.bottomShape?.mobile?.color || 'transparent'};
    // }

    background: linear-gradient(to right top, ${avatar?.bottomShape?.desktop?.color || 'transparent'} 49%, transparent 50%);
  }
  &:nth-of-type(2) {
    // border-width: 0 0 ${avatar?.bottomShape?.desktop?.height || 0}px ${avatar?.bottomShape?.desktop?.height || 0}px;
    // border-color: transparent transparent ${avatar?.bottomShape?.desktop?.color || 'transparent'} transparent;
    // @media(max-width: 600px) {
    //   border-width: 0 0 ${avatar?.bottomShape?.mobile?.height || 0}px ${avatar?.bottomShape?.mobile?.height || 0}px;
    //   border-color: transparent transparent ${avatar?.bottomShape?.mobile?.color || 'transparent'} transparent;
    // }
    background: linear-gradient(to left top, ${avatar?.bottomShape?.desktop?.color || 'transparent'} 49%, transparent 50%);
  }
`);

export const UserAvatar = styled.img`
  max-width: 100%;
  height: auto;
`;

export const UserAvatarSubstitute = styled.div<StyleProps>(
  ({ styles: { avatar }, theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-size: auto ${avatar?.type === 'round'? "75%" : "100%"};
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: ${avatar?.type === 'round'? "50%" : 0};
  overflow: hidden;
  font-size: 42px;
  font-weight: ${theme.fontWeight.semiBold};
  color: ${theme.colors.dlpAvatarSubstituteTextColor};
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  background-color: ${avatar?.emptyState?.backgroundColor || theme.colors.dlpAvatarSubstituteBg};
  box-sizing: border-box;

  @media(max-width: 600px) {
    background-size: auto ${avatar?.type === 'round'? "75%" : "120%"};
  }
`);

export const UserNameContainer = styled.div<StyleProps>(
  ({ styles: { title }, theme }) => `
  position: relative;
  box-sizing: border-box;
  margin-top: ${title?.top || title?.margin?.top || 0}px;
  margin-bottom: ${title?.margin?.bottom || 0}px;
  margin-left: ${title?.margin?.left || 0}px;
  margin-right: ${title?.margin?.right || 0}px;
  padding-top: ${title?.padding?.top || 0}px;
  padding-bottom: ${title?.padding?.bottom || 0}px;
  padding-left: ${title?.padding?.left || 0}px;
  padding-right: ${title?.padding?.right || 0}px;
  width: ${title?.maxWidth ? `${title?.maxWidth}px` : '100%'};
  height: ${title?.height ? `${title?.height}px` : 'auto'};
  font-family: ${title?.font || "Raleway"}, "Raleway";
  font-size:  ${title?.fontSize || 18}px;
  font-weight: ${title?.fontWeight || theme.fontWeight.bold};
  color: ${title?.color || "inherit"};
  text-align: center;
  word-break: break-word;
  z-index: 3;
  ${title?.textBorder ? `text-shadow: -${title?.textBorder?.size || 0}px 0 ${title?.textBorder?.color || '#fff'}, 0 ${title?.textBorder?.size || 0}px ${title?.textBorder?.color || '#fff'}, ${title?.textBorder?.size || 0}px 0 ${title?.textBorder?.color || '#fff'}, 0 -${title?.textBorder?.size || 0}px ${title?.textBorder?.color || '#fff'};`: ''}
  background-image: ${
    title?.backgroundImage
      ? `url('${process.env.REACT_APP_AWS_S3_DOMAIN}${title?.backgroundImage?.startsWith('/') ? '' : '/'}${title?.backgroundImage}')`
      : 'none'
  };
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  ${title?.maxLines ? `
    -webkit-line-clamp: title?.maxLines;
    -webkit-box-orient: vertical;
    overflow: hidden;
  ` : ''}

  @media(max-width: 600px) {
    width: ${title?.mobileMaxWidth ? `${title?.mobileMaxWidth}px` : '100%'};
    margin-top: ${title?.mobileTop || title?.mobileMargin?.top || 0}px;
    margin-bottom: ${title?.mobileMargin?.bottom || 0}px;
    margin-left: ${title?.mobileMargin?.left || 0}px;
    margin-right: ${title?.mobileMargin?.right || 0}px;
    padding-top: ${title?.mobilePadding?.top || 0}px;
    padding-bottom: ${title?.mobilePadding?.bottom || 0}px;
    padding-left: ${title?.mobilePadding?.left || 0}px;
    padding-right: ${title?.mobilePadding?.right || 0}px;
    height: ${title?.mobileHeight ? `${title?.mobileHeight}px` : 'auto'};
  } 
`);

export const UserNameContainerInner = styled.div<StyleProps>(
  ({ styles: { title }, theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(${title?.maxLines} * ${title?.fontSize}px);
`);

export const UserName = styled.p<StyleProps>(
  ({ styles: { title }, theme }) => `
  text-align: center;
  ${title?.maxLines ? `
    display: -webkit-inline-box;;
    word-break: break-word;
    -webkit-line-clamp: ${title?.maxLines};
    -webkit-box-orient: vertical;
    overflow: hidden;
  ` : ''}
`);

export const UserDescriptionContainer = styled.div<StyleProps>(
  ({ styles: { headerContainer } }) =>`
  max-width: ${headerContainer?.maxContentWidth ? `${headerContainer?.maxContentWidth}px` : '100%'};
  text-align: center;
`);

export const UserDescription = styled.pre<StyleProps>(
  ({ styles: { description }, theme }) =>`
  font-family: ${description?.font || "Raleway"}, "Raleway";
  font-size:  ${description?.fontSize || 14}px;
  font-weight: ${description?.fontWeight || theme.fontWeight.medium};
  color: ${description?.color || "inherit"};
  margin-top: ${description?.margin?.top || 0}px;
  margin-bottom: ${description?.margin?.bottom || 0}px;
  height: auto;
  white-space: pre-line;
  background: transparent;
  border: none;
  line-height: 24px;
`);