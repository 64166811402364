import React, { FC, useEffect } from 'react';
import querystring from 'query-string';
import { useLocation, generatePath } from 'react-router-dom';
import { getRoutes } from 'constants/routes';
import { useRedirection } from 'utils/serverEffectActions';
import { FullScreenLoader } from 'uikit';
import { useGetInvitePageInfoQuery } from 'graphqlQueries';

const InviteDrummerRedirectionPage: FC = () => {
  const location = useLocation();
  const queryParams = querystring.parse(location?.search);
  const redirectTo = useRedirection();
  const routes = getRoutes(false);

  const { data: invitationData } = useGetInvitePageInfoQuery({
    variables: {
      input: {
        code: (queryParams.code as string) || '',
      },
    },
    onError: () => {
      redirectTo(routes.PAGE_404);
    },
  });

  const username = invitationData?.dlpInvitePageInfo?.drummer?.username;
  if (username) {
    redirectTo(generatePath(routes.REFERRAL_PAGE, { name: username }));
  }

  return <FullScreenLoader />;
};

export default InviteDrummerRedirectionPage;
