import React, { FC, useState, useEffect } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import actions from 'redux/actions';
import { useRedirection } from 'utils/serverEffectActions';
import { useUsername } from 'context/UserNameContext';
import { getRoutes } from 'constants/routes';
import ReturnLink from 'components/ReturnLink';
import GeneralLayout from 'layouts/GeneralLayout';
import MetaData, {
  makeOfferPhotoThumbFromData,
  makeAvatarFromMeta,
  getFullName,
  makeAvatarImageOriginal,
  makeAvatarSubstituteLink,
} from 'components/MetaData/MetaData';
import VideoPurchaseConfirmation from './components/VideoPurchaseConfirmation';
import DigitalPurchaseConfirmation from './components/DigitalPurchaseConfirmation';
import PhysicalPurchaseConfirmation from './components/PhysicalPurchaseConfirmation';
import CancelButtonStyled from 'components/CancelButtonStyled';
import { IPageParams } from './types';
import { Loader } from 'uikit';
import { sendProductDownloadedAnalytics } from 'helpers/analytics/productsAndLinks';
import {
  useGetLinkPageMetadataQuery,
  useGetProductOrderDetailsQuery,
  ProductTypeEnum,
  ProductOrder,
  ImageStyleName,
} from 'graphqlQueries';
import { Container } from './styles';

interface IParamTypes {
  id: string;
}

const ProductConfirmation: FC<IPageParams> = ({ type, templateStyles }) => {
  const { usernameForRoutes, serverUserNameIsUsed, username } = useUsername();
  const redirectTo = useRedirection();
  const routes = getRoutes(serverUserNameIsUsed);
  const dispatch = useDispatch();
  const { id } = useParams<IParamTypes>();
  const [returnLink] = useState<string>(
    generatePath(routes.LINK_PAGE, { username: usernameForRoutes })
  );

  const { data: metaData } = useGetLinkPageMetadataQuery({
    fetchPolicy: 'cache-first',
    variables: {
      username,
    },
    onError: () => {
      redirectTo(routes.PAGE_404);
    },
  });

  const { data: orderData } = useGetProductOrderDetailsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      id,
    },
    onError: () => {
      redirectTo(routes.PAGE_404);
    },
  });

  const handleDigitalDownload = () => {
    sendProductDownloadedAnalytics({
      orderId: orderData?.getProductOrderDetails?.id || '',
      meta: metaData?.getLinkedPage,
      orderData: orderData?.getProductOrderDetails
    });
  };

  const handleBackButtonClick = () => {
    redirectTo(returnLink);
  };

  useEffect(() => {
    return () => {
      dispatch(actions.checkout.switchCheckoutTab(0));
    };
    // eslint-disable-next-line
  }, []);

  const helmetMeta = React.useMemo(() => {
    const meta = metaData?.getLinkedPage;
    const productTitle = orderData?.getProductOrderDetails?.title;
    const fullName = getFullName(meta);
    const title = fullName
      ? `${fullName} | ${productTitle || 'Product'}`
      : `Product Page`;

    return {
      offerPhotoThumb: makeOfferPhotoThumbFromData(
        orderData?.getProductOrderDetails?.product?.images?.[0],
        ImageStyleName.Original
      ),
      avatarImage: makeAvatarFromMeta(meta),
      avatarImageOriginal: makeAvatarImageOriginal(meta),
      avatarSubstituteLink: makeAvatarSubstituteLink(meta),
      title,
      metaTitle: title,
      metaDescription: 'Purchase Confirmation',
    };
  }, [metaData, orderData]);

  return (
    <>
      <MetaData {...helmetMeta} />
      <GeneralLayout
        showLoader={!metaData}
        metaData={metaData?.getLinkedPage}
        outerContent={<>{returnLink && <ReturnLink to={returnLink} />}</>}
      >
        <Container>
          {!orderData?.getProductOrderDetails ? (
            <Loader />
          ) : (
            <>
              {type === ProductTypeEnum.VideoProduct && (
                <VideoPurchaseConfirmation
                  orderData={orderData.getProductOrderDetails as ProductOrder}
                />
              )}
              {type === ProductTypeEnum.DigitalProduct && (
                <DigitalPurchaseConfirmation
                  orderData={orderData.getProductOrderDetails as ProductOrder}
                  downloadHref={
                    orderData?.getProductOrderDetails?.productDownloadLink || ''
                  }
                  onDownload={handleDigitalDownload}
                />
              )}
              {type === ProductTypeEnum.PhysicalProduct && (
                <PhysicalPurchaseConfirmation
                  orderData={orderData.getProductOrderDetails as ProductOrder}
                />
              )}
              {type === ProductTypeEnum.DigitalProduct ? (
                <CancelButtonStyled onClick={handleBackButtonClick}>
                  Return to links page
                </CancelButtonStyled>
              ) : (
                <CancelButtonStyled onClick={handleBackButtonClick}>
                  Return to links page
                </CancelButtonStyled>
              )}
            </>
          )}
        </Container>
      </GeneralLayout>
    </>
  );
};

export default ProductConfirmation;
