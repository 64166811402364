import React, { FC, ReactNode } from 'react';
import { getImagePath } from 'helpers';
import makeFullNameAndUserAvatarSubstitute from 'utils/makeFullNameAndUserAvatarSubstitute';
import SocialMediaLinks from 'components/SocialMediaLinks';
import {
  Container,
  UserAvatarOuter,
  UserAvatarContainer,
  UserTextContainer,
  UserAvatarShadow,
  UserAvatarShapeContainer,
  UserAvatarShapeTriangleContainer,
  UserAvatarShapeTriangle,
  UserAvatarSubstitute,
  UserNameContainer,
  UserNameContainerInner,
  UserName,
  UserDescriptionContainer,
  UserDescription,
} from './styles';
import {
  ImageStyleName,
  SocialAccount,
  Drummer,
  LinkTreeUser,
  ImageStyle,
  TemplatePreset,
  BottomShapeTypeEnum,
} from 'graphqlQueries';

interface Props {
  user?: Partial<Drummer | LinkTreeUser> | null;
  title?: string;
  description?: string;
  socials?: Partial<SocialAccount>[];
  onSocialClick?: (item: SocialAccount) => void;
  children?: ReactNode;
  styles: TemplatePreset;
}

const UserInfo: FC<Props> = ({
  user,
  title = '',
  description = '',
  socials,
  onSocialClick,
  children,
  styles,
}) => {
  const { fullNameFormatted } = makeFullNameAndUserAvatarSubstitute(user, '');

  const avatarSrc = user?.avatarImage?.styles
    ? getImagePath(
      user?.avatarImage?.styles as ImageStyle[],
      ImageStyleName.Original
    )
    : user?.avatarImage?.url || null;

  return (
    <Container styles={styles}>
      {styles?.title?.position === 'aboveAvatar' && (
        <UserTextContainer styles={styles}>
          <UserNameContainer styles={styles}>
            <UserNameContainerInner styles={styles}>
              <UserName styles={styles}>
                {title ||
                  fullNameFormatted ||
                  (!!user?.username && `@${user?.username}`)}
              </UserName>
            </UserNameContainerInner>
          </UserNameContainer>
        </UserTextContainer>
      )}
      <UserAvatarOuter styles={styles}>
        <UserAvatarContainer
          styles={styles}
          style={{
            backgroundImage: avatarSrc ? `url('${avatarSrc}')` : 'none',
          }}
        >
          {!avatarSrc && (
            <UserAvatarSubstitute
              styles={styles}
              style={{
                backgroundImage: `url('/assets/avatar-placeholder.svg')`,
              }}
            ></UserAvatarSubstitute>
          )}
        </UserAvatarContainer>
        <UserAvatarShadow styles={styles} />
        <UserAvatarShapeContainer
          styles={styles}
          className={styles?.avatar?.bottomShape?.desktop?.type || ''}
        >
          {styles?.avatar?.bottomShape?.desktop?.type ===
            BottomShapeTypeEnum.Triangle && (
              <UserAvatarShapeTriangleContainer styles={styles}>
                <UserAvatarShapeTriangle styles={styles} />
                <UserAvatarShapeTriangle styles={styles} />
              </UserAvatarShapeTriangleContainer>
            )}
        </UserAvatarShapeContainer>
      </UserAvatarOuter>
      <UserTextContainer styles={styles}>
        {styles?.title?.position === 'belowAvatar' && (
          <UserNameContainer styles={styles}>
            <UserNameContainerInner styles={styles}>
              <UserName styles={styles}>
                {title ||
                  fullNameFormatted ||
                  (!!user?.username && `@${user?.username}`)}
              </UserName>
            </UserNameContainerInner>
          </UserNameContainer>
        )}
        {styles?.socialsContainer?.position === 'underTitle' &&
          !!socials?.length && (
            <SocialMediaLinks items={socials} onItemClick={onSocialClick} />
          )}
        <UserDescriptionContainer styles={styles}>
          <UserDescription styles={styles}>{description}</UserDescription>
        </UserDescriptionContainer>
        {styles?.socialsContainer?.position === 'underDescription' &&
          !!socials?.length && (
            <SocialMediaLinks items={socials} onItemClick={onSocialClick} />
          )}
      </UserTextContainer>
      {children}
    </Container>
  );
};

export default UserInfo;
