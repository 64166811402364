import styled from 'styled-components';
import { ITheme } from '../../types';
import { breakpoint } from '../../helpers';

export const Container = styled.div`
  position: relative;

  label {
    position: absolute;
    top: 13px;
    left: 14px;
  }
`;

export const Input = styled.input<ITheme>`
  box-sizing: border-box;
  padding: 10px 5px 10px 30px;
  background-color: ${({ theme }: ITheme) => theme.colors.white};
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: ${({ theme }: ITheme) => theme.defaultRadius};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  font-size: ${({ theme }: ITheme) => theme.size.body2};

  ::placeholder {
    font-size: ${({ theme }: ITheme) => theme.size.body2};
    font-family: ${({ theme }: ITheme) => theme.fontFamily.raleway};
    color: #797979;
    ${breakpoint('xs', 'lg')`
    font-size: ${({ theme }: ITheme) => theme.size.caption};
    `};
`;
