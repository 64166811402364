import styled, { css } from 'styled-components';
import { theme } from 'styles/theme';
import ITemplatePreset from 'types/TemplatePreset';

const buttonMixin = css`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.dlpDefaultButtonBgColor};
  cursor: pointer;
  display: inline-block;

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.colors.dlpDefaultButtonBgColor};
    text-decoration: underline;
  }
`;

export const Container = styled.div`
  padding: 20px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.dlpFormSeparatorColor};
  font-family: inherit;
`;

export const MainContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 250px;
  height: 250px;
  margin-right: 32px;
  overflow: hidden;
  border-radius: 4px;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center center;

  @media(max-width: 600px) {
    width: 100px;
    height: 100px;
  }
`;

export const Image = styled.img`
  position: absolute;
  width: auto;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const InfoContainer = styled.div`
  flex: 1;
  word-break: break-all;
`;

export const Title = styled.p`
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 24px;
`;

export const Category = styled.p`
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 24px;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`;

export const Price = styled.p`
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 24px;
`;

export const RemoveButton = styled.span<ITemplatePreset>((props) =>`
  ${buttonMixin};
  color: ${theme.colors.accentMainColor};
`);

export const NotesContainer = styled.div`
  padding-top: 29px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Notes = styled.pre`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 24px;
  white-space: normal;
`;

export const EditButton = styled.span<ITemplatePreset>((props) =>`
  ${buttonMixin};
  color: ${theme.colors.accentMainColor};
  margin-top: 19px;
  align-self: flex-end;
`);