import { Product } from 'graphqlQueries';

export interface IOption {
  label: string;
  value: string;
}

export enum CartItemTypes {
  good = "good",
  video = "video",
}

export enum PaymentMethods {
  creditCard = "creditCard",
  payPal = "payPal",
}

export enum AddressOptions {
  same = "same",
  different = "different",
}

export interface ICartItem {
  product: Product;
  quantity?: number;
  notes?: string;
  config?: any
}

// export interface IItemData {
//   id: string,
//   type: CartItemTypes,
//   title?: string | null;
//   description?: string | null;
//   size?: string | null;
//   color?: string | null;
//   quantity: number;
//   price?: number | null;
//   previewUrl?: string | null;
//   notes?: string | null;
//   shippingFee?: number | null;
// }