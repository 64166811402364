export const fOfferId = (id: string): string => {
  if (!id) return id;
  if (!/^OFFER_/.test(id)) {
    return `OFFER_${id}`;
  }
  return id;
};

export const fDrummerId = (id: string): string => {
  if (!id) return id;
  if (!/^DRUMMER_/.test(id)) {
    return `DRUMMER_${id}`;
  }
  return id;
};

export const fBusinessId = (id: string): string => {
  if (!id) return id;
  if (!/^BUSINESS_/.test(id)) {
    return `BUSINESS_${id}`;
  }
  return id;
};

export const rawBusinessId = (id: string): string => {
  if (!id) return id;
  if (/^BUSINESS_/.test(id)) {
    return id.replace(/^BUSINESS_/, '');
  }
  return id;
};

export const rawDrummerId = (id: string): string => {
  if (!id) return id;
  if (/^DRUMMER_/.test(id)) {
    return id.replace(/^DRUMMER_/, '');
  }
  return id;
};

export const rawOfferId = (id: string): string => {
  if (!id) return id;
  if (/^OFFER_/.test(id)) {
    return id.replace(/^OFFER_/, '');
  }
  return id;
};
