import React, { FC, useEffect, useState } from 'react';
import window from 'global';
import { getRoutes } from 'constants/routes';
import { fDrummerId } from 'utils/idFormatters';
import {
  useParams,
  useLocation,
  generatePath,
} from 'react-router-dom';
import copy from 'copy-to-clipboard';
import querystring from 'query-string';
import GeneralLayout from 'layouts/GeneralLayout';
import MetaData, { makeOfferPhotoThumbFromData, makeAvatarFromMeta, makeAvatarSubstituteLink, getFullName } from 'components/MetaData/MetaData';
import ReturnLink from 'components/ReturnLink';
import Swiper from 'components/Swiper';
import Delayed from 'components/Delayed';
import { selectImageStyle } from 'helpers';
import PrimaryButtonStyled from 'components/PrimaryButtonStyled';

import {
  makeNewParamsForDestinationUrl,
  generateURLOnlineOffer,
} from 'helpers/offerUrl';
import { getExpirationDate } from 'utils/drumUtils';
import DrumTracking from 'utils/DrumTracking';
import { useUsername } from '../../context/UserNameContext';
import {
  ImageStyle,
  useGetOfferDataQuery,
  useGetLinkPageMetadataQuery,
  GetLinkPageMetadataQuery,
  OfferDlp,
  SocialAccount,
  MediaObject,
} from 'graphqlQueries';
import { Open } from 'assets/svg';
import {
  Container,
  OpenLink,
  GalleryContainer,
  DetailsContainer,
  LogoContainer,
  Logo,
  BusinessDetails,
  BusinessName,
  ExpirationDate,
  OfferDetails,
  OfferName,
  DescriptionContainer,
  Description,
} from './styles';
import { useRedirection } from 'utils/serverEffectActions';
import { GeneralPageProps } from 'types';

interface IParamTypes {
  username: string;
  offerTitle: string;
  offerId: string;
  collectionTitle?: string;
  collectionId?: string;
}

const OfferPage: FC<GeneralPageProps> = () => {
  const location = useLocation();
  const redirectTo = useRedirection();
  const { username, usernameForRoutes, serverUserNameIsUsed } = useUsername();
  const routes = getRoutes(serverUserNameIsUsed);

  const queryParams = querystring.parse(location.search);
  const {
    fromLp: isFromLinkPage,
    cFromLp: isFromCollectionFromLinksPage,
    linkId
  } = queryParams;

  const { offerId, collectionTitle, collectionId  } = useParams<IParamTypes>();

  const [businessLogo, setBusinessLogo] = useState<string>('');
  const [offerPhotoList, setOfferPhotoList] = useState<string[]>([]);
  const [offerUrl, setOfferUrl] = useState<string | undefined>(undefined);
  const [offerData, setOfferData] = useState<Partial<OfferDlp> | undefined>(undefined);
  const [returnLink, setReturnLink] = useState<string | undefined>(undefined);

  const {
    data: metaData,
  } = useGetLinkPageMetadataQuery({
    fetchPolicy: 'cache-first',
    variables: {
      username,
    },
    onError: () => {
      redirectTo(routes.PAGE_404);
    },
  });

  const { data: offerDataRaw } = useGetOfferDataQuery({
    variables: {
      input: {
        offerId: offerId,
      },
    },
    onError: () => {
      redirectTo(routes.PAGE_404);
    },
  });

  const getOfferPhotos = (photos?: MediaObject[] | null) => {
    if (!photos?.length) return [];
    return photos.map((item) => {
      const _image = selectImageStyle(item.styles as ImageStyle[])?.path;

      return _image ? `${process.env.REACT_APP_DOMAIN_ASSET}/${_image}` : '';
    });
  };

  const onSocialClick = (item: SocialAccount) => {
    DrumTracking.onSocialItemClick(item);
  };

  const handleCopy = () => {
    copy(window?.location?.href);
  };

  const formOfferUrl = (offerData: Partial<OfferDlp> | undefined, metaData: GetLinkPageMetadataQuery | undefined) => {
    if(!offerData || !metaData) {
      return;
    }

    //forming offer URL
    const newDestinationUrlParams = makeNewParamsForDestinationUrl({
      urlDestination: offerData?.urlDestination,
      drummer: metaData?.getLinkedPage?.user,
      business: offerData?.business,
      offer: offerData,
      collectionId: (collectionId as string) || null,
      isFromLinkPage,
      isFromCollectionFromLinksPage,
      linkId: queryParams.linkId || '',
      linkPageViewId: DrumTracking?.session.getSavedSessionId() || ''
    });

    setOfferUrl(
      generateURLOnlineOffer(
        newDestinationUrlParams,
        offerData?.urlDestination
      )
    );
  };

  useEffect(() => {
    if (isFromLinkPage) {
      setReturnLink(generatePath(routes.LINK_PAGE, { username: usernameForRoutes }));
    } else if (collectionId) {
      const qs = !isFromLinkPage && !isFromCollectionFromLinksPage ? '' : `fromLp=1&linkId=${linkId}`;
      const qsTail = !qs ? '' : `?${qs}`;
      setReturnLink(
        `${generatePath(routes.COLLECTION_PAGE, {
          ...(serverUserNameIsUsed ? {} : {username: usernameForRoutes}),
          collectionTitle: collectionTitle?.toString() || '',
          collectionId: collectionId?.toString() || '',
        })}${qsTail}`
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      offerDataRaw &&
      offerDataRaw.dlpOfferPage?.__typename === 'DlpOfferPageResult'
    ) {
      setOfferData(offerDataRaw?.dlpOfferPage?.offer);
    }
  }, [offerDataRaw]);

  useEffect(() => {
    if (metaData && offerData) {
      DrumTracking.initLib({
        drummerId: fDrummerId(metaData?.getLinkedPage?.user?.id || ''),
        userId: metaData?.getLinkedPage?.user?.id,
        username,
        user: metaData?.getLinkedPage?.user,
        pageItems: null,
        socials: null,
        pageType: DrumTracking.PAGE_TYPES.offerPage,
        isFromLinkPage
      });
      DrumTracking.session.startSession();
      
      const _businessImage = selectImageStyle(
        offerData?.business?.images?.[0]?.styles as ImageStyle[]
      )?.path;
      setBusinessLogo(
        _businessImage
          ? `${process.env.REACT_APP_DOMAIN_ASSET}/${_businessImage}`
          : ''
      );

      (window as any).addEventListener('focus', DrumTracking.onFocus);
      (window as any).addEventListener('blur', DrumTracking.onBlur);

      setOfferPhotoList(getOfferPhotos(offerData?.photos));

      setTimeout(() => {
        formOfferUrl(offerData, metaData);
      });

      return () => {
        (window as any).removeEventListener('focus', DrumTracking.onFocus);
        (window as any).removeEventListener('blur', DrumTracking.onBlur);
      };
    }
    // eslint-disable-next-line
  }, [metaData, offerData]);

  const helmetMeta = React.useMemo(() => {
    if(offerDataRaw?.dlpOfferPage?.__typename !== 'DlpOfferPageResult') return;
    const meta = metaData?.getLinkedPage;
    const businessName = offerDataRaw?.dlpOfferPage?.offer?.business?.businessName;
    const fullName = getFullName(meta);
    
    return {
      offerPhotoThumb: makeOfferPhotoThumbFromData(offerDataRaw?.dlpOfferPage?.offer?.photos?.[0]),
      avatarImage: makeAvatarFromMeta(meta),
      title: `Check out this great offer${!businessName ? '' : ` from ${businessName}`}. ${offerDataRaw?.dlpOfferPage?.offer?.title}`,
      avatarSubstituteLink: makeAvatarSubstituteLink(meta),
      metaTitle: `${!businessName ? '' : `${businessName}: `}${offerDataRaw?.dlpOfferPage?.offer?.title}`,
      metaDescription: `${fullName} shared “${offerDataRaw?.dlpOfferPage?.offer?.title}”${!businessName ? '' : ` from ${businessName}`
        }. Click to see details.`
    };
    // eslint-disable-next-line
  }, [metaData, offerData]);

  return (
    <>
      <MetaData 
        {...helmetMeta}
      />

      <GeneralLayout
        showLoader={!metaData}
        description="Check out this great recommendation."
        metaData={metaData?.getLinkedPage}
        onSocialClick={onSocialClick}
        outerContent={
          <>
            {returnLink && (
              <ReturnLink to={returnLink} />
            )}
            <OpenLink data-id="session-closer" styles={metaData?.getLinkedPage?.template_preset} href={offerUrl}>
              Open
              <Open />
            </OpenLink>
          </>
        }
      >
        <Container styles={metaData?.getLinkedPage?.template_preset}>
          {metaData?.getLinkedPage && <GalleryContainer>
            <Delayed>{/* we use it because swiper starts rendering when padding values are not yet received */}
              <Swiper images={offerPhotoList} onCopy={handleCopy} />
            </Delayed>
            
          </GalleryContainer>}

          <DetailsContainer
            styles={metaData?.getLinkedPage?.template_preset}
          >
            <LogoContainer>
              <Logo src={businessLogo} />
            </LogoContainer>
            <BusinessDetails>
              <BusinessName>{offerData?.business?.businessName}</BusinessName>
              <ExpirationDate>
                {getExpirationDate(offerData?.endedAt)}
              </ExpirationDate>
            </BusinessDetails>
            <OfferDetails>
              <OfferName>{offerData?.title}</OfferName>
              <DescriptionContainer>
                <Description>{offerData?.description}</Description>
                <PrimaryButtonStyled
                  data-id="session-closer"
                  href={offerUrl}
                >
                  {offerData?.offerStyle?.buttonText || 'Go To Website'}
                </PrimaryButtonStyled>
              </DescriptionContainer>
            </OfferDetails>
          </DetailsContainer>
        </Container>
      </GeneralLayout>  
    </>
  );
};

export default OfferPage;
