import React from 'react';
import IconProps from './IconProps';

const QuestionMark: React.FC<IconProps> = ({ className }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    className={className}
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="8.5" cy="8.5" r="8.5" fill="#00B3FF" />
      <text
        fill="#FFF"
        fontFamily="Roboto-Bold, Roboto"
        fontSize="14"
        fontWeight="bold"
      >
        <tspan x="5" y="14">
          ?
        </tspan>
      </text>
    </g>
  </svg>
);

export default QuestionMark;
