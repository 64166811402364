import styled from 'styled-components';

interface ContainerProps {
  backgroundImage?: string | null;
  font: string;
}

interface ContainerInnerProps {
  backgroundColor?: string | null;
  textColor?: string | null;
}

export const Container = styled.div((props: ContainerProps) => `
  width: 100%;
  font-family: ${props.font || "Raleway"}, "Raleway";
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-direction: column;

  &:after {
    content: "";
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    left: 0;
    right: 0;
    z-index: -1;
    background: url('${props.backgroundImage || '/assets/images/background_link_page.jpg'}');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    //background-position: center;
    background-position: top center;
  }
`);

export const ContainerInner = styled.div<ContainerInnerProps>`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  max-width: 744px;
  background: ${({ backgroundColor }) => backgroundColor || '#fff'};
  flex-grow: 1;
  margin: 120px auto 30px auto;
  border-radius: 15px;
  box-sizing: border-box;
  color: ${({ textColor, theme }) => textColor || theme.colors.dlpDefaultUserTextColor};
  fill: ${({ textColor, theme }) => textColor || theme.colors.dlpDefaultUserTextColor};

  @media (max-width: 400px) {
    display: block;
    margin-bottom: 0;
    min-height: 0;
  }
`;

export const LoaderContainer = styled.div`
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  height: -webkit-fill-available;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.dlpUpgradeLoaderBg};
  opacity: 1;

  &.hidden {
    opacity: 0;
    transition: opacity 0.6s linear;
  }
  &.no-display {
    display: none;
  }

  & > div {
    background: ${({ theme }) => theme.colors.dlpUpgradeLoaderBg};
    width: 100vw;
  }
`;
