import styled from 'styled-components';
import { DrumLogoColored } from 'assets/svg';
import { PrimaryButton, InputGroup, LinkTextMixin } from 'uikit';
import { breakpoint } from 'helpers';

export const ContainerOuter = styled.div`
  padding-bottom: 130px;

  ${breakpoint('xs', 'md')`
    padding-bottom: 0;
	`}
`;

export const Container = styled.div`
  background-color: #F6F8F9;
  padding-left: 100px;
  padding-top: 19px;
  padding-bottom: 74px;
  box-sizing: border-box;

  ${breakpoint('xs', 'lg')`
    width: 100%;
    box-sizing: border-box;
    padding-left: 33px;
    padding-right: 33px;
	`}
`;

export const ContainerInner = styled.div`
  display: flex;
  justify-content: space-between;
  ${breakpoint('lg')`
    max-width: 1440px;
    margin: 0 auto;
	`}

  ${breakpoint('xs', 'lg')`
    flex-direction: column;
    justify-content: flex-start;
	`}
`;

export const LeftContent = styled.div`
  max-width: 592px;
`;

export const RightContent = styled.div`
  padding-right: 100px;
  ${breakpoint('xs', 'lg')`
    padding-right: 0;
	`}
`;

export const Logo = styled(DrumLogoColored)`
  width: 160px;
  height: 35px;
`;

export const Title = styled.p`
  margin-top: 109px;
  font-size: 28px;
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 32px;
`;

export const Description = styled.div`
  margin-top: 27px;
  font-size: ${({ theme }) => theme.size.subtitle};
  line-height: 32px;
`;

export const Label = styled.div`
  margin: 27px 0 6px 0;
  line-height: 32px;
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const InputGroupStyled = styled(InputGroup)`
  border: 1px solid #C0C0C0;
  width: 370px;
  height: 48px;
  padding: 12px 15px;

  ${breakpoint('xs', 'lg')`
    width: 100%;
	`}

  & input {
    display: inline-block;
    height: 100%;
  }
`;

export const SubmitButton = styled(PrimaryButton)`
  margin-top: 24px;
  width: 200px;
  height: 56px;
  border-radius: 8px;
`;

export const SlideImage = styled.img`
  width: 456px;
  top: 140px;
  position: relative;

  ${breakpoint('xs', 'lg')`
    width: 100%; 
    top: 0;
    margin-top: 60px;
	`}
`;

export const NoteLinkBlockBase = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin: 32px auto 0 auto;
  display: none;
`;
export const NoteLinkBlock = styled(NoteLinkBlockBase)`
  ${breakpoint('md')`
    max-width: 1440px;
    display: block;
    padding-left: 100px;
	`}
`;

export const NoteLinkBlockMobile = styled(NoteLinkBlockBase)`
  ${breakpoint('xs', 'md')`
    display: block;
	`}
`;

export const NoteLink = styled.a`
  ${LinkTextMixin}
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;
