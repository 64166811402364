import React from 'react';
import Select, { Styles, Props as SelectProps } from 'react-select';
import { baseSelectStyles, Label } from './styles';
import './styles.scss';

interface Props<OptionType> extends SelectProps<OptionType> {
  className?: string;
  label?: string;
  selectStyles?: Styles<OptionType, true>;
}

function DropDown<OptionType>({
  className,
  label,
  selectStyles,
  ...selectProps
}: Props<OptionType>) {
  return (
    <div className={`${className} custom-select`}>
      {label && <Label>{label}</Label>}
      <Select
        styles={{ ...baseSelectStyles, ...selectStyles }}
        isSearchable={false}
        {...selectProps}
      />
    </div>
  );
}

export default DropDown;
