import React, { FC } from 'react';
import { Emphasized } from 'uikit';
import PrimaryButtonStyled from 'components/PrimaryButtonStyled';
import { IComponentParams } from '../../types';
import { Title, Paragraph,  Email } from '../../styles';

interface Props extends IComponentParams {
  downloadHref?: string | null;
  onDownload?: () => void;
}

const DigitalPurchaseConfirmation: FC<Props> = ({ orderData, downloadHref, onDownload }) => {
  return (
    <>
      <Title>Thank You</Title>
      <Paragraph>Your purchase has been confirmed.</Paragraph>
      <Paragraph><Emphasized>{orderData.title || ''}</Emphasized></Paragraph>
      <PrimaryButtonStyled
        href={downloadHref || ""}
        download
        onClick={onDownload}
      >Download</PrimaryButtonStyled>
      <Paragraph style={{marginTop: 20}}>An email with a download link <br /> has been sent to<br />
        <Email>{orderData.email}</Email>
      </Paragraph>
      
    </>
  );
};

export default DigitalPurchaseConfirmation;
