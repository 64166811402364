import styled from 'styled-components';
import { ITheme, InputBoxProp } from '../../types';
import { breakpoint } from '../../helpers';

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InputBox = styled.div<InputBoxProp>`
  display: flex;
  flex-direction: column;
  padding: 14px 5px;
  padding-left: 20px;
  font-size: ${({ theme }: InputBoxProp) => theme.size.body2};
  background: ${({ theme }: InputBoxProp) => theme.colors.white};
  border: 1px solid
    ${({ error, focused }: InputBoxProp) =>
      error ? 'red' : focused ? '#d4e307' : 'rgba(0, 0, 0, 0.16)'};
  border-radius: 5px;
  box-shadow: ${({ noShadow }: InputBoxProp) =>
    noShadow ? 'none' : '0 0 10px 0 rgba(0, 0, 0, 0.16)'};
`;
export const FormInput = styled.textarea`
  width: 100%;
  border: 0;
  resize: none;
  background: transparent;
  ${breakpoint('xs', 'lg')`
  font-size: ${({ theme }: InputBoxProp) => theme.size.caption};
  `};
  ::placeholder {
    font-size: ${({ theme }: InputBoxProp) => theme.size.body2};
    font-family: ${({ theme }: InputBoxProp) => theme.fontFamily.raleway};
    color: #797979;
    ${breakpoint('xs', 'lg')`
    font-size: ${({ theme }: InputBoxProp) => theme.size.caption};
    `};
  }
`;

export const CharacterLimit = styled.small<ITheme>`
  align-self: flex-end;
  margin-right: 10px;
  font-size: ${({ theme }: InputBoxProp) => theme.size.caption};
  color: ##797979;
`;

export const FormError = styled.p`
 min-height: 12px;
 margin-left: 20px;
 margin-top: 5px;
 color ${({ theme }: ITheme) => theme.colors.danger};
 font-size: ${({ theme }: ITheme) => theme.size.caption};
`;
