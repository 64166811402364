import styled from 'styled-components';

interface ContainerProps {
  font?: string | null;
  color?: string | null;
}

export const Container = styled.div<ContainerProps>`
  max-width: 700px;  
  min-height: 200px;
  color: inherit;
  font-family: inherit;
  width: 100%;
  padding: 0;

  * {
    font-family: inherit;
  }
`;
export const LoaderContainer = styled.div`
  display: flex;
  min-height: 200px;
  justify-content: center;
  align-items: center;
`;