import { createReducer } from "@reduxjs/toolkit";
import {
  USER_METADATA_DEFAULT_DATA,
  USER_METADATA_SET_METADATA
} from '../constants/ActionTypes';
import {
  MetaDataState,
  MetaDataAction,
} from '../types';

const initialState: MetaDataState = {
  root: undefined
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(USER_METADATA_DEFAULT_DATA, (state: MetaDataState) => {
      return state;
    })
    .addCase(USER_METADATA_SET_METADATA, (state: MetaDataState, action: MetaDataAction) => {
      state.root = action.payload;
      // state = Object.assign({}, {
      //   ...state,
      //   root: action.payload
      // });
    })
    
})

export default reducer;
