import React, { FC } from 'react';
import IconProps from './IconProps';

const ChevronLeft: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="6"
    height="10"
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.09795 1.73777L4.17976 0.81958L0 4.99934L0.00112585 5.00047L0.000950409 5.00064L4.18071 9.18041L5.0989 8.26222L1.8362 4.99952L5.09795 1.73777Z"
      fill="white"
    />
  </svg>
);
export default ChevronLeft;
