import React, { FC } from 'react';
import { 
  Container,
  Text,
  DownloadButton,
} from './styles';

interface Props {
  link?: string | null;
}

const AndroidSuccess: FC<Props> = ({ link }) => {

  return (
    <Container>
      <Text>
      Please download the Drum Android app to get started.
      </Text>
      <DownloadButton href={link || ''} target="_blank">
        <img src="/assets/images/google-play.png" alt="google play" />
      </DownloadButton>
    </Container>
  );
};

export default AndroidSuccess;
