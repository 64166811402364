import styled from 'styled-components';
import { ITheme } from '../../types';
import { darken } from 'polished';

const Button = styled.button<ITheme>`
  width: 100%;
  background-color: ${({ theme }: ITheme) => theme.colors.main};
  color: ${({ theme }: ITheme) => theme.colors.white};
  border-radius: ${({ theme }: ITheme) => theme.defaultRadius};
  padding: 14px 0px;

  margin-top: 25px;

  &:hover {
    background-color: ${({ theme }: ITheme) => darken(0.05, theme.colors.main)};
  }
  &:active {
    background-color: ${({ theme }: ITheme) => darken(0.08, theme.colors.main)};
  }
  &:focus {
    background-color: ${({ theme }: ITheme) => darken(0.02, theme.colors.main)};
    border: ${({ theme }: ITheme) =>
      '1px solid ' + darken(0.3, theme.colors.main)};
  }
  &:disabled {
    opacity: 0.5;
  }
`;

export default Button;
