import React, { FC } from 'react';
import { Formik, useFormikContext } from 'formik';
import validationSchema, { SchemaType } from './validationSchema';
import { 
  InputContainer,
  InputBase,
  FieldFooter
} from 'uikit';
import { 
  ContainerOuter,
  Container,
  ContainerInner,
  LeftContent,
  RightContent,
  Logo,
  Title,
  Description,
  Label,
  InputGroupStyled,
  SubmitButton,
  SlideImage,
  NoteLinkBlock,
  NoteLinkBlockMobile,
  NoteLink
} from './styles';
import routes from 'constants/routes';

interface Props {
  initialEmail?: string | null;
  shouldValidateOnChange?: boolean;
  onSubmit: (
    values: SchemaType,
    { setSubmitting, setErrors, validateForm }: any
  ) => void;
  errorMessage?: string;
  username?: string;
  onChange?: () => void;
}



const EmailForm: FC<Props> = ({ onSubmit, onChange, errorMessage, username }) => {

  const { values, errors, setSubmitting, setErrors, setFieldValue, validateForm } =
    useFormikContext<SchemaType>();

  return (
    <ContainerOuter>
      <Container>
        <ContainerInner>
          <LeftContent>
            <Logo>

            </Logo>
            <Title>{username || ''} has invited you to Drum!</Title>
            <Description>Drum is a FREE link in bio page designer allowing you to share links, embed videos, audio, and even create your own store. Connect with your social audience like never before.</Description>
            <Label>Enter your email to get started</Label>
            
            <InputContainer>
              <InputGroupStyled font={"Raleway"} textColor={"#000"} background={"#fff"}>
                <InputBase
                  name={"email"}
                  //onChange={handleChange}
                  onChange={(value) => {
                    onChange?.();
                    setFieldValue(
                      "email",
                      value.target.value
                    );
                  }}
                  value={values.email}
                  placeholder={"Your email address"}
                />
              </InputGroupStyled>
              <FieldFooter error={errors?.email || errorMessage} showAlways={false} />
            </InputContainer>

            <SubmitButton onClick={() => onSubmit(values, {setSubmitting, setErrors, validateForm})}>Submit</SubmitButton>
            <NoteLinkBlockMobile>Do you have a Linktree account? <NoteLink href={routes.UPGRADE_LANDING} target="_blank" >Upgrade to Drum</NoteLink> for FREE.</NoteLinkBlockMobile>
          </LeftContent>
          <RightContent>
            <SlideImage src="/assets/images/referralPage/1.png" alt="drum slide 1"/>
          </RightContent>  
        </ContainerInner>
        
      </Container>
      <NoteLinkBlock>Do you have a Linktree account? <NoteLink href={routes.UPGRADE_LANDING} target="_blank" >Upgrade to Drum</NoteLink> for FREE.</NoteLinkBlock>
    </ContainerOuter>
  );
};

const EmailFormContainer: FC<Props> = (props) => {

  const initialValues: SchemaType = {
    email: props.initialEmail || '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
      validationSchema={validationSchema}
      validateOnChange={props.shouldValidateOnChange}
    >
      <EmailForm
        {...props}
      />
    </Formik>
  );
};

export default EmailFormContainer;
