import React, { FC } from 'react';
import { getIFrameUrl } from 'helpers';
import { Container, ContainerInner, IframeContainer } from './styles';
import { useSSRContext } from 'context/SSRContext';
import Props from '../../types';

const MediaItem: FC<Props> = ({ item, styles, className }) => {
  const { url, provider, type } = item;

  const ssrContext = useSSRContext();
  const URL = getIFrameUrl(url, provider, ssrContext);
  const additionalClass =
    provider === 'spotify' &&
    (url?.indexOf('/show/') !== -1 || url.indexOf('/episode/') !== -1)
      ? 'spotify-podcast'
      : '';

  return (
    <Container
      className={`provider-${provider} type-${type} ${additionalClass} ${className}`} styles={styles}
    >
      <ContainerInner className={`provider-${provider} type-${type} ${additionalClass} `} styles={styles}>
        <IframeContainer
          src={URL}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowTransparency={true}
          allowFullScreen={true}
          data-id={item.id}
          data-type={'media-frame'}
          styles={styles}
        ></IframeContainer>
      </ContainerInner>
      {/* {provider === 'tiktok' ? (
        <Tiktok item={item} />
      ) */}
      
    </Container>
  );
};

export default MediaItem;
