import React, { FC } from 'react';
import { Overlay, StyledLoader } from './styles';

const FullScreenLoader: FC = () => (
  <Overlay>
    <StyledLoader />
  </Overlay>
);

export default FullScreenLoader;
