import * as yup from 'yup';
import { emailRegExp, phoneRegExp, zipRegExp } from 'constants/regExp';

export interface IOption {
  label: string;
  value: string;
}

const shippingFields = {
  firstName: yup.string().required(`Please set first name`),
  lastName: yup.string().required(`Please set last name`),
  street: yup.string().required(`Please set address`),
  appartment: yup.string().notRequired(),
  city: yup.string().required(`Please set city`),
  state: yup.string().required(`Please set state`),
  zipCode: yup.string().required(`Please set ZIP code`).test('invalid zip code', 'Invalid ZIP code', function(value) {
    return zipRegExp.test(value);
  }),
};

export const validationSchemaNonPhysical = yup.object({
  customer: yup.object({
    email: yup.string().required(`Email is required`).test('invalid email', 'Invalid email', function(value) {
      return emailRegExp.test(value);
    }),
    phone: yup.string().test('invalid phone', 'Invalid phone number', function(value) {
      return !value || phoneRegExp.test(value);
    }),
  }),
  
});

export const validationSchemaPhysical = yup.object({
  customer: yup.object({
    email: yup.string().required(`Email is required`).test('invalid email', 'Invalid email', function(value) {
      return emailRegExp.test(value);
    }),
    phone: yup.string().test('invalid phone', 'Invalid phone number', function(value) {
      return !value || phoneRegExp.test(value);
    }),
  }),
  shipping: yup.object(shippingFields),
});

export type SchemaTypeNonPhysical = yup.InferType<typeof validationSchemaNonPhysical>;
export type SchemaTypePhysical = yup.InferType<typeof validationSchemaPhysical>;

export default validationSchemaNonPhysical;