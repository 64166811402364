import window from 'global';
import { getSelectedOptionsFromArray, getLinkType } from './index';
import {
  IImpression,
  ILinkClick,
  IProvidedOrderDetails,
  IProvidedInformation,
  IPlacedOrder,
  IProductDownloaded,
  IViewedProduct,
  ISubscription,
  IMethods,
} from './types';

const baseFields = {
  applicationSource: 'Drum'
};

//USER SAW LINK
export const sendImpressionsAnalytics = (
  props: IImpression
) => {
  const fbq = window?.fbq;

  (props?.items || []).forEach(item => {
    fbq("trackCustom", "Link Impression", {
      ...baseFields,
      drummerId: props.drummerId,
      username: props.username,
      source: props.source,
      itemId: item.CardID || '',
      itemTitle: item.LinkTitle || '',
      itemType: getLinkType(item.itemType),
    });
  })
};

//USER CLICKED LINK
export const sendLinkClickAnalytics = (
  props: ILinkClick
) => {
  const fbq = window?.fbq;
  
  fbq("trackCustom", "Link Clicked", {
    ...baseFields,
    ...props
  });
};

export const sendViewedProductAnalytics = (props: IViewedProduct) => {
  const fbq = window?.fbq;
  
  fbq("trackCustom", "Buyer Viewed Product", {
    ...baseFields,
    drummerId: props.meta?.user_id,
    username: props.meta?.user?.username,
    itemId: props.productId,
    itemType: getLinkType(props.item?.type),
    itemTitle: props.item?.name,
    source: props.source
  });
};

export const sendProvidedOrderDetailsAnalytics = (
  props: IProvidedOrderDetails
) => {
  const fbq = window?.fbq;

  const {
    selectedOptions,
    source
  } = props;

  const drumData = {
    drummerId: props.meta?.user_id,
    username: props.meta?.user?.username,
    itemId: props.product?.id,
    itemType: getLinkType(props.product?.type),
    itemTitle: props.product?.title,
    source
  };

  const _selectedOptions = getSelectedOptionsFromArray(selectedOptions);
  if(_selectedOptions) {
    Object.keys(_selectedOptions).forEach(key => {
      drumData[`selectedOptions_${key}`] = _selectedOptions[key];
    });
  }

  fbq('track', 'InitiateCheckout', {
    ...baseFields,
    ...drumData,
    currency: "USD", 
    value: (props.product?.price || 0) * (props.quantity || 1),
    content_type: 'product',
    price: props.product?.price,
    quantity: props.quantity || 1,
    contents: [
      {
        id: props.product?.id, 
        name: props.product?.title,
        item_variant: `${props.selectedOptions?.[0]?.value || ''} ${props.selectedOptions?.[1]?.value || ''}`.trim(),
        price: props.product?.price,
        quantity: props.quantity || 1
      }
    ]
  });
};

export const sendProvidedInformationAnalytics = (
  props: IProvidedInformation
) => {
  const {
    shippingAddress,
    meta,
    buyerEmail,
    buyerPhone,
  } = props;

  const fbq = window?.fbq;
  const _shippingAddress = shippingAddress || {};
  fbq('trackCustom', 'Added Shipping Info', {
    ...baseFields,
    ..._shippingAddress,
    currency: "USD", 
    value: (props.product?.price || 0) * (props.quantity || 1),
    itemId: props.product?.id,
    itemType: getLinkType(props.product?.type),
    itemTitle: props.product?.title,
    content_type: 'product',
    quantity: props.quantity || 1,
    drummerId: meta?.user_id,
    username: meta?.user?.username,
    source: props.source,
    buyerEmail,
    buyerPhone,
    contents: [
      {
        id: props.product?.id, 
        name: props.product?.title,
        item_variant: `${props.selectedOptions?.[0]?.value || ''} ${props.selectedOptions?.[1]?.value || ''}`.trim(),
        price: props.product?.price,
        quantity: props.quantity || 1
      }
    ]
  });
};

export const sendPlacedOrderAnalytics = (
  props: IPlacedOrder
) => {

  const fbq = window?.fbq;

  const { firstName, lastName, username } = props?.meta?.user || {};
  const {
    buyerEmail,
    buyerPhone,
    orderID,
    productCoverImageURL,
    profileImageURL,
    productType,
    taxes,
    selectedOptions,
    source,
    product
  } = props;
  const type = getLinkType(productType);

  const drumData = {
    drummerId: props.meta?.user_id,
    influencerFirstName: firstName,
    influencerLastName: lastName,
    influencerUsername: username,
    buyerEmail,
    buyerPhone,
    source,
    orderID,
    productCoverImageURL,
    profileImageURL,
    productType: type,
    taxes,
    itemId: product?.id,
    itemType: getLinkType(product?.type),
    itemTitle: product?.title,
    buyerId: props.meta?.user_id,
    date: new Date().toISOString(),
  };

  ["billingAddress", "shippingAddress"].forEach((addressKey) => {
    if(props[addressKey]) {
      Object.keys(props[addressKey]).forEach(key => {
        drumData[`${addressKey}_${key}`] = props[addressKey][key];
      });
    }
  })

  const _selectedOptions = getSelectedOptionsFromArray(selectedOptions);
  if(_selectedOptions) {
    Object.keys(_selectedOptions).forEach(key => {
      drumData[`selectedOptions_${key}`] = _selectedOptions[key];
    });
  }

  fbq('track', 'Purchase', {
    ...baseFields,
    ...drumData,
    currency: "USD", 
    totalPrice: Number(props.cartTotal || 0).toFixed(2),
    content_type: 'product',
    quantity: props.quantity || 1,
    price: props.productPrice || 0,
    contents: [
      {
        id: props.product?.id, 
        name: props.product?.title,
        item_variant: `${props.selectedOptions?.[0]?.value || ''} ${props.selectedOptions?.[1]?.value || ''}`.trim(),
        price: props.productPrice || 0,
        quantity: props.quantity || 1,
      }
    ]
  });
};

export const sendProductDownloadedAnalytics = (props: IProductDownloaded) => {

  const fbq = window?.fbq;

  const { orderId, orderShortUrlId } = props;
  const data = {
    ...baseFields,
    drummerId: props.meta?.user_id,
    orderId,
    orderShortUrlId,
    username: props.meta?.user?.username,
    itemId: props.orderData?.product?.id,
    itemTitle: props.orderData?.product?.title,
    itemType: getLinkType(props.orderData?.product?.type),
  };

  fbq('trackCustom', 'Downloaded File', data);
};

export const sendSubscriptionAnalytics = (
  props: ISubscription
) => {
  const fbq = window?.fbq;

  const { meta, fullName, email, phone } = props;

  const data = {
    ...baseFields,
    drummerId: meta?.user?.id,
    username: meta?.user?.username,
    fullName,
    email,
    phoneNumber: phone
  }
  fbq('trackCustom', 'Complete Registration', data);
};

export const fbPixel: IMethods = {
  sendImpressionsAnalytics,
  sendLinkClickAnalytics,
  sendViewedProductAnalytics,
  sendProvidedOrderDetailsAnalytics,
  sendProvidedInformationAnalytics,
  sendPlacedOrderAnalytics,
  sendProductDownloadedAnalytics,
  sendSubscriptionAnalytics
}

export default fbPixel;