import styled, { CSSProperties } from 'styled-components';
import { Body1, H3, LinkTextMixin } from '../Typography';
import { CloseModalButton, PrimaryButton, OutlinedButton } from '../Buttons';

export const Container = styled.div`
  position: relative;
  padding: 20px 17px 23px 17px;
  text-align: center;
  background: ${({ theme }) => theme.colors.white};
`;

export const StyledCloseButton = styled(CloseModalButton)`
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 1;
  opacity: 0.8;
`;

export const Image = styled.img`
  display: block;
  margin-bottom: 40px;
`;

export const StatusIconContainer = styled.div`
  margin-bottom: 40px;
`;

export const Title = styled(H3)`
  color: ${({ theme }) => theme.colors.danger};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.size.subtitleLarge};
  margin-bottom: 25px;
`;

export const Message = styled(Body1)`
  margin-bottom: 30px;
  line-height: 19px;
`;

export const MainActionButton = styled(PrimaryButton)`
  display: block;
  width: 220px;
  margin: 0 auto 15px auto;
  padding: 8px 0;
  width: 100%;
  max-width: 298px;
  height: 36px;
  line-height: 16px;
`;

export const SecondaryActionButton = styled(OutlinedButton)`
  padding: 15px;
  background-color: transparent;
  ${LinkTextMixin};
  padding: 8px 0;
  width: 100%;
  max-width: 298px;
  height: 36px;
  line-height: 16px;
`;

export const overlayStyle: CSSProperties = {
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  zIndex: 10000,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)'
};
export const contentStyle: CSSProperties = {
  position: 'relative',
  width: '330px',
  boxSizing: 'border-box',
  boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.5)',
  borderRadius: '16px',
  maxHeight: '90%',
  left: '0',
  right: '0',
  top: '0',
  bottom: '0',
  margin: 'auto auto'
};