import { createContext, useContext } from 'react';
import React from 'react';

export type ServerEffectInput = Record<string, any>;

export type ServerEffect = {
  action: string;
  input?: ServerEffectInput;
};

type ScheduleConfig = {
  overridingEffect: boolean;
  rewriteSameEffect: boolean;
};

const DEFAULT_SCHEDULE_CONFIG: ScheduleConfig = {
  overridingEffect: false,
  rewriteSameEffect: false,
};

const processScheduleConfig = (
  configInput?: Partial<ScheduleConfig>
): ScheduleConfig => {
  if (!configInput) {
    return DEFAULT_SCHEDULE_CONFIG;
  }
  return { ...DEFAULT_SCHEDULE_CONFIG, ...configInput };
};

type EffectsRefMap = Record<string, { ref: ServerEffect }>;

type EffectsRefArray = Array<{ ref: ServerEffect }>;

export class SSRContextServerEffects {
  private effectsMap: EffectsRefMap = {};
  private effectsArr: EffectsRefArray = [];
  private overridingEffectsMap: EffectsRefMap = {};
  private overridingEffectsArr: EffectsRefArray = [];

  get overridingEffects() {
    return this.overridingEffectsArr.map((el) => el.ref);
  }

  get effects() {
    return this.effectsArr.map((el) => el.ref);
  }

  private scheduleTo(
    { effect, config }: { effect: ServerEffect; config: ScheduleConfig },
    { arr: efArr, map: efMap }: { arr: EffectsRefArray; map: EffectsRefMap }
  ) {
    const actionRecorded = efMap[effect.action];
    const rewrite = config.rewriteSameEffect;
    if (actionRecorded && !rewrite) {
      return;
    } else if (actionRecorded && rewrite) {
      actionRecorded.ref = effect;
    } else if (!actionRecorded) {
      const effectObjRef = { ref: effect };
      efMap[effect.action] = effectObjRef;
      efArr.push(effectObjRef);
    }
  }

  private scheduleOverridingEffect(
    effect: ServerEffect,
    config: ScheduleConfig
  ) {
    return this.scheduleTo(
      { effect, config },
      {
        arr: this.overridingEffectsArr,
        map: this.overridingEffectsMap,
      }
    );
  }

  private scheduleRegularEffect(effect: ServerEffect, config: ScheduleConfig) {
    return this.scheduleTo(
      { effect, config },
      {
        arr: this.effectsArr,
        map: this.effectsMap,
      }
    );
  }

  schedule(effect: ServerEffect, configInput?: Partial<ScheduleConfig>): void {
    const config = processScheduleConfig(configInput);
    if (config.overridingEffect) {
      this.scheduleOverridingEffect(effect, config);
    } else {
      this.scheduleRegularEffect(effect, config);
    }
  }
}

type SSRServerTimeContext = {
  serverEffects: SSRContextServerEffects;
};

type SSRClientTimeContext = Record<string, any>;

type SSRContextProps = {
  isOnServer: boolean;
  serverTimeContext: SSRServerTimeContext;
  clientTimeContext: SSRClientTimeContext;
};

export const SSRContext = createContext<SSRContextProps>({
  isOnServer: false,
  serverTimeContext: {
    serverEffects: new SSRContextServerEffects(),
  },
  clientTimeContext: {},
});

export const SSRContextProvider = ({
  children,
  serverTimeContext = {},
  clientTimeContext = {},
  isOnServer,
}) => {
  const value = {
    isOnServer,
    serverTimeContext: Object.assign(serverTimeContext, {
      serverEffects: new SSRContextServerEffects(),
    }),
    clientTimeContext,
  };
  return <SSRContext.Provider value={value}>{children}</SSRContext.Provider>;
};

export const useSSRContext = () => {
  return useContext(SSRContext);
};

export const useServerEffect = (effect: ServerEffect) => {
  const {
    serverTimeContext: { serverEffects },
  } = useSSRContext();
  serverEffects.schedule(effect);
};

export const useClientContext = () => {
  const { clientTimeContext } = useSSRContext();
  return clientTimeContext;
};

export const usePageViewId = () => {
  /**
   * TODO
   */
};
