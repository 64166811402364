import React, { FC } from 'react';
import IconProps from './IconProps';

const Plane: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.375 12.375L3.3976 12.5381L4.13663 16.203C4.2033 16.4668 4.40501 16.6897 4.67339 16.8114C4.94177 16.9137 5.27795 16.8922 5.52429 16.75C6.55431 16.1421 7.89677 15.3527 7.87473 15.2919L3.375 12.375ZM0.725426 6.29753C0.336029 6.44611 0.076976 6.78495 0.0126218 7.18738C-0.0528231 7.58929 0.141876 7.99226 0.465828 8.22474L4.14002 10.851L17.1919 0.792751L5.6518 11.9306L10.666 15.5092C10.9894 15.7428 11.4006 15.8069 11.7889 15.6995C12.157 15.5953 12.4586 15.2987 12.6097 14.9368L17.9691 0.537287C18.0345 0.389246 17.9914 0.220361 17.8835 0.114541C17.7755 0.00872082 17.6026 -0.0335003 17.451 0.0295642L0.725426 6.29753Z"
      fill="#17AACF"
    />
  </svg>
);

export default Plane;
