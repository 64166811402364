import { createReducer } from "@reduxjs/toolkit";
import {
  CHECKOUT_TAB_SWITCH,
  CHECKOUT_DEFAULT_DATA,
  CHECKOUT_SET_INFO_FORM_DATA,
  CHECKOUT_SET_PAYMENT_FORM_DATA,
  CHECKOUT_SET_PAYPAL_PAYLOAD,
  CHECKOUT_SET_CREDIT_CARD_PAYLOAD,
  CHECKOUT_SET_BRAINTREE_TOKEN,
  CHECKOUT_SET_LAST_CHECKOUT_TITLE,
  CHECKOUT_RESET_CHECKOUT
} from '../constants/ActionTypes';
import {
  CheckoutState,
  CheckoutAction,
} from '../types';

const initialState: CheckoutState = {
  activeTab: 0,
  infoFormValues: {
    customer: {
      email: "",
      phone: "",
    },
    shipping: {
      firstName: "",
      lastName: "",
      street: "",
      appartment: "",
      city: "",
      state: "",
      zipCode: "",
    },
  },
  paymentFormValues: {
    payment: {
      paymentMethod: 'creditCard',
      billingAddressOption: 'same',
      cardNumber: '',
      cardName: '',
      expirationDate: '',
      securityCode: '',
    },
    billing: {
      firstName: "",
      lastName: "",
      street: "",
      appartment: "",
      city: "",
      state: "",
      zipCode: "",
    },
  },
  paypalPayload: null,
  creditCardPayload: null,
  braintreeToken: null,
  lastCheckoutTitle: null,
}

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(CHECKOUT_DEFAULT_DATA, (state: CheckoutState) => {
      return state;
    })
    .addCase(CHECKOUT_TAB_SWITCH, (state: CheckoutState, action: CheckoutAction) => {
      state.activeTab = action.payload;
    })
    .addCase(CHECKOUT_SET_INFO_FORM_DATA, (state: CheckoutState, action: CheckoutAction) => {
      state.infoFormValues = action.payload;
    })
    .addCase(CHECKOUT_SET_PAYMENT_FORM_DATA, (state: CheckoutState, action: CheckoutAction) => {
      state.paymentFormValues = action.payload;
    })
    .addCase(CHECKOUT_SET_PAYPAL_PAYLOAD, (state: CheckoutState, action: CheckoutAction) => {
      state.paypalPayload = action.payload;
    })
    .addCase(CHECKOUT_SET_CREDIT_CARD_PAYLOAD, (state: CheckoutState, action: CheckoutAction) => {
      state.creditCardPayload = action.payload;
    })
    .addCase(CHECKOUT_SET_BRAINTREE_TOKEN, (state: CheckoutState, action: CheckoutAction) => {
      state.braintreeToken = action.payload;
    })
    .addCase(CHECKOUT_SET_LAST_CHECKOUT_TITLE, (state: CheckoutState, action: CheckoutAction) => {
      state.lastCheckoutTitle = action.payload;
    })
    .addCase(CHECKOUT_RESET_CHECKOUT, (state: CheckoutState) => {
      state = initialState;
      return state;
    })
})

export default reducer;
