import window from 'global';
import { urlifyName } from 'utils/drumUtils';
import { DlpCollectionDetails, LinkTypeEnum } from 'graphqlQueries';
import { getRoutes } from 'constants/routes';
import { generatePath } from 'react-router-dom';

export enum LinkTypes {
  collection,
  offer,
  customLink,
  medialink,
  videoProduct,
  classicProduct,
}

export const getLinkType = (item) => {
  if (item?.collection || item?.type === LinkTypeEnum.Collection)
    return LinkTypes.collection;
  else if (item?.offer || item?.type === LinkTypeEnum.Offer)
    return LinkTypes.offer;
  else if (
    item?.subType === LinkTypeEnum.Video ||
    item?.subType === LinkTypeEnum.Audio ||
    item?.type === LinkTypeEnum.Video ||
    item?.type === LinkTypeEnum.Audio
  )
    return LinkTypes.medialink;
  else if (item?.type === LinkTypeEnum.VideoProduct)
    return LinkTypes.videoProduct;
  return LinkTypes.customLink;
};

export const preprocessUrl = (urlString) => {
  if (!urlString) return '';
  const prefixedUrlString =
    urlString.indexOf('http') === 0 ? urlString : `https://${urlString}`;
  return prefixedUrlString;
};

export const getLandingURL = (
  item: any,
  username = '',
  collectionData?: Partial<DlpCollectionDetails>,
  isCustomDomain = false,
  queryParams?: any
) => {
  const routes = getRoutes(isCustomDomain);
  const urlTitle = urlifyName(item?.name || item?.title);
  let queryTail = collectionData ? `?cFromLp=1` : `?fromLp=1`;
  let path;
  switch (item?.type) {
    case LinkTypeEnum.Collection:
      queryTail += `&linkId=${item?.id}`;
      path = generatePath(routes.COLLECTION_PAGE, {
        username,
        collectionTitle: urlTitle,
        collectionId: item?.opprortunity_group_id,
      });
      return `${path}${queryTail}`;
    case LinkTypeEnum.Offer: {
      queryTail += `&linkId=${queryParams?.linkId || item?.id}`;
      const formattedTitle = urlifyName(item?.name || item?.title);
      if (collectionData) {
        const formattedTitle = urlifyName(item?.name);
        const formattedCollectionName = urlifyName(collectionData?.name);
        const path = generatePath(routes.OFFER_PAGE_FROM_COLLECTION, {
          username,
          collectionTitle: formattedCollectionName,
          collectionId: collectionData.id,
          offerTitle: formattedTitle,
          offerId: item?.id,
        });
        return `${path}${queryTail}`;
      } else {
        const path = generatePath(routes.OFFER_PAGE, {
          username,
          offerTitle: formattedTitle,
          offerId: item?.opportunity_id || item?.id,
        });
        return `${path}${queryTail}`;
      }
    }
    case LinkTypeEnum.VideoProduct:
    case LinkTypeEnum.DigitalProduct:
    case LinkTypeEnum.PhysicalProduct: {
      queryTail += `&linkId=${queryParams?.linkId || item?.id}`;
      const formattedCollectionName = urlifyName(collectionData?.name);
      path = collectionData
        ? generatePath(routes.PURCHASE_PAGE_FROM_COLLECTION, {
            username,
            title: urlTitle,
            collectionTitle: formattedCollectionName,
            collectionId: collectionData.id,
            id: item?.id,
          })
        : generatePath(routes.PURCHASE_PAGE, {
            username,
            title: urlTitle,
            id: item?.id,
          });
      return `${path}${queryTail}`;
    }
    default:
      return preprocessUrl(item?.url);
  }
};

export const sendAnalytics = (item, url, user: any) => {
  const cardId =
    item?.type === LinkTypeEnum.Collection
      ? item?.collection.id
      : item?.offer
      ? item?.offer.id
      : item?.id;
  const data = {
    applicationSource: 'DLP',
    LinkType:
      item?.type === LinkTypeEnum.Collection
        ? 'Drum Collection'
        : item?.type === LinkTypeEnum.Offer
        ? 'Drum Link'
        : 'Custom',
    LinkID: item?.id || '',
    CardID: cardId || '',
    LinkTitle: item?.name || item?.title || '',
    LinkURL: getLandingURL(item, user),
    source: 'LinkPage',
  };
  if (window) {
    (window as any)?.analytics?.track('Link Clicked', data);
  }
};
