import React, { FC, useState } from 'react';
import { Swiper } from 'uikit';
import { Plane } from 'assets/svg';
import './styles.scss';
import {
  Container,
  SwiperContainer,
  Slide,
  OfferImage,
  ShareButtonContainer,
  GalleryButton,
  CopyTooltip,
} from './styles';

interface ISwiperData {
  onCopy?: () => void;
  tooltipTimeout?: number;
  images?: Array<string>;
}

const DEFAULT_TOOLTIP_TIMEOUT = 1500;

const SwiperComponent: FC<ISwiperData> = ({
  onCopy,
  images = [],
  tooltipTimeout = DEFAULT_TOOLTIP_TIMEOUT,
}) => {
  const [showCopyTooltip, setShowCopyTooltip] = useState<boolean>(false);

  const handleCopy = () => {
    setShowCopyTooltip(true);
    setTimeout(() => setShowCopyTooltip(false), tooltipTimeout);
    onCopy?.();
  };

  return (
    <Container>
      {onCopy && (
        <ShareButtonContainer>
          <GalleryButton onClick={handleCopy}>
            <Plane />
            {showCopyTooltip && <CopyTooltip>Copied!</CopyTooltip>}
          </GalleryButton>
        </ShareButtonContainer>
      )}

      <SwiperContainer>
        <Swiper showPagination={images?.length > 1}>
          {images?.map((photo) => (
            <Slide>
              <OfferImage src={photo} alt="slide" />
            </Slide>
          ))}
        </Swiper>
      </SwiperContainer>
    </Container>
  );
};

export default SwiperComponent;
