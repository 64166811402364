import { createContext, useContext } from 'react';
import { useParams } from 'react-router-dom';

interface UserNameProps {
  username?: string | null;
}

export const UserNameContext = createContext<UserNameProps>({
  username: null
});

export const useUsername = () => {
  const { username: usernameFromContext } = useContext(UserNameContext);
  const { username: usernameFromParam } = useParams<any>();

  return {
    username: usernameFromContext || usernameFromParam || null,
    serverUserNameIsUsed: !!usernameFromContext,
    usernameForRoutes: usernameFromParam || ''
  };
};
