import { navigator, document } from 'global';

export const isIOS = () => {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator?.platform)
  
  // iPad on iOS 13 detection
  || (navigator?.userAgent?.includes("Mac") && "ontouchend" in (document || {}))
  || (navigator?.userAgent?.includes("iPhone") && "ontouchend" in (document || {}));
  
}

export const isAndroid = () => {
  return navigator?.userAgent?.toLowerCase()?.indexOf("android") > -1;
}
