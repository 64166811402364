import React, { FC, useState, useEffect } from 'react';
import Big from 'big-js';
import omitDeep from 'omit-deep-lodash';
import { generatePath } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FormikErrors } from 'formik';
import Braintree from 'braintree-web';
import Modal, { ModalProps } from 'uikit/Modal';
import { RootState } from 'redux/store';
import actions from 'redux/actions';
import { getLandingURL } from 'helpers/LinkItem';
import GeneralLayout from 'layouts/GeneralLayout';
import MetaData, {
  makeOfferPhotoThumbFromData,
  makeAvatarFromMeta,
  getFullName,
  makeAvatarImageOriginal,
  makeAvatarSubstituteLink,
} from 'components/MetaData/MetaData';
import ReturnLink from 'components/ReturnLink';
import Cart from './components/Cart';
import Information from './components/Information';
import {
  SchemaTypeNonPhysical as InfoSchemaTypeNonPhysical,
  SchemaTypePhysical as InfoSchemaTypePhysical,
} from './components/Information/validationSchema';
import Payment, {
  SchemaTypeNonPhysical,
  SchemaTypePhysical,
} from './components/Payment';
import { useUsername } from '../../context/UserNameContext';
import { useRedirection } from '../../utils/serverEffectActions';
import { getRoutes } from 'constants/routes';
import {
  sendProvidedOrderDetailsAnalytics,
  sendProvidedInformationAnalytics,
  sendPlacedOrderAnalytics,
  sendPlacedOrderFailedAnalytics,
} from 'helpers/analytics/productsAndLinks';
import { getItem, clearItems } from 'utils/checkoutManagement';
import { generateSavedSessionId, getSavedSessionId } from 'utils/DrumTracking';
import { getTotalWithTaxes, getTotalSum } from './utils';
import { PaymentMethods, AddressOptions } from './types';
import { GeneralPageProps } from 'types';
import {
  useGetLinkPageMetadataQuery,
  useGetBraintreeTokenQuery,
  useCreateVideoProductOrderMutation,
  useCreateDigitalProductOrderMutation,
  useCreatePhysicalProductOrderMutation,
  useCalculateTaxMutation,
  ProductOrder,
  CreateVideoProductOrderMutation,
  CreateDigitalProductOrderMutation,
  CreatePhysicalProductOrderMutation,
  CalculateTaxMutation,
  LinkTypeEnum,
  ImageStyleName,
  ProductTypeEnum,
} from 'graphqlQueries';

import {
  Container,
  StyledTab,
  StyledTabs,
  StyledTabList,
  StyledTabPanel,
  StyledTabChevron,
} from './styles';
import { FullScreenLoader } from 'uikit';
import { getImagePath } from 'helpers';
import { ApolloError } from '@apollo/client';
import { sendPaymentOrderFailedAnalytics } from 'helpers/analytics/productsAndLinks/segment';

const CheckoutPage: FC<GeneralPageProps> = () => {
  //DATA FROM HOOKS

  const { username, serverUserNameIsUsed, usernameForRoutes } = useUsername();
  const routes = getRoutes(serverUserNameIsUsed);

  const activeTab = useSelector((state: RootState) => state.checkout.activeTab);
  const paypalPayload = useSelector(
    (state: RootState) => state.checkout.paypalPayload
  );
  const infoFormValues = useSelector(
    (state: RootState) => state.checkout.infoFormValues
  );
  const paymentFormValues = useSelector(
    (state: RootState) => state.checkout.paymentFormValues
  );

  const dispatch = useDispatch();
  const redirectTo = useRedirection();

  const closeErrorModal = () => {
    setErrorModalData({ ...errorModalData, isOpen: false });
  };
  const openCanceModal = () => {
    setIsCancelModalOpen(true);
  };
  const closeCanceModal = () => {
    setIsCancelModalOpen(false);
  };

  //STATES

  const [items, setItems] = useState<any>([]);
  const [currentTax, setCurrentTax] = useState<number>(0);
  const [returnLink, setReturnLink] = useState<string | undefined>(undefined);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
  const [infoIsDirty, setInfoIsDirty] = useState<boolean>(false);
  const [paymentIsDirty, setPaymentIsDirty] = useState<boolean>(false);
  const [lastOperationNonce, setLastOperationNonce] = useState<string>('');
  const [errorModalData, setErrorModalData] = useState<ModalProps>({
    title: 'Some error has occured',
    isOpen: false,
    mainButton: { text: 'Okay', onClick: closeErrorModal },
    onClose: closeErrorModal,
  });

  //QUERIES AND MUTATIONS

  const { data: tokenResponse } = useGetBraintreeTokenQuery();

  useEffect(() => {
    const itemFromStorage = getItem();
    setItems(itemFromStorage ? [itemFromStorage] : []);

    if (itemFromStorage?.product) {
      setReturnLink(
        getLandingURL(
          itemFromStorage?.product,
          username,
          itemFromStorage?.collectionId
            ? {
              id: itemFromStorage?.collectionId,
              name: itemFromStorage?.collectionName,
            }
            : undefined,
          serverUserNameIsUsed,
          itemFromStorage?.queryParams || {}
        )
      );
    } else {
      setReturnLink(
        generatePath(routes.LINK_PAGE, {
          username: usernameForRoutes,
        })
      );
    }
    // eslint-disable-next-line
  }, []);

  const { data: metaData } = useGetLinkPageMetadataQuery({
    fetchPolicy: 'cache-first',
    variables: {
      username,
    },
    onError: () => {
      redirectTo(routes.PAGE_404);
    },
  });

  const [calculateTaxQuery, { loading: calculateTaxLoading }] =
    useCalculateTaxMutation({
      onCompleted: (data: CalculateTaxMutation) => {
        if (data?.calculateTax?.tax !== null) {
          setCurrentTax(data?.calculateTax?.tax || 0);
        } else {
          setErrorModalData({
            ...errorModalData,
            title: 'Got error calculating tax',
            isOpen: true,
            message: 'Some error occured while calculating tax.',
          });
        }
      },
      onError: (error) => {
        setErrorModalData({
          ...errorModalData,
          title: 'Got error calculating tax',
          isOpen: true,
          message: 'Some error occured while calculating tax.',
        });
      },
    });

  const formOrderAnaylticsData = (
    data: ProductOrder,
    type?: ProductTypeEnum
  ) => {
    const itemFromStorage = getItem();
    const items = itemFromStorage ? [itemFromStorage] : [];

    const billing =
      items[0]?.product?.type === ProductTypeEnum.PhysicalProduct &&
        paymentFormValues.payment.billingAddressOption === AddressOptions.same
        ? (infoFormValues as InfoSchemaTypePhysical)?.shipping
        : paymentFormValues.billing;

    const taxes = Number(currentTax || 0);
    const cartTotal = Number(
      Big(items[0]?.config?.price || items[0]?.product?.price).valueOf() *
      Big(items[0]?.config?.quantity || 1).valueOf()
    );
    const totalPrice = Number(
      Big(currentTax || 0)
        .plus(cartTotal || 0)
        .valueOf()
    );

    if (metaData?.getLinkedPage?.__typename === 'LinkedPage') {
      return {
        meta: metaData?.getLinkedPage,
        buyerEmail: infoFormValues.customer.email,
        buyerPhone: infoFormValues.customer.phone,
        product: items[0]?.product,
        orderID: data?.id,
        productTitle: items[0]?.product?.title,
        productCoverImageURL: getImagePath(
          items[0]?.product?.images[0]?.styles,
          ImageStyleName.Original
        ),
        productPrice: items[0]?.config?.price || items[0]?.product?.price,
        cartTotal,
        taxes,
        totalPrice,
        profileImageURL: makeAvatarFromMeta(metaData?.getLinkedPage),
        productID: items[0]?.product?.id,
        productType: items[0]?.product?.type,
        quantity: items[0]?.config?.quantity,
        selectedOptions: items[0]?.config?.properties,
        shippingAddress: (infoFormValues as InfoSchemaTypePhysical)
          ?.shipping as any,
        billingAddress: billing as any,
        transactionID: lastOperationNonce,
        source: items[0]?.collectionId ? 'collection' : 'linkpage'
      };
    }
    return null;
  };

  const orderSuccessCallback = async (
    data: ProductOrder,
    success: boolean,
    type: ProductTypeEnum
  ) => {
    if (success) {
      const _data = formOrderAnaylticsData(data, type);
      if (_data) {
        sendPlacedOrderAnalytics(_data);
        dispatch(
          actions.checkout.setLastCheckoutTitle(items[0]?.product?.title)
        );
        clearItems();
        let route;

        switch (type) {
          case ProductTypeEnum.VideoProduct:
            route = routes.CHECKOUT_VIDEO_SUCCESS_PAGE;
            break;
          case ProductTypeEnum.DigitalProduct:
            route = routes.CHECKOUT_DIGITAL_SUCCESS_PAGE;
            break;
          case ProductTypeEnum.PhysicalProduct:
            route = routes.CHECKOUT_PHYSICAL_SUCCESS_PAGE;
            break;
        }

        redirectTo(
          generatePath(route, {
            username: usernameForRoutes,
            id: data?.id,
          })
        );
      }
    } else {
      setErrorModalData({
        ...errorModalData,
        title: 'An error occured',
        isOpen: true,
        message: 'Some error occured while creating a transaction.',
      });
    }
  };

  const orderErrorCallback = (error: ApolloError) => {
    setErrorModalData({
      ...errorModalData,
      title: error?.name || 'Error',
      isOpen: true,
      message: error?.message,
    });
    const productData = formProductData(
      paymentFormValues,
      lastOperationNonce
    ) as any;
    orderErrorAnalyticsCallback(
      productData,
      items[0]?.product?.type,
      error?.message
    );
  };

  const orderErrorAnalyticsCallback = (
    data?: any,
    type?: ProductTypeEnum,
    errorMessage?: string | null
  ) => {
    const _data = formOrderAnaylticsData(data, type);
    if (_data) {
      sendPlacedOrderFailedAnalytics({ ..._data, errorMessage });
    }
  };

  const [createVideoProductOrder, { loading: createVideoProductOrderLoading }] =
    useCreateVideoProductOrderMutation({
      onCompleted: async (data: CreateVideoProductOrderMutation) => {
        orderSuccessCallback(
          data?.createVideoProductOrder?.order,
          !!data?.createVideoProductOrder?.success,
          ProductTypeEnum.VideoProduct
        );
      },
      onError: (err) => {
        orderErrorCallback(err);
      },
    });

  const [
    createDigitalProductOrder,
    { loading: createDigitalProductOrderLoading },
  ] = useCreateDigitalProductOrderMutation({
    onCompleted: async (data: CreateDigitalProductOrderMutation) => {
      orderSuccessCallback(
        data?.createDigitalProductOrder?.order,
        !!data?.createDigitalProductOrder?.success,
        ProductTypeEnum.DigitalProduct
      );
    },
    onError: (err) => {
      orderErrorCallback(err);
    },
  });
  const [
    createPhysicalProductOrder,
    { loading: createPhysicalProductOrderLoading },
  ] = useCreatePhysicalProductOrderMutation({
    onCompleted: async (data: CreatePhysicalProductOrderMutation) => {
      orderSuccessCallback(
        data?.createPhysicalProductOrder?.order,
        !!data?.createPhysicalProductOrder?.success,
        ProductTypeEnum.PhysicalProduct
      );
    },
    onError: (err) => {
      orderErrorCallback(err);
    },
  });

  //METHODS

  const getCreateMethodByType = (type: ProductTypeEnum) => {
    switch (type) {
      case ProductTypeEnum.VideoProduct:
        return createVideoProductOrder;
      case ProductTypeEnum.DigitalProduct:
        return createDigitalProductOrder;
      case ProductTypeEnum.PhysicalProduct:
        return createPhysicalProductOrder;
    }
  };

  const handleCancel = () => {
    dispatch(actions.checkout.resetCheckout());
    clearItems();
    returnLink && redirectTo(returnLink);
  };

  const handleItemRemove = (id: string) => {
    dispatch(actions.checkout.resetCheckout());
    clearItems();
    returnLink && redirectTo(returnLink);
  };

  const handleItemEdit = (id: string) => {
    returnLink && redirectTo(returnLink);
  };

  const handleCartContinue = () => {
    if (items[0]?.product?.type === LinkTypeEnum.VideoProduct) {
      sendProvidedOrderDetailsAnalytics({
        product: items[0]?.product,
        productId: items[0]?.product?.id,
        meta: metaData?.getLinkedPage,
        requestText: items[0]?.notes,
        source: items[0]?.collectionId ? 'collection' : 'linkpage',
      });
    }

    sendProvidedOrderDetailsAnalytics({
      product: items[0]?.product,
      productId: items[0]?.product?.id,
      meta: metaData?.getLinkedPage,
      requestText: items[0]?.notes,
      quantity: items[0]?.config?.quantity,
      selectedOptions: items[0]?.config?.properties,
      source: items[0]?.collectionId ? 'collection' : 'linkpage',
    });

    dispatch(actions.checkout.switchCheckoutTab(1));
  };

  const calculateTax = (
    values?: SchemaTypeNonPhysical | SchemaTypePhysical
  ) => {
    const billing = !values
      ? (infoFormValues as InfoSchemaTypePhysical)?.shipping
      : values?.billing;

    if (!items?.length || !billing?.state || !billing.zipCode) {
      return;
    }

    calculateTaxQuery({
      variables: {
        input: {
          productId: items[0]?.product?.id,
          quantity: Number(
            items[0]?.config?.quantity || items[0]?.product?.quantity || 1
          ),
          unit_price: items[0]?.config?.price || items[0]?.product?.price || 0,
          to_state: billing?.state || '',
          to_zip: billing?.zipCode || '',
          shipping: 0, //Change for physical products
        },
      },
    });
  };

  const handleInfoContinue = async (
    validateForm: (
      values?: InfoSchemaTypeNonPhysical | InfoSchemaTypePhysical
    ) => Promise<
      FormikErrors<InfoSchemaTypeNonPhysical | InfoSchemaTypePhysical>
    >,
    setSubmitting: (isSubmitting: boolean) => void,
    values: InfoSchemaTypeNonPhysical | InfoSchemaTypePhysical
  ) => {
    setSubmitting(true);
    validateForm(values).then(async (errors) => {
      setInfoIsDirty(true);
      const errorList = !!Object.values(errors)?.length;

      if (!errorList) {

        const analytics = (window as any)?.analytics;
        analytics?.identify?.(values.customer.email);

        sendProvidedInformationAnalytics({
          product: items[0]?.product,
          productId: items[0]?.product?.id,
          meta: metaData?.getLinkedPage,
          buyerEmail: values.customer.email,
          buyerPhone: values.customer.phone,
          shippingAddress: (values as InfoSchemaTypePhysical)?.shipping as any,
          selectedOptions: items[0]?.config?.properties,
          source: items[0]?.collectionId ? 'collection' : 'linkpage',
        });

        dispatch(actions.checkout.switchCheckoutTab(2));

        if (items[0]?.product?.type === ProductTypeEnum.PhysicalProduct) {
          calculateTax();
        }
      } else {
        // setErrorModalData({
        //   ...errorModalData,
        //   title: 'Please fill all the fields',
        //   isOpen: true,
        //   message: 'Please go back and fill in the missing fields!',
        // });
      }
      setSubmitting(false);
    });
  };

  const formProductData = (
    values: SchemaTypeNonPhysical | SchemaTypePhysical,
    operationNonce: string
  ) => {
    let sessionId = getSavedSessionId();
    if (!sessionId) {
      sessionId = generateSavedSessionId();
    }

    const billing =
      items[0]?.product?.type === ProductTypeEnum.PhysicalProduct &&
        values.payment.billingAddressOption === AddressOptions.same
        ? (infoFormValues as InfoSchemaTypePhysical)?.shipping
        : values.billing;

    const analytics = (window as any)?.analytics;

    return {
      buyerId: analytics?.user?.()?.id?.() || '',
      orderNote:
        items[0]?.product?.type === ProductTypeEnum.VideoProduct
          ? items[0]?.notes || ''
          : undefined,
      customerInfo: {
        email: infoFormValues.customer.email,
        phoneNumber: infoFormValues.customer.phone,
      },
      billingAddress: {
        firstName: billing.firstName || '',
        lastName: billing.lastName || '',
        street: billing.street || '',
        appartment: billing.appartment || '',
        city: billing.city || '',
        state: billing.state || '',
        zipCode: billing?.zipCode || '',
      },
      shippingAddress:
        items[0]?.product?.type === ProductTypeEnum.PhysicalProduct
          ? (infoFormValues as InfoSchemaTypePhysical).shipping
          : undefined,
      taxes: (currentTax || '0.00').toString(),
      transactionInput: {
        braintreeNonce: operationNonce,
        currentProduct: {
          id: items[0]?.product?.id,
          title: items[0]?.product?.title,
          description: items[0]?.product?.description,
          price: Number(getTotalSum(items) || 0),
          images: omitDeep(items[0]?.product?.images, '__typename'),
          digitalProductFilePath:
            items[0]?.product?.type === ProductTypeEnum.DigitalProduct
              ? items[0]?.product?.file_link
              : undefined,
          digitalProductFileType:
            items[0]?.product?.type === ProductTypeEnum.DigitalProduct
              ? items[0]?.product?.file_format
              : undefined,
          digitalProductFileName:
            items[0]?.product?.type === ProductTypeEnum.DigitalProduct
              ? items[0]?.product?.file_name
              : undefined,
          physicalProductQuantity:
            items[0]?.product?.type === ProductTypeEnum.PhysicalProduct
              ? Number(items[0]?.config?.quantity) || 1
              : undefined,
          physicalProductCombination:
            items[0]?.product?.type === ProductTypeEnum.PhysicalProduct &&
              items[0]?.product?.properties?.options?.length
              ? {
                id: items[0]?.config?.id,
                price:
                  items[0]?.config?.price || items[0]?.product?.price || 0,
                properties: items[0]?.config?.properties?.length
                  ? omitDeep(items[0]?.config?.properties || [], '__typename')
                  : [],
              }
              : undefined,
        },
      },
      linkPageViewId: sessionId,
      ...(items[0]?.collectionId
        ? {
          conversionFromCollectionId: items[0]?.collectionId,
          conversionFromCollectionLinkId: items[0]?.collectionLinkId,
        }
        : {}),
    };
  };

  const handleStateChange = async (
    values: SchemaTypeNonPhysical | SchemaTypePhysical
  ) => {
    if (items[0]?.product?.type === ProductTypeEnum.PhysicalProduct) return;
    calculateTax(values);
  };
  const handleZipChange = async (
    values: SchemaTypeNonPhysical | SchemaTypePhysical
  ) => {
    if (
      items[0]?.product?.type === ProductTypeEnum.PhysicalProduct ||
      (values?.billing?.zipCode || '').length !== 5
    )
      return;
    calculateTax(values);
  };

  const handleSubmit = async (
    validateForm: (
      values?: SchemaTypeNonPhysical | SchemaTypePhysical
    ) => Promise<FormikErrors<SchemaTypeNonPhysical | SchemaTypePhysical>>,
    setSubmitting: (isSubmitting: boolean) => void,
    values: SchemaTypeNonPhysical | SchemaTypePhysical
  ) => {
    setSubmitting(true);
    validateForm(values).then(async (errors) => {
      setPaymentIsDirty(true);
      const errorList = !!Object.values(errors)?.length;
      if (!errorList) {
        let operationNonce;

        if (values?.payment?.paymentMethod === PaymentMethods.payPal) {
          if (!paypalPayload?.nonce) {
            setErrorModalData({
              ...errorModalData,
              title: 'Paypal login is required.',
              isOpen: true,
              message:
                'Please login into paypal using paypal checkout button to complete a transaction.',
            });
            return;
          }

          if (!items?.length) {
            setErrorModalData({
              ...errorModalData,
              title: 'The cart is empty.',
              isOpen: true,
              message: 'Please add items to your cart.',
            });
            return;
          }

          operationNonce = paypalPayload.nonce;
          const create = getCreateMethodByType(items[0]?.product?.type);
          setLastOperationNonce(operationNonce);
          create?.({
            variables: {
              input: formProductData(values, operationNonce) as any,
            },
          });
        } else {
          // NOTE: DF change
          const braintreeClient = await Braintree.client.create({
            authorization: tokenResponse?.braintreeToken.token as string,
          });

          // const shipping =
          //   values.payment.shippingAddress === AddressOptions.same
          //     ? infoFormValues.billing
          //     : values.billing;
          const shipping = values.billing;

          const data = {
            creditCard: {
              amount: Number(getTotalWithTaxes(items) || 0),
              number: values.payment.cardNumber,
              cvv: values.payment.securityCode,
              expirationDate:
                values.payment.expirationDate.slice(0, 2) +
                '/' +
                values.payment.expirationDate.slice(2),
              billingAddress: {
                postalCode: shipping.zipCode || '',
                streetAddress: shipping.street,
                countryName: 'United States',
                countryCodeAlpha2: 'US',
                countryCodeAlpha3: 'USA',
                countryCodeNumeric: '840',
              },
              options: {
                validate: true,
              },
            },
          };

          braintreeClient.request(
            {
              endpoint: 'payment_methods/credit_cards',
              method: 'post',
              data: data,
            },
            async function (requestErr, response) {
              if (requestErr) {
                sendPaymentOrderFailedAnalytics({
                  meta: metaData?.getLinkedPage,
                  productId: items[0]?.product?.id,
                  buyerEmail: infoFormValues.customer.email,
                  productType: items[0]?.product?.type,
                  error: requestErr.message,
                })
                setErrorModalData({
                  ...errorModalData,
                  title: 'An error occured',
                  isOpen: true,
                  message: requestErr.message,
                });
                return;
              }

              dispatch(actions.checkout.setCreditCardPayload(response));

              const {
                creditCards: [{ nonce: operationNonce }],
              } = response;

              const create = getCreateMethodByType(items[0]?.product?.type);
              setLastOperationNonce(operationNonce);
              create?.({
                variables: {
                  input: formProductData(values, operationNonce) as any,
                },
              });
            }
          );
        }
      } else {
        setErrorModalData({
          ...errorModalData,
          title: 'Please fill all the fields',
          isOpen: true,
          message: 'Please go back and fill in the missing fields!',
        });
      }
      setSubmitting(false);
    });
  };

  useEffect(() => {
    if (tokenResponse) {
      dispatch(
        actions.checkout.setBraintreeToken(
          tokenResponse?.braintreeToken?.token || null
        )
      );
    }
    // eslint-disable-next-line
  }, [tokenResponse]);

  const helmetMeta = React.useMemo(() => {
    const savedItem = getItem();
    const meta = metaData?.getLinkedPage;
    const fullName = getFullName(meta);
    const title = fullName
      ? `${fullName} | ${savedItem?.product?.title || 'Checkout'}`
      : `Checkout`;

    return {
      offerPhotoThumb: makeOfferPhotoThumbFromData(meta),
      avatarImage: makeAvatarFromMeta(meta),
      avatarImageOriginal: makeAvatarImageOriginal(meta),
      avatarSubstituteLink: makeAvatarSubstituteLink(meta),
      title,
      metaTitle: title,
      metaDescription: '',
    };
  }, [metaData]);

  return (
    <>
      {(createVideoProductOrderLoading ||
        createDigitalProductOrderLoading ||
        createPhysicalProductOrderLoading) && <FullScreenLoader />}
      <MetaData {...helmetMeta} />
      <GeneralLayout
        showLoader={!metaData}
        metaData={metaData?.getLinkedPage}
        outerContent={<>{!!returnLink && <ReturnLink to={returnLink} />}</>}
        usePageStyles={true}
      >
        <Container>
          <StyledTabs
            selectedIndex={activeTab}
            onSelect={(index) =>
              index < activeTab &&
              dispatch(actions.checkout.switchCheckoutTab(index))
            }
          >
            <StyledTabList>
              <StyledTab styles={metaData?.getLinkedPage?.template_preset}>
                Cart
              </StyledTab>
              <StyledTabChevron
                styles={metaData?.getLinkedPage?.template_preset}
              />
              <StyledTab styles={metaData?.getLinkedPage?.template_preset}>
                Information
              </StyledTab>
              <StyledTabChevron
                styles={metaData?.getLinkedPage?.template_preset}
              />
              <StyledTab styles={metaData?.getLinkedPage?.template_preset}>
                Payment
              </StyledTab>
            </StyledTabList>
            <StyledTabPanel>
              <Cart
                items={items}
                onContinue={handleCartContinue}
                onCancel={openCanceModal}
                onItemEdit={handleItemEdit}
                onItemRemove={handleItemRemove}
                templateStyles={metaData?.getLinkedPage?.template_preset}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <Information
                items={items}
                inputStyles={metaData?.getLinkedPage?.link_style}
                onContinue={handleInfoContinue}
                onCancel={openCanceModal}
                shouldValidateOnChange={!!infoIsDirty}
                templateStyles={metaData?.getLinkedPage?.template_preset}
              />
            </StyledTabPanel>
            <StyledTabPanel>
              <Payment
                tax={currentTax}
                items={items}
                inputStyles={metaData?.getLinkedPage?.link_style}
                onSubmit={handleSubmit}
                onCancel={openCanceModal}
                onStateChange={handleStateChange}
                onZipChange={handleZipChange}
                shouldValidateOnChange={!!paymentIsDirty}
                taxIsCalculating={calculateTaxLoading}
                templateStyles={metaData?.getLinkedPage?.template_preset}
              />
            </StyledTabPanel>
          </StyledTabs>
        </Container>
      </GeneralLayout>

      <Modal {...errorModalData}></Modal>

      <Modal
        isOpen={isCancelModalOpen}
        onClose={closeCanceModal}
        title={'Are you sure?'}
        message={'Are you sure you wish to cancel your order?'}
        mainButton={{
          text: 'Yes, cancel my order',
          onClick: handleCancel,
        }}
        secondaryButton={{
          text: 'No, continue checking out',
          onClick: closeCanceModal,
        }}
      />
    </>
  );
};

export default CheckoutPage;
