const colors = {
  danger: '#f95373',
  main: '#008bff',
  mainAlt: '#0074FF',
  secondary: '#767676',
  empty: '#e9e9e9',
  success: '#05ce7c',
  warning: '#fa9900',
  info: '#0063b5',
  black: '#000',
  white: '#fff',
  placeholder: '#929292',
  grey: '#646464',
  lightGrey: '#C4C4C4',
  lightOrange: '#ff8500',
  blackPattern: '#013040',
  green: '#01aa65',
  pink: '#F94780',
  gold: '#BA935D',

  // Updated theme colors
  accentMainColor: '#17AACF',
  accentSecondaryColor: '#00B3FF',
  alertColor: '#F95373',
  backgroundColor: '#f6f8fc',
  borderColor: '#d2d2d2',
  borderColorAlt: '#eaeaea',
  inputColor: '#b4b4b4',
  subtitleColor: '#959595',
  bodyTextColor: '#727272',
  subTitlesTextColor: '#676767',
  snippetCollapseBg: '#F0F0F0',
  snippetCollapseArrowColor: '#020203',
  link: '#3398ff',
  watermark: '#A6A6A6',
  boxShadowDefault: '#AEADAD',
  radioBorder: '#ccc',
  radioBg: '#fff',
  radioFillBg: '#00A3C6',
  radioActiveLabel: '#F94780',
  colorLabelColor: '#bfbfbf',
  colorLabelInner: '#a9a9a9',

  //DLP constants
  dlpMainBgColor: '#fff',
  dlpDefaultTextColor: '#000',
  dlpDefaultBgColor: 'linear-gradient(172.22deg, rgba(154, 154, 154, 0.3) -5.43%, rgba(196, 196, 196, 0) 68.53%);',
  dlpDefaultBgImage: '',
  dlpDefaultFont: 'Raleway',
  dlpDefaultCardBgColor: '#fff',
  dlpDefaultCardTextColor: '#000',
  dlpDefaultUserTextColor: '#000',
  dlpDefaultButtonBgColor: '#00a3c6',
  dlpDefaultButtonTextColor: '#fff',
  dlpDefaultButtonCornerRadius: '8px',
  dlpDefaultButtonBgActiveColor: '#006fcc',
  dlpDefaultButtonTextActiveColor: '#fff',
  dlpAvatarSubstituteTextColor: '#00A3C6',
  dlpAvatarSubstituteBg: '#fff',
  dlpAvatarContainerBg: 'linear-gradient(150.02deg, #17AACF 7.56%, #F94780 92.08%)',
  dlpLinkItemShadowColor: 'rgba(0, 0, 0, 0.1)',
  dlpLinkItemBusinessLogoShadowColor: 'rgba(33, 45, 65, 0.14)',
  dlpLinkItemBusinessLogoBg: 'rgba(33, 45, 65, 0.14)',
  dlpLinkItemBusinessLogoBorderColor: 'rgba(33, 45, 65, 0.14)',
  dlpLinkItemImageFilterColor: 'rgba(0, 0, 0, 0.2)',
  dlpLinkItemEmptyCollectionBg: '#C4C4C4',
  dlpLinkItemEmptyCollectionBoxShadowColor: 'rgba(0, 0, 0, 0.25)',
  dlpLinkItemCollectionDecorationShadowColor: 'rgba(150, 150, 150, 0.25)',
  dlpOpportunityLogoBorderColor: '#fff',
  dlpOpportunityLogoBgColor: '#fff',
  dlpOpportunityLogoShadowColor: 'rgba(33, 45, 65, 0.14)',
  dlpOpportunityButtonShadowColor: 'rgba(14, 23, 56, 0.15)',
  dlpOpportunityOpenLinkColor: '#00A3C6',
  dlpFormInputBg: '#F7F9FC',
  dlpFormLabelColor: '#949494',
  dlpFormInputColor: '#000',

  //DLP UPGRADE PAGE
  dlpUpgradeLoaderBg: '#fff',
  dlpUpgradeLoaderTextColor: '#656565',

  //DLP OFFER PAGE
  dlpOfferTooltipBg: '#000',
  dlpOfferTooltipTextColor: '#fff',

  //DLP UPGRADE PAGE EMAIL CLAIM
  dlpEmailClaimContainerBg: '#fff',
  dlpEmailClaimContainerInputTextColor: '#757575',
  dlpEmailClaimContainerButtonBg: '#00a3c6',
  dlpEmailClaimContainerButtonTextColor: '#fff',
  dlpEmailClaimFinishedEmailContainerBorderColor: '#C9C9C9',
  dlpEmailClaimFinishedEmailContainerTextColor: '#000',
  dlpEmailClaimInfoTextColor: '#000',
  dlpEmailClaimLearnMoreTextColor: '#878787',

  //DLP 404
  dlp404ButtonBg: '#fff',
  dlp404ButtonShadowColor: 'rgba(250, 52, 117, 0.51)',
  dlp404FooterBg: '#fff',
  dlp404LinkColor: '#F95373',
  dlp404CopyrightTextColor: '#495870',

  //Purchase
  dlpPurchaseOutOfStockColor: '#D60000',
  dlpPurchaseCancelButtonColor: '#00A3C6',

  //Payment
  dlpFormSeparatorColor: '#CACACA',

  //Invite page
  dlpInviteAvatarBg: 'rgba(0, 105, 128, 0.1)',
  dlpInviteAvatarColor: '#2A3039',
  dlpInviteHeaderCaptionColor: '#495870',
  dlpInviteLogoBorderColor: '#fff',
  dlpInviteLogoBg: '#fff',
  dlpInviteLogoBoxShadowColor: 'rgba(33, 45, 65, 0.14)',

  //Wide footer
  dlpWideFooterBg: '#013040',
  dlpWideFooterColor: '#fff',
};

export default colors;
