import React, { FC } from 'react';
import IconProps from './IconProps';

const CloseRoundIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    fill="none"
    viewBox="0 0 22 22"
  >
    <path
      fill="#959595"
      fillRule="evenodd"
      d="M14.854 7.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z"
      clipRule="evenodd"
    />
    <path
      fill="#959595"
      fillRule="evenodd"
      d="M14.854 14.854a.5.5 0 01-.708 0l-7-7a.5.5 0 11.708-.708l7 7a.5.5 0 010 .708z"
      clipRule="evenodd"
    />
    <circle cx="11" cy="11" r="10.5" stroke="#959595" />
  </svg>
);

export default CloseRoundIcon;
