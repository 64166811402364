import styled from 'styled-components';
import { PrimaryButton, PrimaryButtonMixin, LinkTextMixin } from 'uikit';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  margin: 25px auto 25px auto;
  border: none;
  border: none !important;
  max-width: 412px;
  
`;

export const Title = styled.p`
  font-size: 28px;
  margin-bottom: 23px;
  * {
    font-family: inherit;
  }

  & > * {
    width: 100%;
  }
`;

export const SubTitle = styled.p`
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 38px;
`;

export const Paragraph = styled.p`
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin-bottom: 45px;
  max-width: 100%;
  overflow-wrap: break-word;
`;
export const Email = styled.span`
  display: inline-block;
  margin-top: 10px;
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  max-width: 100%;
  overflow-wrap: break-word;
`;

export const BackLink = styled.a`
  ${LinkTextMixin}
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  cursor: pointer;
`;

export const BackButton = styled(PrimaryButton)`
  width: 100%;
  height: 56px;
  border-radius: 8px;
  font-family: inherit;
`;

export const DownloadButton = styled.a`
  ${PrimaryButtonMixin}
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  border-radius: 8px;
  font-family: inherit;
  margin: 0 0 45px 0;
`;
