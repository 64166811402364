import { ImageStyle, Maybe, ImageStyleName } from 'graphqlQueries'
import window from 'global';

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
export const debounce = (method, delay) => {
  clearTimeout(method._tId);
  method._tId = setTimeout(function () {
    method();
  }, delay);
};
export const urlifyName = (name) => {
  const EMPTY = '~';

  if (!name) {
    return EMPTY;
  }

  const urlifiedValue = String(name)
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9-_~\s]/g, ' ')
    .split(' ')
    .filter(Boolean)
    .join('-');

  return urlifiedValue || EMPTY;
};

export const unurlifyName = (name) => {
  const EMPTY = '~';

  if (!name) {
    return EMPTY;
  }

  const unurlifiedValue = String(name)
    .trim()
    .split('-')
    .filter(Boolean)
    .map((chunk) => (chunk || '').toUpperCase())
    .join(' ');

  return unurlifiedValue || EMPTY;
};
export const log =
  window?.location?.hostname !== 'localhost' && window?.location?.hostname !== 'share.stg.drum.io'
    ? () => null
    : console.log.bind(console);

type ImageStyleFragment = Maybe<Pick<ImageStyle, 'path' | 'name'>>;

export const getImageStyle = (
  imageStyles: Maybe<Array<ImageStyleFragment>> | undefined,
  imageType: ImageStyleName
) => imageStyles?.find((style) => style?.name === imageType);

export const getExpirationDate = (date?: string | Date | null) => {
  if (!date) {
    return '';
  }

  const _date = new Date(date);

  const month = _date.getMonth();
  const day = _date.getDate();
  const year = _date.getFullYear();

  return `${month}/${day}/${year}`;
};