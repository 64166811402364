import styled, { css } from 'styled-components';
import { Props, ContainerLinkBase } from 'uikit/LinkItems/LinkItems';

export const IFrameMixin = css`
  width: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const Container = styled(ContainerLinkBase)(
  ({ styles }) => `
  ${styles?.link?.base?.divider?.size ? '' : 'border: none !important;'}
  width: 100%;
  padding-top: ${styles?.link?.mediaLink?.padding?.top || 0}px;
  padding-bottom: ${styles?.link?.mediaLink?.padding?.bottom || 0}px;
  padding-left: ${styles?.link?.mediaLink?.padding?.left || 0}px;
  padding-right: ${styles?.link?.mediaLink?.padding?.right || 0}px;

  margin-left: 0;
  margin-right: 0;

  box-shadow: none;

  &:hover {
    box-shadow: none;
  }

  @media(max-width: 600px) {
    padding-top: ${styles?.link?.mediaLink?.mobilePadding?.top || 0}px;
    padding-bottom: ${styles?.link?.mediaLink?.mobilePadding?.bottom || 0}px;
    padding-left: ${styles?.link?.mediaLink?.mobilePadding?.left || 0}px;
    padding-right: ${styles?.link?.mediaLink?.mobilePadding?.right || 0}px;

    margin-left: 0;
    margin-right: 0;
  }  
`
);

export const ContainerInner = styled.div<Props>(
  ({ styles }) => `
  position: relative;
  padding-top: ${styles?.link?.mediaLink?.padding?.top || 0}px;
  padding-bottom: ${styles?.link?.mediaLink?.padding?.bottom || 0}px;
  padding-left: ${styles?.link?.mediaLink?.padding?.left || 0}px;
  padding-right: ${styles?.link?.mediaLink?.padding?.right || 0}px;

  @media(max-width: 600px) {
    padding-top: ${styles?.link?.mediaLink?.mobilePadding?.top || 0}px;
    padding-bottom: ${styles?.link?.mediaLink?.mobilePadding?.bottom || 0}px;
    padding-left: ${styles?.link?.mediaLink?.mobilePadding?.left || 0}px;
    padding-right: ${styles?.link?.mediaLink?.mobilePadding?.right || 0}px;
  }  

  &.provider-soundcloud {
    height: 138px;
  }

  &.provider-twitch,
  &.provider-youtube,
  &.provider-vimeo {
    padding-top: 56.25%;
  }

  &.provider-spotify {
    min-height: 83px;
    height: 83px;
    box-sizing: border-box;
  }
  
  &.provider-spotify.spotify-podcast {
    min-height: 155px;
    height: 155px;
    box-sizing: border-box;
  }

  &:hover {
    transform: none;
  }
`
);

export const IframeContainer = styled.iframe<Props>(
  ({ styles }) => `
  ${IFrameMixin}
  height: 100% !important;
  border-radius: ${styles?.link?.mediaLink?.borderRadius || 0}px;
`);
