import styled from 'styled-components';
import { LinkProps } from '../../types';
import { Link as RouterLink } from 'react-router-dom';

const Link = styled(RouterLink)<LinkProps>`
  color: ${({ theme, color }: LinkProps) =>
    color ? color : theme.colors.main};
  font-size: ${({ theme, size }: LinkProps) =>
    size ? size + 'px' : theme.size.body1};
`;

export default Link;
