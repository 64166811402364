import React, { FC } from 'react';
import nestedProperty  from 'nested-property';
import { IOption } from '../../types';
import DropDown from 'components/DropDown';
import MenuList from 'components/MenuList';
import { USStates, USStatesDetailed} from 'constants/usStates';
import { useFormikContext } from 'formik';
import { InputContainer, InputGroup, InputBase, InputLabel, FormGroupDivider2, FieldFooter } from 'uikit';
//import { SchemaType as InfoSchemaType } from '../Information/validationSchema';
import { SchemaTypeNonPhysical as PaymentSchemaTypeNonPhysical, SchemaTypePhysical as PaymentSchemaTypePhysical } from '../Payment/validationSchema';
import { Container } from './styles';
import { LinkStyleConfiguration, TemplatePreset } from 'graphqlQueries';

interface Props {
  className?: string;
  inputStyles?: Pick<LinkStyleConfiguration, "background_color" | "color" | "font_family"> | null
  fieldsArray: string[];
  templateStyles?: TemplatePreset;
}

const getState = (value) => {
  const result = USStates.find(item => item?.label?.toLowerCase?.() === value?.toLowerCase?.())?.label ||
         USStatesDetailed.find(item => item?.value?.toLowerCase?.() === value?.toLowerCase?.())?.label ||
         undefined;
  return result;      
}

const AddressForm: FC<Props> = ({ className, inputStyles, fieldsArray, templateStyles }) => {
  // const { values, handleChange, setFieldValue, errors } = useFormikContext<
  //   InfoSchemaType
  // >();
  const { values, handleChange, setFieldValue, errors } = useFormikContext<
  PaymentSchemaTypeNonPhysical | PaymentSchemaTypePhysical
  >();
  return (
    <Container className={className}>
      <InputContainer>
        <InputGroup styles={templateStyles}>
          <InputLabel>First Name</InputLabel>
          <InputBase
            name={fieldsArray[0]}
            onChange={handleChange}
            value={nestedProperty.get(values, fieldsArray[0]) || ""}
            styles={templateStyles}
          />
        </InputGroup>
        <FieldFooter error={nestedProperty.get(errors, fieldsArray[0])} showAlways={false} />
      </InputContainer>

      <InputContainer>
        <InputGroup styles={templateStyles}>
          <InputLabel>Last Name</InputLabel>
          <InputBase
            name={fieldsArray[1]}
            onChange={handleChange}
            value={nestedProperty.get(values, fieldsArray[1]) || ""}
            styles={templateStyles}
          />
        </InputGroup>
        <FieldFooter error={nestedProperty.get(errors, fieldsArray[1])} showAlways={false} />
      </InputContainer>

      <InputContainer>
        <InputGroup styles={templateStyles}>
          <InputLabel>Address</InputLabel>
          <InputBase
            name={fieldsArray[2]}
            onChange={handleChange}
            value={nestedProperty.get(values, fieldsArray[2]) || ""}
            styles={templateStyles}
          />
        </InputGroup>
        <FieldFooter error={nestedProperty.get(errors, fieldsArray[2])} showAlways={false} />
      </InputContainer>

      <InputContainer>
        <InputGroup styles={templateStyles}>
          <InputLabel>Apartment, suite, etc. (optional)</InputLabel>
          <InputBase
            name={fieldsArray[3]}
            onChange={handleChange}
            value={nestedProperty.get(values, fieldsArray[3]) || ""}
            styles={templateStyles}
          />
        </InputGroup>
        <FieldFooter error={nestedProperty.get(errors, fieldsArray[3])} showAlways={false} />
      </InputContainer>

      <InputContainer>
        <InputGroup styles={templateStyles}>
          <InputLabel>City</InputLabel>
          <InputBase
            name={fieldsArray[4]}
            onChange={handleChange}
            value={nestedProperty.get(values, fieldsArray[4]) || ""}
            styles={templateStyles}
          />
        </InputGroup>
        <FieldFooter error={nestedProperty.get(errors, fieldsArray[4])} showAlways={false} />
      </InputContainer>

      <FormGroupDivider2>
        <InputContainer>
          <InputGroup styles={templateStyles}>
            <InputLabel>State</InputLabel>
            <DropDown<IOption>
              options={USStates || []}
              components={{ MenuList }}
              onChange={(option) => {
                const value = getState(option?.value);
                setFieldValue(
                  fieldsArray[5],
                  value
                );
              }}
              value={
                nestedProperty.get(values, fieldsArray[5]) ? 
                {
                  label: nestedProperty.get(values, fieldsArray[5]) || "",
                  value: nestedProperty.get(values, fieldsArray[5]) || "",
                }
                : undefined
              }
            />
          </InputGroup>
          {/* DO NOT DELETE THIS INPUTBASE!!!! It serves as chrome autofill ducttape*/}
          <InputBase
            style={{position: "absolute", right: 9999}}
            name={fieldsArray[5]}
            //onChange={handleChange}
            onChange={(e) => {
              const value = getState(e?.target?.value || "");
              setFieldValue(
                fieldsArray[5],
                value
              );
            }}
            value={nestedProperty.get(values, fieldsArray[5])}
            styles={templateStyles}
          />
          <FieldFooter error={nestedProperty.get(errors, fieldsArray[5])} showAlways={false} />
        </InputContainer>

        <InputContainer>
          <InputGroup styles={templateStyles}>
            <InputLabel>ZIP</InputLabel>
            <InputBase
              name={fieldsArray[6]}
              onChange={(e) => {
                if(!e.target.value.match(/^[0-9]*$/)) return;
                const val = e.target.value;
                setFieldValue(fieldsArray[6], (val).toString());
              }}
              value={nestedProperty.get(values, fieldsArray[6] || "")}
              pattern="[0-9]*"
              min={0}
              max={5}
              styles={templateStyles}
            />
          </InputGroup>
          <FieldFooter error={nestedProperty.get(errors, fieldsArray[6])} showAlways={false} />
        </InputContainer>
      </FormGroupDivider2>
    </Container>
  );
};

export default AddressForm;
