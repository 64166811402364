import React, { FC } from 'react';
import {
  Container,
  ContainerInner,
  CreateLink,
  Info,
  ImageOuter,
  ImageContainer,
  Image,
} from './styles';

interface Props {
  backgroundColor?: string | null;
  textColor?: string | null;
}

const Footer: FC<Props> = ({ backgroundColor, textColor }) => (
  <Container backgroundColor={backgroundColor} textColor={textColor}>
    <ContainerInner>
      <CreateLink href="https://www.drum.io/" target="_blank">
        Create your own page
      </CreateLink>
      <Info>
        <ImageOuter>
          <ImageContainer>
            <Image />
          </ImageContainer>
        </ImageOuter>
      </Info>
    </ContainerInner>
  </Container>
);

export default Footer;
