import React, { FC } from 'react';
import { getImagePath } from 'helpers';
import { ImageStyle, ImageStyleName } from 'graphqlQueries';
import Props from '../../types';
import {
  Container,
  ContainerInner,
  Image,
  Content,
  ContentInner,
  Info,
  BusinessImageContainer,
  BusinessImage,
  BusinessName,
  Description,
} from './styles';

const OfferItem: FC<Props> = ({ item, href, onClick, linkStyles, styles, className }) => {
  const {
    name,
    images,
    business,
    format,
    description,
  } = item;
  const companyImage = getImagePath(business?.images?.[0]?.styles as ImageStyle[]);
  const offerPhotoStyles = images?.[0]?.styles || [];
  const recordImages = [getImagePath(offerPhotoStyles as ImageStyle[], ImageStyleName.Original)];
  const offerSizeClass = format === 'large' ? ' large' : ' small';
  const imagesClass = recordImages?.[0] ? '' : ' no-images';
  const descriptionClass = description ? '' : ' no-description';
  const classSet = `${imagesClass} ${descriptionClass} ${offerSizeClass} ${className}`;

  return (
    <Container
      className={classSet}
      to={href || ""}
      onClick={onClick}
      color={linkStyles?.color || ""}
      font={linkStyles?.font_family || ""}
      backgroundColor={linkStyles?.background_color}
      styles={styles}
    >
      <ContainerInner styles={styles} backgroundColor={linkStyles?.background_color}>
        <Image styles={styles} style={{
          backgroundImage: `url(${recordImages?.[0] || ""})`
        }}></Image>
        <Content styles={styles}>
          <ContentInner styles={styles}>
            <Info>
              {companyImage && (
                <BusinessImageContainer>
                  <BusinessImage src={companyImage} alt="Business logo" />
                </BusinessImageContainer>
              )}

              <BusinessName>{business?.businessName || name}</BusinessName>
            </Info>
            {description && <Description styles={styles}>{name}</Description>}
          </ContentInner>
        </Content>
      </ContainerInner>
    </Container>
  );
};

export default OfferItem;
