import React, { FC, useState, useEffect } from 'react';
import querystring from 'query-string';
import { getRoutes } from 'constants/routes';
import { fDrummerId } from 'utils/idFormatters';
import { useParams, generatePath, useLocation } from 'react-router-dom';
import MetaData, {
  makeOfferPhotoThumbFromData,
  makeAvatarFromMeta,
  getFullName,
  makeAvatarSubstituteLink,
} from 'components/MetaData/MetaData';
import GeneralLayout from 'layouts/GeneralLayout';
import LinksList from 'components/LinksList';
import ReturnLink from 'components/ReturnLink';
import { Container, ListContainer } from './styles';
import DrumTracking from 'utils/DrumTracking';
import { useUsername } from '../../context/UserNameContext';
import { sendViewedProductAnalytics } from 'helpers/analytics/productsAndLinks';
import {
  useGetCollectionDetailsQuery,
  useGetLinkPageMetadataQuery,
  DlpCollectionDetails,
  LinkTypeEnum,
  ImageStyleName,
} from 'graphqlQueries';
import { useRedirection } from 'utils/serverEffectActions';
import { GeneralPageProps } from 'types';

interface IParamTypes {
  username: string;
  collectionTitle?: string;
  collectionId?: string;
}

const CollectionPage: FC<GeneralPageProps> = () => {
  const redirectTo = useRedirection();
  const location = useLocation();
  const { username, usernameForRoutes, serverUserNameIsUsed } = useUsername();
  const routes = getRoutes(serverUserNameIsUsed);
  const queryParams = querystring.parse(location?.search);
  const { collectionId } = useParams<IParamTypes>();
  const [collectionData, setCollectionData] = useState<Partial<DlpCollectionDetails> | undefined>(
    undefined
  );
  const [returnLink, setReturnLink] = useState<string | undefined>(undefined);
  const [trackingIsInitialized, setTrackingIsInitialized] = useState<boolean>(false);

  const { data: metaData } = useGetLinkPageMetadataQuery({
    fetchPolicy: 'cache-first',
    variables: {
      username,
    },
    onError: () => {
      redirectTo(routes.PAGE_404);
    },
  });

  const { data: collectionDataRaw } = useGetCollectionDetailsQuery({
    variables: {
      id: collectionId || '',
    },
    onError: () => {
      redirectTo(routes.PAGE_404);
    },
  });

  const onItemClick = (item: any) => {
    DrumTracking?.onItemClick?.(item);
    DrumTracking?.sendClickAnalytics?.(item);
    if (
      item.type === LinkTypeEnum.VideoProduct ||
      item.type === LinkTypeEnum.DigitalProduct ||
      item.type === LinkTypeEnum.PhysicalProduct
    ) {
      sendViewedProductAnalytics({
        meta: metaData?.getLinkedPage,
        productId: item.id,
        item,
        source: 'collection',
      });
    }
  };
  const onItemImpression = (item: any) => {
    DrumTracking?.onImpressionsChange?.([item]);
  };

  useEffect(() => {
    if (collectionDataRaw) {
      setCollectionData(collectionDataRaw?.dlpGetCollectionDetails as DlpCollectionDetails);
    }
  }, [collectionDataRaw]);

  useEffect(() => {
    if (queryParams?.fromLp) {
      setReturnLink(generatePath(routes.LINK_PAGE, { username: usernameForRoutes }));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (collectionData && metaData) {
      DrumTracking.initLib({
        drummerId: fDrummerId(metaData?.getLinkedPage?.user?.id || ''),
        userId: metaData?.getLinkedPage?.user?.id,
        username,
        user: metaData?.getLinkedPage?.user,
        pageItems: collectionData?.offers?.items,
        socials: null,
        pageType: DrumTracking.PAGE_TYPES.collectionPage,
        collectionId: collectionData.id,
        collectionLinkId: queryParams.linkId,
        isFromLinkPage: !!queryParams.fromLp,
      });
      DrumTracking.session.startSession();
      setTrackingIsInitialized(true);

      (window as any).addEventListener('focus', DrumTracking.onFocus);
      (window as any).addEventListener('blur', DrumTracking.onBlur);

      return () => {
        (window as any).removeEventListener('focus', DrumTracking.onFocus);
        (window as any).removeEventListener('blur', DrumTracking.onBlur);
      };
    }
    // eslint-disable-next-line
  }, [collectionData, metaData]);

  const helmetMeta = React.useMemo(() => {
    const meta = metaData?.getLinkedPage;
    const fullName = getFullName(meta);

    return {
      offerPhotoThumb: makeOfferPhotoThumbFromData(
        collectionDataRaw?.dlpGetCollectionDetails?.offers?.items?.[0]?.images?.[0],
        ImageStyleName.Original
      ),
      avatarImage: makeAvatarFromMeta(meta),
      avatarSubstituteLink: makeAvatarSubstituteLink(meta),
      title: `${fullName} shared ${collectionDataRaw?.dlpGetCollectionDetails?.name} with you`,
      metaTitle: `Drummer ${fullName} shared ${collectionDataRaw?.dlpGetCollectionDetails?.name} with you`,
      metaDescription: `${fullName} prepared a collection for you. Click on any of the offers in collection to see more detail.`,
    };
  }, [metaData, collectionDataRaw]);

  const handleSubscriptionSuccess = (item) => {
    DrumTracking.onSubscriptionSubmit(item);
  }

  return (
    <>
      <MetaData {...helmetMeta} />
      <GeneralLayout
        showLoader={!metaData}
        metaData={metaData?.getLinkedPage}
        subTitle={collectionData?.name || ''}
        description={collectionData?.description || ''}
        usePageStyles={true}
        useCollectionStyles={true}
        outerContent={<>{returnLink && <ReturnLink to={returnLink} />}</>}
      >
        <Container>
          <ListContainer>
            {trackingIsInitialized && (
              <LinksList
                items={collectionData?.offers?.items || []}
                onClick={onItemClick}
                onImpression={onItemImpression}
                userName={usernameForRoutes}
                userId={metaData?.getLinkedPage?.user?.id}
                collectionData={collectionData}
                onSubscriptionSubmitSuccess={handleSubscriptionSuccess}
              />
            )}
          </ListContainer>
        </Container>
      </GeneralLayout>
    </>
  );
};

export default CollectionPage;
