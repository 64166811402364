import styled from 'styled-components';
import { TemplatePreset } from 'graphqlQueries';

interface StyleProps {
  styles?: TemplatePreset;
}

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  font-family: inherit;
`;

export const UserAvatarContainer = styled.div<StyleProps>(
  ({ styles, theme }) => `
  width: 94px;
  height: 94px;
  border-radius: 52px;
  overflow: visible;
  position: relative;
  margin-top: ${(styles?.headerContainer?.innerPagesTop || 0) > 50 ? '-47px' : '48px'};
  background: ${theme.colors.dlpAvatarContainerBg};
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
  ${styles?.avatar?.outerShadowOnRoundAvatar ? `filter: drop-shadow(0px 0px ${styles?.avatar?.outerShadowOnRoundAvatar.radius || 0}px ${styles?.avatar?.outerShadowOnRoundAvatar.color})` : ''};
  border: ${styles?.avatar?.border?.size || 0}px solid ${styles?.avatar?.border?.color || '#ffffff00'};

  @media(max-width: 600px) {
    ${(styles?.headerContainer?.innerPagesTop || 0) > 50 ? '-47px' : '45px'};
  }
`);

export const UserAvatar = styled.img`
  max-width: 100%;
  height: auto;
`;

export const UserAvatarSubstitute = styled.div<StyleProps>(
  ({ styles, theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-size: auto ${styles?.avatar?.type === 'round'? "75%" : "100%"};
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 50%;
  overflow: hidden;
  font-size: 42px;
  font-weight: ${theme.fontWeight.semiBold};
  color: ${theme.colors.dlpAvatarSubstituteTextColor};
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  background-color: ${styles?.avatar?.emptyState?.backgroundColor || theme.colors.dlpAvatarSubstituteBg};
  box-sizing: border-box;

  @media(max-width: 600px) {
    background-size: auto ${styles?.avatar?.type === 'round'? "75%" : "120%"};
  }
`);

export const UserName = styled.p<StyleProps>(
  ({ styles, theme }) => `
  margin-top: 20px;
  height: auto;
  margin-bottom: 0;
  max-width: ${styles?.title?.maxWidth ? `${styles?.title?.maxWidth}px` : '80%'};
  font-family: ${styles?.title?.font || "Raleway"}, "Raleway";
  font-size:  ${styles?.title?.fontSize || 18}px;
  font-weight: ${styles?.title?.fontWeight || theme.fontWeight.bold};
  color: ${styles?.title?.color || styles?.innerPagesContentContainer?.color || "inherit"};
  ${styles?.title?.textBorder ? `text-shadow: -${styles?.title?.textBorder?.size || 0}px 0 ${styles?.title?.textBorder?.color || '#fff'}, 0 ${styles?.title?.textBorder?.size || 0}px ${styles?.title?.textBorder?.color || '#fff'}, ${styles?.title?.textBorder?.size || 0}px 0 ${styles?.title?.textBorder?.color || '#fff'}, 0 -${styles?.title?.textBorder?.size || 0}px ${styles?.title?.textBorder?.color || '#fff'};` : ''}
  text-align: center;
  word-break: break-word;
  
  @media(max-width: 600px) {
    max-width: ${styles?.title?.mobileMaxWidth ? `${styles?.title?.mobileMaxWidth}px` : '80%'};
  }
`);

export const UserDescriptionContainer = styled.div`
  max-width: 80%;
  text-align: center;
`;

export const UserSubTitle = styled.p<StyleProps>(
  ({ styles, theme }) => `
  
  max-width: 100%;
  font-family: ${styles?.description?.font || "Raleway"}, "Raleway";
  font-size:  20px;
  font-weight: ${theme.fontWeight.bold};
  color: ${styles?.title?.color || "inherit"};
  margin-top: 22px;
  margin-bottom: 17px;
  height: auto;
  white-space: pre-line;
  background: transparent;
  border: none;
  line-height: 24px;
`);

export const UserDescription = styled.pre<StyleProps>(
  ({ styles, theme }) => `
  
  max-width: 100%;
  font-family: ${styles?.description?.font || "Raleway"}, "Raleway";
  font-size:  ${styles?.description?.fontSize || 14}px;
  font-weight: ${styles?.description?.fontWeight || theme.fontWeight.medium};
  color: ${styles?.description?.color || "inherit"};
  margin-top: 15px;
  margin-bottom: 0px;
  height: auto;
  white-space: pre-line;
  background: transparent;
  border: none;
  line-height: 24px;
`);
