import {
  ApolloCache,
  ApolloClient,
  HttpLink,
  InMemoryCache,
  concat,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import fetch from 'cross-fetch';
import window from 'global';

const createApolloClient = (isServer: boolean) => {
  // console.log('createApolloClient::isServer', isServer);
  // console.log('createApolloClient::process.env.REACT_APP_GRAPHQL', process.env.REACT_APP_GRAPHQL);
  const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL, fetch });
  const authLink = setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        mode: 'no-cors',
        'Access-Control-Allow-Origin': '*',
      },
    };
  });

  const cache = new InMemoryCache({}).restore(
    !isServer ? (window as any).__APOLLO_STATE__ : {}
  ) as ApolloCache<NormalizedCacheObject>;

  const client = new ApolloClient<NormalizedCacheObject>({
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-first',
      },
    },
    link: concat(authLink, httpLink),
    ssrMode: isServer,
    ssrForceFetchDelay: isServer ? 100 : undefined,
  });
  return { client, cache };
};

export default createApolloClient;
