import { CommissionType } from '../types';

export const MIN_PASSWORD_LENGTH = 6;

export const MONEY: CommissionType = {
  label: '$',
  value: 'money'
};

export const PERCENT: CommissionType = {
  label: '%',
  value: 'percent'
};

export const ENTER_KEY = 'Enter';
