import styled from 'styled-components';

export const Container = styled.div`
  font-family: Raleway;
`;
export const ContainerOuter = styled.div`

`;
export const ContainerInner = styled.div`
  background-color: ${({ theme }) => theme.colors.dlpMainBgColor};
  box-sizing: border-box;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
