export const USStates = [
  {
    "value": "AK",
    "label": "AK"
  },
  {
    "value": "AL",
    "label": "AL"
  },
  {
    "value": "AR",
    "label": "AR"
  },
  {
    "value": "AS",
    "label": "AS"
  },
  {
    "value": "AZ",
    "label": "AZ"
  },
  {
    "value": "CA",
    "label": "CA"
  },
  {
    "value": "CO",
    "label": "CO"
  },
  {
    "value": "CT",
    "label": "CT"
  },
  {
    "value": "DC",
    "label": "DC"
  },
  {
    "value": "DE",
    "label": "DE"
  },
  {
    "value": "FL",
    "label": "FL"
  },
  {
    "value": "GA",
    "label": "GA"
  },
  {
    "value": "GU",
    "label": "GU"
  },
  {
    "value": "HI",
    "label": "HI"
  },
  {
    "value": "IA",
    "label": "IA"
  },
  {
    "value": "ID",
    "label": "ID"
  },
  {
    "value": "IL",
    "label": "IL"
  },
  {
    "value": "IN",
    "label": "IN"
  },
  {
    "value": "KS",
    "label": "KS"
  },
  {
    "value": "KY",
    "label": "KY"
  },
  {
    "value": "LA",
    "label": "LA"
  },
  {
    "value": "MA",
    "label": "MA"
  },
  {
    "value": "MD",
    "label": "MD"
  },
  {
    "value": "ME",
    "label": "ME"
  },
  {
    "value": "MI",
    "label": "MI"
  },
  {
    "value": "MN",
    "label": "MN"
  },
  {
    "value": "MO",
    "label": "MO"
  },
  {
    "value": "MS",
    "label": "MS"
  },
  {
    "value": "MT",
    "label": "MT"
  },
  {
    "value": "NC",
    "label": "NC"
  },
  {
    "value": "ND",
    "label": "ND"
  },
  {
    "value": "NE",
    "label": "NE"
  },
  {
    "value": "NH",
    "label": "NH"
  },
  {
    "value": "NJ",
    "label": "NJ"
  },
  {
    "value": "NM",
    "label": "NM"
  },
  {
    "value": "NV",
    "label": "NV"
  },
  {
    "value": "NY",
    "label": "NY"
  },
  {
    "value": "OH",
    "label": "OH"
  },
  {
    "value": "OK",
    "label": "OK"
  },
  {
    "value": "OR",
    "label": "OR"
  },
  {
    "value": "PA",
    "label": "PA"
  },
  {
    "value": "PR",
    "label": "PR"
  },
  {
    "value": "RI",
    "label": "RI"
  },
  {
    "value": "SC",
    "label": "SC"
  },
  {
    "value": "SD",
    "label": "SD"
  },
  {
    "value": "TN",
    "label": "TN"
  },
  {
    "value": "TX",
    "label": "TX"
  },
  {
    "value": "UT",
    "label": "UT"
  },
  {
    "value": "VA",
    "label": "VA"
  },
  {
    "value": "VI",
    "label": "VI"
  },
  {
    "value": "VT",
    "label": "VT"
  },
  {
    "value": "WA",
    "label": "WA"
  },
  {
    "value": "WI",
    "label": "WI"
  },
  {
    "value": "WV",
    "label": "WV"
  },
  {
    "value": "WY",
    "label": "WY"
  }
];

export const USStatesDetailed = [
  {
    value: 'Alaska',
    label: 'AK',
  },
  {
    value: 'Alabama',
    label: 'AL',
  },
  {
    value: 'Arkansas',
    label: 'AR',
  },
  {
    value: 'American Samoa',
    label: 'AS',
  },
  {
    value: 'Arizona',
    label: 'AZ',
  },
  {
    value: 'California',
    label: 'CA',
  },
  {
    value: 'Colorado',
    label: 'CO',
  },
  {
    value: 'Connecticut',
    label: 'CT',
  },
  {
    value: 'District of Columbia',
    label: 'DC',
  },
  {
    value: 'Delaware',
    label: 'DE',
  },
  {
    value: 'Florida',
    label: 'FL',
  },
  {
    value: 'Georgia',
    label: 'GA',
  },
  {
    value: 'Guam',
    label: 'GU',
  },
  {
    value: 'Hawaii',
    label: 'HI',
  },
  {
    value: 'Iowa',
    label: 'IA',
  },
  {
    value: 'Idaho',
    label: 'ID',
  },
  {
    value: 'Illinois',
    label: 'IL',
  },
  {
    value: 'Indiana',
    label: 'IN',
  },
  {
    value: 'Kansas',
    label: 'KS',
  },
  {
    value: 'Kentucky',
    label: 'KY',
  },
  {
    value: 'Louisiana',
    label: 'LA',
  },
  {
    value: 'Massachusetts',
    label: 'MA',
  },
  {
    value: 'Maryland',
    label: 'MD',
  },
  {
    value: 'Maine',
    label: 'ME',
  },
  {
    value: 'Michigan',
    label: 'MI',
  },
  {
    value: 'Minnesota',
    label: 'MN',
  },
  {
    value: 'Missouri',
    label: 'MO',
  },
  {
    value: 'Mississippi',
    label: 'MS',
  },
  {
    value: 'Montana',
    label: 'MT',
  },
  {
    value: 'North Carolina',
    label: 'NC',
  },
  {
    value: ' North Dakota',
    label: 'ND',
  },
  {
    value: 'Nebraska',
    label: 'NE',
  },
  {
    value: 'New Hampshire',
    label: 'NH',
  },
  {
    value: 'New Jersey',
    label: 'NJ',
  },
  {
    value: 'New Mexico',
    label: 'NM',
  },
  {
    value: 'Nevada',
    label: 'NV',
  },
  {
    value: 'New York',
    label: 'NY',
  },
  {
    value: 'Ohio',
    label: 'OH',
  },
  {
    value: 'Oklahoma',
    label: 'OK',
  },
  {
    value: 'Oregon',
    label: 'OR',
  },
  {
    value: 'Pennsylvania',
    label: 'PA',
  },
  {
    value: 'Puerto Rico',
    label: 'PR',
  },
  {
    value: 'Rhode Island',
    label: 'RI',
  },
  {
    value: 'South Carolina',
    label: 'SC',
  },
  {
    value: 'South Dakota',
    label: 'SD',
  },
  {
    value: 'Tennessee',
    label: 'TN',
  },
  {
    value: 'Texas',
    label: 'TX',
  },
  {
    value: 'Utah',
    label: 'UT',
  },
  {
    value: 'Virginia',
    label: 'VA',
  },
  {
    value: 'Virgin Islands',
    label: 'VI',
  },
  {
    value: 'Vermont',
    label: 'VT',
  },
  {
    value: 'Washington',
    label: 'WA',
  },
  {
    value: 'Wisconsin',
    label: 'WI',
  },
  {
    value: 'West Virginia',
    label: 'WV',
  },
  {
    value: 'Wyoming',
    label: 'WY',
  },
];

export const USStatesPlain = [
  'AK',
  'AL',
  'AR',
  'AS',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'GU',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VI',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
];

export default USStates;
