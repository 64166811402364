import React, { FC } from 'react';
import NumberFormat from 'react-number-format';
import { InputBase } from 'uikit';

interface Props {
  name: string;
  onChange: (value: string) => void;
  value: string;
}

const CREDIT_CARD_FORMAT = "#### #### #### ####";

const CardNumberInput: FC<Props> = ({ onChange, value, name }) => (
  <NumberFormat
    name={name}
    onValueChange={({ value }) => onChange(value)}
    value={value}
    format={CREDIT_CARD_FORMAT}
    customInput={InputBase}
  />
);

export default CardNumberInput;
