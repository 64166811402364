import * as yup from 'yup';
import { zipRegExp } from 'constants/regExp';
import { AddressOptions } from '../../types';
import valid from 'card-validator';

const validCardTypes = ['american-express', 'visa', 'mastercard', 'discover'];

export interface IOption {
  label: string;
  value: string;
}

const billingFields = {
  firstName: yup.string().required(`Please set first name`),
  lastName: yup.string().required(`Please set last name`),
  street: yup.string().required(`Please set address`),
  appartment: yup.string().notRequired(),
  city: yup.string().required(`Please set city`),
  state: yup.string().required(`Please set state`),
  zipCode: yup.string().required(`Please set ZIP code`).test('invalid zip code', 'Invalid ZIP code', function(value) {
    return zipRegExp.test(value);
  }),
};

const billingFieldsNotRequired = {
  firstName: yup.string().notRequired(),
  lastName: yup.string().notRequired(),
  street: yup.string().notRequired(),
  appartment: yup.string().notRequired(),
  city: yup.string().notRequired(),
  state: yup.string().notRequired(),
  zipCode: yup.string().notRequired(),
};

const validationSchemaBase = {
  payment: yup.object({
    paymentMethod: yup.string(),
    billingAddressOption: yup.string(),
    cardNumber: yup.string().when('paymentMethod', {
      is: 'creditCard',
      then: yup.string().test('invalid card number', 'Please enter a valid card number', function(value) {
        const cardType = valid.number(this.parent?.cardNumber)?.card?.type;  
        return valid.number(value).isValid && validCardTypes.indexOf(cardType || '') !== -1;
      }),
      otherwise: yup.string().notRequired(),
    }),
    cardName: yup.string().when('paymentMethod', {
      is: 'creditCard',
      then: yup.string().test('invalid cardholder name', 'Please enter a valid cardholder name', function(value) {
        return valid.cardholderName(value).isValid;
      }),
      otherwise: yup.string().notRequired(),
    }),
    expirationDate: yup.string().when('paymentMethod', {
      is: 'creditCard',
      then:yup.string().test('invalid date', 'Please enter a valid expiration date', function(value) {
        return valid.expirationDate(value).isValid;
      }),
      otherwise: yup.string().notRequired(),
    }),
    securityCode: yup.string().when('paymentMethod', {
      is: 'creditCard',
      then: yup.string().test('invalid code', 'Please enter a valid CVV', function(value) {
        const card = valid.number(this.parent?.cardNumber)?.card;      
        return valid.cvv(value, card?.code?.size || 3).isValid;
      }),
      otherwise: yup.string().notRequired(),
    }),
  }),
  
  // billing: yup.object(shippingFieldsNotRequired).when('payment.shippingAddress', {
  //   is: AddressOptions.same,
  //   then:  yup.object(shippingFieldsNotRequired),
  //   otherwise: yup.object(shippingFields),
  // }),

  billing: yup.object({
    firstName: yup.string().required(`Please set first name`),
    lastName: yup.string().required(`Please set last name`),
    street: yup.string().required(`Please set address`),
    appartment: yup.string().notRequired(),
    city: yup.string().required(`Please set city`),
    state: yup.string().required(`Please set state`),
    //stateFake: yup.object<IOption>().required(`Please set state`),
    zipCode: yup.string().required(`Please set ZIP code`).test('invalid zip code', 'Invalid ZIP code', function(value) {
      return zipRegExp.test(value);
    }),
  }),
  
};

export const validationSchemaNonPhysical = yup.object({
  ...validationSchemaBase,
  billing: yup.object(billingFields)
})

export const validationSchemaPhysical = yup.object({
  ...validationSchemaBase,
  billing: yup.object(billingFieldsNotRequired).when('payment.billingAddressOption', {
    is: AddressOptions.same,
    then:  yup.object(billingFieldsNotRequired),
    otherwise: yup.object(billingFields),
  }),
})

export type SchemaTypeNonPhysical = yup.InferType<typeof validationSchemaNonPhysical>;
export type SchemaTypePhysical = yup.InferType<typeof validationSchemaPhysical>;

export default validationSchemaNonPhysical;