import React, { FC } from 'react';
import { document } from 'global';
import { Helmet } from 'react-helmet-async';
import makeFullNameAndUserAvatarSubstitute from 'utils/makeFullNameAndUserAvatarSubstitute';
import { Drummer, ImageStyle, ImageStyleName } from 'graphqlQueries';
import { getImagePath } from 'helpers';

export interface IMetaData {
  offerPhotoThumb?: string;
  avatarImageOriginal?: string;
  avatarSubstituteLink?: string;
  avatarImage?: string;
  title?: string;
  metaTitle?: string;
  metaDescription?: string;
}

export const makeOfferPhotoThumbFromData = (data, type: ImageStyleName = ImageStyleName.Square) => {
  return getImagePath(data?.styles as ImageStyle[], type) || '/assets/images/share-cover.png';
};

export const makeOfferPhotoThumbFromMetaInitials = (metaData, fontFamily = 'Raleway') => {
  const initials = makeFullNameAndUserAvatarSubstitute(
    metaData?.user as Drummer
  )?.userAvatarSubstitute;

  const url = new URL(`${process.env.REACT_APP_DLP_URL}/assets/avatar.png`);
  url.searchParams.set('txt', typeof initials === 'string' ? initials.toUpperCase() : '');
  url.searchParams.set('ff', fontFamily || '');
  return String(url);
};

export const makeAvatarSubstituteLink = (metaData) => {
  try {
    const txt = makeFullNameAndUserAvatarSubstitute(metaData?.user as Drummer).userAvatarSubstitute;
    return (
      process.env.REACT_APP_IMG_RENDER +
      `/${txt}?ff=${encodeURIComponent(metaData?.style?.font_family || '')}`
    );
  } catch (err) {
    console.log('(drum-unhandled-error) makeAvatarSubstituteLink::err', err);
    console.log('(drum-unhandled-error) makeAvatarSubstituteLink::err:json', JSON.stringify(err));
    return '';
  }
};

export const makeAvatarImageOriginal = (metaData) => {
  return (
    getImagePath(metaData?.user?.avatarImage?.styles as ImageStyle[], ImageStyleName.Original) ||
    metaData?.user?.avatarImage?.url ||
    ''
  );
};

export const makeAvatarFromMeta = (metaData) => {
  return (
    getImagePath(metaData?.user?.avatarImage?.styles as ImageStyle[]) ||
    metaData?.user?.avatarImage?.url ||
    ''
  );
};

export const getFullName = (metaData) => {
  return makeFullNameAndUserAvatarSubstitute(metaData?.user as Drummer)?.fullName || '';
};

const MetaData: FC<IMetaData> = ({
  offerPhotoThumb = '/assets/images/share-cover.png',
  avatarImageOriginal = '',
  avatarImage = '',
  avatarSubstituteLink = '',
  title = 'Drum',
  metaTitle = 'Drum',
  metaDescription = 'Check out this page',
}) => {
  document?.getElementById('favicon')?.remove();
  return (
    <Helmet>
      <title>{title}</title>
      {/* Twitter */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:text:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta
        name="twitter:image"
        content={offerPhotoThumb || avatarImageOriginal || avatarImage || avatarSubstituteLink}
      />
      <meta name="twitter:site" content="Drum" />
      {/* FB */}
      <meta property="og:title" content={metaTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Drum" />
      <meta
        property="og:image"
        content={offerPhotoThumb || avatarImageOriginal || avatarImage || avatarSubstituteLink}
      />
      <meta property="og:image:width" content="1337" />
      <meta property="og:image:height" content="700" />
      <meta property="og:description" content={metaDescription} />
      {/* <meta property="og:video" content="https://dretvn31nzvv4.cloudfront.net/users/us-east-1:0e7afd68-533a-4d0e-a669-1d1206442dec/1632413721239.mov" />
      <meta property="og:type" content="video" /> */}

      {avatarImage ? (
        <>
          <link rel="icon" href={avatarImage} type="image/png" />
          <link rel="shortcut icon" href={avatarImage} type="image/png" />
        </>
      ) : (
        <>
          <link rel="icon" href="/assets/favicon.ico" type="image/x-icon" />
          <link rel="shortcut icon" href="/assets/favicon.ico" type="image/x-icon" />
        </>
      )}
    </Helmet>
  );
};

export default MetaData;
