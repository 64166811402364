import React, { FC } from 'react';
import Props from '../../types';
import {
  Container,
  ContainerInner,
  Content,
  ContentInner,
  Info,
  BusinessName,
} from './styles';

const TitleItem: FC<Props> = ({ item, linkStyles, styles, className }) => {
  const {
    name,
    alignment,
    fontStyle,
    isBackgroundCardEnabled
  } = item;
  const offerSizeClass = ' large';
  const imagesClass = ' no-images';
  const descriptionClass = ' no-description';
  const classSet = `${imagesClass} ${descriptionClass} ${offerSizeClass} ${className}`;
  return (
    <Container
      className={classSet}
      target="_blank"
      color={linkStyles?.color || ""}
      font={linkStyles?.font_family || ""}
      backgroundColor={linkStyles?.background_color}
      styles={styles}
      isBackgroundCardEnabled={isBackgroundCardEnabled}
      disableAnimation={true}
    >
      <ContainerInner styles={styles} backgroundColor={linkStyles?.background_color}>

        <Content styles={styles} alignment={alignment}>
          <ContentInner styles={styles}>
            <Info>
              <BusinessName fontStyle={fontStyle} alignment={alignment}>{name}</BusinessName>
            </Info>
          </ContentInner>
        </Content>
      </ContainerInner>
    </Container>
  );
};

export default TitleItem;
