import React, { useEffect, useState } from 'react';
import Validator from 'password-validator';
import { LevelOfSave } from './styled';
import { Colors } from '../../types';
import { MIN_PASSWORD_LENGTH } from '../../constants/parameters';

interface Props {
  password: string;
}

const validationSchema = new Validator();
validationSchema
  .is()
  .min(MIN_PASSWORD_LENGTH)
  .is()
  .has()
  .letters()
  .has()
  .uppercase()
  .has()
  .digits()
  .has()
  .symbols();

const StrangePasswordMeter: React.FC<Props> = ({ password }) => {
  const [saveLevel, setSaveLevel] = useState<number>(0);
  const [color, setColor] = useState<Colors>(Colors.gray);

  const toValidate = (inputValue: string) => {
    if (!inputValue.length) {
      setSaveLevel(0);
      setColor(Colors.gray);
      return;
    }
    if (inputValue.length < MIN_PASSWORD_LENGTH) {
      setSaveLevel(1);
      setColor(Colors.red);
      return;
    }

    const validatedValue = validationSchema.validate(inputValue, {
      list: true
    }) as string[];
    const minValid =
      !validatedValue.includes('min') &&
      !validatedValue.includes('max') &&
      !validatedValue.includes('letters');
    const hasUpperCase = !validatedValue.includes('uppercase');
    const hasDigits = !validatedValue.includes('digits');

    if (minValid && inputValue.length > 10 && hasDigits && hasUpperCase) {
      setSaveLevel(5);
      setColor(Colors.green);
      return;
    }
    if (minValid && hasDigits && hasUpperCase) {
      setSaveLevel(4);
      setColor(Colors.greenLighten);
      return;
    }
    if (minValid && (hasDigits || hasUpperCase)) {
      setSaveLevel(3);
      setColor(Colors.orange);
      return;
    }
    if (minValid) {
      setSaveLevel(2);
      setColor(Colors.redLigthen);
      return;
    }
  };

  useEffect(() => {
    toValidate(password);
  }, [password]);

  return (
    <LevelOfSave level={saveLevel} color={color}>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </LevelOfSave>
  );
};

export default StrangePasswordMeter;
