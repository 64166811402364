import React from 'react';
import window from 'global';
import { Helmet } from 'react-helmet-async';
import { getRoutes } from 'constants/routes';
import { useUsername } from '../../context/UserNameContext';
import {
  Container,
  Header,
  HeaderInner,
  LogoContainer,
  Logo,
  Menu,
  MenuItem,
  Content,
  ContentInner,
  ContentTitle,
  ContentImage,
  ContentText,
  ReturnButton,
  Footer,
  FooterInner,
  CopyrightContainer,
  CopyrightLogo,
  CopyrightText,
  FooterLinksContainer,
  FooterLink,
} from './styles';

const Page404 = () => {
  const { serverUserNameIsUsed } = useUsername();
  const routes = getRoutes(serverUserNameIsUsed);

  const handleReturnClick = () => {
    if (window) {
      window.location.href = routes.DRUM_PAGE;
    }
  };

  return (
    <>
      <Helmet>
        <title>404 Error</title>
      </Helmet>
      <Container>
        <Header>
          <HeaderInner>
            <LogoContainer to={routes.DRUM_PAGE}>
              <Logo />
            </LogoContainer>
            <Menu>
              <MenuItem href={routes.EARN_REWARDS}>Earn Rewards</MenuItem>
              <MenuItem href={routes.DRUM_FOR_BUSINESS}>
                Drum for Business
              </MenuItem>
            </Menu>
          </HeaderInner>
        </Header>
        <Content>
          <ContentInner>
            <ContentTitle>Page Inactive</ContentTitle>
            <ContentImage src="/assets/images/error-page.svg"></ContentImage>
            <ContentText>
              Unfortunately the page you are looking for cannot be found.
            </ContentText>
            <ReturnButton onClick={handleReturnClick}>Return Home</ReturnButton>
          </ContentInner>
        </Content>
        <Footer>
          <FooterInner>
            <CopyrightContainer>
              <CopyrightLogo />
              <CopyrightText>
                © Drum Technologies Inc. All rights reserved.
              </CopyrightText>
            </CopyrightContainer>
            <FooterLinksContainer>
              <FooterLink href={routes.TERMS_OF_SERVICE}>
                Terms of Service
              </FooterLink>
              <FooterLink href={routes.PRIVACY_POLICY}>
                Privacy Policy
              </FooterLink>
              <FooterLink href={routes.SUPPORT}>Support</FooterLink>
            </FooterLinksContainer>
          </FooterInner>
        </Footer>
      </Container>
    </>
  );
};

export default Page404;
