import * as types from '../constants/ActionTypes'

const _switchCheckoutTab = tabNumber => ({
  type: types.CHECKOUT_TAB_SWITCH,
  payload: tabNumber
})

export const switchCheckoutTab = tabNumber => dispatch => {
  dispatch(_switchCheckoutTab(tabNumber))
}

const _setCheckoutInfoForm = values => ({
  type: types.CHECKOUT_SET_INFO_FORM_DATA,
  payload: values
})

export const setCheckoutInfoForm = values => dispatch => {
  dispatch(_setCheckoutInfoForm(values))
}

const _setCheckoutPaymentForm = values => ({
  type: types.CHECKOUT_SET_PAYMENT_FORM_DATA,
  payload: values
})

export const setCheckoutPaymentForm = values => dispatch => {
  dispatch(_setCheckoutPaymentForm(values))
}

const _setPaypalPayload = values => ({
  type: types.CHECKOUT_SET_PAYPAL_PAYLOAD,
  payload: values
})

export const setPaypalPayload = values => dispatch => {
  dispatch(_setPaypalPayload(values))
}

const _setCreditCardPayload = values => ({
  type: types.CHECKOUT_SET_CREDIT_CARD_PAYLOAD,
  payload: values
})

export const setCreditCardPayload = values => dispatch => {
  dispatch(_setCreditCardPayload(values))
}

const _setBraintreeToken = values => ({
  type: types.CHECKOUT_SET_BRAINTREE_TOKEN,
  payload: values
})

export const setBraintreeToken = values => dispatch => {
  dispatch(_setBraintreeToken(values))
}

const _setLastCheckoutTitle = values => ({
  type: types.CHECKOUT_SET_LAST_CHECKOUT_TITLE,
  payload: values
})

export const setLastCheckoutTitle = values => dispatch => {
  dispatch(_setLastCheckoutTitle(values))
}

const _resetCheckout = () => ({
  type: types.CHECKOUT_RESET_CHECKOUT,
})

export const resetCheckout = () => dispatch => {
  dispatch(_resetCheckout())
}


