import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export const ListContainer = styled.div`
  
`;

export const ListLabel = styled.p`
  margin-left: 30px;
  &:first-of-type {
    margin-top: 16px;
  }
`;

export const SubDescription = styled.pre`
  line-height: 16px;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  margin-bottom: 0;
  text-align: center;
  margin-top: 15px;
  white-space: pre-line;
`;