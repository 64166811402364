import styled from 'styled-components';
import Tooltip from '../Tooltip';

export const Container = styled.label`
  display: block;
`;

export const StyledTooltip = styled(Tooltip)`
  display: inline-block;
  margin-left: 8px;
  position: relative;
  top: 3px;
`;
