import styled from 'styled-components';
import { TemplatePreset } from 'graphqlQueries';

interface StyleProps {
  styles?: TemplatePreset;
}

interface ContainerProps extends StyleProps {
  usePageStyles?: boolean;
  useCollectionStyles?: boolean;
}

export const headerBackgroundMixin = (styles?: TemplatePreset) => `
  background-color: ${
    styles?.headerContainer?.background?.color || styles?.headerContainer?.backgroundColor || 'transparent'
  };
  background-image: ${
    styles?.headerContainer?.background?.image
      ? `url('${process.env.REACT_APP_AWS_S3_DOMAIN}${styles?.headerContainer?.background?.image?.startsWith('/') ? '' : '/'}${styles?.headerContainer?.background?.image}')`
      : styles?.headerContainer?.backgroundImage
      ? `url('${process.env.REACT_APP_AWS_S3_DOMAIN}${styles?.headerContainer?.backgroundImage?.startsWith('/') ? '' : '/'}${styles?.headerContainer?.backgroundImage}')`
      : 'none'
    };
  background-size: ${styles?.headerContainer?.background?.size ? `${styles?.headerContainer?.background?.size?.desktop?.width || 0}px ${styles?.headerContainer?.background?.size?.desktop?.height || 0}px` : "cover"};
  background-position:  ${styles?.headerContainer?.background?.size ? `${styles?.headerContainer?.background?.offset?.desktop?.left || 0}px ${styles?.headerContainer?.background?.offset?.desktop?.top || 0}px` : "top center"};

  @media (max-width: 600px) {
    background-size: ${styles?.headerContainer?.background?.size ? `${styles?.headerContainer?.background?.size?.mobile?.width || 0}px ${styles?.headerContainer?.background?.size?.mobile?.height || 0}px` : "cover"};
    background-position:  ${styles?.headerContainer?.background?.size ? `${styles?.headerContainer?.background?.offset?.mobile?.left || 0}px ${styles?.headerContainer?.background?.offset?.mobile?.top || 0}px` : "center"};
  }
`;
export const ContainerOmni = styled.div``;
export const ContainerOuter = styled.div<ContainerProps>(
  ({ styles, usePageStyles }) => `
  // background-image: ${
    styles?.container?.pageBackgroundImage
      ? `url('${process.env.REACT_APP_AWS_S3_DOMAIN}${styles?.container?.pageBackgroundImage?.startsWith('/') ? '' : '/'}${styles?.container?.pageBackgroundImage}')`
      : 'none'
  };
  // background-size: 100% auto;
  // background-position: top center;
  padding-top: ${usePageStyles ? 0 : styles?.container?.pageBgHeight || 0}px;
  @media (max-width: 600px) {
    padding-top: ${
      usePageStyles ? 0 : styles?.container?.pageBgHeightMobile || 0
    }px;
  }

  &:after {
    content: "";
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    left: 0;
    right: 0;
    z-index: -1;
    background-image: ${
      styles?.container?.pageBackgroundImage
        ? `url('${process.env.REACT_APP_AWS_S3_DOMAIN}${styles?.container?.pageBackgroundImage?.startsWith('/') ? '' : '/'}${styles?.container?.pageBackgroundImage}')`
        : 'none'
    };
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: top center;
  }
`
);

export const OuterContentContainer = styled.div<StyleProps>(
  ({ styles }) => `
  position: relative;
  height: 0;
  overflow: visible;
  box-sizing: border-box;
  width: 100%;
  z-index: 2;

  //max-width: calc(${styles?.container?.maxWidth || 800}px - ${
    styles?.contentContainer?.padding?.left || 0
  }px - ${styles?.contentContainer?.padding?.right || 0}px);
  max-width: ${styles?.container?.maxWidth || 800}px;
  padding-left: ${styles?.contentContainer?.padding?.left || 0}px;
  padding-right: ${styles?.contentContainer?.padding?.right || 0}px;
  margin: 0 auto;
`
);

export const Container = styled.div<ContainerProps>(
  ({ styles, usePageStyles }) => `
  width: 100%;
  //min-height: calc(100vh - ${usePageStyles ? 0 : styles?.container?.pageBgHeight || 0}px);
  font-family: ${styles?.container?.font || 'Raleway'}, "Raleway";
  color: ${styles?.container?.color || 'inherit'};
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  background-color: ${
    styles?.container?.maxWidthOuter !== undefined &&
    styles?.container?.maxWidthOuter !== null
      ? styles?.container?.pageBackgroundColor
      : 'transparent'
  };
  margin-top: ${
    (usePageStyles
      ? styles?.headerContainer?.innerPagesTop || 0
      : 0)
  }px;
  
  &:after {
    content: "";
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    left: 0;
    right: 0;
    z-index: -1;
    background-color: ${
      styles?.container?.pageBackgroundColor || 'transparent'
    };
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
  }
`
);

export const InnerHeaderContainer = styled.div<ContainerProps>(
  ({ styles }) => `
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  margin: 0 auto;
  max-width: ${styles?.container?.maxWidth || 800}px;
  min-height: ${styles?.headerContainer?.minHeight || 500}px;
  ${headerBackgroundMixin(styles)};
`
);

export const ContainerInner = styled.div<ContainerProps>(
  ({ styles, usePageStyles, useCollectionStyles }) => `
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  max-width: ${styles?.container?.maxWidth || 800}px;
  background: ${
    (usePageStyles && !useCollectionStyles
      ? styles?.innerPagesContentContainer?.backgroundColor
      : styles?.container?.backgroundColor) || '#fff'
  };
  
  padding-top: ${styles?.container?.padding?.top || 0}px;
  padding-bottom: ${styles?.container?.padding?.bottom || 0}px;
  padding-left: ${styles?.container?.padding?.left || 0}px;
  padding-right: ${styles?.container?.padding?.right || 0}px;
  flex-grow: 1;
  // margin: 120px auto 30px auto;
  margin: 0 auto 0 auto;
  border-radius: ${styles?.container?.borderRadius || 0}px;
  box-sizing: border-box;

  @media (max-width: 400px) {
    display: block;
    margin-bottom: 0;
    min-height: 0;
  }
`
);

export const Header = styled.div<StyleProps>(
  ({ styles }) => `
  ${headerBackgroundMixin(styles)};
  min-height: ${styles?.headerContainer?.minHeight || 0}px;

  @media (max-width: 600px) {
    top: ${styles?.headerContainer?.mobileTop || 0}px;
    min-height: ${styles?.headerContainer?.mobileMinHeight || 0}px;
  }
`
);

export const HeaderInner = styled.div<StyleProps>(
  () => `
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 600px) {

  }
`
);

export const Content = styled.div<StyleProps>(
  ({ styles }) => `
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  top: ${styles?.contentContainer?.top || 0}px;  
  background-image: ${
    styles?.contentContainer?.background?.image
      ? `url('${process.env.REACT_APP_AWS_S3_DOMAIN}${styles?.contentContainer?.background?.image?.startsWith('/') ? '' : '/'}${styles?.contentContainer?.background?.image}')`
      : 'none'
  };
  background-position: ${styles?.contentContainer?.background?.offset?.desktop?.left || 0}px ${styles?.contentContainer?.background?.offset?.desktop?.top || 0}px;
  background-size: ${styles?.contentContainer?.background?.size?.desktop?.width ? `${styles?.contentContainer?.background?.size?.desktop?.width}px` : '100%'} ${styles?.contentContainer?.background?.size?.desktop?.height ? `${styles?.contentContainer?.background?.size?.desktop?.height}px` : '100%'};
  background-repeat: no-repeat;

  @media (max-width: 600px) {
    margin-left: auto;
    margin-right: auto;
    background-position: ${styles?.contentContainer?.background?.offset?.mobile?.left || 0}px ${styles?.contentContainer?.background?.offset?.mobile?.top || 0}px;
    background-size: ${styles?.contentContainer?.background?.size?.mobile?.width ? `${styles?.contentContainer?.background?.size?.mobile?.width}px` : '100%'} ${styles?.contentContainer?.background?.size?.mobile?.height ? `${styles?.contentContainer?.background?.size?.mobile?.height}px` : '100%'};
  }
`
);

export const ChildrenContainer = styled.div<ContainerProps>(
  ({ styles, usePageStyles, useCollectionStyles }) => `
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  margin-top: ${usePageStyles ? 30 : styles?.contentContainer?.top || 0}px;
  padding-top: ${usePageStyles && !useCollectionStyles ? styles?.innerPagesContentContainer?.padding?.top : styles?.contentContainer?.padding?.top || 0}px;
  padding-bottom: ${usePageStyles && !useCollectionStyles ? styles?.innerPagesContentContainer?.padding?.bottom : styles?.contentContainer?.padding?.bottom || 0}px;
  padding-left: ${usePageStyles && !useCollectionStyles ? styles?.innerPagesContentContainer?.padding?.left : styles?.contentContainer?.padding?.left || 0}px;
  padding-right: ${usePageStyles && !useCollectionStyles ? styles?.innerPagesContentContainer?.padding?.right : styles?.contentContainer?.padding?.right || 0}px;
  /*background-color: ${
    usePageStyles
      ? 'transparent'
      : styles?.innerPagesContentContainer?.backgroundColor
  };*/
  background-color: transparent;
  color: ${usePageStyles ? styles?.innerPagesContentContainer?.color : ''};
  border-radius: 15px;

  @media (max-width: 600px) {
    padding-top: ${usePageStyles && !useCollectionStyles ? styles?.innerPagesContentContainer?.mobilePadding?.top : styles?.contentContainer?.mobilePadding?.top || 0}px;
    padding-bottom: ${usePageStyles && !useCollectionStyles ? styles?.innerPagesContentContainer?.mobilePadding?.bottom : styles?.contentContainer?.mobilePadding?.bottom || 0}px;
    padding-left: ${usePageStyles && !useCollectionStyles ? styles?.innerPagesContentContainer?.mobilePadding?.left : styles?.contentContainer?.mobilePadding?.left || 0}px;
    padding-right: ${usePageStyles && !useCollectionStyles ? styles?.innerPagesContentContainer?.mobilePadding?.right : styles?.contentContainer?.mobilePadding?.right || 0}px;
    margin-right: auto;
  }
`
);

export const LoaderContainer = styled.div`
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  height: -webkit-fill-available;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.dlpUpgradeLoaderBg};
  opacity: 1;

  &.hidden {
    opacity: 0;
    transition: opacity 0.6s linear;
  }
  &.no-display {
    display: none;
  }

  & > div {
    background: ${({ theme }) => theme.colors.dlpUpgradeLoaderBg};
    width: 100vw;
  }
`;
