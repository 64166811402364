import React, { FC, useState, useEffect } from 'react';
import querystring from 'query-string';
import { FormikErrors } from 'formik';
import { useLocation, useParams, generatePath } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import actions from 'redux/actions';
import Modal, { ModalProps } from 'uikit/Modal';
import MetaData, {
  makeOfferPhotoThumbFromData,
  makeAvatarFromMeta,
  getFullName,
  makeAvatarImageOriginal,
  makeAvatarSubstituteLink,
} from 'components/MetaData/MetaData';
import { Loader } from 'uikit';
import { getRoutes } from 'constants/routes';
import ReturnLink from 'components/ReturnLink';
import PurchaseVideoProduct from './components/PurchaseVideoProduct';
import { SchemaType as VideoSchemaType } from './components/PurchaseVideoProduct/validationSchema';
import PurchaseDigitalProduct from './components/PurchaseDigitalProduct';
import PurchasePhysicalProduct from './components/PurchasePhysicalProduct';
import { useRedirection } from 'utils/serverEffectActions';
import { useUsername } from '../../context/UserNameContext';
import { sendStartedCheckoutAnalytics } from 'helpers/analytics/productsAndLinks';
import { saveItem, getItem } from 'utils/checkoutManagement';
import GeneralLayout from 'layouts/GeneralLayout';

import {
  useGetLinkPageMetadataQuery,
  useGetProductQuery,
  ProductTypeEnum,
  ImageStyleName,
  BulkChangeProductProductPropertiesCombinations,
} from 'graphqlQueries';
import { GeneralPageProps } from 'types';
import { Container, LoaderContainer } from './styles';

interface IParamTypes {
  id: string;
  collectionTitle?: string;
  collectionId?: string;
}

const PurchasePage: FC<GeneralPageProps> = () => {
  const { serverUserNameIsUsed, usernameForRoutes, username } = useUsername();
  const { id, collectionTitle, collectionId } = useParams<IParamTypes>();
  const routes = getRoutes(serverUserNameIsUsed);
  const location = useLocation();
  const redirectTo = useRedirection();
  const queryParams = querystring.parse(location.search);
  const {
    linkId,
    fromLp: isFromLinkPage,
    cFromLp: isFromCollectionFromLinksPage,
  } = queryParams;

  const [initialNotes, setInitialNotes] = useState<string | undefined>(
    undefined
  );
  const [shouldValidateOnChange, setShouldValidateOnChange] = useState<boolean>(
    false
  );

  const [returnLink, setReturnLink] = useState('');

  const dispatch = useDispatch();

  const closeErrorModal = () => {
    setErrorModalData({ ...errorModalData, isOpen: false });
  };

  const [errorModalData, setErrorModalData] = useState<ModalProps>({
    title: 'Some error has occured',
    isOpen: false,
    mainButton: { text: 'Okay', onClick: closeErrorModal },
    onClose: closeErrorModal,
  });

  const {
    data: metaData,
  } = useGetLinkPageMetadataQuery({
    fetchPolicy: 'cache-first',
    variables: {
      username,
    },
    onError: () => {
      redirectTo(routes.PAGE_404);
    },
  });

  const {
    data: productData,
  } = useGetProductQuery({
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
    onError: () => {
      redirectTo(routes.PAGE_404);
    },
  });

  const onDigitalSubmit = () => {
    saveItem({
      product: productData?.getProduct,
      collectionLinkId: linkId,
      collectionId,
      collectionTitle,
      queryParams
    });

    sendStartedCheckoutAnalytics({
      meta: metaData?.getLinkedPage,
      product: productData?.getProduct,
      productId: productData?.getProduct?.id,
    });

    dispatch(actions.checkout.resetCheckout());

    redirectTo(
      generatePath(routes.CHECKOUT_PAGE, { username: usernameForRoutes })
    );
  }

  const onPhysicalSubmit = (
    validateForm: (values?: any) => Promise<FormikErrors<any>>,
    setSubmitting: (isSubmitting: boolean) => void,
    values: any,
    combinationValues: BulkChangeProductProductPropertiesCombinations | undefined | null
  ) => {
    setSubmitting(true);
    
    validateForm(values).then((errors) => {
      const errorList = !!Object.values(errors)?.length;
      if (!errorList) {
        dispatch(actions.checkout.resetCheckout());
        saveItem({
          product: productData?.getProduct,
          config: {...combinationValues, ...values},
          collectionLinkId: linkId,
          collectionId,
          collectionTitle,
          queryParams,
        });       

        setShouldValidateOnChange(true);

        sendStartedCheckoutAnalytics({
          meta: metaData?.getLinkedPage,
          product: productData?.getProduct,
          productId: productData?.getProduct?.id,
          quantity: values?.quantity,
          selectedOptions: combinationValues?.properties
        });

        redirectTo(
          generatePath(routes.CHECKOUT_PAGE, { username: usernameForRoutes })
        );
      } else {
        setErrorModalData({
          ...errorModalData,
          title: 'Please make sure all fields are valid',
          isOpen: true,
          message: 'Please go back and make sure all fields are valid.',
        });
      }
      setSubmitting(false);
    });
  }

  const onVideoSubmit = (
    values: VideoSchemaType,
    { setSubmitting, validateForm }: any
  ) => {
    setSubmitting(true);

    validateForm(values).then((errors) => {
      const errorList = !!Object.values(errors)?.length;
      if (!errorList) {
        dispatch(actions.checkout.resetCheckout());
        saveItem({
          product: productData?.getProduct,
          collectionLinkId: linkId,
          collectionId,
          collectionTitle,
          queryParams,
          ...values,
        });
        

        sendStartedCheckoutAnalytics({
          meta: metaData?.getLinkedPage,
          product: productData?.getProduct,
          productId: productData?.getProduct?.id,
        });

        redirectTo(
          generatePath(routes.CHECKOUT_PAGE, { username: usernameForRoutes })
        );
      } else {
        setErrorModalData({
          ...errorModalData,
          title: 'Please fill all the fields',
          isOpen: true,
          message: 'Please go back and fill in the missing fields!',
        });
      }
      setSubmitting(false);
    });
  };

  useEffect(() => {
    if (isFromLinkPage) {
      setReturnLink(
        generatePath(routes.LINK_PAGE, { username: usernameForRoutes })
      );
    } else if (collectionId) {
      const qs =
        !isFromLinkPage && !isFromCollectionFromLinksPage
          ? ''
          : `fromLp=1&linkId=${linkId}`;
      const qsTail = !qs ? '' : `?${qs}`;
      const path = generatePath(routes.COLLECTION_PAGE, {
        ...(serverUserNameIsUsed ? {} : { username: usernameForRoutes }),
        collectionTitle: collectionTitle?.toString() || '',
        collectionId: collectionId?.toString() || '',
      });

      setReturnLink(`${path}${qsTail}`);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (productData && metaData) {
      const savedItem = getItem();

      setInitialNotes(
        savedItem?.product?.id === productData?.getProduct?.id
          ? savedItem.notes
          : ''
      );
    }
  }, [productData, metaData]);

  const helmetMeta = React.useMemo(() => {
    const meta = metaData?.getLinkedPage;
    const fullName = getFullName(meta);
    const title = fullName
      ? `${fullName} | ${productData?.getProduct?.title || 'Product'}`
      : `Product Page`;

    return {
      offerPhotoThumb: makeOfferPhotoThumbFromData(
        productData?.getProduct?.images?.[0],
        ImageStyleName.Original
      ),
      avatarImage: makeAvatarFromMeta(meta),
      avatarImageOriginal: makeAvatarImageOriginal(meta),
      avatarSubstituteLink: makeAvatarSubstituteLink(meta),
      title,
      metaTitle: title,
      metaDescription: 'Click to see details.',
    };
  }, [metaData, productData]);

  return (
    <>
      <MetaData {...helmetMeta} />

      <GeneralLayout
        showLoader={!metaData}
        metaData={metaData?.getLinkedPage}
        outerContent={
          <>
            {returnLink && <ReturnLink
              to={returnLink}
            />}
          </>
        }
      >
        {!productData?.getProduct ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          <Container>
            {productData?.getProduct?.type === ProductTypeEnum.VideoProduct && (
              <>
                {initialNotes !== undefined && (
                  <PurchaseVideoProduct
                    values={productData?.getProduct}
                    user={metaData?.getLinkedPage?.user}
                    onSubmit={onVideoSubmit}
                    initialNotes={initialNotes}
                    inputStyles={metaData?.getLinkedPage?.link_style}
                    styles={metaData?.getLinkedPage?.template_preset}
                  />
                )}
              </>
            )}

            {productData?.getProduct?.type === ProductTypeEnum.DigitalProduct && (
              <>
                {(
                  <PurchaseDigitalProduct
                    values={productData?.getProduct}
                    user={metaData?.getLinkedPage?.user}
                    onSubmit={onDigitalSubmit}
                    inputStyles={metaData?.getLinkedPage?.link_style}
                    styles={metaData?.getLinkedPage?.template_preset}
                  />
                )}
              </>
            )}

            {productData?.getProduct?.type === ProductTypeEnum.PhysicalProduct && (
              <>
                {(
                  <PurchasePhysicalProduct
                    values={productData?.getProduct}
                    user={metaData?.getLinkedPage?.user}
                    inputStyles={metaData?.getLinkedPage?.link_style}
                    shouldValidateOnChange={shouldValidateOnChange}
                    onSubmit={onPhysicalSubmit}
                    styles={metaData?.getLinkedPage?.template_preset}
                  />
                )}
              </>
            )}
          </Container>
        )}
      </GeneralLayout>

      <Modal {...errorModalData}></Modal>
    </>
  );
};

export default PurchasePage;
