import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const MenuFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 15px 0 0 0;
`;

export const ViewAllLink = styled(Link)`
  padding: 12px 60px;
  border-radius: 21px;
  border: solid 2px ${({ theme }) => theme.colors.main};
  font-family: ${({ theme }) => theme.fontFamily.raleway};
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.main};
  background-color: ${({ theme }) => theme.colors.white};
`;

export const DDArrow = styled.div`
  position: absolute;
  top: 12px;
  right: 20px;
  z-index: 1;

  svg {
    width: 27px;
    height: 27px;
    transform: rotate(180deg);
    
    path {
      color: ${({ theme }) => theme.colors.snippetCollapseArrowColor};
      color: ${({ theme }) => theme.colors.snippetCollapseArrowColor};
    }
  }
`;