import window from 'global';
import React, { FC, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import copy from 'copy-to-clipboard';
import { useParams } from 'react-router-dom';
import { Plane } from 'assets/svg';
import PrimaryButtonStyled from 'components/PrimaryButtonStyled';
import MetaData, {
  makeOfferPhotoThumbFromData,
  makeAvatarFromMeta,
  getFullName,
  makeAvatarImageOriginal,
  makeAvatarSubstituteLink,
} from 'components/MetaData/MetaData';
import GeneralLayout from 'layouts/GeneralLayout';
import { useRedirection } from 'utils/serverEffectActions';
import { useUsername } from 'context/UserNameContext';
import { getRoutes } from 'constants/routes';
import { sendProductViewedAnalytics, sendProductDownloadedAnalytics } from 'helpers/analytics/productsAndLinks';
import {
  Container,
  VideoContainer,
  DetailsContainer,
  ButtonsContainer,
  Title,
  Description,
  LoaderContainer,
  ShareButtonContainer,
  ShareButton,
  CopyTooltip,
} from './styles';
import { GeneralPageProps } from 'types';
import { Loader } from 'uikit';
import {
  useGetVideoSaleDetailsQuery,
  useGetLinkPageMetadataQuery,
  ImageStyleName
} from 'graphqlQueries';

interface IParamTypes {
  id: string;
}

const VideoProductPage: FC<GeneralPageProps> = () => {
  const { serverUserNameIsUsed, username } = useUsername();
  const { id } = useParams<IParamTypes>();
  const routes = getRoutes(serverUserNameIsUsed);
  const redirectTo = useRedirection();

  const [showCopyTooltip, setShowCopyTooltip] = useState<boolean>(false);

  const handleCopy = () => {
    setShowCopyTooltip(true);
    setTimeout(() => setShowCopyTooltip(false), 1500);
    copy(window?.location?.href);
  };

  const {
    data: orderData,
  } = useGetVideoSaleDetailsQuery({
    variables: {
      id,
    },
    onError: () => {
      redirectTo(routes.PAGE_404);
    },
  });

  const {
    data: metaData,
  } = useGetLinkPageMetadataQuery({
    fetchPolicy: 'cache-first',
    variables: {
      username,
    },
    onError: (err) => {
      redirectTo(routes.PAGE_404);
    },
  });

  const handleDownloadClick = () => {
    sendProductDownloadedAnalytics({
      orderId: orderData?.getVideoSaleDetails?.orderId || '',
      meta: metaData?.getLinkedPage,
      orderData: orderData?.getVideoSaleDetails
    })
  }

  useEffect(() => {
    if(!orderData) return;
    sendProductViewedAnalytics({
      orderId: orderData?.getVideoSaleDetails?.orderId || '',
      orderShortUrlId: id as string || '',
      meta: metaData?.getLinkedPage,
    })
    // eslint-disable-next-line
  }, [orderData])

  const helmetMeta = React.useMemo(() => {
    //if(offerDataRaw?.dlpOfferPage?.__typename !== 'DlpOfferPageResult') return;
    const meta = metaData?.getLinkedPage;
    const productTitle = orderData?.getVideoSaleDetails?.product?.title;
    const fullName = getFullName(meta);
    const title = fullName
      ? `${fullName} | ${productTitle || 'Product'}`
      : `Product Page`;

    return {
      offerPhotoThumb: makeOfferPhotoThumbFromData(orderData?.getVideoSaleDetails?.product?.images?.[0], ImageStyleName.Original),
      avatarImage: makeAvatarFromMeta(meta),
      avatarImageOriginal: makeAvatarImageOriginal(meta),
      avatarSubstituteLink: makeAvatarSubstituteLink(meta),
      title,
      metaTitle: title,
      metaDescription: 'Click to see details.',
    };
  }, [metaData, orderData]);

  return (
    <>
      <MetaData {...helmetMeta} />
      <GeneralLayout
        showLoader={!metaData}
        metaData={metaData?.getLinkedPage}
        outerContent={
          <ShareButtonContainer>
            <ShareButton
              backgroundColor={
                metaData?.getLinkedPage?.background?.background_color
              }
              onClick={handleCopy}
            >
              <Plane />
              {showCopyTooltip && <CopyTooltip>Copied!</CopyTooltip>}
            </ShareButton>
          </ShareButtonContainer>
        }
      >
        <Container>
          {!orderData ? (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          ) : (
            <VideoContainer>
              <ReactPlayer
                controls={true}
                //url={"https://share.stg.drum.io/nastia11/play-video/64d08d1e-b17d-4084-bc18-aa01650d5c76.mov?password=x74zen"}
                url={orderData?.getVideoSaleDetails?.downloadUrl}
                //url={"http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"}
                width="100%"
              />
              {/* <Plyr
                source={
                  {
                    type: 'video',
                    sources: [{
                      provider: "html5",
                      src: orderData?.getVideoSaleDetails?.downloadUrl || ''
                    }]
                  }
                }
                // options={
                //   {

                //   }
                // }
              /> */}
            </VideoContainer>
          )}

          <DetailsContainer>
            <Title>A video message from {getFullName(metaData?.getLinkedPage)}</Title>
            <Description>{orderData?.getVideoSaleDetails?.note}</Description>
          </DetailsContainer>

          <ButtonsContainer>
            <PrimaryButtonStyled
              href={orderData?.getVideoSaleDetails?.downloadUrl}
              download
              onClick={handleDownloadClick}
            >
              Download
            </PrimaryButtonStyled>
            {/* <DownloadButton
              href={videoUrl}
              download
            >
              Download
            </DownloadButton> */}
          </ButtonsContainer>
        </Container>
      </GeneralLayout>
    </>
  );
};

export default VideoProductPage;
