import styled from 'styled-components';
import { colord } from 'colord';
import {
  ContainerProps,
  ContainerBase,
  ContainerInnerBase,
  ImageBase,
  ContentBase,
  ContentInnerBase,
  InfoBase,
  BusinessImageContainerBase,
  BusinessImageBase,
  BusinessNameBase,
  DescriptionBase,
} from 'uikit/LinkItems';
import { PrimaryButton } from 'uikit';

export const Container = styled(ContainerBase)``;
export const ContainerInner = styled(ContainerInnerBase)``;

export const Image = styled(ImageBase)`
  ${Container}.no-images & {
    display: none;
  }
`;

export const Content = styled(ContentBase)`
  position: relative;
`;

export const ContentInner = styled(ContentInnerBase)``;

export const Info = styled(InfoBase)``;
export const BusinessImageContainer = styled(BusinessImageContainerBase)``;
export const BusinessImage = styled(BusinessImageBase)``;
export const BusinessName = styled(BusinessNameBase)``;
export const Description = styled(DescriptionBase)``;

export const BuyButton = styled(PrimaryButton)<ContainerProps>(
  ({ theme, styles }) => `
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-family: inherit;
  width: ${styles?.link?.product?.buyButton?.width || '50'}px;
  height: ${styles?.link?.product?.buyButton?.height || '27'}px;
  color: ${styles?.link?.product?.buyButton?.color || 'inherit'};
  cursor: pointer;
  ${
    styles?.link?.product?.buyButton?.backgroundColor
      ? `
    background-color: ${styles?.link?.product?.buyButton?.backgroundColor};
    :hover {
      background-color: ${colord(
        styles?.link?.product?.buyButton?.backgroundColor
      )
        .darken(0.15)
        .toHex()};
    }
    :active {
      background-color: ${colord(
        styles?.link?.product?.buyButton?.backgroundColor
      )
        .darken(0.15)
        .toHex()};
    }
    :disabled {
      background-color: ${colord(
        styles?.link?.product?.buyButton?.backgroundColor
      )
        .desaturate(1)
        .toHex()};
    }
  `
      : ''
  }
  border-radius: ${styles?.link?.product?.buyButton?.borderRadius || 0}px;
  padding: 0;
  align-self: center;
  font-size: ${({ theme }) => theme.size.body2};

  .large & {
    right: ${styles?.link?.base?.largeItemPadding?.right ? 0 : styles?.link?.base?.padding?.right || 0}px;

    @media(max-width:600px) {
      right: ${styles?.link?.base?.largeItemMobilePadding?.right ? 0 : styles?.link?.base?.mobilePadding?.right || 0}px;
    }
  }
`
);
