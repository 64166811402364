import React, { FC } from 'react';
import IconProps from './IconProps';

const ChevronRight: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="8"
    viewBox="0 0 6 8"
  >
    <path
      fill="none"
      fillRule="evenodd"
      stroke="#373737"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15.867 9L12 12.867 15.867 16.734"
      transform="matrix(-1 0 0 1 16.867 -9)"
    />
  </svg>
);

export default ChevronRight;
