import createApolloClient from 'graphqlQueries/client';
import React from 'react';
import { hydrate } from 'react-dom';
import * as serviceWorker from './serviceWorker';
import Root from 'Root';
import window from 'global';

const { client, cache } = createApolloClient(false) as any;
(window as any).__cache = cache;

console.log('REACT:toplevel::window.__drum_username', window.__drum_username);
console.log(
  'REACT:toplevel::window.__drum_pageViewId',
  window.__drum_pageViewId
);

hydrate(
  <Root
    apolloClient={client}
    username={window.__drum_username}
    pageViewId={window.__drum_pageViewId}
    isOnServer={false}
  />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
