import styled, { DefaultTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';
import { TemplatePreset } from 'graphqlQueries';
import ITemplatePreset from '../../types/TemplatePreset';

interface IStyleProps {
  backgroundColor?: string | null;
}

export const ReturnLinkMixin = (theme: DefaultTheme, styles?: TemplatePreset) =>`
  position: absolute;
  top: calc(13px - ${
    (styles?.headerContainer?.innerPagesTop || 0)
  }px);
  left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 33px;
  background-color: ${styles?.backButton?.backgroundColor ? styles?.backButton?.backgroundColor : theme.colors.dlpDefaultCardBgColor};
  color: ${styles?.backButton?.color ? styles?.backButton?.color : theme.colors.accentSecondaryColor};
  font-family: "Roboto";
  font-size: ${theme.size.body1};
  font-weight: ${theme.fontWeight.regular};
  box-shadow: ${styles?.backButton?.useShadow ? `0px 4px 4px rgba(0, 0, 0, 0.25)` : 'none' };
  border-radius: 16.5px;
  text-decoration: none;
  z-index: 4;

  svg {
    fill: none;
    margin-right: 7px;

    path {
      stroke: ${styles?.backButton?.color ? styles?.backButton?.color : theme.colors.accentSecondaryColor};
      fill: ${styles?.backButton?.color ? styles?.backButton?.color : theme.colors.accentSecondaryColor};
    }
  }
`;

export const ReturnRouterLink = styled(Link)<ITemplatePreset>((props) =>`
  ${ReturnLinkMixin(theme, props.styles)};
  
`);

export const ReturnExternalLink = styled.a<ITemplatePreset>((props) =>`
  ${ReturnLinkMixin(theme, props.styles)};
`);