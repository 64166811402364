import styled from 'styled-components';
import { TemplatePreset } from 'graphqlQueries';

interface Props {
  styles?: TemplatePreset | any;
}

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  @media (max-width: 600px) {
    
  }
`;

export const ListContainer = styled.div`
  
`;

export const ListLabel = styled.p<Props>(({styles, theme}) =>`
  display: inline-block;
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.size.body2};

  color: ${styles?.linksListTitle?.color || 'inherit'};
  background-color: ${styles?.linksListTitle?.backgroundColor || 'transparent'};

  margin-top: ${styles?.linksListTitle?.margin?.top || 0}px;
  margin-bottom: ${styles?.linksListTitle?.margin?.bottom || 0}px;
  margin-left: ${styles?.linksListTitle?.margin?.left || 0}px;
  margin-right: ${styles?.linksListTitle?.margin?.right || 0}px;

  padding-top: ${styles?.linksListTitle?.padding?.top || 0}px;
  padding-bottom: ${styles?.linksListTitle?.padding?.bottom || 0}px;
  padding-left: ${styles?.linksListTitle?.padding?.left || 0}px;
  padding-right: ${styles?.linksListTitle?.padding?.right || 0}px;
  
  &.no-margin{
    margin-top: 0;
  }

  @media(max-width: 600px) {
    margin-top: ${styles?.linksListTitle?.mobileMargin?.top || 0}px;
    margin-bottom: ${styles?.linksListTitle?.mobileMargin?.bottom || 0}px;
    margin-left: ${styles?.linksListTitle?.mobileMargin?.left || 0}px;
    margin-right: ${styles?.linksListTitle?.mobileMargin?.right || 0}px;

    padding-top: ${styles?.linksListTitle?.mobilePadding?.top || 0}px;
    padding-bottom: ${styles?.linksListTitle?.mobilePadding?.bottom || 0}px;
    padding-left: ${styles?.linksListTitle?.mobilePadding?.left || 0}px;
    padding-right: ${styles?.linksListTitle?.mobilePadding?.right || 0}px;

    &.no-margin {
      margin-top: 0;
    }
  }

`);

export const SocialSubstitute = styled.div<Props>(({styles}) =>`
  height: 16px;
`);
export const SocialsWideContainer = styled.div`
  & > * {
    padding: 0;
    & > * {
      max-width: 400px;
    }
  }

  @media(max-width: 600px) {
    & > * {
      padding: 0 5px;
      max-width: 100%;
    }
  }
`;

