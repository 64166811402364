import styled from 'styled-components';
import { Tab as OriginTab, Tabs as OriginTabs, TabList as OriginTabList, TabPanel as OriginTabPanel } from 'react-tabs';
import { breakpoint } from '../../helpers';
import { TemplatePreset } from 'graphqlQueries';

interface StyleProps {
  styles?: TemplatePreset;
}


export const Tabs = styled(OriginTabs)`

`;

export const TabList = styled(OriginTabList)`
  display: flex;
`;

export const Tab = styled(OriginTab)<StyleProps>(({theme, styles}) => `
  position: relative;
  top: 1px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 243px;
  height: 54px;
  margin-right: 9px;
  padding: 0 15px;
  font-size: ${theme.size.body1};
  font-weight: ${theme.fontWeight.bold};
  color: inherit;
  border: 1px solid #ECECEC;
  border-radius: 6px 6px 0 0;
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  color: ${styles?.cart?.inactiveTabColor || "inherit"};

  &.react-tabs__tab--selected {
    color: ${styles?.cart?.activeTabColor || theme.colors.danger};
    z-index: 1;
    border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  }

  ${breakpoint('xs', 'md')`
    flex: 0.5;
    margin-right: 0;
    min-width: 0;
    height: 27px;
    font-size: 10px;
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    padding: 0 5px;
  `}
`);



export const TabPanel = styled(OriginTabPanel)`
  display: none;
  &.react-tabs__tab-panel--selected {
    display: block;
    background-color: transparent;
    border: 1px solid #ECECEC;

  }
`;

