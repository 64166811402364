import React, { FC } from 'react';
import { Emphasized } from 'uikit';
import { IComponentParams } from '../../types';
import { Title, SubTitle, Paragraph } from '../../styles';

const VideoPurchaseConfirmation: FC<IComponentParams> = ({ orderData }) => {
  return (
    <>
      <Title>Thank You</Title>
      <SubTitle>Purchase Confirmation</SubTitle>
      <Paragraph>Your video message request has been
      received. Upon approval an email with a link
      to the video message will be sent to{' '}
      <Emphasized>{orderData?.email}</Emphasized>
      </Paragraph>
    </>
  );
};

export default VideoPurchaseConfirmation;
