import React, { FC } from 'react';
import IconProps from './IconProps';

const DropDownArrow: FC<IconProps> = ({ className }) => (
  <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path d="M4.516 7.548c.436-.446 1.043-.481 1.576 0L10 11.295l3.908-3.747c.533-.481 1.141-.446 1.574 0 .436.445.408 1.197 0 1.615-.406.418-4.695 4.502-4.695 4.502a1.095 1.095 0 01-1.576 0S4.924 9.581 4.516 9.163s-.436-1.17 0-1.615z"></path>
    </svg>
);

export default DropDownArrow;
