import { switchCheckoutTab, setCheckoutInfoForm, setCheckoutPaymentForm, setPaypalPayload, setCreditCardPayload, setBraintreeToken, setLastCheckoutTitle, resetCheckout } from "./checkout"; 
import { setUserMetaData } from "./userMetaData"; 

const actions = {
  checkout: {
    switchCheckoutTab,
    setCheckoutInfoForm,
    setCheckoutPaymentForm,
    setPaypalPayload,
    setCreditCardPayload,
    setBraintreeToken,
    setLastCheckoutTitle,
    resetCheckout
  },
  userMetaData: {
    setUserMetaData
  }
}

export default actions;