import * as yup from 'yup';
import { emailRegExp, phoneRegExp } from 'constants/regExp';
import { isValidNumber } from 'libphonenumber-js';

export const validationSchemas = {
  small: {
    email: yup.object({
        email: yup.string().required(`Email is required`).test('invalid email', 'Error. Wrong e-mail format.', function(value) {
          return emailRegExp.test(value);
        }),
    }),
    phone: yup.object({
      phone: yup.string().test('invalid phone', 'Error. Wrong phone format.', function(value) {
          return isValidNumber(`+${value}`);
        }),
    }) 
  },
  large: {
    email: yup.object({
      fullName: yup
        .string()
        .test('long fullname', 'Error. Full name is too long', val => val.toString().length <= 100)
        .required('Please set full name'),
        email: yup.string().required(`Email is required`).test('invalid email', 'Error. Wrong e-mail format.', function(value) {
          return emailRegExp.test(value);
        }),
    }),
    phone: yup.object({
      fullName: yup
        .string()
        .test('long fullname', 'Error. Full name is too long', val => val.toString().length <= 100)
        .required('Please set full name'),
      phone: yup.string().test('invalid phone', 'Error. Wrong phone format.', function(value) {
        return isValidNumber(`+${value}`);
      }),
    })
  }
}

export type SchemaTypeSmallEmail = yup.InferType<typeof validationSchemas.small.email>;
export type SchemaTypeSmallPhone = yup.InferType<typeof validationSchemas.small.phone>;
export type SchemaTypeLargeEmail = yup.InferType<typeof validationSchemas.large.email>;
export type SchemaTypeLargePhone = yup.InferType<typeof validationSchemas.large.phone>;

export default validationSchemas;