import selectImageStyle from './selectImageStyle';
import { UpgradePage, LinkedPage, ImageStyle } from 'graphqlQueries';

export default function getGeneralLayoutBg(data?: Partial<UpgradePage> | Partial<LinkedPage> | null, useDomain = true) {
  if(!data) return '';
  const bgImg = selectImageStyle(
    data?.background?.background_image
      ?.styles as ImageStyle[]
  )?.path || data?.background?.background_image?.url;
  return bgImg ? `${useDomain ? `${process.env.REACT_APP_DOMAIN_ASSET}/` : ''}${bgImg}` :  '';
}