import styled, { css } from 'styled-components';
import { LinkTextMixin } from 'uikit';
import { CheckMark, RadioLabel as uikitRadioLabel } from 'uikit/RadioButton/styled';
import DefaultAddressForm from '../AddressForm';
import { CheckmarkAlt } from 'assets/svg';
import {
  GroupLabel as uikitGroupLabel,
  GroupSubLabel as uikitGroupSubLabel,
  RadioButton as uikitRadioButton,
} from 'uikit';

export const innerContainerMixin = css`
  width: 100%;
  max-width: 443px;
  padding: 0 20px 0 24px;
  box-sizing: border-box;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: inherit;

  * {
    font-family: inherit;
  }
`;

export const GroupLabel = styled(uikitGroupLabel)`
  margin-bottom: 5px;
`;

export const GroupSubLabel = styled(uikitGroupSubLabel)`

`;

export const ItemsContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;
export const ItemsContainerInner = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  max-width: 443px;
  padding: 11px 0 11px 8px;
  margin: 0 auto;
  justify-content: space-between;
`;

export const ItemsLeft = styled.div``;

export const ItemsQuantity = styled.div`
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  vertical-align: top;
  /* line-height: 18px; */
  line-height: 22px;
`;

export const IQEm = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const ItemsRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Price = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 190px;
  font-size: ${({ theme }) => theme.size.subtitle};
  line-height: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const TotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 190px;
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const TotalSum = styled.span`
  font-size: 22px;
`;

export const Form = styled.form`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PaymentGroup = styled.div`
  width: 100%;
  max-width: 420px;
`;

export const AddressGroup = styled.div`
  width: 100%;
  max-width: 420px;

  ${GroupLabel} {
    margin-bottom: 28px;
  }
`;

export const CreditCardsContainer = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-left: 5px;
  }
`;

export const RadioGroup = styled.div`
  width: 100%;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  
  & > * {
    width: 100%;
  }
  
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0;
  justify-content: space-between;

  &.large-padding {
    padding: 18px 0;
  }
`;

export const RadioButton = styled(uikitRadioButton)`
  ${CheckMark} {
    width: 31px;
    height: 31px;
    box-sizing: border-box;

    &:checked,
    &.checked {
      border: 6px solid ${({ theme }) => theme.colors.radioFillBg};

      &:after {
        display: none;
        content: '';
        position: absolute;
        top: 4px;
        left: 4px;
        
        background: ${({ theme }) => theme.colors.radioFillBg};
        border-radius: 50%;
        
      }
    }
  }

  ${uikitRadioLabel} {
    font-family: inherit;
    font-size: ${({ theme }) => theme.size.subtitle}; 
  }

  &.bold-label ${uikitRadioLabel} {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
`;

export const RadioLabel = styled.div`
  display: flex;
  align-items: center;

  & img {
    height: 31px;
  }
`;

export const SuccessIcon = styled(CheckmarkAlt)`
  margin-left: 14px;
`;

export const AddressForm = styled(DefaultAddressForm)`
  margin-top: 20px;
`;

export const PaypalButtonContainer = styled.div`
  margin: 12px auto 0 auto;
  text-align: center;
`;

export const PaypalEmail = styled.p`
  font-weight: 500;
  margin-top: 19px;
  text-align: center;
`;

export const ConnectedEmail = styled.p`
  font-size: ${({ theme }) => theme.size.body1}; 
`;

export const PaymentDetails = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

export const PaymentInfoContainer = styled.div`
  width: calc(100% - 95px);
`;

export const PaymentInfo = styled.p`
  width: 100%;
  margin-top: 4px;
  line-height: 28px;

  span {
    vertical-align: top;
  }

  span:first-of-type {
    display: inline-block;
    margin-right: 20px;
  }
  span:last-of-type {
    max-width: 100%;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const PaymentIconContainer = styled.div`
  
`;

export const PaymentIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 91px;
  height: 37px;
  padding: 5px 8px;
  background-color: #f2f2f2;
  border-radius: 6px;
  
  img {
    width: 100%;
  }
`;

export const ChangePaymentLinkContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 8px;
`;
export const ChangePaymentLink = styled.a`
  ${LinkTextMixin};
  cursor: pointer;
`;

