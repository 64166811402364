import React, { FC } from 'react';
import { getImagePath } from 'helpers';
import Props from '../../types';
import { ImageStyle, ImageStyleName } from 'graphqlQueries';
import {
  Container,
  ContainerInner,
  Image,
  Content,
  ContentInner,
  Info,
  BusinessImageContainer,
  BusinessImage,
  BusinessName,
  Description,
} from './styles';

const CustomLinkItem: FC<Props> = ({ item, href, onClick, linkStyles, styles, className }) => {
  const {
    name,
    images,
    business,
    format,
    description,
  } = item;
  const imagesField = JSON.parse(JSON.stringify(images || []));
  const companyImage = getImagePath(business?.images?.[0]?.styles as ImageStyle[]);
  const offerPhotoStyles = imagesField?.[0]?.styles;
  const offerPhotoUrl = imagesField?.[0]?.url;
  const recordImages = [getImagePath(offerPhotoStyles, ImageStyleName.Original) || offerPhotoUrl];
  let thumbImage = '';
  if(recordImages?.[0]) {
    const parts = recordImages[0].split('/');
    const last = parts?.pop();
    const lastUrl = encodeURI(last).replace(/[!'()*]/g, function(c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
    thumbImage = `${parts.join('/')}/${lastUrl}`;
  }
  const offerSizeClass = format === 'large' ? ' large' : ' small';
  const imagesClass = recordImages?.[0] ? '' : ' no-images';
  const descriptionClass = description ? '' : ' no-description';
  const classSet = `${imagesClass} ${descriptionClass} ${offerSizeClass} ${className}`;
  return (
    <Container
      className={classSet}
      href={href || ""}
      target="_blank"
      onClick={onClick}
      color={linkStyles?.color || ""}
      font={linkStyles?.font_family || ""}
      backgroundColor={linkStyles?.background_color}
      styles={styles}
    >
      <ContainerInner styles={styles} backgroundColor={linkStyles?.background_color}>
        <Image styles={styles} style={{
          backgroundImage: `url(${thumbImage || ""})`
        }}></Image>
        <Content styles={styles}>
          <ContentInner styles={styles}>
            <Info>
              {companyImage && (
                <BusinessImageContainer>
                  <BusinessImage src={companyImage} alt="Business logo" />
                </BusinessImageContainer>
              )}

              <BusinessName>{business?.businessName || name}</BusinessName>
            </Info>
            {description && <Description styles={styles}>{description}</Description>}
          </ContentInner>
        </Content>
      </ContainerInner>
    </Container>
  );
};

export default CustomLinkItem;
