import React, { useState, useEffect, FC } from 'react';
import window from 'global';
import setViewIdData from 'utils/PageViewId';
import { getRoutes } from 'constants/routes';
import MetaData, {
  makeAvatarFromMeta,
  makeAvatarImageOriginal,
  makeAvatarSubstituteLink,
  getFullName,
} from 'components/MetaData/MetaData';
import SocialMediaLinks from 'components/SocialMediaLinks';
import GeneralLayout from 'layouts/GeneralLayout';
import LinksList from 'components/LinksList';
import {
  Container,
  ListContainer,
  ListLabel,
  SocialsWideContainer,
  SocialSubstitute,
} from './styles';
import { sendViewedProductAnalytics } from 'helpers/analytics/productsAndLinks';
import DrumTracking from 'utils/DrumTracking';
import { fDrummerId } from 'utils/idFormatters';
import {
  useGetLinkPageDataQuery,
  SocialAccountIconPositionEnum,
  Link,
  SocialAccount,
  LinkTypeEnum,
  SocialsContainerPosition,
  SocialNetworkEnum,
} from 'graphqlQueries';
import { useUsername } from '../../context/UserNameContext';
import { useRedirection } from '../../utils/serverEffectActions';
import { GeneralPageProps } from 'types';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { sendContactClickAnalytics } from 'helpers/analytics/productsAndLinks/segment';

const LinkPage: FC<GeneralPageProps> = () => {
  const { username, usernameForRoutes, serverUserNameIsUsed } = useUsername();
  const routes = getRoutes(serverUserNameIsUsed);
  const [featuredItems, setFeaturedItems] = useState<Array<Partial<Link>>>([]);
  const [linksItems, setLinksItems] = useState<Array<Partial<Link>>>([]);
  const [trackingIsInitialized, setTrackingIsInitialized] = useState<boolean>(false);
  const redirectTo = useRedirection();


  const templateStyles = useSelector(
    (state: RootState) => state.userMetaData.root?.template_preset
  );

  const { data: pageData, loading: pageDataLoading } = useGetLinkPageDataQuery({
    fetchPolicy: 'cache-first',
    variables: {
      input: {
        username,
      },
    },
    onCompleted: (data: any) => {
      // console.log('****** data', data);
    },
    onError: (err) => {
      console.log('useGetLinkPageLinksQuery::error:json', JSON.stringify(err));
      redirectTo(routes.PAGE_404);
    },
  });

  useEffect(() => {
    if (pageData) {
      setFeaturedItems(
        (pageData?.getLinkPageData?.linksMetadata?.items || []).filter(
          (item) => item?.pinned
        ) as Array<Partial<Link>>
      );
      setLinksItems(
        (pageData?.getLinkPageData?.linksMetadata?.items || []).filter(
          (item) => !item?.pinned
        ) as Array<Partial<Link>>
      );

      const { pageViewId } = setViewIdData();
      const socials = pageData?.getLinkPageData?.linkPage?.social?.items;

      DrumTracking.initLib({
        linkPageViewId: pageViewId,
        isLinkPageViewFromCookie: true,
        drummerId: fDrummerId(pageData?.getLinkPageData?.linkPage?.user?.id || ''),
        userId: pageData?.getLinkPageData?.linkPage?.user?.id,
        username,
        user: pageData?.getLinkPageData?.linkPage?.user,
        pageItems: pageData?.getLinkPageData?.linksMetadata?.items,
        socials: socials,
        pageType: DrumTracking.PAGE_TYPES.linkPage,
        isFromLinkPage: true,
      });
      DrumTracking.session.startSession();
      setTrackingIsInitialized(true);
      DrumTracking.sendPageLoadAnalytics();

      (window as any).addEventListener('focus', DrumTracking.onFocus);
      (window as any).addEventListener('blur', DrumTracking.onBlur);

      return () => {
        (window as any).removeEventListener('focus', DrumTracking.onFocus);
        (window as any).removeEventListener('blur', DrumTracking.onBlur);
      };
    }
    // eslint-disable-next-line
  }, [pageData]);

  const onItemClick = async (item: any) => {
    await DrumTracking.sendClickAnalytics(item);
    await DrumTracking.onItemClick(item);
    if (
      item.type === LinkTypeEnum.VideoProduct ||
      item.type === LinkTypeEnum.DigitalProduct ||
      item.type === LinkTypeEnum.PhysicalProduct
    ) {
      sendViewedProductAnalytics({
        meta: pageData?.getLinkPageData?.linkPage,
        productId: item.id,
        item,
        source: "linkpage"
      });
    }
  };

  const onItemImpression = (item: any) => {
    DrumTracking.onImpressionsChange([{ ...item }]);
  };

  const onSocialClick = (item: SocialAccount) => {
    DrumTracking.onSocialItemClick(item);
    if (item.social === SocialNetworkEnum.Vcard) {
      sendContactClickAnalytics({
        linkId: item.id || '',
        drummerId: pageData?.getLinkPageData?.linkPage?.user?.id || '',
        drummerUsername: username || '',
        title: item.social,
        place: 'social'
      })
    }
  };

  // if (linksError) {
  //   console.log('LinkPage:err::metaError || linksError', linksError);
  //   console.log('LinkPage:err::linksError', JSON.stringify(linksError));
  //   redirectTo(routes.PAGE_404);
  // }

  const handleSubscriptionSuccess = (item) => {
    DrumTracking.onSubscriptionSubmit(item);
  }

  const helmetMeta = React.useMemo(() => {
    const meta = pageData?.getLinkPageData?.linkPage;
    const fullName = getFullName(meta);
    const title = meta?.user?.username ? `${fullName} | My Page` : `My Page`;

    return {
      offerPhotoThumb: makeAvatarFromMeta(meta),
      avatarImage: makeAvatarFromMeta(meta),
      avatarImageOriginal: makeAvatarImageOriginal(meta),
      avatarSubstituteLink: makeAvatarSubstituteLink(meta),
      title,
      metaTitle: title,
      metaDescription: `Click on any of the links to see more.`,
    };
  }, [pageData]);

  return (
    <>
      <MetaData {...helmetMeta} />
      <GeneralLayout
        showLoader={pageDataLoading || !pageData?.getLinkPageData?.linkPage}
        metaData={pageData?.getLinkPageData?.linkPage}
        description={pageData?.getLinkPageData?.linkPage?.description || ''}
        socials={
          pageData?.getLinkPageData?.linkPage?.social_account_position ===
            SocialAccountIconPositionEnum.Top
            ? (pageData?.getLinkPageData?.linkPage?.social?.items as SocialAccount[]) || []
            : []
        }
        onSocialClick={onSocialClick}
        usePageStyles={false}
        useCollectionStyles={true}
      >
        <Container>
          <>
            {pageData?.getLinkPageData?.linkPage?.social_account_position ===
              SocialAccountIconPositionEnum.Bottom &&
              pageData?.getLinkPageData?.linkPage?.template_preset?.socialsContainer?.position ===
              SocialsContainerPosition.Top && <SocialSubstitute></SocialSubstitute>}
            {trackingIsInitialized && (
              <>
                {!!featuredItems.length && (
                  <ListContainer>
                    <ListLabel
                      className="no-margin"
                      styles={templateStyles}
                    >
                      Featured
                    </ListLabel>
                    <LinksList
                      items={featuredItems || []}
                      onClick={onItemClick}
                      onImpression={onItemImpression}
                      userName={usernameForRoutes}
                      userId={pageData?.getLinkPageData?.linkPage?.user?.id}
                      onSubscriptionSubmitSuccess={handleSubscriptionSuccess}
                    />
                  </ListContainer>
                )}

                {!!linksItems.length && (
                  <ListContainer>
                    {!!featuredItems.length && (
                      <ListLabel styles={templateStyles}>
                        My links
                      </ListLabel>
                    )}
                    <LinksList
                      items={linksItems || []}
                      onClick={onItemClick}
                      onImpression={onItemImpression}
                      userName={usernameForRoutes}
                      userId={pageData?.getLinkPageData?.linkPage?.user?.id}
                      onSubscriptionSubmitSuccess={handleSubscriptionSuccess}
                    />
                  </ListContainer>
                )}
              </>
            )}

            {pageData?.getLinkPageData?.linkPage?.social_account_position ===
              SocialAccountIconPositionEnum.Bottom && (
                <SocialsWideContainer>
                  <SocialMediaLinks
                    items={
                      (pageData?.getLinkPageData?.linkPage?.social?.items as SocialAccount[]) || []
                    }
                    onItemClick={onSocialClick}
                    className="bottom"
                  />
                </SocialsWideContainer>
              )}
          </>
        </Container>
      </GeneralLayout>
    </>
  );
};

export default LinkPage;
