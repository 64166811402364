import React, { FC } from 'react';
import styled from 'styled-components';

const LoaderContainer = styled.div`
  display: flex;
  min-height: 200px;
  justify-content: center;
  align-items: center;
`;
const Loader = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid ${({ theme }) => theme.colors.accentMainColor};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ theme }) => theme.colors.accentMainColor} transparent
      transparent transparent;
  }
  & div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

interface Props {
  className?: string;
}

const UILoader: FC<Props> = ({ className }) => (
  <LoaderContainer>
    <Loader className={className}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Loader>
  </LoaderContainer>
);

export default UILoader;
