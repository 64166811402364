import React, { FC } from 'react';
import { ICartItem } from '../../types';
import { ProductTypeEnum, ImageStyle, ImageStyleName, TemplatePreset } from 'graphqlQueries';
import { getImagePath } from 'helpers';
import {
  Container,
  MainContainer,
  ImageContainer,
  InfoContainer,
  Title,
  Category,
  RightContainer,
  Price,
  RemoveButton,
  NotesContainer,
  Notes,
  EditButton
} from './styles';
import { getMoneyFormat } from '../../utils';
export interface IOption {
  label: string;
  value: string;
}

interface ICart {
  item: ICartItem;
  templateStyles?: TemplatePreset;
  onRemove?: (id: string) => void;
  onEdit?: (id: string) => void;
}

const Cart: FC<ICart> = ({ item, templateStyles, onRemove, onEdit }) => {
  const handleRemove = (id: string) => {
    onRemove?.(id);
  };

  const handleEdit = (id: string) => {
    onEdit?.(id);
  };

  const previewUrl = getImagePath(item.product?.images?.[0]?.styles as ImageStyle[], ImageStyleName.Original);

  return (
    <Container>
      <MainContainer>
        {previewUrl &&
          <ImageContainer style={{backgroundImage: `url(${previewUrl})`}}>
            {/* <Image src={previewUrl || ""} alt={item.product.title || ""} /> */}
          </ImageContainer>
        }

        <InfoContainer>
          <Title>{item.product.title || ''}</Title>
          {item.product.type === ProductTypeEnum.PhysicalProduct && (
            <>
              {item.product?.properties?.options?.map(option => (
                <>
                  {(option?.field && option?.id) &&
                    <>
                      <Category>{option.field}: {item?.config?.[option.id]?.label || ""}</Category>
                    </>
                  }
                </>
              ))}
              <Category>Quantity: {item?.config?.quantity || 1}</Category>
            </>
          )}
        </InfoContainer>

        <RightContainer>
          {item.product.type === ProductTypeEnum.PhysicalProduct ?
            <Price>${getMoneyFormat(item.config.price || item.product.price || 0)}</Price>
            :
            <Price>${getMoneyFormat(item.product.price || 0)}</Price>
          }
          
          <RemoveButton styles={templateStyles} onClick={() => handleRemove(item.product.id || '')}>
            Remove
          </RemoveButton>
        </RightContainer>
      </MainContainer>
      {item.product.type === ProductTypeEnum.VideoProduct && <NotesContainer>
        <Notes>{item.notes}</Notes>
        <EditButton styles={templateStyles} onClick={() => handleEdit(item.product.id || '')}>Edit</EditButton>
      </NotesContainer>}
    </Container>
  );
};

export default Cart;
