import { ImageStyle } from "graphqlQueries";

const DEFAULT_OPTIONS = {
  priorityOrder: ['original', 'medium', 'large', 'small', 'square', 'cropped'],
  styleFallbackIndex: 0,
  fallback: null,
};

export default function selectImageStyle(
  styles: ImageStyle[] | null,
  optionsInput = {}
): ImageStyle | null {
  const options = Object.assign({}, DEFAULT_OPTIONS, optionsInput);
  if (!styles || !Array.isArray(styles) || styles.length === 0) {
    return options.fallback;
  }
  for (const styleName of options.priorityOrder) {
    const style = styles.find((s) => s.name === styleName);
    if (style) {
      return style;
    }
  }
  if (!options.fallback && options.styleFallbackIndex) {
    return styles[options.styleFallbackIndex];
  }
  return options.fallback;
}