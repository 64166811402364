import styled from 'styled-components';
import { Body3, ErrorTextMixin } from '../Typography';

export const Container = styled.div`
  display: flex;
  align-self: center;
  margin-top: 5px;

  
`;

export const ErrorMessage = styled.div`
  ${ErrorTextMixin};

  height: 20px;
`;

export const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 5px;
`;

export const CharactersLimit = styled(Body3)`
  margin-left: auto;
  color: ${({ theme }) => theme.colors.bodyTextColor};
`;
