import * as yup from 'yup';
import { emailRegExp } from 'constants/regExp';

const validationSchema = yup.object({
  email: yup.string().required(`Email is required`).test('invalid email', 'Invalid email', function(value) {
    return emailRegExp.test(value);
  }),
});

export type SchemaType = yup.InferType<typeof validationSchema>;

export default validationSchema;