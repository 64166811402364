import styled, { css } from 'styled-components';

export const WidgetContainerMixin = css`
  border-radius: 6px;
  border: 1px solid #dbdbdb;
`;

export const WidgetContainer = styled.div`
  ${WidgetContainerMixin}
`;


