import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TemplatePreset } from 'graphqlQueries';

interface Props {
  styles?: TemplatePreset;
}

export const Container = styled.div<Props>(({styles}) =>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  padding-left: ${styles?.link?.base?.desktopListPadding?.left || 0}px;
  padding-right: ${styles?.link?.base?.desktopListPadding?.right || 0}px;

  @media(max-width: 600px) {
    padding-left: ${styles?.link?.base?.mobileListPadding?.left || 0}px;
    padding-right: ${styles?.link?.base?.mobileListPadding?.right || 0}px;
  }
  
  .infinite-scroll-component {
    overflow: initial !important;
  }
`);
export const InfiniteScrollContainer = styled.div`
  width: 100%;

  & > div,
  & > div > div {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
