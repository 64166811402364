import React, { ReactElement } from 'react';

// import 'swiper/css';
import 'swiper/swiper-bundle.css'
// import 'swiper/css/swiper.min.css'
// import 'swiper/css/swiper.css' // << DM: VZ need to check it 
import { default as ReactSwiper, ReactIdSwiperProps } from 'react-id-swiper';
import { Container } from './styled';

type Props = {
  children: ReactElement<any>[];
  showPagination?: boolean
};

const Swiper: React.FC<Props> = ({ children, showPagination = false }: Props) => {

  const params: ReactIdSwiperProps = {
    navigation: {
      nextEl: '.swiper-button-nav.swiper-button-next.customized-swiper-button-next',
      prevEl: '.swiper-button-nav.swiper-button-prev.customized-swiper-button-prev'
    },
  }
  if (showPagination) {
    params.pagination = {
      el: '.swiper-pagination',
      type: 'bullets' as 'bullets' | 'fraction' | 'progressbar' | 'custom',
      clickable: true
    }
  }

  return (
    <Container>
      <ReactSwiper {...params}>
        {children}
      </ReactSwiper>
    </Container>
  );
};

export default Swiper;
