import styled, { DefaultTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';
import { colord } from 'colord';
import { PrimaryButtonMixin, PrimaryButton } from 'uikit';
import { TemplatePreset } from 'graphqlQueries';
import ITemplatePreset from '../../types/TemplatePreset';

export const ButtonStyledMixin = (
  theme: DefaultTheme,
  styles?: TemplatePreset
) => `
  ${PrimaryButtonMixin}

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  text-align: center;
  font-size: ${theme.size.subtitle};
  font-weight: ${theme.fontWeight.bold};
  text-decoration: none;
  background: ${theme.colors.dlpDefaultButtonBgColor};
  border: none;
  box-shadow: 0px 1px 3px ${theme.colors.dlpOpportunityButtonShadowColor};
  border-radius: ${styles?.primaryButton?.borderRadius || 0}px;

  ${
    styles?.primaryButton?.backgroundColor &&
    `background-color: ${styles?.primaryButton?.backgroundColor}`
  };
  ${styles?.primaryButton?.color && `color: ${styles?.primaryButton?.color}`};
  
  ${
    styles?.primaryButton?.backgroundColor &&
    `
    :hover {
      background-color: ${colord(styles.primaryButton.backgroundColor)
        .darken(0.15)
        .toHex()};
    }

    :active {
      background-color: ${colord(styles.primaryButton.backgroundColor)
        .darken(0.15)
        .toHex()};
    }

    :disabled {
      background-color: ${colord(styles.primaryButton.backgroundColor)
        .desaturate(1)
        .toHex()};
    }
    `
  }
`;

export const LinkStyled = styled(Link)<ITemplatePreset>(
  (props) => `
  ${ButtonStyledMixin(theme, props.styles)}
`
);
export const HyperLinkStyled = styled.a<ITemplatePreset>(
  (props) => `
  ${ButtonStyledMixin(theme, props.styles)}
`
);
export const ButtonStyled = styled(PrimaryButton)<ITemplatePreset>(
  (props) => `
  ${ButtonStyledMixin(theme, props.styles)}
`
);

export default ButtonStyled;
