import React, { FC } from 'react';
import { generatePath } from 'react-router-dom';
import { getRoutes } from 'constants/routes';
import { useUsername } from 'context/UserNameContext';
import {
  Container,
  ContainerInner,
  CreateLink,
  Info,
  ImageOuter,
  ImageContainer,
  Image,
} from './styles';
import { TemplatePreset } from 'graphqlQueries';

interface Props {
  backgroundColor?: string | null;
  textColor?: string | null;
  styles?: TemplatePreset;
}

const Footer: FC<Props> = ({ backgroundColor, textColor, styles }) => {
  const { serverUserNameIsUsed, username } = useUsername();
  const routes = getRoutes(serverUserNameIsUsed);
  const createPageLink = generatePath(routes.REFERRAL_PAGE, {
    name: username,
  });

  return (
    <Container
      styles={styles}
      backgroundColor={backgroundColor}
      textColor={textColor}
    >
      <ContainerInner styles={styles}>
        <CreateLink styles={styles} href={createPageLink} target="_blank">
          Create your own page
        </CreateLink>
        <Info>
          <ImageOuter styles={styles}>
            <ImageContainer styles={styles}>
              <Image styles={styles} />
            </ImageContainer>
          </ImageOuter>
        </Info>
      </ContainerInner>
    </Container>
  );
};

export default Footer;
