import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  padding-left: 37px;
  padding-right: 37px;
`;

export const ListContainer = styled.div`
  
`;

