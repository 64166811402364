import styled, { css } from 'styled-components';
import { Tab, Tabs, TabList, TabPanel, PrimaryButton, ButtonBase } from 'uikit';
import { ChevronRight } from 'assets/svg';
import { TemplatePreset } from 'graphqlQueries';

interface ContainerProps {
  font?: string | null;
}

interface StyleProps {
  styles?: TemplatePreset;
}

export const innerContainerMixin = css`
  width: 100%;
  max-width: 360px;
  box-sizing: border-box;
`;

export const Container = styled.div<ContainerProps>`
  color: inherit;
  font-family: ${({ font }) => font || 'inherit'};
  width: 100%;
  padding: 0;

  * {
    font-family: inherit;
  }
`;

export const StyledTabs = styled(Tabs)`
  width: 100%;
`;

export const StyledTabList = styled(TabList)`
  padding: 0 35px;
  justify-content: center;
  align-items: center;
  @media(max-width: 400px) {
    padding: 0 25px;
  }
`;

export const StyledTabPanel = styled(TabPanel)`
  margin-top: 10px;
  border: none;
  border: none !important;
`;

export const CartQuantity = styled.div`
  position: absolute;
  top: 6px;
  right: 0;
  width: 27px;
  height: 19px;
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 15px;
  text-align: center;
  background: ${({ theme }) => theme.colors.black};
`;

export const StyledTab = styled(Tab)<StyleProps>(({theme, styles}) => `
  position: relative;
  font-size: ${theme.size.body1};
  font-weight: ${theme.fontWeight.regular};
  height: 36px;
  min-width: 0;
  line-height: 21px;
  padding: 0;
  align-items: center;
  justify-content: center;
  border: none;

  &.react-tabs__tab--selected {
    color: ${styles?.cart?.activeTabColor || theme.colors.danger};
    border-bottom: none;
    z-index: 1;
    ${CartQuantity} {
      background: ${styles?.cart?.activeTabColor || theme.colors.danger};
    }
    
  }
`);

export const StyledTabChevron = styled(ChevronRight)<StyleProps>(({styles}) => `
  width: 14px;
  height: 22px;
  margin: 0 14px;
  position: relative;
  top: 1px;

  & path {
    stroke: ${styles?.cart?.arrowColor || '#C4C4C4'};
  }
`);

export const ButtonsContainer = styled.div`
  ${innerContainerMixin};
  margin-top: 40px;
  text-align: center;
`;

export const SubmitButton = styled(PrimaryButton)`
  width: 100%;
  height: 56px;
  border-radius: 8px;
`;

export const CancelButton = styled(ButtonBase)`
  margin-top: 15px;
  display: inline-block;
  background: transparent;
  color: ${({ theme }) => theme.colors.dlpPurchaseCancelButtonColor};
  border-radius: 8px;
  box-shadow: none;
  border: none;

  &:hover,
  &:active,
  &:focus {
    background: transparent !important;
    color: ${({ theme }) => theme.colors.dlpPurchaseCancelButtonColor};
    text-decoration: underline;
  }
`;