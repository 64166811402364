import React, { FC, useEffect } from 'react';
import { Formik, useFormikContext, FormikErrors } from 'formik';
import { CartItemTypes, ICartItem } from '../../types';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'redux/store';
import actions from 'redux/actions';
import ShippingHandling from 'components/ShippingHandling';
import PrimaryButtonStyled from 'components/PrimaryButtonStyled';
import CancelButtonStyled from 'components/CancelButtonStyled';
import { getShippingSum } from '../../utils';
import {
  validationSchemaNonPhysical,
  validationSchemaPhysical,
  SchemaTypeNonPhysical,
} from './validationSchema';
import {
  InputContainer,
  InputGroup,
  InputBase,
  InputLabel,
  GroupLabel,
  FieldFooter,
  PhoneInput,
} from 'uikit';
import {
  LinkStyleConfiguration,
  ProductTypeEnum,
  TemplatePreset,
} from 'graphqlQueries';
import { Container, FormGroup, AddressForm } from './styles';
import { ButtonsContainer } from '../../styles';

interface IInfo {
  items: ICartItem[];
  type?: CartItemTypes;
  inputStyles?: Pick<
    LinkStyleConfiguration,
    'background_color' | 'color' | 'font_family'
  > | null;
  templateStyles?: TemplatePreset;
  onContinue?: (
    validateForm: (
      values?: SchemaTypeNonPhysical
    ) => Promise<FormikErrors<SchemaTypeNonPhysical>>,
    setSubmitting: (isSubmitting: boolean) => void,
    values: SchemaTypeNonPhysical
  ) => void;
  onCancel?: () => void;
  shouldValidateOnChange: boolean;
}

export interface InfoValues {
  firstName?: string | null;
  lastName?: string | null;
}

const addressFields = [
  'shipping.firstName',
  'shipping.lastName',
  'shipping.street',
  'shipping.appartment',
  'shipping.city',
  'shipping.state',
  'shipping.zipCode',
];

const Information: FC<IInfo> = ({
  items,
  type = CartItemTypes.video,
  inputStyles,
  templateStyles,
  onContinue,
  onCancel,
}) => {
  const dispatch = useDispatch();

  const {
    errors,
    handleChange,
    isSubmitting,
    setSubmitting,
    values,
    setFieldValue,
    validateForm,
  } = useFormikContext<SchemaTypeNonPhysical>();

  const handleSubmit = () => {
    onContinue?.(validateForm, setSubmitting, values);
  };

  const handleCancel = () => {
    onCancel?.();
  };
  useEffect(() => {
    dispatch(actions.checkout.setCheckoutInfoForm(values));
    // eslint-disable-next-line
  }, [values]);

  const shippingFee = getShippingSum(items);
  const showShipping = !!items.find(
    (item) => item.product.type === ProductTypeEnum.PhysicalProduct
  );

  return (
    <Container>
      <>
        {/* CUSTOMER INFO */}
        <GroupLabel>My Information</GroupLabel>
        <FormGroup>
          <InputContainer>
            <InputGroup styles={templateStyles}>
              <InputLabel>Email</InputLabel>
              <InputBase
                name="customer.email"
                onChange={handleChange}
                value={values.customer.email || ''}
                styles={templateStyles}
              />
            </InputGroup>
            <FieldFooter error={errors.customer?.email} showAlways={false} />
          </InputContainer>

          <InputContainer>
            <InputGroup styles={templateStyles}>
              <InputLabel>Phone (optional)</InputLabel>
              <PhoneInput
                name="customer.phone"
                onChange={(value) => {
                  setFieldValue('customer.phone', value);
                }}
                value={values.customer.phone || ''}
              />
            </InputGroup>
            <FieldFooter error={errors.customer?.phone} showAlways={false} />
          </InputContainer>
        </FormGroup>
      </>

      {/* SHIPPING INFO */}
      {items[0]?.product?.type === ProductTypeEnum.PhysicalProduct && (
        <>
          <GroupLabel>Shipping Address</GroupLabel>
          <AddressForm
            inputStyles={inputStyles}
            fieldsArray={addressFields}
            templateStyles={templateStyles}
          />
        </>
      )}

      <ShippingHandling
        showTaxes={true}
        shippingFee={shippingFee}
        showShipping={showShipping}
      />

      <ButtonsContainer>
        <PrimaryButtonStyled
          type="submit"
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          Continue
        </PrimaryButtonStyled>
        <CancelButtonStyled type="button" onClick={handleCancel}>
          Cancel
        </CancelButtonStyled>
      </ButtonsContainer>
    </Container>
  );
};

const InformationContainer: FC<IInfo> = (props) => {
  const initialValues = useSelector(
    (state: RootState) => state.checkout.infoFormValues
  );
  const schema =
    props.items[0]?.product?.type === ProductTypeEnum.PhysicalProduct
      ? validationSchemaPhysical
      : validationSchemaNonPhysical;
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
      validationSchema={schema}
      validateOnChange={props.shouldValidateOnChange}
    >
      <Information {...props} />
    </Formik>
  );
};

export default InformationContainer;
