import { window } from 'global';

const getIFrameUrl = (url, provider, ssrContext) => {
  const { isOnServer, serverTimeContext } = ssrContext;
  const { hostname: dlpUrl } = !isOnServer
    ? new URL(window?.location?.href)
    : { hostname: serverTimeContext.req.header('Host') };

  let _url = url;
  if (provider === 'youtube' && provider.indexOf('/embed/') === -1) {
    let parts = (url || '').split('?v=', 2);
    let urlPart = parts[1];
    if (parts.length === 1) {
      parts = (url || '').split('/');
      urlPart = parts.pop();
    }
    urlPart = urlPart.replace('&t=', '&start=');
    urlPart = urlPart.replace('?t=', '?start=');
    _url = 'https://www.youtube.com/embed/' + urlPart;
  } else if (provider === 'vimeo' && provider.indexOf('//player') === -1) {
    const parts = (url || '').split('/');
    const id = parts.pop();
    _url = `https://player.vimeo.com/video/${id}`;
  } else if (provider === 'twitch' && provider.indexOf('//player') === -1) {
    const parts = (url || '').split('/');
    let id = parts.pop();
    let type = parts.pop();

    if (type === 'videos') {
      type = 'video';
    } else if (type !== 'video' && type !== 'collection') {
      type = 'channel';
    }

    id = id?.replace('?', '&');

    if(url.indexOf('https://clips.twitch.tv') !== -1) {
      _url = `https://clips.twitch.tv/embed?clip=${id}&parent=${dlpUrl}&autoplay=false`;
    } else {
      _url = `https://player.twitch.tv/?${type}=${id}&parent=${dlpUrl}&autoplay=false`;
    }

    
    
  } else if (provider === 'spotify' && provider.indexOf('/embed/') === -1) {
    _url = url.replace('spotify.com/', 'spotify.com/embed/');
  } else if (provider === 'soundcloud' && provider.indexOf('/player/') === -1) {
    _url = `https://w.soundcloud.com/player/?url=${url}&show_teaser=false`;
  }

  if (_url.indexOf('&') !== -1 && _url.indexOf('?') === -1) {
    _url = _url.replace('&', '?');
  }

  return _url;
};

export default getIFrameUrl;
