import styled from 'styled-components';
import { PrimaryButtonMixin, LinkTextMixin } from 'uikit';

export const Container = styled.div`
  font-family: Roboto;
`;

export const Text = styled.p`
  margin-bottom: 32px;
  line-height: 26px;
`;

export const AppLink = styled.a`
  ${LinkTextMixin};
`;

export const DownloadButton = styled.a`
  ${PrimaryButtonMixin}
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 56px;
  box-sizing: border-box;
  margin: 32px auto 0 auto;
  border-radius: 8px;
`;