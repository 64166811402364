import React from 'react';
import { Container, Logo, Copyright } from './styles';

const WideFooter = () => {
  return (
		<Container>
			<Logo src="/assets/drum-white.png" alt="drum"/>
			<Copyright>© Drum Technologies Inc. All rights reserved.</Copyright>
		</Container>
	)
	;
};

export default WideFooter;
