import { v4 as uuid } from 'uuid';
import window from 'global';
import { getLandingURL } from 'helpers/LinkItem';
import { log } from 'utils/drumUtils';
import drumCookies from 'utils/drumCookies';
import { LinkTypeEnum, SocialAccount, Link, OfferDlp } from 'graphqlQueries';
import {
  sendImpressionsAnalytics,
  sendLinkClickAnalytics,
} from '../helpers/analytics/productsAndLinks/';

const DrumTracking: any = {
  track: () => { },
  onImpressionsChange: () => { },
  isInitialized: false,
};

DrumTracking.PAGE_TYPES = {
  collectionPage: 'collection-page',
  offerPage: 'offer-page',
  linkPage: 'link-page',
};

DrumTracking.LINK_PAGE_VIEW_ID_SOURCES = {
  argument: 'argument',
  sessionStorage: 'sessionStorage',
  cookie: 'cookie',
};

export const DRUM_SESSION_ID_KEY = '__drumSessionId';

export const getSavedSessionId = () => {
  return sessionStorage.getItem(DRUM_SESSION_ID_KEY);
};

export const setSavedSessionId = (val) => {
  sessionStorage.setItem(DRUM_SESSION_ID_KEY, val);
};

export const generateSavedSessionId = () => {
  const uid = uuid();
  setSavedSessionId(uid);
  return uid;
};

export const deleteSavedSessionId = () => {
  sessionStorage.removeItem(DRUM_SESSION_ID_KEY);
};

DrumTracking.initLib = ({
  drummerId,
  userId,
  username,
  user,
  pageItems,
  socials,
  collectionLinkId,
  collectionId,
  pageType,
  isFromLinkPage,
}) => {
  //const isFromLinkPage = pageType === DrumTracking.PAGE_TYPES.linkPage;
  const isOnCollectionPage = pageType === DrumTracking.PAGE_TYPES.collectionPage;
  const isOnOfferPage = pageType === DrumTracking.PAGE_TYPES.offerPage;
  DrumTracking.isInitialized = true;

  log(
    'DrumTracking.initLib',
    JSON.stringify({
      linkPageTrackUrl: process.env.REACT_APP_LINK_PAGE_TRACK_URL,
      drummerId,
      userId,
      username,
      user,
      pageItems: (Array.isArray(pageItems) && pageItems.length) || pageItems,
      socials: (Array.isArray(socials) && socials.length) || socials,
    })
  );

  /**
   * ---
   * --- Link Page Tracking Utils
   * ---
   */

  DrumTracking.track = async (eventName, eventData) => {
    const linkPageViewId = DrumTracking.session.getSavedSessionId();

    if (!linkPageViewId) {
      console.warn(
        'Tried to track with falsy DrumTracking.session.currentSessionId:',
        DrumTracking.session.getSavedSessionId()
      );
      return;
    }
    const debuggableUrl = [
      process.env.REACT_APP_LINK_PAGE_TRACK_URL,
      eventName.slice(8),
      linkPageViewId.slice(0, 5),
      ...(eventData.pauseId ? [eventData.pauseId.slice(0, 4)] : []),
    ].join('_');
    const body = JSON.stringify({
      eventName,
      linkPageViewId,
      drummerId,
      eventData: {
        ...eventData,
        userAgent: navigator.userAgent,
        cookiesData: {
          linkPageViewIdCookie: DrumTracking.session.getSavedSessionId(),
          linkPageDidCloseSessionAtUrlCookie:
            DrumTracking.session.getLinkPageDidCloseSessionAtUrlCookie(),
          refCountCookie: drumCookies.getRefCountCookie(),
        },
        pageData: {
          locationHref: (window as any)?.location.href,
        },
        invitedByDrummerId: user?.invitedByDrummerId,
      },
    });
    navigator.sendBeacon(debuggableUrl, body);
  };
  DrumTracking.LP_VIEW_EVENTS = {
    ini: 'LPEvent-Ini',
    opened: 'LPEvent-Opened',
    reopened: 'LPEvent-Reopened',
    paused: 'LPEvent-Paused',
    unpaused: 'LPEvent-Unpaused',
    closed: 'LPEvent-Closed',
  };
  DrumTracking.LP_ON_PAGE_EVENTS = {
    click: 'LPEvent-Click',
    impression: 'LPEvent-Impression',
    subscriptionSubmitEmail: 'LPEvent-SubmitEmail',
    subscriptionSubmitPhone: 'LPEvent-SubmitPhone'
  };
  DrumTracking.LP_CARD_TYPES = {
    offer: 'offer',
    customLink: 'customLink',
    collection: 'collection',
    videoProduct: 'videoProduct',
    digitalProduct: 'digitalProduct',
    physicalProduct: 'physicalProduct',
    subscribe: 'subscribe',
    nestedOffer: 'nestedOffer',
    nestedCustomLink: 'nestedCustomLink',
    vcard: 'vcard',
    nestedVcard: 'nestedVcard'
  };
  // DrumTracking.LP_CARD_SUBSCRIBE_LINK_SUBTYPES = {
  //   email: 'email',
  //   phone: 'phone'
  // };
  DrumTracking.LP_CARD_CUSTOM_LINK_SUBTYPES = {
    classicLink: 'classicLink',
    socialLink: 'socialLink',
    mediaLink: 'mediaLink',
  };
  DrumTracking.cardTypeAssertions = {};
  DrumTracking.cardTypeAssertions.isOfferCardType = (type) => {
    return type === DrumTracking.LP_CARD_TYPES.offer;
  };
  DrumTracking.cardTypeAssertions.isCollectionCardType = (type) => {
    return type === DrumTracking.LP_CARD_TYPES.collection;
  };
  DrumTracking.cardTypeAssertions.isCustomLinkCardType = (type) => {
    return type === DrumTracking.LP_CARD_TYPES.customLink;
  };
  DrumTracking.cardTypeAssertions.isNestedOfferCardType = (type) => {
    return type === DrumTracking.LP_CARD_TYPES.nestedOffer;
  };
  DrumTracking.cardTypeAssertions.isNestedCustomLinkCardType = (type) => {
    return type === DrumTracking.LP_CARD_TYPES.nestedCustomLink;
  };
  DrumTracking.trackPageViewInitiated = async (linkPageViewId) => {
    return await DrumTracking.track(DrumTracking.LP_VIEW_EVENTS.ini, {
      linkPageViewId,
      username,
      timestamp: new Date().toISOString(),
    });
  };
  DrumTracking.trackPageOpened = async () => {
    return await DrumTracking.track(DrumTracking.LP_VIEW_EVENTS.opened, {
      timestamp: new Date().toISOString(),
    });
  };
  DrumTracking.trackPageReopened = async () => {
    return await DrumTracking.track(DrumTracking.LP_VIEW_EVENTS.reopened, {
      timestamp: new Date().toISOString(),
    });
  };
  /**
   * @param {string} pauseId - unique id for the current pause
   */
  DrumTracking.trackPagePaused = async (pauseId, eventData) => {
    return await DrumTracking.track(DrumTracking.LP_VIEW_EVENTS.paused, {
      ...eventData,
      timestamp: new Date().toISOString(),
      pauseId,
    });
  };
  /**
   * @param {string} pauseId - unique id for the current pause
   */
  DrumTracking.trackPageUnpaused = async (pauseId, eventData) => {
    return await DrumTracking.track(DrumTracking.LP_VIEW_EVENTS.unpaused, {
      ...eventData,
      timestamp: new Date().toISOString(),
      pauseId,
    });
  };
  DrumTracking.trackPageClosed = async () => {
    return await DrumTracking.track(DrumTracking.LP_VIEW_EVENTS.closed, {
      timestamp: new Date().toISOString(),
    });
  };
  /**
   * @param {string} onPageEventData.cardId  - offerId or collectionId or linkId if it is event from the Link Page,
   * offerId or customLink id if it is event from Collection Page
   * @param {string} onPageEventData.cardType - DrumTracking.LP_CARD_TYPES
   * @param {string} onPageEventData.cardSubType - DrumTracking.LP_CARD_CUSTOM_LINK_SUBTYPES
   * @param {string} onPageEventData.linkId - link page link id if it is event from the Link Page
   * @param {string=} onPageEventData.collectionId - if it is the event from Collection Page
   *
   * ...
   */
  DrumTracking.trackClick = async (onPageEventData) => {
    log(
      'DrumTracking.trackClick::',
      `[${onPageEventData.cardType}:${onPageEventData.cardSubType}]`,
      onPageEventData.cardId,
      new Error()?.stack
        ?.split('\n')
        .slice(1, 5)
        .map((x) => x.trim())
    );

    return await DrumTracking.track(DrumTracking.LP_ON_PAGE_EVENTS.click, {
      timestamp: new Date().toISOString(),
      onPageEventData: {
        ...onPageEventData,
        ...(!isOnCollectionPage
          ? {}
          : {
            collectionLinkId,
            collectionId,
          }),
      },
    });
  };
  DrumTracking.trackSubscriptionSuccess = async (onPageEventData) => {
    log(
      'DrumTracking.trackSubscriptionSuccess::',
      `[${onPageEventData.cardType}:${onPageEventData.cardSubType}]`,
      onPageEventData.cardId,
      new Error()?.stack
        ?.split('\n')
        .slice(1, 5)
        .map((x) => x.trim())
    );

    const method = onPageEventData.cardSubType === "email" ? DrumTracking.LP_ON_PAGE_EVENTS.subscriptionSubmitEmail : DrumTracking.LP_ON_PAGE_EVENTS.subscriptionSubmitPhone;

    return await DrumTracking.track(method, {
      timestamp: new Date().toISOString(),
      onPageEventData: {
        ...onPageEventData,
        ...(!isOnCollectionPage
          ? {}
          : {
            collectionLinkId,
            collectionId,
          }),
      },
    });
  };
  /**
   * @param {string} onPageEventData.cardId  - offerId or collectionId or linkId if it is event from the Link Page,
   * offerId or customLink id if it is event from Collection Page
   * @param {string} onPageEventData.cardType - DrumTracking.LP_CARD_TYPES
   * @param {string} onPageEventData.linkId - link page link id if it is event from the Link Page
   * @param {string} onPageEventData.collectionId - if it is the event from Collection Page
   */
  DrumTracking.trackImpression = async (onPageEventData) => {
    return await DrumTracking.track(DrumTracking.LP_ON_PAGE_EVENTS.impression, {
      timestamp: new Date().toISOString(),
      onPageEventData,
    });
  };

  /**
   * ---
   * --- Link Page Session Management
   * ---
   */

  DrumTracking.session = {
    IMPRESSION_REQUEST_INTERVAL: 2500,
    DRUM_SESSION_ID_KEY,
    DRUM_REDIRECTED_NOT_CLOSED_KEY: '__redirectedNotClosed',
    DRUM_SESSION_IMPRESSION_ITEMS_KEY: '__drumImpressionItems',
    DRUM_LINK_PAGE_DID_CLOSE_SESSION_AT_URL_COOKIE_KEY: 'drum_linkPageDidCloseSessionAtUrl',
    currentSessionId: null,
    impressionsRequestIntervalId: null,
    currentPauseId: uuid(),
    pollingActiveMediaLinkIframeElementIntervalId:
      DrumTracking?.session?.pollingActiveMediaLinkIframeElementIntervalId || null,
  };
  DrumTracking.session.impressionsTracking = {
    settingImpressions: false,
    getSettingImpressionsLock() {
      return this.settingImpressions;
    },
    setSettingImpressionsLock(val) {
      this.settingImpressions = val;
    },
    newImpressionItems: [],
    getNewImpressionItems() {
      return this.newImpressionItems;
    },
    setNewImpressionItems(val) {
      return (this.newImpressionItems = val);
    },
    //impressionItems: [],
    impressionItems: {
      linkPage: [],
    },
    getImpressionItems() {
      return this.impressionItems[collectionId || 'linkPage'] || [];
    },
    setImpressionItems(val) {
      return (this.impressionItems[collectionId || 'linkPage'] = val);
    },
    getAllImpressionItems() {
      //All impression items means link page and all collections visited during that session
      return this.impressionItems;
    },
    setAllImpressionItems(val) {
      return (this.impressionItems = val);
    },
  };
  DrumTracking.session.mediaLinks = {
    MEDIA_LINKS_QUERY_INTERVAL: 1000,
    mediaLinkIframes: [],
    setMediaLinkIframes(val) {
      this.mediaLinkIframes = val;
    },
    getMediaLinkIframes() {
      return this.mediaLinkIframes;
    },
  };

  DrumTracking.session.getIsRedirectedSessionKey = () => {
    return sessionStorage.getItem(DrumTracking.session.DRUM_REDIRECTED_NOT_CLOSED_KEY);
  };

  DrumTracking.session.setIsRedirectedSessionKey = (val) => {
    return sessionStorage.setItem(DrumTracking.session.DRUM_REDIRECTED_NOT_CLOSED_KEY, val);
  };

  DrumTracking.session.deleteIsRedirectedSessionKey = () => {
    return sessionStorage.removeItem(DrumTracking.session.DRUM_REDIRECTED_NOT_CLOSED_KEY);
  };

  DrumTracking.session.closeSession = () => {
    //const { DRUM_SESSION_IMPRESSION_ITEMS_KEY } = DrumTracking.session;
    const currentSessionId = DrumTracking.session.getSavedSessionId();
    if (currentSessionId) {
      DrumTracking.trackPageClosed();
      log(`Link Page Session Ended: ${currentSessionId}`);
    }

    //sessionStorage.removeItem(DRUM_SESSION_IMPRESSION_ITEMS_KEY); // now we clear impressions data on new session's opening
    DrumTracking.session.clearImpressionsInterval();
    clearInterval(DrumTracking.session.pollingActiveMediaLinkIframeElementIntervalId);
    DrumTracking.session.initLinkPageDidCloseSessionCookie();
  };
  DrumTracking.session.initMediaLinks = () => {
    const mediaLinkIframes = [
      (pageItems || []).map((item) => {
        return item.id;
      }),
    ];
    DrumTracking.session.mediaLinks.setMediaLinkIframes(mediaLinkIframes);
  };
  DrumTracking.session.initImpressionsData = () => {
    const impressionItemsFromSessionStorage = sessionStorage.getItem(
      DrumTracking.session.DRUM_SESSION_IMPRESSION_ITEMS_KEY
    );
    if (impressionItemsFromSessionStorage) {
      DrumTracking.session.impressionsTracking.setAllImpressionItems(
        JSON.parse(impressionItemsFromSessionStorage)
      );
    }
  };
  DrumTracking.session.initSessionIdAKALinkPageViewId = () => {
    let savedSessionId = DrumTracking.session.getSavedSessionId();
    const wasRedirected = DrumTracking.session.getIsRedirectedSessionKey(); //It means we called onBeforeUnload, but it was not page close, but rather redirect

    if (savedSessionId) {
      DrumTracking.session.currentSessionId = savedSessionId;
      if (wasRedirected) {
        DrumTracking.session.deleteIsRedirectedSessionKey();
        DrumTracking.trackPageReopened();
      } else {
        DrumTracking.trackPageOpened();
      }
    } else {
      savedSessionId = generateSavedSessionId();
      DrumTracking.trackPageViewInitiated(savedSessionId);
    }

    const pageRefCount = drumCookies.getRefCountCookie();
    log('>>', 'pageRefCount', pageRefCount);
    DrumTracking.session.deleteLinkPageDidCloseSessionAtUrlCookie();
  };
  DrumTracking.session.initSessionData = () => {
    DrumTracking.session.initMediaLinks();
    DrumTracking.session.initSessionIdAKALinkPageViewId();
    DrumTracking.session.initImpressionsData();
  };
  DrumTracking.session.mediaLinks.findElementAmongIframes = (id) => {
    return DrumTracking.session.mediaLinks.mediaLinkIframes.find((iframeId) => iframeId === id);
  };
  DrumTracking.session.mediaLinks.logActiveMediaLinkIframePolling = false;
  DrumTracking.session.mediaLinks.turnOnLoggingActiveMediaLinkPolling = () => {
    DrumTracking.session.mediaLinks.logActiveMediaLinkIframePolling = true;
  };
  DrumTracking.session.mediaLinks.turnOffLoggingActiveMediaLinkPolling = () => {
    DrumTracking.session.mediaLinks.logActiveMediaLinkIframePolling = false;
  };
  DrumTracking.session.mediaLinks.toggleLoggingActiveMediaLinkPolling = () => {
    DrumTracking.session.mediaLinks.logActiveMediaLinkIframePolling =
      !DrumTracking.session.mediaLinks.logActiveMediaLinkIframePolling;
  };
  DrumTracking.session.mediaLinks.startPollingActiveMediaLinkIframeElement = () => {
    let prevActiveElement = null;
    let mediaLinkItemOfPrevActiveElement = null;

    const getIdFromMediaLinkItem = (mediaLinkItem) => {
      if (!mediaLinkItem) {
        return;
      }
      return mediaLinkItem.dataset.id;
    };

    clearInterval(DrumTracking.session.pollingActiveMediaLinkIframeElementIntervalId);
    DrumTracking.session.pollingActiveMediaLinkIframeElementIntervalId = setInterval(() => {
      const { type } = (document as any)?.activeElement?.dataset || {};

      const activeElementDidChange = prevActiveElement !== (document as any)?.activeElement;

      if (type !== 'media-frame') {
        prevActiveElement = null;
        mediaLinkItemOfPrevActiveElement = null;
        return;
      }

      const mediaLinkItemOfAnActiveElement = (document as any)?.activeElement;
      if (
        mediaLinkItemOfAnActiveElement &&
        activeElementDidChange &&
        mediaLinkItemOfPrevActiveElement
      ) {
        /**
         * DF: [media_links_click_tracking_comment]
         *
         * This is where the continuous clicks between the iframes are tracked.
         *
         * The other place with this mark is where the first click into the iframe is tracked..
         */
        const activeElementCardId = getIdFromMediaLinkItem(mediaLinkItemOfAnActiveElement);
        DrumTracking.trackClick({
          cardId: activeElementCardId,
          linkId: activeElementCardId,
          cardType: isOnCollectionPage
            ? DrumTracking.LP_CARD_TYPES.nestedCustomLink
            : DrumTracking.LP_CARD_TYPES.customLink,
          cardSubType: DrumTracking.LP_CARD_CUSTOM_LINK_SUBTYPES.mediaLink,
        });
      }
      prevActiveElement = (document as any)?.activeElement;
      mediaLinkItemOfPrevActiveElement = mediaLinkItemOfAnActiveElement;
    }, DrumTracking.session.mediaLinks.MEDIA_LINKS_QUERY_INTERVAL);
  };
  DrumTracking.session.registerUnloadEventListener = () => {
    log('DrumTracking.session.registerUnloadEventListener');
    (window as any)?.addEventListener('beforeunload', DrumTracking.onBeforeUnload());
  };
  DrumTracking.session.startSession = () => {
    // log('DrumTracking.session.startSession');
    DrumTracking.session.initSessionData();
    DrumTracking.session.startIntervals();
    DrumTracking.session.fillImpressionsArray();
    DrumTracking.session.mediaLinks.startPollingActiveMediaLinkIframeElement();
    DrumTracking.session.registerUnloadEventListener();
    DrumTracking.session.sendSocialImpressions(socials || []);

    setTimeout(() => {
      if ((window as any)?.analytics?.user?.()?.id?.() !== userId) {
        (window as any)?.analytics?.identify?.(userId);
      }
    });

    log(`Link Page Session Started: ${DrumTracking.session.currentSessionId}`);
  };

  DrumTracking.getCardTypeLabel = (item: Link | OfferDlp) => {
    if (item.type === LinkTypeEnum.Collection) {
      return 'Drum Collection';
    }

    if (item.type === LinkTypeEnum.VideoProduct) {
      return 'Video Product';
    }

    if (item.type === LinkTypeEnum.DigitalProduct) {
      return 'Digital Product';
    }

    if (item.type === LinkTypeEnum.PhysicalProduct) {
      return 'Physical Product';
    }

    if (item.type === LinkTypeEnum.Offer) {
      return 'Drum Link';
    }
    return 'Custom';
  };

  DrumTracking.session.sendImpressionsAnalytics = (filteredItems) => {
    const { isCollectionCardType, isOfferCardType } = DrumTracking.cardTypeAssertions;
    const itemsData = filteredItems.map((item) => {
      const type = item.collection
        ? DrumTracking.LP_CARD_TYPES.collection
        : item.offer
          ? DrumTracking.LP_CARD_TYPES.offer
          : DrumTracking.LP_CARD_TYPES.customLink;
      const cardId = isCollectionCardType(type)
        ? item.collection.id
        : isOfferCardType(type)
          ? item.offer.id
          : item.id;
      return {
        LinkType: DrumTracking.getCardTypeLabel(item),
        LinkID: item.id || '',
        CardID: cardId || '',
        LinkTitle: item.name || '',
        LinkURL: getLandingURL(item, username),
        itemType: item.type || 'social',
      };
    });

    const data = {
      applicationSource: 'DLP',
      source: collectionId ? 'collection' : 'linkpage',
      drummerId: userId,
      username: username,
      items: itemsData || [],
    };

    sendImpressionsAnalytics(data);
  };
  DrumTracking.getSocialsInput = () => {
    if (!socials) {
      return [];
    }
    return socials;
  };
  DrumTracking.session.sendImpressions = () => {
    const { impressionsTracking } = DrumTracking.session;
    if (impressionsTracking.getSettingImpressionsLock()) return;
    if (impressionsTracking.getNewImpressionItems().length) {
      const filteredItems = [
        ...(pageItems || []).filter((item) =>
          impressionsTracking.getNewImpressionItems().find((impItem) => impItem.id === item.id)
        ),
        ...DrumTracking.getSocialsInput().filter(
          (
            item //Remove later
          ) => impressionsTracking.getNewImpressionItems().find((impItem) => impItem.id === item.id)
        ),
      ];

      const impressionItems = impressionsTracking.getNewImpressionItems().map((item) => {
        const cardType = DrumTracking.getCardType(item);
        const cardId = DrumTracking.getCardId(item);
        const linkId = DrumTracking.getLinkId(item);
        const cardSubType = DrumTracking.getCardSubType(item) || null;
        return {
          linkId: linkId,
          cardType: cardType,
          cardSubType: cardSubType,
          cardId: cardId,
          social: item.social,
          socialLink: item.socialLink,
          ...(!isOnCollectionPage
            ? {}
            : {
              collectionLinkId,
              collectionId,
            }),
        };
      });
      DrumTracking.trackImpression(impressionItems);
      DrumTracking.session.sendImpressionsAnalytics(filteredItems);

      sessionStorage.setItem(
        DrumTracking.session.DRUM_SESSION_IMPRESSION_ITEMS_KEY,
        JSON.stringify(impressionsTracking.getAllImpressionItems())
      );
      log('Sending Impressions Array: ', impressionItems);
      impressionsTracking.setNewImpressionItems([]);
    }
  };
  DrumTracking.session.startImpressionsSendInterval = () => {
    // log('DrumTracking.session.startImpressionsSendInterval');

    clearInterval(DrumTracking.session.impressionsRequestIntervalId);

    DrumTracking.session.impressionsRequestIntervalId = setInterval(
      () => DrumTracking.session.sendImpressions(),
      DrumTracking.session.IMPRESSION_REQUEST_INTERVAL
    );
  };
  DrumTracking.session.startIntervals = () => {
    DrumTracking.session.startImpressionsSendInterval();
  };

  DrumTracking.session.clearImpressionsInterval = () => {
    clearInterval(DrumTracking.session.impressionsRequestIntervalId);
  };
  DrumTracking.session.fillImpressionsArray = (items: any[]) => {
    if (!items?.length) return;
    const { impressionsTracking } = DrumTracking.session;
    impressionsTracking.setSettingImpressionsLock(true);

    const _newItems =
      items.filter((item: Link) => {
        return (
          impressionsTracking.getImpressionItems().indexOf(item.id) === -1 &&
          !impressionsTracking.getNewImpressionItems().find((_item) => _item.id === item.id)
        );
      }) || [];

    const _newImpressionItems = [...impressionsTracking.getNewImpressionItems(), ..._newItems];
    impressionsTracking.setNewImpressionItems(_newImpressionItems);

    const _impressionItems = [
      ...impressionsTracking.getImpressionItems(),
      ..._newItems.map((item) => item.id),
    ];

    impressionsTracking.setImpressionItems(_impressionItems);

    impressionsTracking.setSettingImpressionsLock(false);
  };
  DrumTracking.session.getSavedSessionId = () => {
    return getSavedSessionId();
  };
  DrumTracking.session.setSavedSessionId = (val) => {
    setSavedSessionId(val);
  };
  DrumTracking.session.deleteSavedSessionId = () => {
    deleteSavedSessionId();
  };
  DrumTracking.session.setLinkPageDidCloseSessionAtUrlCookie = (val) => {
    drumCookies.set(DrumTracking.session.DRUM_LINK_PAGE_DID_CLOSE_SESSION_AT_URL_COOKIE_KEY, val, {
      'max-age': 20,
      Path: '/' + username,
    });
  };
  DrumTracking.session.getLinkPageDidCloseSessionAtUrlCookie = () => {
    return drumCookies.get(DrumTracking.session.DRUM_LINK_PAGE_DID_CLOSE_SESSION_AT_URL_COOKIE_KEY);
  };
  DrumTracking.session.deleteLinkPageDidCloseSessionAtUrlCookie = () => {
    drumCookies.del(DrumTracking.session.DRUM_LINK_PAGE_DID_CLOSE_SESSION_AT_URL_COOKIE_KEY);
  };

  DrumTracking.session.initLinkPageDidCloseSessionCookie = () => {
    DrumTracking.session.setLinkPageDidCloseSessionAtUrlCookie(encodeURI(window?.location.href));
  };

  DrumTracking.getCardType = (item: Link | OfferDlp) => {
    if (item.type === LinkTypeEnum.Collection) {
      return DrumTracking.LP_CARD_TYPES.collection;
    }

    if (item.type === LinkTypeEnum.VideoProduct) {
      return DrumTracking.LP_CARD_TYPES.videoProduct;
    }

    if (item.type === LinkTypeEnum.DigitalProduct) {
      return DrumTracking.LP_CARD_TYPES.digitalProduct;
    }

    if (item.type === LinkTypeEnum.PhysicalProduct) {
      return DrumTracking.LP_CARD_TYPES.physicalProduct;
    }

    if (item.type === LinkTypeEnum.Offer) {
      return !isOnCollectionPage
        ? DrumTracking.LP_CARD_TYPES.offer
        : DrumTracking.LP_CARD_TYPES.nestedOffer;
    }

    if (item.type === LinkTypeEnum.Subscribe) {
      return DrumTracking.LP_CARD_TYPES.subscribe
    }

    if (item.type === LinkTypeEnum.Vcard) {
      return isOnCollectionPage
        ? DrumTracking.LP_CARD_TYPES.nestedVcard
        : DrumTracking.LP_CARD_TYPES.vcard
    }

    return !isOnCollectionPage
      ? DrumTracking.LP_CARD_TYPES.customLink
      : DrumTracking.LP_CARD_TYPES.nestedCustomLink;
  };

  DrumTracking.getCardSubType = (item: any) => {
    if (item.social) {
      return DrumTracking.LP_CARD_CUSTOM_LINK_SUBTYPES.socialLink;
    }
    if (
      item.subType === LinkTypeEnum.Video ||
      item.subType === LinkTypeEnum.Audio ||
      item.type === LinkTypeEnum.Video ||
      item.type === LinkTypeEnum.Audio
    ) {
      return DrumTracking.LP_CARD_CUSTOM_LINK_SUBTYPES.mediaLink;
    }
    if (item.type === LinkTypeEnum.Page) {
      return DrumTracking.LP_CARD_CUSTOM_LINK_SUBTYPES.classicLink;
    }
    if (item.type === LinkTypeEnum.Subscribe) {
      return item?.meta?.subscribersContactType;
    }


    return null;
  };

  DrumTracking.getCardId = (item: any) => {
    if (item.type === LinkTypeEnum.Collection) {
      return item.collection.id;
    }
    if (item.type === LinkTypeEnum.Offer && isOnCollectionPage) {
      return item.id;
    }
    if (item.type === LinkTypeEnum.Offer) {
      return item.opportunity_id;
    }
    if (item.type === LinkTypeEnum.Page && isOnCollectionPage) {
      return item.link_id || item.id;
    }
    return item.id;
  };

  DrumTracking.getLinkId = (item: any) => {
    if (item.type === LinkTypeEnum.Page && isOnCollectionPage) {
      return item.link_id;
    }
    return item.id;
  };

  DrumTracking.sendClickAnalytics = (item: Link | OfferDlp) => {
    const cardId = DrumTracking.getCardId(item);
    const linkId = DrumTracking.getLinkId(item);
    const data = {
      LinkType: DrumTracking.getCardTypeLabel(item),
      LinkID: linkId || '',
      CardID: cardId || '',
      LinkTitle: item.name || '',
      LinkURL: getLandingURL(item, username),
      source: collectionId ? 'collection' : 'linkpage',
      drummerId: userId,
      username: username,
    };
    sendLinkClickAnalytics(data);
  };

  DrumTracking.sendPageLoadAnalytics = () => {
    const data = {
      applicationSource: 'DLP',
      source: collectionId ? 'collection' : 'linkpage',
      drummerId: userId,
      username: username,
    };
    (window as any)?.analytics?.track('Page Loaded', data);
  };

  DrumTracking.onItemClick = (item: any) => {
    if (item) {
      const cardType = DrumTracking.getCardType(item);
      const cardId = DrumTracking.getCardId(item);
      const linkId = DrumTracking.getLinkId(item);
      const cardSubType = DrumTracking.getCardSubType(item) || null;

      DrumTracking.trackClick({
        cardId,
        cardType,
        cardSubType,
        linkId,
      });
      log(`Clicked${item.social ? ' social ' : ' '}"${cardType}" Card: `, item);
    }
  };

  DrumTracking.onSubscriptionSubmit = (item: any) => {
    if (item) {
      const cardType = DrumTracking.getCardType(item);
      const cardId = DrumTracking.getCardId(item);
      const linkId = DrumTracking.getLinkId(item);
      const cardSubType = DrumTracking.getCardSubType(item) || null;
      DrumTracking.trackSubscriptionSuccess({
        cardId,
        cardType,
        cardSubType,
        linkId,
      });
      log(`Clicked${item.social ? ' social ' : ' '}"${cardType}" Card: `, item);
    }
  };

  DrumTracking.onSocialItemClick = (item: SocialAccount) => {
    DrumTracking.trackClick({
      cardId: item.id,
      cardType: DrumTracking.LP_CARD_TYPES.customLink,
      cardSubType: DrumTracking.getCardSubType(item),
      social: item.social,
      socialLink: item.link,
      linkId: item.id,
    });
  };
  DrumTracking.onBlur = () => {
    const initLinkPageForOnBlurHandling = () => {
      if (!isFromLinkPage) {
        return { handleIframeFocus: () => null };
      }

      /**
       * DF: This is important for window to be focused for the "blur" trick to work.
       */

      (window as any)?.focus();

      const handleIframeFocus = () => {
        /**
         * DF: [onblur_setTimeout_comment]
         *
         * According to this comment
         * https://stackoverflow.com/questions/5456239/detecting-when-an-iframe-gets-or-loses-focus#comment116098623_50864085
         * and also to my experience,
         *
         * > "Firefox seems to update the activeElement only after firing the blur event"
         *
         * that is why we need this setTimeout
         *
         * DF: [media_links_click_tracking_comment]
         *
         * This is where the first click into the iframe is tracked.
         *
         * The other place with this mark is where the continuous clicks between the iframes are tracked.
         */
        setTimeout(() => {
          const containerEl = (document as any)?.activeElement;

          if (!containerEl) {
            return;
          }

          const { id, type } = containerEl.dataset;
          if (type !== 'media-frame') {
            return;
          }

          DrumTracking.trackClick({
            cardId: id,
            cardType: isOnCollectionPage
              ? DrumTracking.LP_CARD_TYPES.nestedCustomLink
              : DrumTracking.LP_CARD_TYPES.customLink,
            cardSubType: DrumTracking.LP_CARD_CUSTOM_LINK_SUBTYPES.mediaLink,
            linkId: id,
          });
        }, 0);
      };

      return { handleIframeFocus };
    };

    const { handleIframeFocus } = initLinkPageForOnBlurHandling();

    const handlePausing = () => {
      DrumTracking.session.currentPauseId = uuid();
      DrumTracking.session.clearImpressionsInterval();
      setTimeout(() => {
        /**
         * DF: We don't want to track paused event if we are focusing on an iframe inside the page
         * UPD: But we do this anyway because
         * we cannot differentiate
         *  a blur event with an iframe activeElement
         *  *that leads to leaving the page*
         * from
         *  a blur event a blur event with an iframe activeElement
         *  *that leads to actions within the iframe within the current tab*
         */
        DrumTracking.trackPagePaused(DrumTracking.session.currentPauseId);
        log(`Link Page Session Paused: pauseId=${DrumTracking.session.currentPauseId}`);
        // }
      }, 0);
    };

    log('Blur on window');
    handlePausing();
    handleIframeFocus();
  };
  DrumTracking.onFocus = () => {
    DrumTracking.session.startIntervals();
    DrumTracking.trackPageUnpaused(DrumTracking.session.currentPauseId);
    log(`Link Page Session Resumed: pauseId=${DrumTracking.session.currentPauseId}`);
  };
  DrumTracking.onImpressionsChange = (items: any[]) => {
    DrumTracking.session.fillImpressionsArray(items);
  };
  DrumTracking.session.sendSocialImpressions = (items: any[] = []) => {
    DrumTracking.onImpressionsChange(items);
  };
  DrumTracking.onBeforeUnload = () => () => {
    // Following code means that we unloaded the page not by link redirect,
    // but rather by either reload, tab closing or browser closiing
    DrumTracking.session.sendImpressions(); //In case we got redirected before new inpressions were sent
    if (
      (document as any)?.activeElement.tagName === 'BODY' ||
      !(isOnOfferPage && (document as any)?.activeElement?.dataset?.id === 'session-closer')
    ) {
      DrumTracking.session.setIsRedirectedSessionKey(true);
    } else {
      DrumTracking.session.deleteSavedSessionId();
    }
    DrumTracking.session.closeSession();
    log('beforeunload::closeSession');
  };
};

export default DrumTracking;
