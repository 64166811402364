import React, { ReactNode } from 'react';
import { StaticRouter, StaticRouterContext } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import ScrollTop from 'components/ScrollTop';
import Routes from './pages';

interface AppProps {
  context?: StaticRouterContext;
  url?: string;
  username?: string | null;
}

interface RouterProps {
  context?: StaticRouterContext;
  url?: string;
  children?: ReactNode;
}

function Router(props: RouterProps) {
  if (props.url && props.context) {
    return (
      <StaticRouter location={props.url} context={props.context}>
        {props.children}
      </StaticRouter>
    );
  } else {
    return <BrowserRouter>{props.children}</BrowserRouter>;
  }
}

function App<AppProps>({ context, url, username}) {
  return(
    <Router url={url} context={context}>
      <ScrollTop />
      <Routes username={username} />
    </Router>
  );
}
export default App;
