import { useSSRContext } from '../context/SSRContext';
import { useHistory } from 'react-router-dom';

export enum ServerEffectActions {
  redirect = 'redirect',
}

export const useRedirection = () => {
  const {
    isOnServer,
    serverTimeContext: { serverEffects },
  } = useSSRContext();
  const history = useHistory();
  return (
    redirectionDst,
    effectSchedulerConfig = {
      overridingEffect: true,
      rewriteSameEffect: false,
    }
  ) => {
    if (isOnServer) {
      serverEffects.schedule(
        {
          action: ServerEffectActions.redirect,
          input: { dst: redirectionDst },
        },
        effectSchedulerConfig
      );
    } else {
      history.push(redirectionDst);
    }
  };
};
