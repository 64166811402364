export const drumIoWWWRedirects = {
  PRIVACY_POLICY_INNER: '/privacy',
  PRIVACY_POLICY_INNER_2: '/privacy-policy',
  TERMS_OF_SERVICE_INNER: '/terms-conditions',
};

export const drummerLinkPageImpostorRoutes = {
  PAGE_404: '/404',
  CONNECT_PAYMENT: '/connect-payment',
  ...drumIoWWWRedirects,
};

export const baseRoutes = {
  ...drummerLinkPageImpostorRoutes,
  UPGRADE_PAGE: '/upgrade/:username',
  DRUM_PAGE: 'https://www.drum.io/',
  EARN_REWARDS: 'https://www.drum.io/',
  DRUM_FOR_BUSINESS: `https://www.drum.io/business`,
  TERMS_OF_SERVICE: `https://www.drum.io/terms-conditions`,
  PRIVACY_POLICY: `https://www.drum.io/privacy-policy`,
  UPGRADE_LANDING: `https://www.drum.io/free-linktree-alternative`,
  SUPPORT: 'https://help.drum.io/hc/en-us/requests/new',
  SUBSCRIBE_PAGE: '/subscribe/:campaignId/:customerId/:linkUriComponent',
  UNSUBSCRIBE_PAGE: '/unsubscribe/:campaignId/:customerId',
  INVITE_BY_DRUMMER_PAGE: '/invite/drummer',
  REFERRAL_PAGE: '/invite/:name',
};

export const drummerLinkPageRoutes = {
  GENERAL_BASE: '/:username',
  PURCHASE_PAGE: '/:username/product/:title/:id',
  PURCHASE_PAGE_FROM_COLLECTION:
    '/:username/collection/:collectionTitle/:collectionId/product/:title/:id',
  CHECKOUT_PAGE: '/:username/checkout',
  CHECKOUT_VIDEO_SUCCESS_PAGE: '/:username/checkout/success/video/:id',
  CHECKOUT_DIGITAL_SUCCESS_PAGE: '/:username/checkout/success/digital/:id',
  CHECKOUT_PHYSICAL_SUCCESS_PAGE: '/:username/checkout/success/physical/:id',
  LINK_PAGE: '/:username',
  OFFER_PAGE: '/:username/:offerTitle/:offerId',
  OFFER_PAGE_FROM_COLLECTION:
    '/:username/collection/:collectionTitle/:offerTitle/:collectionId/:offerId',
  COLLECTION_PAGE: '/:username/collection/:collectionTitle/:collectionId',
  VIDEO_PRODUCT_PAGE: '/:username/video/:id',
  DIGITAL_PRODUCT_PAGE: '/:username/digital/:id',
};

export const routes = {
  ...baseRoutes,
  ...drummerLinkPageRoutes,
};

export const drummerLinkPageCustomDomainRoutes = {
  GENERAL_BASE: '/',
  PURCHASE_PAGE: '/product/:title/:id',
  PURCHASE_PAGE_FROM_COLLECTION:
    '/collection/:collectionTitle/:collectionId/product/:title/:id',
  CHECKOUT_PAGE: '/checkout',
  CHECKOUT_VIDEO_SUCCESS_PAGE: '/checkout/success/video/:id',
  CHECKOUT_DIGITAL_SUCCESS_PAGE: '/checkout/success/digital/:id',
  CHECKOUT_PHYSICAL_SUCCESS_PAGE: '/checkout/success/physical/:id',
  LINK_PAGE: '/',
  OFFER_PAGE: '/:offerTitle/:offerId',
  OFFER_PAGE_FROM_COLLECTION:
    '/collection/:collectionTitle/:offerTitle/:collectionId/:offerId',
  COLLECTION_PAGE: '/collection/:collectionTitle/:collectionId',
  VIDEO_PRODUCT_PAGE: '/video/:id',
  DIGITAL_PRODUCT_PAGE: '/digital/:id',
};

export const customDomainRoutes = {
  ...baseRoutes,
  ...drummerLinkPageCustomDomainRoutes,
};

export const getRoutes = (useCustomDomain: boolean | string) => {
  // console.log(
  //   'getRoutes::useCustomDomain',
  //   useCustomDomain,
  //   new Error()?.stack?.replace(/\n/g, ' <br> ')
  // );
  return useCustomDomain ? customDomainRoutes : routes;
};

export default routes;
