import React, { FC, useEffect, useState } from 'react';
import Swiper from 'components/Swiper';
import Delayed from 'components/Delayed';
import { Emphasized } from 'uikit';
import {
  Product,
  SelectedImages,
  ImageStyle,
  ImageStyleName,
  LinkStyleConfiguration,
  TemplatePreset,
} from 'graphqlQueries';
import { getImagePath } from 'helpers';
import { getMoneyFormat } from 'pages/CheckoutPage/utils';
import PrimaryButtonStyled from 'components/PrimaryButtonStyled';
import {
  Container,
  ImageContainer,
  DetailsContainer,
  ButtonsContainer,
  Title,
  Description,
  FormatContainer,
  Format,
  PriceContainer,
  Price,
  PriceBlock,
  PriceNotes,
} from '../styles';

interface IPurchaseVideoProduct {
  values: Partial<Product>;
  user: any;
  inputStyles?: Pick<
    LinkStyleConfiguration,
    'background_color' | 'color' | 'font_family'
  > | null;
  styles?: TemplatePreset;
  onSubmit: () => void;
}

const PurchaseDigitalProduct: FC<IPurchaseVideoProduct> = ({
  values,
  styles,
  onSubmit,
}) => {
  const [galleryImages, setGalleryImages] = useState<Array<string>>([]);
  const { title, description, price, images } = values;

  useEffect(() => {
    const _imgs =
      (images as SelectedImages[])?.map((item, index) => {
        return getImagePath(
          item.styles as ImageStyle[],
          ImageStyleName.Original
        );
      }) || [];
    setGalleryImages(_imgs);
  }, [images]);

  return (
    <Container>
      {!!galleryImages?.length && styles && (
        <ImageContainer>
          <Delayed>{/* we use it because swiper starts rendering when padding values are not yet received */}
            <Swiper images={galleryImages || []} />
          </Delayed>
        </ImageContainer>
      )}

      <DetailsContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </DetailsContainer>

      <FormatContainer>
        <Emphasized>Format</Emphasized>: <Format>{values?.file_format}</Format>
      </FormatContainer>

      <PriceContainer>
        <PriceBlock>
          <span>
            Price: <Price>${getMoneyFormat(price || 0)}</Price>
          </span>
        </PriceBlock>
        <PriceNotes>
          *File available for download immediatly upon payment.
        </PriceNotes>
      </PriceContainer>

      <ButtonsContainer>
        <PrimaryButtonStyled type="submit" onClick={onSubmit}>
          Check out
        </PrimaryButtonStyled>
      </ButtonsContainer>
    </Container>
  );
};

export default PurchaseDigitalProduct;
