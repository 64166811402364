import React from 'react';
import { useSSRContext } from '../context/SSRContext';
import { Redirect, Route, Switch } from 'react-router-dom';
import { getRoutes } from 'constants/routes';
import UpgradePage from 'pages/UpgradePage';
import Page404 from 'pages/Page404';
import SubscribePage from 'pages/SubscribePage';
import UnsubscribePage from 'pages/UnsubscribePage';
import PurchasePage from 'pages/PurchasePage';
import CheckoutPage from 'pages/CheckoutPage';
import PurchaseConfirmation from 'pages/CheckoutPage/components/PurchaseConfirmation';
import InviteDrummerRedirectionPage from 'pages/InviteDrummerRedirectionPage';
import ReferralPage from 'pages/ReferralPage';
import VideoProductPage from 'pages/VideoProductPage';
import DigitalProductPage from 'pages/DigitalProductPage';
import LinkPage from 'pages/LinkPage';
import OfferPage from 'pages/OfferPage';
import CollectionPage from 'pages/CollectionPage';
import { ProductTypeEnum } from 'graphqlQueries';
import ConnectPayment from './ConnectPayment';

const scriptRedirect = (to) => {
  return () => {
    global.window &&
      (global.window.location.href = to);
    return null;
  };
};

export default function Routes({ username }) {
  const routes = getRoutes(username);
  const { isOnServer } = useSSRContext();

  return (
    <Switch>

      <Route exact path={routes.PRIVACY_POLICY_INNER} component={scriptRedirect(routes.PRIVACY_POLICY)} />
      <Route exact path={routes.PRIVACY_POLICY_INNER_2} component={scriptRedirect(routes.PRIVACY_POLICY)} />
      <Route exact path={routes.TERMS_OF_SERVICE_INNER} component={scriptRedirect(routes.TERMS_OF_SERVICE)} />

      <Route exact path={routes.PAGE_404}>
        <Page404 />
      </Route>

      <Route exact path={routes.CONNECT_PAYMENT}><ConnectPayment /></Route>

      <Route exact path={routes.VIDEO_PRODUCT_PAGE}>
        <VideoProductPage />
      </Route>

      <Route exact path={routes.DIGITAL_PRODUCT_PAGE}>
        <DigitalProductPage />
      </Route>

      <Route exact path={routes.UPGRADE_PAGE}>
        <UpgradePage />
      </Route>

      <Route exact path={routes.INVITE_BY_DRUMMER_PAGE}>
        <InviteDrummerRedirectionPage />
      </Route>

      <Route exact path={routes.REFERRAL_PAGE}>
        <ReferralPage />
      </Route>

      <Route exact path={routes.PURCHASE_PAGE}>
        <PurchasePage />
      </Route>

      <Route exact path={routes.PURCHASE_PAGE_FROM_COLLECTION}>
        <PurchasePage />
      </Route>

      <Route exact path={routes.CHECKOUT_VIDEO_SUCCESS_PAGE}>
        <PurchaseConfirmation type={ProductTypeEnum.VideoProduct} />
      </Route>

      <Route exact path={routes.CHECKOUT_DIGITAL_SUCCESS_PAGE}>
        <PurchaseConfirmation type={ProductTypeEnum.DigitalProduct} />
      </Route>

      <Route exact path={routes.CHECKOUT_PHYSICAL_SUCCESS_PAGE}>
        <PurchaseConfirmation type={ProductTypeEnum.PhysicalProduct} />
      </Route>

      <Route exact path={routes.CHECKOUT_PAGE}>
        <CheckoutPage />
      </Route>

      <Route exact path={routes.LINK_PAGE}>
        <LinkPage />
      </Route>

      <Route
        exact
        path={'/'}
        component={() => {
          if (isOnServer) {
            return <Redirect to={routes.DRUM_PAGE} />;
          } else {
            global.window &&
              (global.window.location.href = routes.DRUM_PAGE);
            return null;
          }
        }}
      ></Route>

      <Route exact path={routes.OFFER_PAGE}>
        <OfferPage />
      </Route>

      <Route exact path={routes.OFFER_PAGE_FROM_COLLECTION}>
        <OfferPage />
      </Route>

      <Route exact path={routes.COLLECTION_PAGE}>
        <CollectionPage />
      </Route>

      <Route exact path={routes.SUBSCRIBE_PAGE}>
        <SubscribePage />
      </Route>

      <Route exact path={routes.UNSUBSCRIBE_PAGE}>
        <UnsubscribePage />
      </Route>

      <Redirect to={routes.PAGE_404} />
    </Switch>
  );
}
