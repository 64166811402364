import React, { FC, useEffect } from 'react';
import window from 'global';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet-async';

interface RouterProps {
  campaignId?: string;
  customerId?: string;
}

const UnsubscribePage: FC = () => {
  const { campaignId, customerId } = useParams<RouterProps>();

  useEffect(() => {
    const unsubscribeLink = `https://${
    process.env.DISTRIBUTION_DOMAIN_NAME
  }/campaign/unsub/${campaignId}/${customerId}`;
    (window as any).location = unsubscribeLink;
  }, []);

  return (
    <>
      <Helmet>
        <title>Unsubscribe</title>
      </Helmet>
    </>
  );
};

export default UnsubscribePage;
