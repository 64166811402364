import styled from 'styled-components';

import { Form as FormikForm } from 'formik';
import { FormProps } from '../../types';

const Form = styled(FormikForm)<FormProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: ${({ width }: FormProps) => (width ? width : 'auto')};
  margin: auto;
`;

export default Form;
