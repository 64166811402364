import React, {
  FC,
  ReactNode,
  CSSProperties,
  useState,
  useEffect,
} from 'react';
import SocialMediaLinks from 'components/SocialMediaLinks';
import UserInfo from 'components/UserInfo';
import UserInfoSmall from 'components/UserInfoSmall';
import Footer from './components/Footer';
import { FullScreenLoader } from 'uikit';
import actions from 'redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'redux/store';
//import { schema as mockPreset } from 'layouts/GeneralLayout/mockData/templates/Template20';
import { SocialAccount, LinkedPage } from 'graphqlQueries';
import {
  ContainerOmni,
  ContainerOuter,
  OuterContentContainer,
  Container,
  InnerHeaderContainer,
  ContainerInner,
  LoaderContainer,
  Header,
  HeaderInner,
  Content,
  ChildrenContainer,
} from './styles';

interface Props {
  metaData?: Partial<LinkedPage> | null;
  title?: string;
  subTitle?: string;
  description?: string;
  socials?: Partial<SocialAccount>[];
  onSocialClick?: (item: SocialAccount) => void;
  children: ReactNode;
  backgroundImage?: string | null;
  backgroundColor?: string | null;
  textColor?: string | null;
  font?: string | null;
  headerContent?: ReactNode;
  outerContent?: ReactNode;
  innerStyles?: CSSProperties;
  footerBackgroundColor?: string | null;
  footerTextColor?: string | null;
  showLoader?: boolean;
  showFooter?: boolean;
  usePageStyles?: boolean; //Whether we should use page bg's or the ones for lists (relevant for template15, template17)
  useCollectionStyles?: boolean;
}

const GeneralLayout: FC<Props> = ({
  metaData,
  title = '',
  subTitle = '',
  description = '',
  socials,
  onSocialClick,
  children,
  backgroundImage,
  backgroundColor,
  textColor,
  headerContent,
  outerContent,
  innerStyles,
  footerTextColor,
  footerBackgroundColor,
  showLoader,
  showFooter = true,
  usePageStyles = true,
  useCollectionStyles = false,
}) => {
  const dispatch = useDispatch();
  const templateStyles = useSelector(
    (state: RootState) => state.userMetaData.root?.template_preset
  );

  const [hideLoader, setHideLoader] = useState(!showLoader);

  useEffect(() => {
    setTimeout(() => {
      setHideLoader(!showLoader);
    }, 600);
  }, [showLoader]);

  useEffect(() => {
    if (metaData) {
      const _alteredMeta = JSON.parse(JSON.stringify(metaData));
      //_alteredMeta.template_preset = mockPreset; //REMOVE LATER!!
      dispatch(actions.userMetaData.setUserMetaData(_alteredMeta));
    }
    // eslint-disable-next-line
  }, [metaData]);

  return (
    <ContainerOmni>
      <OuterContentContainer styles={templateStyles}>
        {outerContent}
      </OuterContentContainer>
      <ContainerOuter
        styles={templateStyles}
        style={{ backgroundImage: backgroundImage || '' }}
        usePageStyles={usePageStyles}
        useCollectionStyles={useCollectionStyles}
      >
        <Container usePageStyles={usePageStyles} styles={templateStyles}>
          {templateStyles?.imageField === 'header' &&
            usePageStyles &&
            templateStyles?.headerContainer?.innerPagesTop && (
              <InnerHeaderContainer
                styles={templateStyles}
              ></InnerHeaderContainer>
            )}

          <LoaderContainer
            className={`${!showLoader ? 'hidden' : ''} ${hideLoader ? 'no-display' : ''
              }`}
          >
            <FullScreenLoader />
          </LoaderContainer>
          <ContainerInner
            usePageStyles={usePageStyles}
            useCollectionStyles={useCollectionStyles}
            styles={templateStyles}
            style={innerStyles}
          >
            {!usePageStyles && (
              <Header styles={templateStyles}>
                <HeaderInner styles={templateStyles}>
                  {headerContent}
                  {templateStyles?.headerContainer?.position === 'header' && (
                    <UserInfo
                      user={metaData?.user}
                      title={title || metaData?.title || ''}
                      description={description}
                      socials={socials}
                      onSocialClick={onSocialClick}
                      styles={templateStyles}
                    />
                  )}
                </HeaderInner>
              </Header>
            )}
            <Content styles={templateStyles}>
              {templateStyles?.headerContainer?.position === 'content' &&
                !usePageStyles && (
                  <UserInfo
                    user={metaData?.user}
                    title={title || metaData?.title || ''}
                    description={description}
                    socials={socials}
                    onSocialClick={onSocialClick}
                    styles={templateStyles}
                  />
                )}

              {usePageStyles && (
                <UserInfoSmall
                  title={title || metaData?.title || ''}
                  user={metaData?.user}
                  subTitle={subTitle}
                  description={description}
                  socials={socials}
                  onSocialClick={onSocialClick}
                  styles={templateStyles}
                />
              )}
              {templateStyles?.socialsContainer?.position === 'top' &&
                !!socials?.length && (
                  <SocialMediaLinks
                    items={socials}
                    onItemClick={onSocialClick}
                  />
                )}
              <ChildrenContainer
                styles={templateStyles}
                usePageStyles={usePageStyles}
                useCollectionStyles={useCollectionStyles}
              >
                {children}
              </ChildrenContainer>
              {showFooter && (
                <Footer
                  textColor={footerTextColor || textColor}
                  backgroundColor={footerBackgroundColor || backgroundColor}
                  styles={templateStyles}
                />
              )}
            </Content>
          </ContainerInner>
        </Container>
      </ContainerOuter>
    </ContainerOmni>
  );
};

export default GeneralLayout;
