import React, { FC } from 'react';
import NumberFormat from 'react-number-format';
import { InputBase } from 'uikit';

interface Props {
  name: string;
  onChange: (value: string) => void;
  value: string;
}

const limit = (val, max) => {
  if (val.length === 1 && val[0] > max[0]) {
    val = '0' + val;
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      val = '01';

    //this can happen when user paste number
  } else if (val > max) {
      val = max;
    }
  }

  return val;
}

const yearParse = (val) => {
  if (val.length === 3) {
    val = val.substring(0, 2);
  }
  if (val.length > 3) {
    val = val.substring(2, 4);
  }
  return val;
}

const cardExpiry = (val) => {
  const month = limit(val.substring(0, 2), '12');
  //let year = val.length > 4 ? val.substring(4, 6) : val.substring(2, 4);
  const year = yearParse(val.substring(2, 6));

  return month + (year.length ? '/' + year : '');
}

const CardExpirationInput: FC<Props> = ({ onChange, value, name }) => (
  <NumberFormat
    name={name}
    onValueChange={({ value }) => onChange(value)}
    value={value}
    format={cardExpiry}
    customInput={InputBase}
  />
);

export default CardExpirationInput;
