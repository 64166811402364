
import { parse as urlParse, format as urlFormat } from 'url';
import * as queryString from 'querystring';

export const getStrippedId = (id) => {
  if (!id) return null;
  id = id.replace(/^BUSINESS_/, '');
  id = id.replace(/^DRUMMER_/, '');
  id = id.replace(/^BUYER_/, '');
  id = id.replace(/^OFFER_/, '');
  return id;
};

export const makeNewParamsForDestinationUrl = ({
  urlDestination,
  drummer,
  business,
  offer,
  collectionId = null,
  isFromLinkPage,
  isFromCollectionFromLinksPage,
  linkId,
  linkPageViewId,
}: any) => {
  const { isPotentialDrummer } = drummer;
  const drummerId =
    (drummer &&
      (!isPotentialDrummer ? getStrippedId(drummer.pk) : [drummer.id, 'refp'].join('~'))) ||
    '';
  const offerInfoParams = {
    d: drummerId,
    o: getStrippedId(offer.pk) || '',
    bz: (business && getStrippedId(business.pk)) || '',
    pc: offer.promoCode || '',
    p: 'website',
    _cfg: `rp${Number(Boolean(isPotentialDrummer))}`,
    lp_cid: collectionId || '',
    lp_islp: isFromLinkPage || isFromCollectionFromLinksPage || '',
    lp_lid: linkId || '',
    lp_vid: linkPageViewId || '',
  };
  // DRUMG-340: Detecting Refersion url
  const destinationParams = queryString.parse(urlParse(urlDestination).query || "");
  const { rfsn } = destinationParams;
  const rfsnParams = !rfsn ? {} : { subid: drummerId };
  // DRUMG-428: Affiliate networks integration / add input to select affiliate network
  const { drummerIdQueryParamName } = offer;
  const drummerIdCustomQueryParam = !drummerIdQueryParamName
    ? {}
    : {
      [drummerIdQueryParamName]: drummerId,
    };
  return {
    ...offerInfoParams,
    ...rfsnParams,
    ...drummerIdCustomQueryParam,
  };
};

export const generateURLOnlineOffer = (newDestinationUrlParams, urlDestination) => {
  if (!urlDestination) return '';
  const { protocol, hostname, pathname, query } = urlParse(urlDestination);
  const destinationUrlWithParams = urlFormat({
    protocol,
    hostname,
    pathname,
    query: {
      ...queryString.parse(query || ""),
      ...newDestinationUrlParams,
    },
  });
  //Doing this crap, because f*cking lib skips protocol and puts 3 slashes for some reason if it's specified
  //const url = `${encodeURIComponent((protocol ? '' : 'http://') + destinationUrlWithParams)}`;
  const url = `${(protocol ? '' : 'http://') + destinationUrlWithParams}`;
  //return `${process.env.REACT_APP_DLP_URL}/online-offer?url=${url}`;
  return url;
};