import React, { FC, ReactNode, CSSProperties, useState, useEffect } from 'react';
import { Container, ContainerInner, LoaderContainer } from './styles';
import { FullScreenLoader } from 'uikit';
import Footer from './components/Footer';

interface Props {
  children: ReactNode;
  backgroundImage?: string | null;
  backgroundColor?: string | null;
  textColor?: string | null;
  font?: string | null;
  headerContent?: ReactNode;
  innerStyles?: CSSProperties;
  footerBackgroundColor?: string | null;
  footerTextColor?: string | null;
  showLoader?: boolean;
  showFooter?: boolean;
}

const UpgradeLayout: FC<Props> = ({
  children,
  backgroundImage,
  backgroundColor,
  textColor,
  font,
  headerContent,
  innerStyles,
  footerTextColor,
  footerBackgroundColor,
  showLoader,
  showFooter = true,
}) => {
  const [hideLoader, setHideLoader] = useState(!showLoader);

  useEffect(() => {
    setTimeout(() => {
      setHideLoader(!showLoader);
    }, 600)
  }, [showLoader])

  return (
    <Container backgroundImage={backgroundImage} font={font || ''}>
      <LoaderContainer className={ `${!showLoader ? 'hidden' : ''} ${hideLoader ? 'no-display' : ''}`}>
        <FullScreenLoader />
      </LoaderContainer>  
      {headerContent}
      <ContainerInner
        textColor={textColor}
        backgroundColor={backgroundColor}
        style={innerStyles}
      >
        
        {children}
        {showFooter && <Footer
          textColor={footerTextColor || textColor}
          backgroundColor={footerBackgroundColor || backgroundColor}
        />}
      </ContainerInner>
    </Container>
  )
};

export default UpgradeLayout;
