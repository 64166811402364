import React, { FC, ReactNode } from 'react';
import { ReturnRouterLink, ReturnExternalLink } from './styles';
import { LocationDescriptor, Location } from 'react-router-dom/Link';
import { ChevronLeft } from 'assets/svg';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

interface Props {
  to?:
    | LocationDescriptor<unknown>
    | ((location: Location<unknown>) => LocationDescriptor<unknown>);
  href?: string;
  onClick?: () => void;
  children?: ReactNode;
}

const ReturnLink: FC<Props> = ({
  to,
  href,
  onClick,
  children = (
    <>
      <ChevronLeft />
      Back
    </>
  ),
}) => {
  const styles = useSelector(
    (state: RootState) =>
      state.userMetaData.root?.template_preset
  );
  
  return (
    <>
      {to ? (
        <ReturnRouterLink
          to={to}
          onClick={onClick}
          styles={styles}
        >
          {children}
        </ReturnRouterLink>
      ) : (
        <ReturnExternalLink
          href={href || ''}
          onClick={onClick}
          styles={styles}
        >
          {children}
        </ReturnExternalLink>
      )}
    </>
  );
};

export default ReturnLink;
