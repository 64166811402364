import React, { FC, useState, useEffect } from 'react';
import InnerHTML from 'dangerously-set-html-content';
import { useParams } from 'react-router-dom';
import WideFooter from 'components/WideFooter';
import EmailForm from './components/EmailForm';
import { isIOS, isAndroid} from 'helpers/platformDetector';
import { ImageStyle } from 'graphqlQueries';
import { getImagePath } from 'helpers';
import QrCode from './components/QrCode';
import IOSSuccess from './components/IOSSuccess';
import AndroidSuccess from './components/AndroidSuccess';
import { SchemaType as EmailFormSchemaType } from './components/EmailForm/validationSchema';
import { getRoutes } from 'constants/routes';
import { useRedirection } from 'utils/serverEffectActions';
import makeFullNameAndUserAvatarSubstitute from 'utils/makeFullNameAndUserAvatarSubstitute';
import { FullScreenLoader, Modal } from 'uikit';
import MetaData from 'components/MetaData/MetaData';
import {
  useGetInvitePageInfoQuery,
  useSubmitDrummerInvitationMutation,
  SubmitDrummerInvitationMutation,
  Drummer,
} from 'graphqlQueries';
import { 
  Container,
  ContainerOuter,
  ContainerInner,
  Content,
} from './styles';

interface IParamTypes {
  name: string;
}

const ReferralPage: FC = () => {
  const redirectTo = useRedirection();
  const params = useParams<IParamTypes>();
  const routes = getRoutes(false);

  const [emailSubmitLoading, setEmailSubmitLoading] = useState<boolean>(false);
  const [shouldValidateEmailOnChange, setShouldValidateEmailOnChange] = useState<boolean>(false);
  const [isLinkModalOpen, setIsLinkModalOpen] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string | undefined>(undefined);
  const [lastEmail, setLastEmail] = useState<string | undefined>(undefined);
  const [username, setUsername] = useState<string>('');
  const [branchLink, setBranchLink] = useState<string>('');

  const { data: invitationData, loading: invitationDataLoading } =
    useGetInvitePageInfoQuery({
      variables: {
        input: {
          username: params.name as string || ''
        },
      },
      onError: () => {
        redirectTo(routes.PAGE_404);
      },
    });

  const [submitDrummerInvitation, { loading: submitDrummerInvitationLoading }] =
  useSubmitDrummerInvitationMutation({
      onCompleted: async (data: SubmitDrummerInvitationMutation) => {
        setBranchLink(data?.submitDrummerInvitation?.branchLink || '');
        setIsLinkModalOpen(true);
        (window as any)?.analytics?.identify?.(invitationData?.dlpInvitePageInfo?.drummer?.id, {
          referrerId: invitationData?.dlpInvitePageInfo?.drummer?.id,
          referrer: invitationData?.dlpInvitePageInfo?.drummer?.username,
          email: lastEmail || '',
          source: "DLP"
        });
      },
      onError: (err) => {
        setEmailError((err?.graphQLErrors?.[0] as any)?.displayMessage || 'Some error occured');
        (window as any)?.analytics?.track('Referral Page: Submit failed', {
          drummerId: invitationData?.dlpInvitePageInfo?.drummer?.id,
          drummerUsername: invitationData?.dlpInvitePageInfo?.drummer?.username,
          email: lastEmail || '',
          error: (err?.graphQLErrors?.[0] as any)?.displayMessage,
        });
      }
    }); 
    
  const closePhoneModal = () => {
    setIsLinkModalOpen(false);
  }  

  useEffect(() => {
    if(invitationData?.dlpInvitePageInfo) {
      const formattedData = makeFullNameAndUserAvatarSubstitute(invitationData?.dlpInvitePageInfo?.drummer as Drummer);
      setUsername(formattedData?.fullName);
      (window as any)?.analytics?.track('Referral Page: Page Loaded', {
        drummerId: invitationData?.dlpInvitePageInfo?.drummer?.id,
        drummerUsername: invitationData?.dlpInvitePageInfo?.drummer?.username,
      });
    }
  }, [invitationData]) 
  
  const onEmailSubmit = (
    values: EmailFormSchemaType,
    { setSubmitting, validateForm }: any
  ) => {
    setSubmitting(true);
    setEmailSubmitLoading(true);
    setLastEmail(values?.email);

    validateForm(values).then((errors) => {
      const errorList = !!Object.values(errors)?.length;
      if (!errorList) {
        submitDrummerInvitation({
          variables: {
            input: {
              invitedByDrummerUsername: invitationData?.dlpInvitePageInfo?.drummer?.username || '',
              invitedEmail: values?.email?.toLowerCase() || ''
            }
          }
        })
      }
      setSubmitting(false);
      setEmailSubmitLoading(false);
      setShouldValidateEmailOnChange(true);
    });
  };

  const helmetMeta = React.useMemo(() => {
    const title = 'Check out Drum! It’s a free link in bio page designer.';
    const metaImage = `${process.env.REACT_APP_DOMAIN_ASSET}/assets/images/referralPage/drum-landing-600.jpg`;
    const avatarImage = getImagePath(invitationData?.dlpInvitePageInfo?.drummer?.avatarImage?.styles as ImageStyle[] ||
    invitationData?.dlpInvitePageInfo?.drummer?.avatarImage?.url ||
    '');

    return {
      offerPhotoThumb: metaImage,
      avatarImage,
      avatarImageOriginal: metaImage,
      avatarSubstituteLink: metaImage,
      title,
      metaTitle: title,
      metaDescription: 'Build a mobile friendly page with all your links, embedded video, and audio. Even create your own store. All for FREE.'
    };
  }, [invitationData]);

  return (
    <>
      <MetaData {...helmetMeta} />
      <Container>
        {invitationDataLoading ?
          <FullScreenLoader />
          :
          <>
            {(emailSubmitLoading || submitDrummerInvitationLoading) && <FullScreenLoader />}
            <InnerHTML html={`<script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=12f6ba5d-2c6e-45a2-a6bf-e8a021778551"> </script>`} />
            <ContainerOuter>
              <ContainerInner>
                <Content> 
                  <EmailForm onChange={() => setEmailError(undefined)} errorMessage={emailError} onSubmit={onEmailSubmit} shouldValidateOnChange={shouldValidateEmailOnChange} username={username}/>
                </Content>   
              </ContainerInner>
            </ContainerOuter>
            <WideFooter />

            <Modal
              isOpen={isLinkModalOpen}
              onClose={closePhoneModal}
              title={isIOS() || isAndroid() ? 'Download Drum' : 'Welcome to Drum!'}
            >
              <>
                {isIOS() && <IOSSuccess link={branchLink} />}
                {isAndroid() && <AndroidSuccess link={branchLink} />}
                {(!isIOS() && !isAndroid()) && <QrCode link={branchLink} />}
              </>
            </Modal>
          </>
        }
        
      </Container>
    </>
  );
};

export default ReferralPage;
