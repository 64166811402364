import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  font-family: inherit;
`;

export const UserAvatarContainer = styled.div`
  width: 104px;
  height: 104px;
  border-radius: 52px;
  overflow: hidden;
  position: relative;
  margin-top: -52px;
  background: ${({ theme }) => theme.colors.dlpAvatarContainerBg};
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
`;

export const UserAvatarSubstitute = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  font-size: 42px;
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.dlpAvatarSubstituteTextColor};
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  background: ${({ theme }) => theme.colors.dlpAvatarSubstituteBg};
  box-sizing: border-box;
`;

export const UserName = styled.p`
  margin-top: 20px;
  max-width: 80%;
  height: auto;
  font-size: 18px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 0;
  text-align: center;
  word-break: break-word;
`;

export const UserDescriptionContainer = styled.div`
  max-width: 80%;
  text-align: center;
`;

export const UserDescription = styled.pre`
  
  max-width: 100%;
  height: auto;
  font-family: inherit;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: 0;
  white-space: pre-line;
  background: transparent;
  border: none;
  margin-top: 15px;
  line-height: 24px;
`;



