import React from 'react';
import { useField } from 'formik';
import { RadioFieldProp } from '../../types';
import {
  RadioContainer,
  Radio,
  RadioLabel,
  CheckMark
} from './styled';
import { useEffect } from 'react';

const RadioButton: React.FC<RadioFieldProp> = ({
  name,
  label,
  id,
  className,
  labelClass,
  disabled,
  children,
  onChange,
  onClick
}) => {
  const [field] = useField<string>(name);

  const inputProps = {
    ...field
  };

  const checkedClass = field.value?.toString() === id ? 'checked' : '';

  useEffect(() => {
    onChange?.(field);
    // eslint-disable-next-line
  }, [field])

  return (
    <RadioContainer onClick={onClick} className={`${className} ${checkedClass}`}>
      <Radio disabled={disabled} {...inputProps} className={className} id={id} type="radio" value={id} />
      <CheckMark className={checkedClass}/>
      {label && <RadioLabel className={`${labelClass} ${checkedClass}`} htmlFor={id}>{label}</RadioLabel>}
      {children && <RadioLabel className={checkedClass} htmlFor={id}>{children}</RadioLabel>}
    </RadioContainer>
  );
};

export default RadioButton;
